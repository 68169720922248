/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useRef } from 'react'
import { Link } from 'react-router-dom'
import TimeComp from '../../../../components/dateComponent/TimeComp'
import { constraintConfig } from '../../../../constraintConfig'
import { selectAuth } from '../../../../features/authSlice'
import { useAppSelector } from '../../../../hooks'
import { toAbsoluteUrl } from '../../../helpers'
import { LoadingComponent } from '../../../../components/loadingComponent/LoadingComponent'
import NoRecordFound from '../../../../components/noRecord/NoRecordFound'

type Props = {
  notifications: any
  notiCountRefetch: any
  isLoading: any
}
const HeaderNotificationsMenu = ({ notifications, notiCountRefetch, isLoading }: Props) => {
  const { roleID } = useAppSelector(selectAuth);
  const menuRef = useRef<HTMLDivElement>(null);

  const closeMenu = () => {
    if (menuRef.current) {
      menuRef.current.setAttribute('data-kt-menu-dismiss', 'true');
    }
    notiCountRefetch()
  };
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true' ref={menuRef}
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')` }}
      >
        <h3 className='text-white fw-semibold px-9 mt-10 mb-6'>
          Notifications
          {/* <span className='fs-8 opacity-75 ps-3'>24 reports</span> */}
        </h3>

        <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-semibold px-9'>
          <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_1'
            >
              Alerts
            </a>
          </li>

          {/* <li className='nav-item'>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4 '
            data-bs-toggle='tab'
            href='#kt_topbar_notifications_2'
          >
            Updates
          </a>
        </li> */}
          {roleID === constraintConfig.roleID.role4 &&
            <li className='nav-item'>
              <a
                className='nav-link text-white opacity-75 opacity-state-100 pb-4'
                data-bs-toggle='tab'
                href='#kt_topbar_notifications_3'
              >
                Team Notifications
              </a>
            </li>
          }
        </ul>
      </div>

      <div className='tab-content'>
        <div className='tab-pane fade show active mh-300px overflow-auto' id='kt_topbar_notifications_1' role='tabpanel'>
          {notifications?.result?.notificationVM.length > 0 ?
            <>
              {roleID === constraintConfig.roleID.role4 &&
                <>
                  {Array.isArray(notifications?.result?.notificationVM) ? (notifications?.result?.notificationVM?.map((alert: any, index: any) => (
                    <div key={index} className={`scroll-y mh-325px my-1 px-8 ${alert?.active === true ? 'bg-light-primary ' : ''}`} onClick={closeMenu}>
                      <Link to={`/approvers/${alert?.expense?.expenseId}/${alert?.createdDate}`}>
                        <div className='d-flex flex-stack py-4'>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-35px me-4'>
                              <span className={clsx('symbol-label', `bg-light-${alert?.state}`)}>
                                {' '}
                                <i className='fa fa-bell fs-3'></i>
                              </span>
                            </div>

                            <div className='mb-0 me-2'>
                              <span className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                                {alert?.expense?.expenseTitle}
                              </span>
                              <div className='text-gray-400 fs-7'>Expense
                                {/* {alert?.expenseStatus?.value} */}
                                {alert?.expenseStatus?.id === constraintConfig.expenseStatus?.drafted ?
                                  <span className="badge badge-light-primary fs-7 fw-semibold ms-2">{alert?.expenseStatus?.value}</span> :
                                  alert?.expenseStatus?.id === constraintConfig.expenseStatus?.pendingForApproval ?
                                    <span className="badge badge-light-info fs-7 fw-semibold ms-2">{alert?.expenseStatus?.value}</span> :
                                    // alert?.expenseStatus?.id === constraintConfig.expenseStatus?.clarification ?
                                    //     <span className="badge badge-light-warning fs-7 fw-semibold ms-2">{labelKey.clarification}</span> :
                                    alert?.expenseStatus?.id === constraintConfig.expenseStatus?.approved ?
                                      <span className="badge badge-light-success fs-7 fw-semibold ms-2">{alert?.expenseStatus?.value}</span> :
                                      alert?.expenseStatus?.id === constraintConfig.expenseStatus?.rejected ?
                                        <span className="badge badge-light-danger fs-7 fw-semibold ms-2">{alert?.expenseStatus?.value}</span> :
                                        alert?.expenseStatus?.id === constraintConfig.expenseStatus?.apApproved ?
                                          <span className="badge badge-light-success fs-7 fw-semibold ms-2">{alert?.expenseStatus?.value}</span> :
                                          alert?.expenseStatus?.id === constraintConfig.expenseStatus?.processing ?
                                            <span className="badge badge-light-primary fs-7 fw-semibold ms-2">{alert?.expenseStatus?.value}</span> :
                                            alert?.expenseStatus?.id === constraintConfig.expenseStatus?.paid ?
                                              <span className="badge badge-light-success fs-7 fw-semibold ms-2">{alert?.expenseStatus?.value}</span> :
                                              <span className="badge badge-light-primary fs-7 fw-semibold ms-2">{alert?.expenseStatus?.value}</span>
                                }
                              </div>
                            </div>
                          </div>
                          <span className='badge badge-light fs-8'><TimeComp formattedDate={alert?.createdDate} /></span>
                        </div>

                      </Link>
                    </div>
                  ))
                  ) : <LoadingComponent />}
                </>
              }
              {roleID !== constraintConfig.roleID.role1 &&
                <>
                  {Array.isArray(notifications?.result?.notificationVM) ? (notifications?.result?.notificationVM?.map((alert: any, index: any) => (
                    <div key={index} className={`scroll-y mh-325px my-1 px-8 ${alert?.active === true ? 'bg-light-primary ' : ''}`} onClick={closeMenu}>
                      <Link to={`/expense-item-detail/${alert?.expense?.expenseId}/${alert?.createdDate}}`}>
                        <div className='d-flex flex-stack py-4'>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-35px me-4'>
                              <span className={clsx('symbol-label', `bg-light-${alert?.state}`)}>
                                {' '}
                                <i className='fa fa-bell fs-3'></i>
                              </span>
                            </div>

                            <div className='mb-0 me-2'>
                              <span className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                                {alert?.expense?.expenseTitle}
                              </span>
                              <div className='text-dark fs-7'>Expense
                                {/* {alert?.expenseStatus?.value} */}

                                {alert?.expenseStatus?.id === constraintConfig.expenseStatus?.drafted ?
                                  <span className="badge badge-light-primary fs-7 fw-semibold ms-2">{alert?.expenseStatus?.value}</span> :
                                  alert?.expenseStatus?.id === constraintConfig.expenseStatus?.pendingForApproval ?
                                    <span className="badge badge-light-info fs-7 fw-semibold ms-2">{alert?.expenseStatus?.value}</span> :
                                    // alert?.expenseStatus?.id === constraintConfig.expenseStatus?.clarification ?
                                    //     <span className="badge badge-light-warning fs-7 fw-semibold ms-2">{labelKey.clarification}</span> :
                                    alert?.expenseStatus?.id === constraintConfig.expenseStatus?.approved ?
                                      <span className="badge badge-light-success fs-7 fw-semibold ms-2">{alert?.expenseStatus?.value}</span> :
                                      alert?.expenseStatus?.id === constraintConfig.expenseStatus?.rejected ?
                                        <span className="badge badge-light-danger fs-7 fw-semibold ms-2">{alert?.expenseStatus?.value}</span> :
                                        alert?.expenseStatus?.id === constraintConfig.expenseStatus?.apApproved ?
                                          <span className="badge badge-light-success fs-7 fw-semibold ms-2">{alert?.expenseStatus?.value}</span> :
                                          alert?.expenseStatus?.id === constraintConfig.expenseStatus?.processing ?
                                            <span className="badge badge-light-primary fs-7 fw-semibold ms-2">{alert?.expenseStatus?.value}</span> :
                                            alert?.expenseStatus?.id === constraintConfig.expenseStatus?.paid ?
                                              <span className="badge badge-light-success fs-7 fw-semibold ms-2">{alert?.expenseStatus?.value}</span> :
                                              <span className="badge badge-light-primary fs-7 fw-semibold ms-2">{alert?.expenseStatus?.value}</span>
                                }

                              </div>
                            </div>
                          </div>
                          <span className='badge badge-light fs-8'><TimeComp formattedDate={alert?.createdDate} /></span>
                        </div>
                      </Link>
                    </div>
                  ))
                  ) : (
                    // Handle the case where notifications.result is not an array
                    <LoadingComponent />
                  )}
                </>
              }
            </> : <p className='text-center mt-5'>No notifications to display.</p>
          }
          {/* <div className='py-3 text-center border-top'>
            <Link
              to='/crafted/pages/profile'
              className='btn btn-color-gray-600 btn-active-color-primary'
            >
              View All <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
            </Link>
          </div> */}
        </div>

        {/* <div className='tab-pane fade' id='kt_topbar_notifications_2' role='tabpanel'>
          <div className='d-flex flex-column px-9'>
            <div className='pt-10 pb-0'>
              <h3 className='text-dark text-center fw-bold'>Get Pro Access</h3>

              <div className='text-center text-gray-600 fw-semibold pt-1'>
                Outlines keep you honest. They stoping you from amazing poorly about drive
              </div>

              <div className='text-center mt-5 mb-9'>
                <a
                  href='#'
                  className='btn btn-sm btn-primary px-6'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_upgrade_plan'
                >
                  Upgrade
                </a>
              </div>
            </div>

            <div className='text-center px-4'>
              <img
                className='mw-100 mh-200px'
                alt='metronic'
                src={toAbsoluteUrl('/media/illustrations/sketchy-1/1.png')}
              />
            </div>
          </div>
        </div> */}

        <div className='tab-pane fade' id='kt_topbar_notifications_3' role='tabpanel'>
          <div className='scroll-y mh-325px my-5 px-8'>
            {notifications?.result?.notificationVM.length > 0 ?
              <>
                {Array.isArray(notifications?.result?.notificationVM) ? (notifications?.result?.assignManagerNotifications?.map((newUser: any, index: any) => (
                  <div key={`newUser${index}`} className='d-flex flex-stack py-4 w-100'>
                    <div className='d-flex justify-content-between align-items-center me-2 w-100'>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-35px me-4'>
                          <span className={clsx('symbol-label', `bg-light-${newUser?.state}`)}>
                            {' '}
                            <i className='fa fa-bell fs-3'></i>
                          </span>
                        </div>
                        {/* <Link to={`/user-profile/expense-items/${newUser?.user?.orgUserID}`}> */}
                        <Link to={`/team-management`}>
                          <div className='text-gray-800 text-hover-primary fw-semibold'>
                            <p className='mb-0'><b className='text-capitalize'>
                              {newUser?.user?.firstName}&nbsp;
                              {newUser?.user?.middleName}&nbsp;
                              {newUser?.user?.lastName}&nbsp;</b> add in your team.</p>
                            <span>{newUser?.user?.email}</span>
                          </div>
                        </Link>
                      </div>
                      <span className='badge badge-light fs-8 ms-auto'><TimeComp formattedDate={newUser?.createdDate} /></span>
                    </div>
                  </div>
                ))
                ) : <LoadingComponent />}
              </>
              : <p className='text-center mt-5'>No notifications to display.</p>
            }
          </div>
          {/* <div className='py-3 text-center border-top'>
            <Link
              to='/crafted/pages/profile'
              className='btn btn-color-gray-600 btn-active-color-primary'
            >
              View All <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
            </Link>
          </div> */}
        </div>
      </div>
    </div >
  )
}

export { HeaderNotificationsMenu }

