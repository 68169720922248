import moment from 'moment';

export const timePeriodOptions = [
    {
        id: 'today',
        value: 'Today'
    },
    {
        id: 'yesterday',
        value: 'Yesterday'
    },
    {
        id: 'last3Days',
        value: 'Last 3 Days'
    },
    {
        id: 'last7Days',
        value: 'Last 7 Days'
    },
    {
        id: 'last30Days',
        value: 'Last 30 Days'
    },
    {
        id: 'last90Days',
        value: 'Last 90 Days'
    },
    {
        id: 'thisMonth',
        value: 'This Month'
    },
    {
        id: 'lastMonth',
        value: 'Last Month'
    },
    {
        id: 'thisQuarter',
        value: 'This Quarter'
    },
    {
        id: 'lastQuarter',
        value: 'Last Quarter'
    },
    {
        id: 'thisYear',
        value: 'This Year'
    },
    {
        id: 'lastYear',
        value: 'Last Year'
    },
]

export const getRanges = () => {
    const ranges: any = {};
    timePeriodOptions.forEach(option => {
        switch (option.id) {
            case 'today':
                ranges[option.value] = [moment(), moment()];
                break;
            case 'yesterday':
                ranges[option.value] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
                break;
            case 'last3Days':
                ranges[option.value] = [moment().subtract(3, 'days'), moment()];
                break;
            case 'last7Days':
                ranges[option.value] = [moment().subtract(6, 'days'), moment()];
                break;
            case 'last30Days':
                ranges[option.value] = [moment().subtract(29, 'days'), moment()];
                break;
            case 'last90Days':
                ranges[option.value] = [moment().subtract(89, 'days'), moment()];
                break;
            case 'thisMonth':
                ranges[option.value] = [moment().startOf('month'), moment().endOf('month')];
                break;
            case 'lastMonth':
                ranges[option.value] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
                break;
            case 'thisQuarter':
                ranges[option.value] = [moment().startOf('quarter'), moment().endOf('quarter')];
                break;
            case 'lastQuarter':
                ranges[option.value] = [
                    moment().subtract(1, 'quarter').startOf('quarter'),
                    moment().subtract(1, 'quarter').endOf('quarter')
                ];
                break;
            case 'thisYear':
                ranges[option.value] = [moment().startOf('year'), moment().endOf('year')];
                break;
            case 'lastYear':
                ranges[option.value] = [
                    moment().subtract(1, 'year').startOf('year'),
                    moment().subtract(1, 'year').endOf('year')
                ];
                break;
            default:
                break;
        }
    });
    return ranges;
};