import 'bootstrap-daterangepicker/daterangepicker.css'
import { debounce } from 'lodash'
import moment from 'moment'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { FaHistory } from 'react-icons/fa'
import { FaCheck, FaEye } from 'react-icons/fa6'
import { IoIosRepeat, IoIosWarning, IoMdClose } from 'react-icons/io'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import ReactReadMoreReadLess from "react-read-more-read-less"
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { KTSVG } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp'
import DateComp from '../../../components/dateComponent/DateComp'
import DateRangeComp from '../../../components/dateComponent/DateRangeComp'
import FilterMenu from '../../../components/filterMenu/FilterMenu'
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent'
import NoRecordFound from '../../../components/noRecord/NoRecordFound'
import Pagination from '../../../components/pagination/Pagination'
import SearchBarComponent from '../../../components/searchBar/SearchBarComponent'
import TableHeading from '../../../components/tableHeading/TableHeading'
import TableSettingMenu from '../../../components/tableHeading/TableSettingMenu'
import TextField from '../../../components/textFields/TextField'
import { constraintConfig } from '../../../constraintConfig'
import { selectAuth } from '../../../features/authSlice'
import { useAppSelector } from '../../../hooks'
import { useGetExpenseOfManagerTeamQuery, useGetExpensesOfProvidersQuery, useLazyGetExpenseItemByExpenseIdQuery } from '../../../services/ExpenseApi'
import { useGetAllExpenseTypeQuery, useGetManagerTeamLookupQuery } from '../../../services/GeneralApi'
import { API_END_POINTS } from '../../../services/apiEndpoints'
import useColumnVisibility from '../../customHooks/useColumnVisibility'
import labelKey from "../../localization/label.json"
import tosterKey from "../../localization/toster.json"
import DownloadReport from '../reportAnalytics/DownloadReport'
import UpdateAprroverStatusModal from '../workFlow/UpdateAprroverStatusModal'
import CategoriesWithIcons from './CategoriesWithIcons'
import ExpenseDetailModal from './ExpenseDetailModal'
import ExpenseDetailModalForApproval from './ExpenseDetailModalForApproval'
import ExpenseDetailWithOcrModal from './ExpenseDetailWithOcrModal'
import ExpenseItemViolationModal from './ExpenseItemViolationModal'
import ExpenseLogModal from './ExpenseLogModal'
import RefreshComponent from '../../../components/refreshComponent/RefreshComponent'

interface LightboxData {
    imageURL: string;
}
const TeamMemberExpenses = () => {
    const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'approvalQueue' });
    const location = useLocation();
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
    const { userAccountID, roleID, token, baseURL } = useAppSelector(selectAuth);
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState(' '); // debounce search
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [sortBy, setSortBy] = useState("trackingId");
    const [sortOrder, setSortOrder] = useState("desc");
    // const [filterByDate, setFilterByDate] = useState("");
    const [filterByStatus, setFilterByStatus] = useState(1);
    const [sortedColumn, setSortedColumn] = useState("trackingId");
    const [checkedBoxes, setCheckedBoxes] = useState<string[]>([]);
    const [searchUser, setSearchUser] = useState('');
    const [filterByStartDate, setFilterByStartDate] = useState("");
    const [filterByEndDate, setFilterByEndDate] = useState("");

    const [localFilterByStartDate, setLocalFilterByStartDate] = useState("");
    const [localFilterByEndDate, setLocalFilterByEndDate] = useState("");
    const [localCheckedBoxes, setLocalCheckedBoxes] = useState<string[]>([]);
    const [filterByTrackingId, setFilterByTrackingId] = useState('');
    const [localFilterByTrackingId, setLocalFilterByTrackingId] = useState('');


    const [isApplyingFilters, setIsApplyingFilters] = useState(false);

    const [showInnerTable, setShowInnerTable] = useState(null);
    const [expenseItemData, setExpenseItemData] = useState<any>(null); // Initialize with null
    const [expenseItemDataIsLoading, setExpenseItemDataIsLoading] = useState(false);
    const { data: getManagerTeamLookup } = useGetManagerTeamLookupQuery({ managerId: userAccountID });
    const { data: getAllExpenseType } = useGetAllExpenseTypeQuery('')
    const [expenseItemByIdTrigger, { data: expenseItemsData, isFetching }] = useLazyGetExpenseItemByExpenseIdQuery({})


    const handleUserChange = (e: React.ChangeEvent<HTMLInputElement>, userId: string) => {
        const checkboxId = userId;
        const isChecked = e.target.checked;

        if (isChecked) {
            setLocalCheckedBoxes([...localCheckedBoxes, checkboxId]);
        } else {
            const updatedCheckedBoxes = localCheckedBoxes.filter((id) => id !== checkboxId);
            setLocalCheckedBoxes(updatedCheckedBoxes);
        }
    };
    // Function to filter users based on search input
    const filteredUsers = getManagerTeamLookup?.result.filter(
        (user: any) =>
            user.value.toLowerCase().includes(searchUser.toLowerCase())
    );

    // Handler for user search input change
    const handleUserSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchUser(e.target.value);
    };
    const queryHook = roleID === constraintConfig.roleID.roleExecutive
        ? useGetExpensesOfProvidersQuery
        : useGetExpenseOfManagerTeamQuery;
    const { data, isFetching: isLoading, refetch, isSuccess } = queryHook({
        managerId: userAccountID,
        pageNumber: page,
        pageSize: pageSize,
        sortBy: sortBy,
        sortOrder: sortOrder,
        searchStr: searchStr,
        startDate: filterByStartDate,
        endDate: filterByEndDate,
        filterByStatusId: filterByStatus,
        filterbyUser: checkedBoxes.length > 0 ? checkedBoxes : null,
        trackingId: filterByTrackingId
    })

    // pagination
    const handlePageClick = (e: any) => {
        setPage(e.selected + 1);
    };
    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPage(1); // Reset page number to 1
    };
    const handleSort = (property: string) => {
        setSortBy(property);
        if (sortedColumn === property) {
            setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortedColumn(property);
            setSortOrder("asc");
        }
        // Perform additional sorting logic...
    };

    //per page record
    const handlePageRecords = (e: any) => {
        setPage(1); // Reset the page to 1
    };
    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state

        delayedSearch(value); // Update debounced search state
        setPage(1);
    };

    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );

    const handleDateRangeChange = (event: any, picker: any) => {
        const startDateFormatted = moment(picker.startDate).format('MM-DD-YYYY');
        const endDateFormatted = moment(picker.endDate).format('MM-DD-YYYY');

        setLocalFilterByStartDate(startDateFormatted);
        setLocalFilterByEndDate(endDateFormatted);
    };
    const handleCancelDateSelection = (event: any, picker: any) => {
        // Reset the state to empty strings
        setFilterByStartDate("");
        setFilterByEndDate("");
        setLocalFilterByStartDate("");
        setLocalFilterByEndDate("");
    };
    const handleTrackingIdSearchChange = (e: any) => {
        const { value } = e.target;
        setLocalFilterByTrackingId(value);
    };
    const applyFilters = () => {
        setIsApplyingFilters(true);
        setFilterByStartDate(localFilterByStartDate);
        setFilterByEndDate(localFilterByEndDate);
        setCheckedBoxes(localCheckedBoxes);
        setFilterByTrackingId(localFilterByTrackingId);
        setPage(1);
    };
    const resetFilters = () => {
        setFilterByStartDate('');
        setFilterByEndDate('');
        setSearchUser('');
        setCheckedBoxes([]);
        setLocalFilterByStartDate('');
        setLocalFilterByEndDate('');
        setLocalCheckedBoxes([]);
        setFilterByTrackingId('');
        setLocalFilterByTrackingId('');
    }
    const debouncedRefetch = debounce(() => {
        refetch().finally(() => {
            setIsApplyingFilters(false);
        });
    }, 300);
    useEffect(() => {
        if (isApplyingFilters) {
            debouncedRefetch();
        }
    }, [isApplyingFilters]);

    const fetchExpenseItemData = async (expenseId: any, token: any) => {
        const headers = {
            Authorization: `Bearer ${token}`, // Replace 'Bearer' with your token type if needed
            // Add any other headers you need here
        };
        const response = await fetch(`${baseURL}/api/Expense${API_END_POINTS.expenseItemById}?ExpenseId=${expenseId}`, {
            headers,
        });
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return response.json();
    };


    const handleRowClick = async (rowId: any, expenseId: any) => {
        if (showInnerTable === rowId) {
            setShowInnerTable(null);
        } else {
            setShowInnerTable(rowId);
            if (expenseId) {
                setExpenseItemDataIsLoading(true);
                try {
                    const data = await fetchExpenseItemData(expenseId, token);
                    setExpenseItemData(data);

                } catch (error) {
                    setExpenseItemData(null);
                } finally {
                    setExpenseItemDataIsLoading(false); // Set loading state to false after fetching data
                }
            }
        }
    };
    const [showExpenseDetailModal, setShowExpenseDetailModal] = useState(false)
    const [expenseDetailData, setExpenseDetailData] = useState({});
    const expenseDetailHandler = () => {
        setShowExpenseDetailModal((prev) => !prev);
    };
    const [showExpenseDetailForApprovalModal, setShowExpenseDetailForApprovalModal] = useState(false)

    const expenseDetailForApprovalHandler = () => {
        setShowExpenseDetailForApprovalModal((prev) => !prev);
    };

    const [showExpenseDetailWithOcrModal, setShowExpenseDetailWithOcrModal] = useState(false)
    const expenseDetailWithOcrHandler = () => {
        setShowExpenseDetailWithOcrModal((prev) => !prev);
    };
    const [showExpenseItemViolationsModal, setShowExpenseItemViolationsModal] = useState(false)
    const [expenseItemId, setExpenseItemId] = useState();
    const expenseItemViolationsHandler = () => {
        setShowExpenseItemViolationsModal((prev) => !prev);
    };

    const [showUpdateApproverStatusModal, setShowUpdateApproverStatusModal] = useState(false)
    const [rejectExpense, setRejectExpense] = useState('')
    const [approvedExpense, setApprovedExpense] = useState('')
    const [editData, setEditData] = useState({});
    const editApproverStatusHandler = () => {
        setShowUpdateApproverStatusModal((prev) => !prev);
    };

    const [showExpenseLogModal, setShowExpenseLogModal] = useState(false)
    const [expenseLogData, setExpenseLogData] = useState({});
    const expenseLogHandler = () => {
        setShowExpenseLogModal((prev) => !prev);
    };

    // Tab state and handler
    const [activeTab, setActiveTab] = useState(1);

    const handleTabChange = (status: number) => {
        setActiveTab(status);
        setFilterByStatus(status);
        setShowInnerTable(null);
        setPage(1)
        setPageSize(10)
    };

    const handleRefresh = () => {
        setShowInnerTable(null)
        refetch()
            .then(() => {
                if (isSuccess) {
                    toast.success(tosterKey.dataRefreshedSuccesfully);
                }
            });
    };
    useEffect(() => {
        if (searchStr === "" ||
            sortOrder === "asc" ||
            sortBy === "") {
            refetch();
        }
    }, [sortOrder, sortBy, searchStr]);

    const isPdfFile = (filePath: string | undefined) => {
        if (!filePath) return false;
        const fileExtension = filePath.toLowerCase().split('.').pop();
        return fileExtension === 'pdf';
    };
    const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
        if (attachmentFile) {
            if (isPdfFile(attachmentFile)) {
                // For PDF files, open in the browser
                window.open(attachmentFile, '_blank');
            } else {
                // For image files, open in the Lightbox
                setLightboxData({ imageURL: attachmentFile });
                setLightboxOpen(true);
            }
        }
    };
    useEffect(() => {
        // Check the current route and set the selected value accordingly
        if (location.pathname === '/approval-queue/all') {
            setFilterByStatus(0);
            setActiveTab(0);
        }
    }, [location.pathname]);

    return (
        <>
            <UpdateAprroverStatusModal
                show={showUpdateApproverStatusModal}
                handleClose={() => setShowUpdateApproverStatusModal(false)}
                data={editData}
                approvedExpense={approvedExpense}
                rejectExpense={rejectExpense}
                refetch={refetch}
                setShowInnerTable={setShowInnerTable} />
            <ExpenseDetailModal show={showExpenseDetailModal} handleClose={() => setShowExpenseDetailModal(false)} data={expenseDetailData} expenseItems={expenseItemsData?.result} itsApprover={true} />
            <ExpenseDetailModalForApproval show={showExpenseDetailForApprovalModal} handleClose={() => setShowExpenseDetailForApprovalModal(false)} data={expenseDetailData} expenseItems={expenseItemsData?.result} refetch={refetch} itsApprover={true} isFetching={isFetching} expenseItemByIdTrigger={expenseItemByIdTrigger} />

            <ExpenseLogModal show={showExpenseLogModal} handleClose={() => setShowExpenseLogModal(false)} data={expenseLogData} />
            <ExpenseDetailWithOcrModal show={showExpenseDetailWithOcrModal} handleClose={() => setShowExpenseDetailWithOcrModal(false)} data={expenseDetailData} refetch={refetch} />
            <ExpenseItemViolationModal show={showExpenseItemViolationsModal} handleClose={() => setShowExpenseItemViolationsModal(false)} data={expenseDetailData} expenseItemId={expenseItemId} refetch={refetch} setShowInnerTable={setShowInnerTable} />


            <PageTitle breadcrumbs={[]}>{labelKey.approvalQueue}</PageTitle>
            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5  align-items-center'>
                    <div>
                        <div className='btn-group'>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === 1 ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange(1)}
                            >
                                {labelKey.pending}
                            </button>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === 2 ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange(2)}
                            >
                                {labelKey.approved}
                            </button>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === 4 ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange(4)}
                            >
                                {labelKey.rejected}
                            </button>
                            {/* <button
                                type='button'
                                className={`btn btn-sm ${activeTab === 'Clarification' ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange('Clarification')}
                            >
                                {labelKey.clarification}
                            </button> */}

                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === 0 ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange(0)}
                            >
                                {labelKey.all}
                            </button>
                        </div>
                        <Tooltip id="refreshData" place="top" />
                        <RefreshComponent onClick={handleRefresh} />
                    </div>

                    <div className='d-flex align-items-center'>
                        <SearchBarComponent
                            className='me-4'
                            placeholder='Expense'
                            value={immediateSearchStr}
                            onChange={handleSearchChange}
                        />
                        <FilterMenu
                            onclickApplyFilters={applyFilters}
                            onclickResetFilters={resetFilters}
                            isApplyingFiltersLoading={isApplyingFilters}
                        >
                            <>
                                <div className='mb-5'>
                                    <DateRangeComp
                                        startDate={localFilterByStartDate || moment()}
                                        endDate={localFilterByEndDate || moment()}
                                        onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}
                                        value={localFilterByStartDate && localFilterByEndDate ? `${localFilterByStartDate} - ${localFilterByEndDate}` : "MM/DD/YYYY"} />
                                </div>
                                <div className="mb-5">
                                    <label className='form-label fs-6 fw-bold'>{labelKey.reportId}:</label>
                                    <SearchBarComponent
                                        placeholder='Tracking ID'
                                        value={localFilterByTrackingId}
                                        onChange={handleTrackingIdSearchChange}
                                        className='w-100'
                                    />
                                </div>
                                {roleID === constraintConfig.roleID.role4 || roleID === constraintConfig.roleID.roleExecutive ?
                                    <div className='mt-3'>
                                        <label className='form-label fs-6 fw-bold'>{labelKey.users}:</label>
                                        <input
                                            type='text'
                                            className='form-control form-control-sm'
                                            placeholder='Search User...'
                                            value={searchUser}
                                            onChange={handleUserSearchChange}
                                        />
                                        <div className='mh-200px overflow-auto mt-2'>
                                            {filteredUsers?.map((user: any) => (
                                                <div key={user.id}>
                                                    <div className='d-flex align-items-center gap-3 mb-2'>
                                                        <TextField
                                                            type="checkbox"
                                                            fieldClass='cursor-pointer'
                                                            onChange={(e) => handleUserChange(e, user.id)}
                                                            value={user.id}
                                                            checked={localCheckedBoxes.includes(user.id)}
                                                        />
                                                        <span className='fs-5 fw-semibold text-capitalize'>{user.value}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    : null}
                            </>
                        </FilterMenu>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive table-height'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {hiddenColumns.includes('trackingId') && (
                                        <TableHeading
                                            label={labelKey.reportId}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='trackingId'
                                            className="ps-4"
                                        />
                                    )}
                                    {hiddenColumns.includes('userAccount') && (
                                        <TableHeading
                                            label={labelKey.reportees}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='userAccount'
                                        />
                                    )}
                                    {hiddenColumns.includes('expenseType') && (
                                        <TableHeading
                                            label={labelKey.type}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='expenseType'
                                        />
                                    )}
                                    {hiddenColumns.includes('title') && (
                                        <TableHeading
                                            label={labelKey.title}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='title'
                                            className="w-200px"
                                        />
                                    )}
                                    {hiddenColumns.includes('expenseDetail') && (
                                        <TableHeading
                                            label={labelKey.description}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='expenseDetail'
                                            className="w-200px"
                                        />
                                    )}
                                    {hiddenColumns.includes('amount') && (
                                        <TableHeading
                                            label={labelKey.totalAmount}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='amount'
                                            className="text-end mw-100px"
                                        />
                                    )}
                                    {hiddenColumns.includes('reportDate') && (
                                        <TableHeading
                                            label={labelKey.date}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='reportDate'
                                        />
                                    )}
                                    {hiddenColumns.includes('expenseStatus') && (
                                        <TableHeading
                                            label={labelKey.status}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='expenseStatus'
                                        />
                                    )}
                                    {hiddenColumns.includes('clarificationNote') && (
                                        <TableHeading
                                            label={labelKey.clarificationNote}
                                            columnId='clarificationNote'
                                            className='w-300px'
                                        />
                                    )}
                                    <th className='text-end rounded-end pe-2 border-0'>
                                        {hiddenColumns.includes('actions') && (
                                            <>
                                                {labelKey.actions}
                                            </>
                                        )}
                                        <TableSettingMenu>
                                            <TextField
                                                rightLabel={labelKey.reportId}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("trackingId")}
                                                checked={hiddenColumns.includes('trackingId')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.reportees}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("userAccount")}
                                                checked={hiddenColumns.includes('userAccount')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.title}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("title")}
                                                checked={hiddenColumns.includes('title')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.description}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("expenseDetail")}
                                                checked={hiddenColumns.includes('expenseDetail')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.totalAmount}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("amount")}
                                                checked={hiddenColumns.includes('amount')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.date}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("reportDate")}
                                                checked={hiddenColumns.includes('reportDate')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.status}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("expenseStatus")}
                                                checked={hiddenColumns.includes('expenseStatus')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.clarificationNote}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("clarificationNote")}
                                                checked={hiddenColumns.includes('clarificationNote')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.actions}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("actions")}
                                                checked={hiddenColumns.includes('actions')}
                                                fieldClass='mb-4'
                                            />
                                        </TableSettingMenu>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading &&
                                    <>
                                        {data?.result?.data?.length > 0 ? (
                                            <>
                                                {data?.result?.data?.map((data: any, index: any) => (
                                                    <Fragment key={index}>
                                                        <Tooltip id="my-tooltip" place="bottom" />
                                                        <tr
                                                            data-tooltip-id="my-tooltip" data-tooltip-content={labelKey.clickHereToExpand}
                                                            className={`cursor-pointer ${showInnerTable === index ? 'highlighted-row' : ''}`}
                                                            onClick={() => {
                                                                handleRowClick(index, data?.expenseId);
                                                            }}>
                                                            {hiddenColumns.includes('trackingId') && (
                                                                <td className='ps-5'>{data?.trackingId}</td>
                                                            )}
                                                            {hiddenColumns.includes('userAccount') && (
                                                                <td>
                                                                    <Link to={`/user-profile/expense-items/${data.userAccount?.orgUserID}`}>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='symbol symbol-50px me-5'>
                                                                                {/* {data?.userAccount?.imageUrl ? <img src={data?.userAccount?.imageUrl} alt="" /> : */}
                                                                                <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.userAccount?.firstName?.charAt(0)}</div>
                                                                                {/* } */}
                                                                            </div>
                                                                            <div className='d-flex justify-content-start flex-column'>
                                                                                <span className='text-capitalize mb-0'>
                                                                                    {data?.userAccount?.firstName}  {data?.userAccount?.middleName} {data?.userAccount?.lastName}
                                                                                </span>
                                                                                <span className='text-muted fw-semibold text-muted d-block fs-7 text-lowercase'>
                                                                                    {data?.userAccount?.email}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('expenseType') && (
                                                                <td>{data?.expenseType?.title}</td>
                                                            )}
                                                            {hiddenColumns.includes('title') && (
                                                                <td>{data?.title}</td>
                                                            )}
                                                            {hiddenColumns.includes('expenseDetail') && (
                                                                <td className='w-300px text-wrap'>
                                                                    {data && data.expenseDetail && <ReactReadMoreReadLess
                                                                        charLimit={30}
                                                                        readMoreText={"Read more"}
                                                                        readLessText={"Read less"}
                                                                        readMoreClassName="readMore"
                                                                        readLessClassName="readLess"
                                                                    >
                                                                        {data.expenseDetail}
                                                                    </ReactReadMoreReadLess>}
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('amount') && (
                                                                <td className='text-end'>
                                                                    <CurrencyComp amount={data?.amount} />
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('reportDate') && (
                                                                <td><DateComp formattedDate={data?.reportDate} /></td>
                                                            )}
                                                            {hiddenColumns.includes('expenseStatus') && (
                                                                <td>
                                                                    {data?.approverStatus?.id === constraintConfig.approverStatus.pending ?
                                                                        <span className="badge badge-light-primary fs-7 fw-semibold">{data?.approverStatus?.title}</span> :
                                                                        data?.approverStatus?.id === constraintConfig.approverStatus.approved ?
                                                                            <span className="badge badge-light-success fs-7 fw-semibold">{data?.approverStatus?.title}</span> :
                                                                            data?.approverStatus?.id === constraintConfig.approverStatus.rejected ?
                                                                                <span className="badge badge-light-danger fs-7 fw-semibold">{data?.approverStatus?.title}</span> :
                                                                                // data?.approverStatus?.id === "Clarification" ?
                                                                                //     <span className="badge badge-light-warning fs-7 fw-semibold">{data?.approverStatus?.title}</span> :
                                                                                data?.approverStatus?.id === constraintConfig.approverStatus.accountsPayable ?
                                                                                    <span className="badge badge-light-success fs-7 fw-semibold">{data?.approverStatus?.title}</span> :
                                                                                    <span className="badge badge-light-primary fs-7 fw-semibold">{data?.approverStatus?.title}</span>
                                                                    }
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('clarificationNote') && (
                                                                <td className='text-wrap w-300px'>
                                                                    {data && data.clarificationNote && <ReactReadMoreReadLess
                                                                        charLimit={30}
                                                                        readMoreText={"Read more"}
                                                                        readLessText={"Read less"}
                                                                        readMoreClassName="readMore"
                                                                        readLessClassName="readLess"
                                                                    >
                                                                        {data.clarificationNote}
                                                                    </ReactReadMoreReadLess>}
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('actions') && (
                                                                <td className='text-end' onClick={(event) => event.stopPropagation()}>
                                                                    <div className='d-flex align-items-center justify-content-end gap-2' >
                                                                        <Tooltip id="expense-violation" place="top" />
                                                                        {data?.isMgrViolation === true ?
                                                                            <div
                                                                                data-tooltip-id="expense-violation" data-tooltip-content='Expense violation'
                                                                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                                                                onClick={() => {
                                                                                    setExpenseDetailData(data);
                                                                                    expenseDetailWithOcrHandler();
                                                                                }}
                                                                            >
                                                                                <IoIosWarning className='text-danger h-15px w-15px' />
                                                                            </div>
                                                                            : data?.isMgrViolation === false ?
                                                                                <div
                                                                                    data-tooltip-id="expense-violation" data-tooltip-content='Expense violation'
                                                                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                                                                    onClick={() => {
                                                                                        setExpenseDetailData(data);
                                                                                        expenseDetailWithOcrHandler();
                                                                                    }}
                                                                                >
                                                                                    <IoIosWarning className='text-success h-15px w-15px' />
                                                                                </div> : null
                                                                        }
                                                                        {data?.approverStatus?.id === 1 && (
                                                                            <>
                                                                                <Tooltip id="Approve-Status" place="top" />
                                                                                <div
                                                                                    data-tooltip-id="expense-detail" data-tooltip-content='Approve & Reject Report with items'
                                                                                    className='btn btn-bg-light btn-active-color-primary btn-sm'
                                                                                    onClick={() => {
                                                                                        expenseItemByIdTrigger({ ExpenseId: data?.expenseId });
                                                                                        setExpenseDetailData(data);
                                                                                        expenseDetailForApprovalHandler();
                                                                                    }}
                                                                                >
                                                                                    <FaCheck className='h-14px w-14px' /> & <IoMdClose className='h-15px w-15px' />
                                                                                </div>
                                                                                {/* <div
                                                                                    data-tooltip-id="Approve-Status"
                                                                                    data-tooltip-content={
                                                                                        roleID === constraintConfig.roleID.role2 ?
                                                                                            String(labelKey.orgAdminApprovalStatus) :
                                                                                            roleID === constraintConfig.roleID.role4 ?
                                                                                                String(labelKey.managerApprovalStatus) :
                                                                                                ''
                                                                                    }
                                                                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                                                    onClick={() => {
                                                                                        setEditData(data);
                                                                                        editApproverStatusHandler();
                                                                                    }}
                                                                                >
                                                                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                                                </div> */}
                                                                                {/* <div
                                                                                    data-tooltip-id="Approve-Status"
                                                                                    data-tooltip-content='Approve Expense Report'
                                                                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                                                    onClick={() => {
                                                                                        setEditData(data);
                                                                                        setApprovedExpense('approved')
                                                                                        setRejectExpense('');
                                                                                        editApproverStatusHandler();
                                                                                    }}
                                                                                >
                                                                                    <FaCheck className='h-14px w-14px' />
                                                                                </div>
                                                                                <div
                                                                                    data-tooltip-id="Approve-Status"
                                                                                    data-tooltip-content='Reject Expense Report'
                                                                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                                                    onClick={() => {
                                                                                        setEditData(data);
                                                                                        setRejectExpense('rejected')
                                                                                        setApprovedExpense('');
                                                                                        editApproverStatusHandler();
                                                                                    }}
                                                                                >
                                                                                    <IoMdClose className='h-15px w-15px' />
                                                                                </div> */}
                                                                            </>
                                                                        )}
                                                                        <Tooltip id="expense-log" place="top" />
                                                                        <div
                                                                            data-tooltip-id="expense-log" data-tooltip-content=' Expense Log'
                                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                                            onClick={() => {
                                                                                setExpenseLogData(data);
                                                                                expenseLogHandler();
                                                                            }}
                                                                        >
                                                                            <FaHistory className='text-dark text-hover-primary' />
                                                                        </div>
                                                                        <Tooltip id="expense-detail" place="top" />
                                                                        <div
                                                                            data-tooltip-id="expense-detail" data-tooltip-content='Expense Detail'
                                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                                            onClick={() => {
                                                                                expenseItemByIdTrigger({ ExpenseId: data?.expenseId });
                                                                                setExpenseDetailData(data);
                                                                                expenseDetailHandler();
                                                                            }}
                                                                        >
                                                                            <KTSVG
                                                                                path='/media/icons/duotune/arrows/arr064.svg'
                                                                                className='svg-icon-3'
                                                                            />
                                                                        </div>
                                                                        <DownloadReport data={data} />
                                                                    </div>
                                                                </td>
                                                            )}
                                                        </tr>
                                                        {showInnerTable === index && (
                                                            <tr
                                                                className={`inner-row ${showInnerTable === index ? 'highlighted-row' : ''}`}>
                                                                <td className='pt-0 px-3' colSpan={10}>
                                                                    <div className="table-responsive sticky-responsive">
                                                                        <table className="table align-middle gs-0 gy-4">
                                                                            <thead>
                                                                                <tr className='fw-bold text-muted bg-light'>
                                                                                    <th className='ps-4 w-50px'>{labelKey.sr}</th>
                                                                                    <th className='ps-4'>{labelKey.itemDate}</th>
                                                                                    <th>{labelKey.expenseType}</th>
                                                                                    <th>{labelKey.category}</th>
                                                                                    {/* <th>{labelKey.currency}</th> */}
                                                                                    <th className='w-100px text-end pe-15'>{labelKey.amount}</th>
                                                                                    <th className='w-150px text-end pe-15'>{labelKey.reimbursableDistance}</th>
                                                                                    <th>{labelKey.vendor}</th>
                                                                                    <th>{labelKey.paymentMethod}</th>
                                                                                    {/* <th>{labelKey.receipt}</th> */}
                                                                                    <th>{labelKey.attachment}</th>
                                                                                    <th>{labelKey.description}</th>
                                                                                    <th>{labelKey.source}</th>
                                                                                    {/* {(data?.expenseStatus?.id === constraintConfig.expenseStatus.drafted) && ( */}
                                                                                    <th className='text-end pe-3'>{labelKey.action}</th>
                                                                                    {/* )} */}
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {!expenseItemDataIsLoading && <>
                                                                                    {expenseItemData?.result?.map((itemData: any, index: any) => (
                                                                                        <tr key={index}>
                                                                                            <td className=' ps-5'>
                                                                                                {index + 1}
                                                                                            </td>
                                                                                            <td className='ps-4'><DateComp formattedDate={itemData.itemDate} /></td>
                                                                                            <td>{itemData?.expenseType?.title}</td>
                                                                                            <td className='ps-5'><CategoriesWithIcons itemData={itemData} /></td>
                                                                                            {/* <td>{itemData?.currency?.title}</td> */}
                                                                                            <td className='text-end pe-15'>
                                                                                                <CurrencyComp amount={itemData?.amount} />
                                                                                            </td>
                                                                                            <td className='text-end pe-15'>
                                                                                                {itemData?.expenseCategory?.id === constraintConfig.categoriesId.mileage ?
                                                                                                    <>
                                                                                                        {itemData?.reimbursableDistance}
                                                                                                    </>
                                                                                                    : null
                                                                                                }
                                                                                            </td>
                                                                                            <td>{itemData?.vendor?.name !== " " ? itemData?.vendor?.name : "N/A"}</td>
                                                                                            <td>{itemData?.paymentMethod?.title}</td>
                                                                                            {/* <td>
                                                                                                {itemData?.ocrPassed === true ?
                                                                                                    <span className="badge badge-light-success fs-7 fw-semibold">Passed</span> :
                                                                                                    <span className="badge badge-light-danger fs-7 fw-semibold">Failed</span>
                                                                                                }
                                                                                            </td> */}

                                                                                            <td>
                                                                                                {itemData?.attachmentFile === null ||
                                                                                                    itemData?.attachmentFile === 'null' ||
                                                                                                    itemData?.attachmentFile === "undefined" ||
                                                                                                    itemData?.attachmentFile === "" ||
                                                                                                    itemData?.attachmentFile === "Receipt is not available" ?
                                                                                                    <span className='ps-9'>N/A</span> :
                                                                                                    <FaEye className='ms-10' onClick={() => openPdfInBrowserOrLightbox(itemData?.attachmentFile)} />
                                                                                                }
                                                                                                {lightboxOpen && (
                                                                                                    <Lightbox
                                                                                                        mainSrc={lightboxData.imageURL}
                                                                                                        onCloseRequest={() => setLightboxOpen(false)}
                                                                                                        imageCaption="Attachment"
                                                                                                        enableZoom={true}
                                                                                                        imagePadding={50}
                                                                                                    />
                                                                                                )}
                                                                                            </td>
                                                                                            <td>{itemData?.itemDetail?.length > 60 ? `${itemData?.itemDetail.substring(0, 40)}...` : itemData?.itemDetail}</td>
                                                                                            <td>
                                                                                                {itemData?.recordSourceId === constraintConfig.recordSourceId.WebApp ?
                                                                                                    <KTSVG path="/media/icons/figmaIcons/Web.svg" className='svg-icon-1 ms-4'
                                                                                                        svgClassName='w-20px h-20px'
                                                                                                        data-tooltip-id="web-source" data-tooltip-content='Website' />
                                                                                                    :
                                                                                                    itemData?.recordSourceId === constraintConfig.recordSourceId.Mobile ?
                                                                                                        <KTSVG path="/media/icons/figmaIcons/Mobile.svg" className='svg-icon-1 ms-4'
                                                                                                            data-tooltip-id="mobile-source" data-tooltip-content='Mobile' /> :
                                                                                                        itemData?.recordSourceId === constraintConfig.recordSourceId.BankFeed ?
                                                                                                            <KTSVG path="/media/icons/figmaIcons/Bank.svg" className='svg-icon-1 ms-4'
                                                                                                                data-tooltip-id="bank-source" data-tooltip-content='Bank' />
                                                                                                            : null}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className='d-flex justify-content-end'>
                                                                                                    {itemData?.isMgrViolation === true ?
                                                                                                        <div
                                                                                                            data-tooltip-id="expense-violation" data-tooltip-content='Expense violation'
                                                                                                            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                                                                                            onClick={() => {
                                                                                                                setExpenseDetailData(data);
                                                                                                                setExpenseItemId(itemData?.expenseItemId)
                                                                                                                expenseItemViolationsHandler();
                                                                                                            }}
                                                                                                        >
                                                                                                            <IoIosWarning className='text-danger h-15px w-15px' />
                                                                                                        </div>
                                                                                                        : data?.isMgrViolation === false ?
                                                                                                            <div
                                                                                                                data-tooltip-id="expense-violation" data-tooltip-content='Expense violation'
                                                                                                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                                                                                                onClick={() => {
                                                                                                                    setExpenseDetailData(data);
                                                                                                                    setExpenseItemId(itemData?.expenseItemId)
                                                                                                                    expenseItemViolationsHandler();
                                                                                                                }}
                                                                                                            >
                                                                                                                <IoIosWarning className='text-success h-15px w-15px' />
                                                                                                            </div>
                                                                                                            : null
                                                                                                    }
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </>}
                                                                                {expenseItemDataIsLoading && (
                                                                                    <tr>
                                                                                        <td colSpan={11} className="text-center">
                                                                                            <LoadingComponent />
                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </Fragment>
                                                ))}
                                            </>
                                        ) :
                                            (
                                                <tr>
                                                    <td colSpan={11}>
                                                        <NoRecordFound />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </>
                                }
                                {isLoading && (
                                    <tr>
                                        <td colSpan={10} className="text-center">
                                            <LoadingComponent />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table >
                    </div >
                    <Pagination
                        totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                        toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                        ofResult={data?.result?.totalRecords}
                        onChange={handlePageSizeChange}
                        pageSize={pageSize}
                        onPageChange={handlePageClick}
                        pageCount={data?.result?.totalPages || 0}
                        currentPage={page}
                    />
                    {/* end::Table container */}
                </div >


                {/* begin::Body */}
            </div >
        </>
    )
}

export default TeamMemberExpenses