/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { KTSVG } from '../../../../../_metronic/helpers';
import { useAllManagersAndAdminsQuery } from '../../../../../services/authApi';
import labelKey from "../../../../localization/label.json";
import DeleteWorkFlow from '../DeleteWorkFlow';
import TextField from '../../../../../components/textFields/TextField';

type Props = {
  formik: any
  data?: any
}
const Step2 = ({ formik, data }: Props) => {
  const [workflowApprover, setWorkflowApprover] = useState<any[]>(formik.values.workflowApprover || []);
  const [selectedItemToDelete, setSelectedItemToDelete] = useState<number | null>(null);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [sequence, setSequence] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState<{ [key: number]: any }>({});
  const { data: allManagersAndAdmin } = useAllManagersAndAdminsQuery('')

  const initialRows = data
    ? [...workflowApprover.map((_, index) => ({ id: index, showInnerTable: true }))]
    : [{ id: 0, showInnerTable: true }];
  const [rows, setRows] = useState(initialRows);

  // Update initialRows whenever workflowApprover data changes
  useEffect(() => {
    setWorkflowApprover(formik.values.workflowApprover || []);
  }, [formik.values.workflowApprover]);

  // Update rows whenever initialRows changes
  // useEffect(() => {
  //   // Only execute the effect when data is available
  //   if (data) {
  //     const newRows = [...initialRows.map((_, index) => ({ id: index, showInnerTable: true }))];
  //     setRows(newRows);
  //   }
  // }, [initialRows, data]);
  
  // Update rows whenever initialRows changes
  // useEffect(() => {
  //   if (data && workflowApprover) {
  //     // Convert iterator to array and then map over it
  //     const newRows = Array.from(Array(workflowApprover.length).keys()).map(index => ({
  //       id: index,
  //       showInnerTable: true
  //     }));
  //     setRows(newRows);
  //   }
  // }, [data, workflowApprover]);
  useEffect(() => {
    if (workflowApprover?.length > 0) {
      const newRows = workflowApprover.map((_, index) => ({
        id: index,
        showInnerTable: true
      }));
      setRows(newRows);
    }
  }, [workflowApprover]);
  


  const handleRowClick = (rowId: number) => {
    setRows((prevRows) =>
      prevRows.map((row) => {
        if (row.id === rowId) {
          return {
            ...row,
            showInnerTable: !row.showInnerTable,
          };
        }
        return row;
      })
    );
  };

  const handleAddNewItem = () => {
    const newRowId = rows.length;
    const newRow = { id: newRowId, showInnerTable: true };
    // Set the select value for the new row
    // const updatedValue = previousRowSelectedValue === selectedUser ? selectedUser : userAccountID;
    formik.setFieldValue(
      `workflowApprover.${newRowId}.sequence`,
      newRowId + 1
    );
    setSelectedUsers((prevSelectedUsers) => ({
      ...prevSelectedUsers,
      [newRowId]: undefined,
    }));

    setRows((prevRows) => {
      // Toggle the showInnerTable value of all previous rows to false
      const updatedRows = prevRows.map((row) => ({ ...row, showInnerTable: false }));
      return [...updatedRows, newRow];
    });

    setSequence((prevSequence) => prevSequence + 1);

  };
  const handleDeleteItem = (rowId: number) => {
    setSelectedItemToDelete(rowId);
    setShowDeleteConfirmationModal(true);
  };
  const handleConfirmDelete = () => {
    formik.setFieldValue(
      'workflowApprover',
      formik.values.workflowApprover.filter((_: any, index: any) => index !== selectedItemToDelete)
    );

    // Set the updated rows state and the formik.values.workflowApprover array
    setRows((prevRows) =>
      prevRows
        .filter((row) => row.id !== selectedItemToDelete)
        .map((row, index) => ({ ...row, id: index, showInnerTable: row.id === 0 })) // Reset row IDs and keep the first row open
    );
    // Close the confirmation modal
    setShowDeleteConfirmationModal(false);
    setSelectedItemToDelete(null);
  };
  
  return (
    <>
      <DeleteWorkFlow
        show={showDeleteConfirmationModal}
        handleClose={() => setShowDeleteConfirmationModal(false)}
        handleDeleteConfirm={handleConfirmDelete}
      />
      <div className='pe-3 ' data-kt-stepper-element='content'>
        <div className='w-100'>
          <div className='w-100'>
            {rows?.map((row: any, index: any) => (
              <div className="border" key={index}>
                <div className="bg-light py-3 px-3 cursor-pointer" onClick={() => handleRowClick(row.id)}>
                  <div className='d-flex justify-content-between align-items-center'>
                    <p className='mb-0 fs-6 fw-bold'>{labelKey.workflowApprover}:{row.id + 1}</p>
                    {!data &&
                      <div className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm' onClick={() => handleDeleteItem(row.id)}>
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-3 text-danger'
                        />
                      </div>}
                  </div>
                </div>
                {row.showInnerTable && (
                  <div className="row m-0 px-4 py-3">
                    <div className='col-md-6 mb-10'>
                      <TextField
                        label={labelKey.maximumAmount}
                        required={true}
                        type='number'
                        placeholder='Enter Max Amount'
                        min={0}
                        {...formik.getFieldProps(`workflowApprover.${row.id}.maximumAmount`)}
                      />
                    </div>

                    <div className='col-md-6 mb-5'>
                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className='required'>{labelKey.approvers}</span>
                      </label>
                      <Select
                        options={[
                          ...(allManagersAndAdmin?.result || []).map((option: any, index: any) => ({
                            value: option.id,
                            label: option?.value,
                          })),
                        ]}
                        placeholder='Select Approvers'
                        value={
                          selectedUsers[row?.id] ||
                          (formik?.values?.workflowApprover?.[row?.id]?.userAccount?.orgUserID
                            ? {
                              value: formik?.values?.workflowApprover?.[row?.id]?.userAccount?.orgUserID,
                              label: `${formik?.values?.workflowApprover?.[row?.id]?.userAccount?.firstName || ''} ${formik?.values?.workflowApprover?.[row?.id]?.userAccount?.middleName || ''
                                } ${formik?.values?.workflowApprover?.[row?.id]?.userAccount?.lastName || ''}`,
                            }
                            : null)
                        }
                        onChange={(selectedOption: any) => {
                          setSelectedUsers((prevSelectedUsers) => ({
                            ...prevSelectedUsers,
                            [row.id]: selectedOption,
                          }));
                          formik.setFieldValue(`workflowApprover.[${row.id}].userAccount.orgUserID`, selectedOption.value);
                          formik.setFieldValue(`workflowApprover.[${row.id}].userAccount.firstName`, selectedOption.label);

                        }}
                      />
                    </div>
                    <div className='col-md-12 mb-10'>
                      <TextField
                        label={labelKey.level}
                        required={true}
                        type='number'
                        placeholder='Enter Max Amount'
                        min={0}
                        {...formik.getFieldProps(`workflowApprover.${row.id}.sequence`)}
                        value={row.id + 1}
                        readOnly={true}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="row mt-4">
            <div className='col-md-12'>
              <div className='d-flex align-items-center gap-3 justify-content-center'>
                <div className='border w-100'></div>
                <div className='btn btn-sm btn-light-primary w-100' onClick={handleAddNewItem}>{labelKey.addNewWorkflowApprover}</div>
                <div className='border w-100'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { Step2 };

