import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { selectAuth } from "../features/authSlice";
import { RootState } from "../store";
import { API_END_POINTS } from './apiEndpoints';
import { baseUrl } from './baseUrl';
import { DynamicBaseQueryHeader } from './DynamicBaseQueryHeader';

// const rawBaseQuery = fetchBaseQuery({
//     baseUrl: baseUrl.MAIN_BASE_URL,
// })

// type ExtraOptions = {
//     headers?: Record<string, string>;
//     // Define other optional properties here if needed
// };

// const dynamicBaseQuery: BaseQueryFn<
//     string | FetchArgs,
//     unknown,
//     FetchBaseQueryError,
//     ExtraOptions
// > = async (args, api, extraOptions) => {
//     const orgBaseUrl = selectAuth(api.getState() as RootState).baseURL;
//     // gracefully handle scenarios where data to generate the URL is missing
//     if (!orgBaseUrl) {
//         return {
//             error: {
//                 status: 400,
//                 statusText: 'Bad Request',
//                 data: 'dynamic route not found',
//             },
//         }
//     }

//     const urlEnd = typeof args === 'string' ? args : args.url

//     // construct a dynamically generated portion of the url
//     const adjustedUrl = `${orgBaseUrl}/api/Workflow${urlEnd}`
//     const adjustedArgs = typeof args === 'string' ? { url: adjustedUrl } : { ...args, url: adjustedUrl };
//     // Get the token from localStorage
//     const token = localStorage.getItem("user")
//         ? JSON.parse(localStorage.getItem("user")!).token
//         : null;
//     // Set headers if token is available
//     const headers = token ? { Authorization: `Bearer ${token}` } : {};
//     // Merge headers with existing headers
//     const mergedHeaders = { ...headers, ...extraOptions?.headers };
//     // provide the amended url and other params to the raw base query
//     return rawBaseQuery({ ...adjustedArgs, headers: mergedHeaders }, api, extraOptions)

// }

export const WorkFlowApi = createApi({
    reducerPath: 'WorkFlowApi',
    // baseQuery: dynamicBaseQuery,
    baseQuery: DynamicBaseQueryHeader("Workflow"),

    tagTypes: ['WorkFlow'],
    endpoints: (builder) => ({
        getAllWorkflow: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getWorkflow,
                    method: "GET",
                }
            },
            providesTags: ['WorkFlow']
        }),
        getSingleWorkflow: builder.query({
            query: (workflowId) => {
                return {
                    url: `${API_END_POINTS.getWorkflowByWorkflowId}?workflowId=${workflowId}`,
                    method: "GET",
                }
            },
            providesTags: ['WorkFlow']
        }),
        createWorkflow: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.createWorkflow,
                    method: "POST",
                    body,
                }
            },
        }),
        updateWorkflowWithApprovers: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateWorkflowWithApprovers,
                    method: "PUT",
                    body,
                }
            },
        }),
        getApproverNotification: builder.query({
            query: ({ userAccountId }) => {
                return {
                    url: `${API_END_POINTS.getUserNotification}?userAccountId=${userAccountId}`,
                    method: "GET",
                }
            },
            providesTags: ['WorkFlow']
        }),
        getUserNotificationCount: builder.query({
            query: ({ userAccountId }) => {
                return {
                    url: `${API_END_POINTS.getUserNotificationCount}?userAccountId=${userAccountId}`,
                    method: "GET",
                }
            },
            providesTags: ['WorkFlow']
        }),
        getExpenseToApprove: builder.query({
            query: ({ userAccountId, pageSize, pageNumber, searchStr, filterByStatus }) => {
                return {
                    url: `${API_END_POINTS.getExpenseToApprove}?userAccountId=${userAccountId}&pageSize=${pageSize}&pageNumber=${pageNumber}&searchStr=${searchStr}&filterByStatus=${filterByStatus}`,
                    method: "GET",
                }
            },
            providesTags: ['WorkFlow']
        }),
        getExpenseToApproveByExpenseId: builder.query({
            query: ({ userAccountId, expenseId, dateTime }) => {
                return {
                    url: `${API_END_POINTS.getExpenseToApproveByExpenseId}?userAccountId=${userAccountId}&expenseId=${expenseId}&dateTime=${dateTime}`,
                    method: "GET",
                }
            },
            providesTags: ['WorkFlow']
        }),

        updateStatusToApproveExpense: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateStatusToApproveExpense,
                    method: "PUT",
                    body,
                }
            },
        }),
        getWorkflowByUserAccountId: builder.query({
            query: ({ userAccountId }) => {
                return {
                    url: `${API_END_POINTS.getWorkflowByUserAccountId}?userAccountId=${userAccountId}`,
                    method: "GET",
                }
            },
            providesTags: ['WorkFlow']
        }),
        activeOrInactiveWorkflowStatus: builder.mutation({
            query: ({ workflowId, status }) => {
                return {
                    url: `${API_END_POINTS.activeOrInactiveWorkflowStatus}?workflowId=${workflowId}&status=${status}`,
                    method: "PUT",
                }
            },
        }),
        getWorkflowApprovers: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getWorkflowApprovers}`,
                    method: "GET",
                }
            },
            providesTags: ['WorkFlow']
        }),
        updateStatusToApproveExpenseV2: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateStatusToApproveExpenseV2,
                    method: "PUT",
                    body,
                }
            },
        }),
    }),
})


export const {
    useGetAllWorkflowQuery,
    useGetSingleWorkflowQuery,
    useCreateWorkflowMutation,
    useUpdateWorkflowWithApproversMutation,
    useGetApproverNotificationQuery,
    useLazyGetApproverNotificationQuery,
    useGetExpenseToApproveQuery,
    useGetExpenseToApproveByExpenseIdQuery,
    useUpdateStatusToApproveExpenseMutation,
    useGetUserNotificationCountQuery,
    useGetWorkflowByUserAccountIdQuery,
    useActiveOrInactiveWorkflowStatusMutation,
    useGetWorkflowApproversQuery,
    useUpdateStatusToApproveExpenseV2Mutation
} = WorkFlowApi