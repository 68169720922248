import { useFormik } from 'formik';
import { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { MasterInit } from '../_metronic/layout/MasterInit';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import HardReloadModal from '../components/hardReload/HardReloadModal';
import { logout, selectAuth } from '../features/authSlice';
import { handleSetUser } from '../helpers/SetUserHelper';
import { useAppDispatch, useAppSelector } from '../hooks';
import { useGetSingleUserQuery, useRenewTokenMutation } from '../services/authApi';
import { store } from '../store';
import UserAddressModal from './pages/userOnloadModal/UserAddressModal';
import RevertImpersonateUser from './pages/users/RevertImpersonateUser';
import AddCardModal from './pages/vendorAndCard/card/AddCardModal';

const App = () => {
  const auth = useSelector(selectAuth);
  const { userAccountID, webVersion, email, role, userPermission, roleID, userInOrgId, token, microServiceURL, baseURL, expiresAt, locationType, expenseType, reportPermision, timeZone, firstName, lastName, contactPhone, businessPhone, businessExt, location, locationName, isHomeAddressPopup, isCreditCardInfoPopup } = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [tokenRenew, { data, isSuccess }] = useRenewTokenMutation();
  const [userActive, setUserActive] = useState(true);

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    onSubmit: (values) => {
      // This is where you can handle token renewal
      if (userActive) {
        tokenRenew({ ...values });
      } else {
        // Handle the case where there's no user activity
        handleNoUserActivity();
      }
    },
  });

  // Function to handle logout when there's no user activity and the token has expired
  const handleNoUserActivity = () => {
    if (auth.expiresAt) {
      const currentTime = new Date();
      const expirationTime = new Date(auth.expiresAt);

      if (currentTime > expirationTime) {
        // Token has expired, perform logout and navigate to the home page
        store.dispatch(logout());
        navigate('/');
        toast.error('Session Expired, Please login again');
      }
    }
  };

  useEffect(() => {
    const handleUserActivity = () => {
      setUserActive(true);
    };

    const resetUserActivityTimer = () => {
      setUserActive(false);
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    // Clean up event listeners
    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // This is where you can handle token renewal based on the interval
      tokenRenew({ ...formik.values });
    }, 600000); // 10 minutes in milliseconds
    return () => {
      clearInterval(intervalId); // Clean up the interval
    };
  }, [formik.values, tokenRenew]);

  useEffect(() => {
    if (isSuccess) {
      handleSetUser({
        dispatch,
        data,
        overrides: {
          isCreditCardInfoPopup: isCreditCardInfoPopup ?? false,
        }
      });
    }
  }, [isSuccess]);
  useEffect(() => {
    // Check if token has expired
    if (auth.expiresAt) {
      const currentTime = new Date();
      const expirationTime = new Date(auth.expiresAt);
      if (currentTime > expirationTime) {
        // Token has expired, perform logout and navigate to home page
        store.dispatch(logout());
        navigate('/');
        toast.error('Session Expired, Please login again');
      }
    }
  }, [auth.expiresAt, navigate]);

  useEffect(() => {
    const logoutChannel = new BroadcastChannel('logoutChannel');
    // Listen for logout messages from other tabs
    logoutChannel.addEventListener('message', (event) => {
      if (event.data === 'logout') {
        // Handle the logout action here
        // Example: dispatch(logout());
        dispatch(logout());
      }
    });
    // Clean up the channel when the component unmounts
    return () => {
      logoutChannel.close();
    };
  }, []);

  // const [isHomeAddressModal, setIsHomeAddressModal] = useState(false)
  const [hasOpenedModal, setHasOpenedModal] = useState(false);

  // useEffect(() => {
  //   if (isHomeAddressPopup && !hasOpenedModal) {
  //     setIsHomeAddressModal(true);
  //     setHasOpenedModal(true); // Set the flag to true to prevent re-opening
  //   }
  // }, [isHomeAddressPopup, hasOpenedModal]);
  useEffect(() => {
    // console.log('Redux isHomeAddressPopup:', isHomeAddressPopup);
  }, [isHomeAddressPopup]);
  const [showAddUpdateCardModal, setShowAddUpdateCardModal] = useState<boolean>(false);
  useEffect(() => {
    if (isCreditCardInfoPopup && !isHomeAddressPopup) {
      setShowAddUpdateCardModal(true);
    }
  }, [isCreditCardInfoPopup, isHomeAddressPopup]);
  const handleCloseCreditCardModal = () => {
    setShowAddUpdateCardModal(false)
    handleSetUser({
      dispatch,
      data,
      overrides: {
        token: token ?? "",
        expiresAt: expiresAt ?? "",
        baseURL: baseURL ?? "",
        microServiceURL: microServiceURL ?? "",
        webVersion: webVersion ?? "",
        firstName: firstName,
        lastName: lastName,
        email: email ?? "",
        role: role ?? "",
        roleID: roleID ?? 0,
        contactPhone: contactPhone,
        businessPhone: businessPhone,
        businessExt: businessExt,
        userAccountID: userAccountID ?? 0,
        userInOrgId: userInOrgId ?? 0,
        locationType: locationType ?? 0,
        expenseType: expenseType ?? 0,
        location: location,
        locationName: locationName,
        reportPermision: reportPermision ?? false,
        timeZone: timeZone ?? "",
        isHomeAddressPopup: isHomeAddressPopup ?? false,
        isCreditCardInfoPopup: false,
        userPermission: userPermission ?? []
      }
    });
  }
  return (
    <>


      {auth.token &&
        <>
          <>
            {isHomeAddressPopup ? (
              <UserAddressModal
                show={isHomeAddressPopup}
                handleClose={() => {
                  // Close `UserAddressModal` and update the state
                  handleSetUser({
                    dispatch: store.dispatch,
                    data,
                    overrides: {
                      isHomeAddressPopup: false,
                      isCreditCardInfoPopup: isCreditCardInfoPopup || false,
                    },
                  });
                }}
              />
            ) : (
              <AddCardModal
                show={showAddUpdateCardModal}
                handleClose={() => handleCloseCreditCardModal()}
              />
            )}
          </>
          {/* <UserAddressModal show={isHomeAddressModal} handleClose={() => setIsHomeAddressModal(false)} setIsHomeAddressModal={setIsHomeAddressModal} /> */}
          <HardReloadModal />
        </>
      }
      {auth.isImpersonating && <RevertImpersonateUser />}
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <Outlet />
            <MasterInit />
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </>
  );
};

export { App };

