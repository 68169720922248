import 'bootstrap-daterangepicker/daterangepicker.css';
import { debounce } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import 'react-image-lightbox/style.css';
import ReactReadMoreReadLess from "react-read-more-read-less";
import { useLocation } from 'react-router-dom';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { KTSVG } from '../../../_metronic/helpers';
import DateRangeComp from '../../../components/dateComponent/DateRangeComp';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../components/noRecord/NoRecordFound';
import Pagination from '../../../components/pagination/Pagination';
import RefreshComponent from '../../../components/refreshComponent/RefreshComponent';
import SearchBarComponent from '../../../components/searchBar/SearchBarComponent';
import TableHeading from '../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../components/tableHeading/TableSettingMenu';
import SearchSelect from '../../../components/textFields/SearchSelect';
import TextField from '../../../components/textFields/TextField';
import { constraintConfig } from '../../../constraintConfig';
import { selectAuth } from '../../../features/authSlice';
import { useAppSelector } from '../../../hooks';
import { useExpenseCategoryLookupQuery, useGetAllDepartmentByManagerQuery, useGetAllManagersLookupQuery, useGetAllUsersLocationQuery, useGetUsersByManagerIdQuery } from '../../../services/GeneralApi';
import { useGetExpenseViolationReportQuery } from '../../../services/ReportAndAnalyticsApi';
import useColumnVisibility from '../../customHooks/useColumnVisibility';
import labelKey from "../../localization/label.json";
import tosterKey from "../../localization/toster.json";
import { myReportPaths, queryReportPaths, teamReportPaths } from './ReportPaths';

const { Option } = components;
type Option = {
    value: string;
    label: string;
};

// Custom Option component to add checkboxes
const CheckboxOption = (props: any) => {
    return (
        <Option {...props} className='d-flex align-items-start gap-2'>
            <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
            />{" "}
            <label>{props.label}</label>
        </Option>
    );
};

const ExpenseViolationReport = () => {
    const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'ExpenseVoilationReport' });
    const location = useLocation()
    const currentDate = moment().format('MM/DD/YYYY');
    const sevenDaysAgo = moment().subtract(6, 'days').format('MM/DD/YYYY');
    const [filterByCategory, setFilterByCategory] = useState("0");
    const [filterByStartDate, setFilterByStartDate] = useState(sevenDaysAgo);
    const [filterByEndDate, setFilterByEndDate] = useState(currentDate);
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [filterByDeptId, setFilterByDeptId] = useState<string[]>([]);
    const [filterByLocationIds, setFilterByLocationIds] = useState<string[]>([]);
    const [filterByUserIds, setFilterByUserIds] = useState<string[]>([]);
    const [filterByManager, setFilterByManager] = useState(0);
    const [filterByTimePeriod, setFilterByTimePeriod] = useState('');

    const [selectedDeptId, setSelectedDeptId] = useState<Option[]>([]);
    const [selectedLocationIds, setSelectedLocationIds] = useState<Option[]>([]);
    const [selectedUserIds, setSelectedUserIds] = useState<Option[]>([]);

    const [localFilterByStartDate, setLocalFilterByStartDate] = useState(sevenDaysAgo);
    const [localFilterByEndDate, setLocalFilterByEndDate] = useState(currentDate);
    const [localFilterByCategory, setLocalFilterByCategory] = useState("0");
    const [localFilterByDeptId, setLocalFilterByDeptId] = useState<string[]>([]);
    const [localFilterByLocationIds, setLocalFilterByLocationIds] = useState<string[]>([]);
    const [localFilterByUserIds, setLocalFilterByUserIds] = useState<string[]>([]);
    const [localFilterByTimePeriod, setLocalFilterByTimePeriod] = useState("");

    const [isApplyingFilters, setIsApplyingFilters] = useState(false);

    const myReportpath = myReportPaths.includes(location.pathname);
    const queryFeportPath = queryReportPaths.includes(location.pathname);
    const teamFeportPath = teamReportPaths.includes(location.pathname);


    const { userAccountID, roleID, reportPermision } = useAppSelector(selectAuth)
    const { data: expenseCategory } = useExpenseCategoryLookupQuery('')
    const { data: getAllDepByManager, refetch: getAllDepByManagerRefetch } = useGetAllDepartmentByManagerQuery({ reportPermission: queryFeportPath ? reportPermision : false })
    const { data: getAllUsersLocation, refetch: getAllUsersLocationRefetch } = useGetAllUsersLocationQuery({ reportPermission: queryFeportPath ? reportPermision : false })
    const { data: getAllManagers } = useGetAllManagersLookupQuery('')
    const { data: getUserByManager, refetch: userByManagerRefetch } = useGetUsersByManagerIdQuery({ managerId: filterByManager })

    useEffect(() => {
        if (filterByManager) {
            userByManagerRefetch()
        }
        userByManagerRefetch()
        getAllDepByManagerRefetch()
        getAllUsersLocationRefetch()
    }, [filterByManager])





    const { data, isFetching: isLoading, refetch, isSuccess } = useGetExpenseViolationReportQuery(
        {
            reportFilter: myReportpath ? 0 : queryFeportPath ? 2 : 1,
            userAccountId: userAccountID,
            categoryId: filterByCategory,
            startDate: filterByStartDate,
            endDate: filterByEndDate,
            pageNumber: page,
            pageSize: pageSize,
            searchstr: searchStr,
            userIds: filterByUserIds.length > 0 ? filterByUserIds : null,
            dptIds: filterByDeptId.length > 0 ? filterByDeptId : null,
            locationIds: filterByLocationIds.length > 0 ? filterByLocationIds : null,
            timePeriod: filterByTimePeriod
        })

    const deptOptions: Option[] = getAllDepByManager?.result?.map((dept: any) => ({
        value: dept.id,
        label: dept.value,
    }));

    const handleDeptChange = (selected: Option[]) => {
        setSelectedDeptId(selected);
        setLocalFilterByDeptId(selected.map((dept) => dept.value));
    };

    const locationOptions: Option[] = getAllUsersLocation?.result?.map((location: any) => ({
        value: location.id,
        label: location.value,
    }));

    const handleLocationChange = (selected: Option[]) => {
        setSelectedLocationIds(selected);
        setLocalFilterByLocationIds(selected?.map((location) => location.value));
    };

    const handleManagerChange = (selectedOption: any) => {
        setFilterByManager(selectedOption.value);
    };
    const handleTimePeriodChange = (selectedOption: any) => {
        setLocalFilterByTimePeriod(selectedOption.value);
        setLocalFilterByStartDate('');
        setLocalFilterByEndDate('');
    };

    const userIdsOptions: Option[] = getUserByManager?.result.map((userByManager: any) => ({
        value: userByManager.id,
        label: userByManager.value,
    }));

    const handleUserIdsChange = (selected: Option[]) => {
        setSelectedUserIds(selected);
        setLocalFilterByUserIds(selected?.map((userByManager) => userByManager.value));
    };

    const handleCategoryChange = (selectedOption: any) => {
        setLocalFilterByCategory(selectedOption.value);
    };
    const handleDateRangeChange = (event: any, picker: any) => {
        const startDateFormatted = moment(picker.startDate).format('MM-DD-YYYY');
        const endDateFormatted = moment(picker.endDate).format('MM-DD-YYYY');

        setLocalFilterByStartDate(startDateFormatted);
        setLocalFilterByEndDate(endDateFormatted);
        setLocalFilterByTimePeriod('')
    };
    const handleCancelDateSelection = (event: any, picker: any) => {
        // Reset the state to empty strings
        setFilterByStartDate("");
        setFilterByEndDate("");
        setLocalFilterByStartDate("");
        setLocalFilterByEndDate("");
    };
    const applyFilters = () => {
        setIsApplyingFilters(true);
        setFilterByStartDate(localFilterByStartDate);
        setFilterByEndDate(localFilterByEndDate);
        setFilterByCategory(localFilterByCategory);
        setFilterByDeptId(localFilterByDeptId);
        setFilterByLocationIds(localFilterByLocationIds);
        setFilterByUserIds(localFilterByUserIds);
        setFilterByTimePeriod(localFilterByTimePeriod);
        setPage(1);
    };
    const resetFilters = () => {
        setFilterByStartDate(sevenDaysAgo)
        setFilterByEndDate(currentDate)
        setFilterByCategory("0")
        setFilterByDeptId([])
        setFilterByLocationIds([])
        setFilterByUserIds([])
        setFilterByManager(0)
        setFilterByTimePeriod('')

        setLocalFilterByStartDate(sevenDaysAgo);
        setLocalFilterByEndDate(currentDate);
        setLocalFilterByCategory("0");
        setLocalFilterByDeptId([])
        setLocalFilterByLocationIds([])
        setLocalFilterByUserIds([])
        setLocalFilterByTimePeriod('')
        setSelectedDeptId([])
        setSelectedLocationIds([])
        setSelectedUserIds([])

    }
    const debouncedRefetch = debounce(() => {
        refetch().finally(() => {
            setIsApplyingFilters(false);
        });
    }, 300);
    useEffect(() => {
        if (isApplyingFilters) {
            debouncedRefetch();
        }
    }, [isApplyingFilters]);

    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPage(1); // Reset page number to 1
    };
    // pagination
    const handlePageClick = (e: any) => {
        setPage(e.selected + 1);
    };
    //per page record
    const handlePageRecords = (e: any) => {
        setPage(1); // Reset the page to 1
    };
    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state

        delayedSearch(value); // Update debounced search state
        setPage(1);
    };
    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );
    useEffect(() => {
        refetch();
        if (searchStr === "") {
            refetch();
        }
    }, [searchStr]);

    const handleRefresh = () => {
        // Set the state values
        // setFilterByStartDate(sevenDaysAgo);
        // setFilterByEndDate(currentDate);
        // setLocalFilterByStartDate(sevenDaysAgo);
        // setLocalFilterByEndDate(currentDate);

        // Trigger refetch after state updates are done
        setTimeout(() => {
            refetch()
                .then(() => {
                    if (isSuccess) {
                        toast.success(tosterKey.dataRefreshedSuccesfully);
                    }
                });
        }, 0);
    };
    return (
        <>
            <div className='card' style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                <div className='card-header border-0  align-items-center'>
                    <div className='d-flex align-items-center gap-2'>
                        <SearchBarComponent
                            placeholder='Expense violation'
                            value={immediateSearchStr}
                            onChange={handleSearchChange}
                        />
                        <RefreshComponent onClick={handleRefresh} />
                    </div>

                    <button
                        type='button'
                        className='btn btn-sm btn-light-primary me-3 d-flex justify-content-end'
                        role='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#report_filters'
                        aria-expanded='true'
                        aria-controls='report_filters'
                    >
                        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                        {labelKey.filter}
                    </button>

                </div>
                <div className='card-body py-3'>
                    <div className="row collapse border-bottom" id='report_filters'>
                        <>
                            <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                                <DateRangeComp
                                    startDate={localFilterByStartDate || moment().subtract(6, 'days')}
                                    endDate={localFilterByEndDate || moment()}
                                    onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}
                                    value={localFilterByStartDate && localFilterByEndDate ? `${localFilterByStartDate} - ${localFilterByEndDate}` : "MM/DD/YYYY"} />
                            </div>
                            {/* <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <label className='form-label fs-6 fw-bold text-dark'>{labelKey.date}:</label>
                                    <div className='position-relative mb-2'>
                                        <label className='position-absolute text-primary'>Custom Date Range</label>
                                        <DateRangePicker onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}>
                                            <input type="text"
                                                className='h-10px mw-100px opacity-0 cursor-pointer'
                                                value={localFilterByStartDate && localFilterByEndDate ? `${localFilterByStartDate} - ${localFilterByEndDate}` : "mm/dd/yyyy"} />
                                        </DateRangePicker>
                                    </div>
                                </div>
                                <Select
                                    // customLabelClick={() => setDateRangeHide(true)}
                                    options={[
                                        { value: 0, label: 'All' }, // Add the empty option
                                        ...(timePeriodOptions?.map((option: any) => ({
                                            value: option.id,
                                            label: option?.value,
                                        })))
                                    ]}
                                    placeholder={localFilterByStartDate && localFilterByEndDate ? `${localFilterByStartDate} - ${localFilterByEndDate}` : 'Select Date'}
                                    value={localFilterByTimePeriod && localFilterByTimePeriod !== ''
                                        ? {
                                            label: timePeriodOptions?.find((option: any) => option.id === localFilterByTimePeriod)?.value || '',
                                            value: localFilterByTimePeriod,
                                        }
                                        : null
                                    }
                                    onChange={handleTimePeriodChange}
                                />

                            </div> */}

                            <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                                <SearchSelect
                                    label={`${labelKey.category}:`}
                                    options={[
                                        // { value: 0, label: 'All' }, // Add the empty option
                                        ...(Array.isArray(expenseCategory?.result) ? expenseCategory.result.map((option: any) => ({
                                            value: option.id,
                                            label: option?.value,
                                        })) : [])
                                    ]}
                                    placeholder='Select Category'
                                    value={localFilterByCategory && localFilterByCategory !== '0'
                                        ? {
                                            value: localFilterByCategory,
                                            label: expenseCategory?.result.find((option: any) => option.id === localFilterByCategory)?.value
                                        }
                                        : null
                                    }
                                    onChange={handleCategoryChange}
                                />

                            </div>
                            {!myReportpath && <>
                                {(roleID === constraintConfig.roleID.role4 && queryFeportPath) ||
                                    (roleID === constraintConfig.roleID.role10 && queryFeportPath) ||
                                    (roleID === constraintConfig.roleID.role2 && queryFeportPath) ? (
                                    <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                                        <SearchSelect
                                            label={`${labelKey.manager}:`}
                                            options={[
                                                // { value: 0, label: 'All' }, // Add the empty option
                                                ...(Array.isArray(getAllManagers?.result) ? getAllManagers.result.map((option: any) => ({
                                                    value: option.id,
                                                    label: option?.value,
                                                })) : [])
                                            ]}
                                            placeholder='Select Manager...'
                                            value={filterByManager && filterByManager !== 0
                                                ? {
                                                    value: filterByManager,
                                                    label: getAllManagers?.result.find((option: any) => option.id === filterByManager)?.value
                                                }
                                                : null
                                            }
                                            onChange={handleManagerChange}
                                        />
                                    </div>
                                ) : null}
                                <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                                    <label className='form-label fs-6 fw-bold'>{labelKey.users}:</label>
                                    <Select
                                        isMulti
                                        options={userIdsOptions}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{ Option: CheckboxOption }}
                                        onChange={(selected) => handleUserIdsChange(selected as Option[])} // Casting to Option[]
                                        value={selectedUserIds} // Directly use selectedDeptId as it is of type Option[]
                                        placeholder='Search User...'
                                        className='filter-valueContainer'
                                    />
                                </div>
                                <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                                    <label className='form-label fs-6 fw-bold'>{labelKey.department}:</label>
                                    <Select
                                        isMulti
                                        options={deptOptions}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{ Option: CheckboxOption }}
                                        onChange={(selected) => handleDeptChange(selected as Option[])} // Casting to Option[]
                                        value={selectedDeptId} // Directly use selectedDeptId as it is of type Option[]
                                        placeholder='Search Dept...'
                                        className='filter-valueContainer'
                                    />
                                </div>
                                <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                                    <label className='form-label fs-6 fw-bold'>{labelKey.location}:</label>
                                    <Select
                                        isMulti
                                        options={locationOptions}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{ Option: CheckboxOption }}
                                        onChange={(selected) => handleLocationChange(selected as Option[])} // Casting to Option[]
                                        value={selectedLocationIds} // Directly use selectedDeptId as it is of type Option[]
                                        placeholder='Search Location...'
                                        className='filter-valueContainer'
                                    />
                                </div>
                            </>}
                            <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                                <div className='d-flex align-items-end h-100 gap-3'>
                                    <button className='btn btn-danger pull-right me-3' onClick={resetFilters}>Reset Filters</button>

                                    <button className='btn btn-primary pull-right' onClick={applyFilters}>
                                        {isApplyingFilters ? <>
                                            <span>loading...</span>
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                        </> : "Apply Filters"}
                                    </button>
                                </div>
                            </div>
                        </>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className='table-responsive table-height'>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                        <tr className='fw-bold text-muted'>
                                            {hiddenColumns.includes('trackingId') && (
                                                <TableHeading
                                                    label={labelKey.reportId}
                                                    columnId='trackingId'
                                                    className="ps-4"
                                                />
                                            )}
                                            {hiddenColumns.includes('category') && (
                                                <TableHeading
                                                    label={labelKey.category}
                                                    columnId='category'
                                                />
                                            )}
                                            {hiddenColumns.includes('user') && (
                                                <TableHeading
                                                    label={labelKey.user}
                                                    columnId='user'
                                                />
                                            )}
                                            {hiddenColumns.includes('voilation') && (
                                                <TableHeading
                                                    label={labelKey.violation}
                                                    columnId='voilation'
                                                    className="w-400px"
                                                />
                                            )}
                                            {hiddenColumns.includes('providedValues') && (
                                                <TableHeading
                                                    label={labelKey.providedValues}
                                                    columnId='providedValues'
                                                />
                                            )}
                                            {hiddenColumns.includes('receiptValues') && (
                                                <TableHeading
                                                    label={labelKey.receiptValues}
                                                    columnId='receiptValues'
                                                />
                                            )}

                                            <TableHeading className='text-end rounded-end pe-2 border-0' columnId='menu'>
                                                <TableSettingMenu>
                                                    <TextField
                                                        rightLabel={labelKey.reportId}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("trackingId")}
                                                        checked={hiddenColumns.includes('trackingId')}
                                                        fieldClass='mb-4'
                                                    />

                                                    <TextField
                                                        rightLabel={labelKey.category}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("category")}
                                                        checked={hiddenColumns.includes('category')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.user}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("user")}
                                                        checked={hiddenColumns.includes('user')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.violation}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("voilation")}
                                                        checked={hiddenColumns.includes('voilation')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.providedValues}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("providedValues")}
                                                        checked={hiddenColumns.includes('providedValues')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.receiptValues}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("receiptValues")}
                                                        checked={hiddenColumns.includes('receiptValues')}
                                                        fieldClass='mb-4'
                                                    />

                                                </TableSettingMenu>
                                            </TableHeading>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!isLoading &&
                                            <>
                                                {data?.result?.data?.length > 0 ? (
                                                    <>
                                                        {data?.result?.data?.map((data: any, index: any) => (
                                                            <tr key={index}>
                                                                {hiddenColumns.includes('trackingId') && (
                                                                    <td className='ps-4'>{data?.trackingId}</td>
                                                                )}
                                                                {hiddenColumns.includes('category') && (
                                                                    <td className='ps-5'>{data?.expenseItemName}</td>
                                                                )}
                                                                {hiddenColumns.includes('user') && (
                                                                    <td>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                                <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.firstName.charAt(0)}</div>
                                                                            </div>
                                                                            <div className="d-flex flex-column">
                                                                                <p className="text-gray-800 fw-bold text-hover-primary mb-1 text-capitalize">{data?.firstName} {data?.lastName}</p>
                                                                                <span className='text-lowercase'>{data?.email}</span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('voilation') && (
                                                                    <td className='text-wrap w-400px'>
                                                                        {data && data.violationPolicyFieldDescription && <ReactReadMoreReadLess
                                                                            charLimit={30}
                                                                            readMoreText={"Read more"}
                                                                            readLessText={"Read less"}
                                                                            readMoreClassName="readMore"
                                                                            readLessClassName="readLess"
                                                                        >
                                                                            {data.violationPolicyFieldDescription}
                                                                        </ReactReadMoreReadLess>}
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('providedValues') && (
                                                                    <td>{data?.violationPolicyFieldValue}</td>
                                                                )}
                                                                {hiddenColumns.includes('receiptValues') && (
                                                                    <td colSpan={2}>{data?.ocrFieldValue}</td>
                                                                )}
                                                            </tr>
                                                        ))}
                                                    </>
                                                ) :
                                                    (
                                                        <tr>
                                                            <td colSpan={10}>
                                                                <NoRecordFound />
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </>
                                        }
                                        {isLoading && (
                                            <tr>
                                                <td colSpan={10} className="text-center">
                                                    <LoadingComponent />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Pagination
                            totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                            toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                            ofResult={data?.result?.totalRecords}
                            onChange={handlePageSizeChange}
                            pageSize={pageSize}
                            onPageChange={handlePageClick}
                            pageCount={data?.result?.totalPages || 0}
                            currentPage={page}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpenseViolationReport