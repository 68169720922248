import { Fragment, useState } from 'react';
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { PageTitle } from '../../../_metronic/layout/core';
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp';
import DateComp from '../../../components/dateComponent/DateComp';
import TableHeading from '../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../components/tableHeading/TableSettingMenu';
import TextField from '../../../components/textFields/TextField';
import { selectAuth } from '../../../features/authSlice';
import { useAppSelector } from '../../../hooks';
import { useGetBudgetHistoryQuery } from '../../../services/BudgetApi';
import labelKey from "../../localization/label.json";
import { API_END_POINTS } from '../../../services/apiEndpoints';
import NoRecordFound from '../../../components/noRecord/NoRecordFound';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';
import useColumnVisibility from '../../customHooks/useColumnVisibility';
import CategoriesWithIcons from '../expenseItems/CategoriesWithIcons';
import { constraintConfig } from '../../../constraintConfig';
import { KTSVG } from '../../../_metronic/helpers';
import DateTimeComp from '../../../components/dateComponent/DateTimeComp';
import { FaEye } from 'react-icons/fa6';
import Lightbox from 'react-image-lightbox';

interface LightboxData {
    imageURL: string;
}
const BudgetHistory = () => {
    const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'budgetHistory' });
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
    const { userAccountID, token, baseURL } = useAppSelector(selectAuth);
    const { data, isLoading } = useGetBudgetHistoryQuery({
        userAccountId: userAccountID
    })
    const fetchExpenseItemData = async (budgetId: any, token: any) => {
        const headers = {
            Authorization: `Bearer ${token}`, // Replace 'Bearer' with your token type if needed
            // Add any other headers you need here
        };
        const response = await fetch(`${baseURL}/api/Budget${API_END_POINTS.budgetSubHistory}?budgetId=${budgetId}`, {
            headers,
        });
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return response.json();
    };
    const [showInnerTable, setShowInnerTable] = useState(null);
    const [subHistoryIsLoading, setSubHistoryIsLoading] = useState(false);
    const [getBudgetSubHistory, setGetBudgetSubHistory] = useState<any>(null);

    // console.log('getBudgetSubHistory', getBudgetSubHistory);

    const handleRowClick = async (rowId: any, budgetId: any) => {
        // setShowInnerTable(!showInnerTable);
        if (showInnerTable === rowId) {
            // If the clicked row is already expanded, collapse it
            setShowInnerTable(null);
        } else {
            // Expand the clicked row
            if (budgetId) {
                setShowInnerTable(rowId);
                try {
                    setSubHistoryIsLoading(true);
                    const data = await fetchExpenseItemData(budgetId, token);
                    setGetBudgetSubHistory(data);
                } catch (error) {
                } finally {
                    setSubHistoryIsLoading(false); // Set loading state to false after fetching data
                }
            }
        }
    };
    const isPdfFile = (filePath: string | undefined) => {
        if (!filePath) return false;
        const fileExtension = filePath.toLowerCase().split('.').pop();
        return fileExtension === 'pdf';
    };
    const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
        if (attachmentFile) {
            if (isPdfFile(attachmentFile)) {
                // For PDF files, open in the browser
                window.open(attachmentFile, '_blank');
            } else {
                // For image files, open in the Lightbox
                setLightboxData({ imageURL: attachmentFile });
                setLightboxOpen(true);
            }
        }
    };
    return (
        <>
            <PageTitle breadcrumbs={[]}>{labelKey.myBudgetHistory}</PageTitle>
            <div className={`card`}>
                <div className='card-body py-8'>
                    <div className='table-responsive table-height'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {hiddenColumns.includes('sr') && (
                                        <TableHeading
                                            label={labelKey.sr}
                                            columnId='sr'
                                            className="ps-4 w-60px"
                                        />
                                    )}
                                    {hiddenColumns.includes('user') && (
                                        <TableHeading
                                            label={labelKey.user}
                                            columnId='user'
                                        />
                                    )}
                                    {hiddenColumns.includes('allocatedBudget') && (
                                        <TableHeading
                                            label={labelKey.allocatedBudget}
                                            columnId='allocatedBudget'
                                            className="w-200px"
                                        />
                                    )}
                                    {hiddenColumns.includes('startDate') && (
                                        <TableHeading
                                            label={labelKey.startDate}
                                            columnId='startDate'
                                        />
                                    )}
                                    {hiddenColumns.includes('endDate') && (
                                        <TableHeading
                                            label={labelKey.endDate}
                                            columnId='endDate'
                                        />
                                    )}
                                    {hiddenColumns.includes('remainingBudget') && (
                                        <TableHeading
                                            label={labelKey.remainingBudget}
                                            columnId='remainingBudget'
                                        />
                                    )}
                                    {hiddenColumns.includes('comments') && (
                                        <TableHeading
                                            label={labelKey.comments}
                                            columnId='comments'
                                        />
                                    )}
                                    <TableHeading className='text-end rounded-end pe-2 border-0' columnId='menu'>
                                        <TableSettingMenu>
                                            <TextField
                                                rightLabel={labelKey.sr}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("sr")}
                                                checked={hiddenColumns.includes('sr')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.user}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("user")}
                                                checked={hiddenColumns.includes('user')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.allocatedBudget}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("allocatedBudget")}
                                                checked={hiddenColumns.includes('allocatedBudget')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.startDate}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("startDate")}
                                                checked={hiddenColumns.includes('startDate')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.endDate}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("endDate")}
                                                checked={hiddenColumns.includes('endDate')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.remainingBudget}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("remainingBudget")}
                                                checked={hiddenColumns.includes('remainingBudget')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.comments}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("comments")}
                                                checked={hiddenColumns.includes('comments')}
                                                fieldClass='mb-4'
                                            />
                                        </TableSettingMenu>
                                    </TableHeading>

                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading &&
                                    <>
                                        {data?.result?.length > 0 ?
                                            (
                                                <>
                                                    {data?.result?.map((data: any, index: any) => (
                                                        <Fragment>
                                                            <Tooltip id="my-tooltip" place="bottom" />
                                                            <tr
                                                                key={index}
                                                                data-tooltip-id="my-tooltip" data-tooltip-content={labelKey.clickHereToExpand}
                                                                className={`cursor-pointer ${showInnerTable === index ? 'highlighted-row' : ''}`}
                                                                onClick={() => {
                                                                    handleRowClick(index, data?.budgetId);
                                                                }}
                                                            >
                                                                {hiddenColumns.includes('sr') && (
                                                                    <td className='ps-4'>{index + 1}</td>
                                                                )}
                                                                {hiddenColumns.includes('user') && (
                                                                    <td>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                                {data?.userAccount?.imageUrl ? <img src={data?.userAccount?.imageUrl} alt="" /> :
                                                                                    <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.userAccount?.firstName?.charAt(0)}</div>
                                                                                }
                                                                            </div>
                                                                            <div className="d-flex flex-column">
                                                                                <span className="text-gray-800 fw-bold mb-1 text-capitalize">
                                                                                    {data?.userAccount?.firstName}&nbsp;
                                                                                    {data?.userAccount?.middleName}&nbsp;
                                                                                    {data?.userAccount?.lastName}
                                                                                </span>
                                                                                <span className='text-lowercase'>{data?.userAccount?.email}</span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('allocatedBudget') && (
                                                                    <td>
                                                                        <CurrencyComp amount={data?.allocateBudget} />
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('startDate') && (
                                                                    <td><DateComp formattedDate={data.startDate} /></td>
                                                                )}
                                                                {hiddenColumns.includes('endDate') && (
                                                                    <td><DateComp formattedDate={data.endDate} /></td>
                                                                )}
                                                                {hiddenColumns.includes('remainingBudget') && (
                                                                    <td>
                                                                        <CurrencyComp amount={data?.remainingBudget} />
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('comments') && (
                                                                    <td colSpan={2}>
                                                                        {data && data.comment && <ReactReadMoreReadLess
                                                                            charLimit={35}
                                                                            readMoreText={"Read more"}
                                                                            readLessText={"Read less"}
                                                                            readMoreClassName="readMore"
                                                                            readLessClassName="readLess"
                                                                        >
                                                                            {data.comment}
                                                                        </ReactReadMoreReadLess>}
                                                                    </td>
                                                                )}
                                                            </tr>
                                                            {showInnerTable === index && (
                                                                <tr className={`inner-row ${showInnerTable === index ? 'highlighted-row' : ''}`}>
                                                                    <td className='pt-0 px-3' colSpan={20}>
                                                                        <div className="table-responsive sticky-responsive">
                                                                            <table className="table align-middle gs-0 gy-4">
                                                                                <thead>
                                                                                    <tr className='fw-bold text-muted bg-light'>
                                                                                        <th>{labelKey.reportId}</th>
                                                                                        <th className='ps-4'>{labelKey.itemDate}</th>
                                                                                        <th>{labelKey.expenseType}</th>
                                                                                        <th>{labelKey.category}</th>
                                                                                        <th className='w-100px text-end pe-15'>{labelKey.amount}</th>
                                                                                        <th className='w-150px pe-15 text-end'>{labelKey.reimbursableDistance}</th>
                                                                                        <th>{labelKey.vendor}</th>
                                                                                        <th>{labelKey.paymentMethod}</th>
                                                                                        <th>{labelKey.attachment}</th>
                                                                                        <th>{labelKey.description}</th>
                                                                                        <th>{labelKey.source}</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {!subHistoryIsLoading && <>
                                                                                        {getBudgetSubHistory?.result?.length > 0 ? <>
                                                                                            {getBudgetSubHistory?.result?.map((itemData: any, index: any) => (
                                                                                                <tr key={index}>
                                                                                                    <td>{itemData.trackingId}</td>
                                                                                                    <td><DateComp formattedDate={itemData.itemDate} /></td>
                                                                                                    <td>{itemData?.expenseType?.title}</td>
                                                                                                    <td className='ps-5'>
                                                                                                        <CategoriesWithIcons itemData={itemData} />
                                                                                                    </td>
                                                                                                    <td className={`text-end pe-15 ${itemData?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator ? 'text-danger' : null}`}>
                                                                                                        <CurrencyComp amount={itemData?.amount} />
                                                                                                    </td>
                                                                                                    <td className='text-end pe-15'>
                                                                                                        {itemData?.expenseCategory?.id === constraintConfig.categoriesId.mileage ?
                                                                                                            <>
                                                                                                                {itemData?.reimbursableDistance}
                                                                                                            </>
                                                                                                            : null
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td>{itemData?.vendor?.name !== " " ? itemData?.vendor?.name : "N/A"}</td>
                                                                                                    <td>{itemData?.paymentMethod?.title}</td>
                                                                                                    <td>
                                                                                                        {itemData?.attachmentFile === "undefined" ||
                                                                                                            itemData?.attachmentFile === "null" ||
                                                                                                            itemData?.attachmentFile === null ||
                                                                                                            itemData?.attachmentFile === "" ||
                                                                                                            itemData?.attachmentFile === "Receipt is not available" ?
                                                                                                            <span className='ps-9'>N/A</span> :
                                                                                                            <FaEye className='ms-10' onClick={() => openPdfInBrowserOrLightbox(itemData?.attachmentFile)} />
                                                                                                        }
                                                                                                        {lightboxOpen && (
                                                                                                            <Lightbox
                                                                                                                mainSrc={lightboxData.imageURL}
                                                                                                                onCloseRequest={() => setLightboxOpen(false)}
                                                                                                                imageCaption="Attachment"
                                                                                                                enableZoom={true}
                                                                                                                imagePadding={50}
                                                                                                            />
                                                                                                        )}
                                                                                                    </td>
                                                                                                    <td>{itemData?.itemDetail?.length > 60 ? `${itemData?.itemDetail.substring(0, 40)}...` : itemData?.itemDetail}</td>
                                                                                                    <td>
                                                                                                        {itemData?.recordSourceId === constraintConfig.recordSourceId.WebApp ?
                                                                                                            <KTSVG path="/media/icons/figmaIcons/Web.svg" className='svg-icon-1 ms-4'
                                                                                                                svgClassName='w-20px h-20px'
                                                                                                                data-tooltip-id="web-source" data-tooltip-content='Website' />
                                                                                                            :
                                                                                                            itemData?.recordSourceId === constraintConfig.recordSourceId.Mobile ?
                                                                                                                <KTSVG path="/media/icons/figmaIcons/Mobile.svg" className='svg-icon-1 ms-4'
                                                                                                                    data-tooltip-id="mobile-source" data-tooltip-content='Mobile' /> :
                                                                                                                itemData?.recordSourceId === constraintConfig.recordSourceId.BankFeed ?
                                                                                                                    <KTSVG path="/media/icons/figmaIcons/Bank.svg" className='svg-icon-1 ms-4'
                                                                                                                        data-tooltip-id="bank-source" data-tooltip-content='Bank' />
                                                                                                                    : null}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </>
                                                                                            :
                                                                                            <tr>
                                                                                                <td colSpan={13}>
                                                                                                    <NoRecordFound />
                                                                                                </td>
                                                                                            </tr>
                                                                                        }
                                                                                    </>}
                                                                                    {subHistoryIsLoading && (
                                                                                        <tr>
                                                                                            <td colSpan={13} className="text-center">
                                                                                                <LoadingComponent />
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </Fragment>
                                                    ))}
                                                </>
                                            ) :
                                            (
                                                <tr>
                                                    <td colSpan={10}>
                                                        <NoRecordFound />
                                                    </td>
                                                </tr>
                                            )}
                                    </>
                                }
                                {isLoading && (
                                    <tr>
                                        <td colSpan={10} className="text-center">
                                            <LoadingComponent />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BudgetHistory
