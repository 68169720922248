import React, { useEffect, useMemo, useRef, useState } from 'react'
// import { IProfileDetails, profileDetailsInitValues as initialValues } from '../SettingsModel'
import {
  Autocomplete,
  Libraries,
  useJsApiLoader
} from '@react-google-maps/api'
import "cropperjs/dist/cropper.css"
import { useFormik } from 'formik'
import { Button, Modal } from 'react-bootstrap'
import Cropper, { ReactCropperElement } from "react-cropper"
import { FaTrash } from 'react-icons/fa6'
import ReactInputMask from 'react-input-mask'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import SearchSelect from '../../../../../../components/textFields/SearchSelect'
import TextField from '../../../../../../components/textFields/TextField'
import { selectAuth } from '../../../../../../features/authSlice'
import { useAppSelector } from '../../../../../../hooks'
import { useDeleteUserAccountImgMutation, useGetSingleUserQuery, useUpdateUserBasicInfoMutation, useUpdateUserMutation } from '../../../../../../services/authApi'
import { baseUrl } from '../../../../../../services/baseUrl'
import { useGetAllCountryQuery, useGetAllLocationsQuery } from '../../../../../../services/GeneralApi'
import { useUploadFileToAzureMutation } from '../../../../../../services/IntegrartionApi'
import labelKey from '../../../../../localization/label.json'

const profileDetailsSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  findAddress: Yup.string().required('Find a Address'),
  location: Yup.object().shape({
    id: Yup.number()
      .typeError('Office Location must be a required')
      .min(1, 'Office Location is required')
      .required('Office Location is required'),
  }),

})
interface ProfileDetailsProps {
  showOnlyHomeAddress?: boolean;
}
const ProfileDetails: React.FC<ProfileDetailsProps> = ({ showOnlyHomeAddress = false }) => {
  const [uploadFileToAzure, { data: azurefileData, isLoading: fileIsloading }] = useUploadFileToAzureMutation();
  const [deleteProfileImage, { isSuccess: delImgIsSuccess, isLoading: delImgIsLoading }] = useDeleteUserAccountImgMutation();
  const { data: getAllcountries, refetch: countriesRefetch } = useGetAllCountryQuery('')



  const navigate = useNavigate();
  const [updateContact, { isSuccess, isError, isLoading, }] = useUpdateUserBasicInfoMutation()
  // const [updateProfileImage, { isSuccess: imageIsSuccess }] = useUpdateProfileImageMutation()
  // const [deleteFileToAzureServer] = useDeleteFileToAzureServerMutation();

  const { userAccountID } = useAppSelector(selectAuth);
  const { data: userDetail, refetch, } = useGetSingleUserQuery(userAccountID);
  const { data: officeLocationsData, refetch: officeLocationRefetch } = useGetAllLocationsQuery('')

  const initialValues = {
    active: true,
    orgId: 0,
    role: {
      roleID: 0,
      name: ''
    },
    expenseType: {
      id: '',
      value: ''
    },
    locationType: {
      id: 0,
      value: ''
    },
    location: {
      id: 0,
      value: ''
    },
    userAccountID: userAccountID,
    employeeId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    cellPhone: '',
    businessPhone: '',
    businessExt: '',
    email: '',
    mgrApproveMaxLimit: 0,
    department: {
      id: 0,
      title: ''
    },
    designation: {
      id: 0,
      title: ''
    },
    division: {
      id: 0,
      title: ''
    },
    userRole: {
      fk_UserAccountID: 0,
      roleID: 0,
      roleName: ''
    },
    userOrg: {
      userInOrgId: 0,
      userInOrgName: ''
    },
    managerId: 0,
    managerName: '',
    country: {
      id: 0,
      title: ''
    },
    // city: {
    //   id: 0,
    //   title: ''
    // },
    city: '',
    addressLine1: '',
    addressLine2: '',
    state: '',
    postalCode: '',
    latitude: '',
    longitude: '',
    findAddress: ''
  }
  useEffect(() => {
    if (userAccountID) {
      if (userDetail) {
        formik.setValues({
          ...userDetail.result,
          orgId: userDetail.result?.userOrg?.userInOrgId,
          userRole: {
            fk_UserAccountID: userDetail.result?.userAccountID,
            roleID: userDetail.result.userRole?.roleID,
            roleName: userDetail.result.userRole?.roleName
          }
        })
      }
    } else {
      formik.setValues({ ...initialValues })
    }
  }, [userAccountID, userDetail]);
  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values, { resetForm }) => {
      updateContact({ ...values, userAccountID })
    },
  })

  useEffect(() => {
    if (isError) {
      toast.error('Some thing went wrong')
    }
    if (isSuccess) {
      toast.success('Profile is Updated Successfully')
      navigate("/account/overview");
    }

  }, [isError, isSuccess])

  const [showCropperModal, setShowCropperModal] = useState(false);
  // const [image, setImage] = useState();
  const [image, setImage] = useState<string | null>(null);

  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const cropperRef = useRef<ReactCropperElement>(null);
  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
    setShowCropperModal(true)
  };

  const getCropData = async () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();
      if (croppedCanvas) {
        // Convert cropped canvas to Blob object
        const croppedImageDataURL = croppedCanvas.toDataURL();
        setCroppedImage(croppedImageDataURL);
        croppedCanvas.toBlob(async (blob: any) => {
          if (blob) {
            const formData = new FormData();
            formData.append('file', blob, 'cropped_image.jpg'); // Set the desired file name ('cropped_image.jpg' in this case)

            try {
              // Upload the cropped image to Azure
              const response = await uploadFileToAzure(formData);
              const uploadedFileUrl = (response as { data?: any })?.data?.result;

              // Set the uploaded file URL in the state
              formik.setFieldValue(`imageUrl`, uploadedFileUrl);

              // Submit the form after image upload is successful
              setTimeout(() => {
                formik.handleSubmit();
              }, 200);
            } catch (error) {
              console.error(error);
              // Handle upload error if needed
            }
          }
        }, 'image/jpeg'); // Set the desired MIME type ('image/jpeg' for JPEG format)
      }
    }
    setShowCropperModal(false)

  };
  const handleError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = '/media/avatars/blank.png';
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleCancel = () => {
    setImage(null);
    setShowCropperModal(false);
    const fileInput = fileInputRef.current;
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const handleDeleteProfileImg = async () => {
    await deleteProfileImage(userAccountID)
  }

  useEffect(() => {
    if (delImgIsSuccess) {
      toast.success('Profile Image Deleted Successfully')
      // navigate("/account/overview");
      refetch()
    }
  }, [delImgIsSuccess])




  const libraries: Libraries = useMemo(() => ['places'], []);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: baseUrl.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });
  const originRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, ref: React.RefObject<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (ref.current && ref.current.value !== '') {
        if (ref === originRef) {
          formik.setFieldValue(`findAddress`, ref.current.value);
        }
      }
    }
  };

  const handleLocationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    ref: React.RefObject<HTMLInputElement>
  ) => {
    const updatedLocation = event.target.value;

    if (ref === originRef) {
      formik.setFieldValue(`findAddress`, updatedLocation);
    }
  };
  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedPostalCode, setSelectedPostalCode] = useState('');

  useEffect(() => {
    countriesRefetch();
    if (selectedCountry) {
      countriesRefetch()

    }
  }, [selectedCountry])

  const originAutocompleteRef = useRef<google.maps.places.Autocomplete>();
  const handleStartPlaceChanged = () => {
    const place = originAutocompleteRef.current?.getPlace(); // Get the selected place

    // console.log('place', place);

    if (place && place.geometry && place.geometry.location) {
      const selectedLat = place.geometry.location.lat();
      const selectedLng = place.geometry.location.lng();

      formik.setFieldValue('latitude', selectedLat.toString());
      formik.setFieldValue('longitude', selectedLng.toString());
      const formattedAddress = place?.formatted_address ?? '';
      formik.setFieldValue('findAddress', formattedAddress);

      if (place.address_components) {
        let city = '';
        let country = '';
        let postalCode = '';
        let state = '';
        let streetNumber = '';
        let route = '';


        place.address_components.forEach(component => {
          if (component.types.includes('street_number')) {
            streetNumber = component.long_name;
          }
          if (component.types.includes('route')) {
            route = component.long_name;
          }
          if (component.types.includes('locality')) {
            city = component.long_name;
            setSelectedCity(city)
            formik.setFieldValue(`city`, city);
          }
          if (component.types.includes('country')) {
            country = component.long_name;
            setSelectedCountry(country)
            // formik.setFieldValue(`country`, country);

            // Find the matching country in the options and set it as selected
            // const matchingCountry = getAllcountries?.result.find((option: any) => option.title === country);
            const matchingCountry = getAllcountries?.result.find((option: any) =>
              option.title.toLowerCase() === country.toLowerCase()
            );

            if (matchingCountry) {
              formik.setFieldValue(`country.id`, matchingCountry.id);
              formik.setFieldValue(`country.title`, matchingCountry.title);
            }
            else {
              formik.setFieldValue(`country.title`, country);
              // console.error('No matching country found for:', country);
            }
          }
          if (component.types.includes('postal_code')) {
            postalCode = component.long_name;
            setSelectedPostalCode(postalCode)
            formik.setFieldValue(`postalCode`, postalCode);
            // console.log('Postal Code:', postalCode);
          }
          if (component.types.includes('administrative_area_level_1')) {
            state = component.long_name;
            setSelectedState(state)
            formik.setFieldValue(`state`, state);
            // console.log('State:', state);
          }
        });
        const address = `${streetNumber} ${route}`.trim();
        setSelectedAddress(address)
        formik.setFieldValue(`addressLine1`, address);
        // console.log('City:', city);
        // console.log('Country:', country);
      }
    }
  };

  return (
    <div className='card mb-5 mb-xl-10'>
      {!showOnlyHomeAddress && (
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Profile Details</h3>
          </div>
        </div>
      )}

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          {showOnlyHomeAddress && (
            <>
              <HomeAddress
                formik={formik}
                isLoaded={isLoaded}
                originRef={originRef}
                labelKey={labelKey}
                handleKeyDown={handleKeyDown}
                handleLocationChange={handleLocationChange}
                selectedAddress={selectedAddress}
                selectedCity={selectedCity}
                selectedState={selectedState}
                selectedPostalCode={selectedPostalCode}
                getAllcountries={getAllcountries}
                originAutocompleteRef={originAutocompleteRef}
                handleStartPlaceChanged={handleStartPlaceChanged}
              />
              <OfficeAddress
                formik={formik}
                officeLocationsData={officeLocationsData}
              />
            </>
          )}
          {!showOnlyHomeAddress && (
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <Modal show={showCropperModal} onHide={handleCancel} size="lg">
                  <Modal.Body>
                    <Cropper
                      style={{ height: 400, width: "100%" }}
                      initialAspectRatio={1}
                      preview=".img-preview"
                      src={image}
                      ref={cropperRef}
                      viewMode={1}
                      guides={true}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      background={false}
                      responsive={true}
                      checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCancel}>
                      Cancel
                    </button>
                    <button className="btn btn-primary" onClick={getCropData}>
                      Crop Image
                    </button>
                  </Modal.Footer>
                </Modal>
                <div className="col-md-3">
                  {/* <label className='col-form-label fw-bold fs-6 ps-2'>Avatar</label> */}
                  <div>
                    <div
                      className='image-input image-input-outline'
                      data-kt-image-input='true'>
                      <div className='image-input-wrapper w-125px h-125px'>
                        {!fileIsloading &&
                          <>
                            {croppedImage ?
                              <img src={croppedImage ? croppedImage : '/media/avatars/blank.png'}
                                width={120} height={120} alt=""
                                className='rounded' />
                              :
                              <img src={userDetail?.result?.imageUrl ? userDetail?.result?.imageUrl : '/media/avatars/blank.png'}
                                width={120} height={120} alt=""
                                className='position-absolute rounded top-0 mt-1'
                                onError={handleError}
                              />
                            }
                          </>
                        }
                        <div className='d-flex justify-content-center align-items-center h-100'>
                          {fileIsloading &&
                            <div className="spinner-border spinner-border-sm" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>}
                        </div>
                      </div>
                      <input type="file" className='w-100 h-100 position-absolute opacity-0 top-0 cursor-pointer'
                        name="imageFile" onChange={onChange}
                        id="imageFile"
                        accept=".png, .jpg, .jpeg"
                        ref={fileInputRef}
                      />
                      {userDetail?.result?.imageUrl &&
                        <Button variant='light' className='position-absolute bottom-0 p-2 m-3' onClick={handleDeleteProfileImg} style={{ right: 0 }}>
                          {delImgIsLoading ?
                            <div className="spinner-border spinner-border-sm" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            :
                            <FaTrash className='text-danger ' />
                          }
                        </Button>
                      }
                    </div>
                    <div className='position-relative' style={{ bottom: "22px", left: "-6px" }}>
                      <i className='fa fa-camera text-white bg-primary h-25px w-25px rounded-circle d-flex justify-content-center align-items-center'></i>
                      <input type="file" className='h-25px w-25px rounded-circle position-absolute opacity-0 top-0'
                        name="imageFile" onChange={onChange}
                        id="imageFile"
                        accept=".png, .jpg, .jpeg"
                        ref={fileInputRef}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className='row mb-6'>
                    <input type="hidden"  {...formik.getFieldProps('userAccountID')} />
                    {/* <label className='col-lg-12 col-form-label required fw-bold fs-6'>Full Name</label> */}
                    <div className='col-lg-12'>
                      <div className='border border-3 p-5 position-relative'>
                        <h2 className='position-absolute bg-light py-2 px-3 rounded' style={{ top: '-15px' }}>Contact Detail</h2>
                        <div className='row mt-5'>
                          <div className='col-lg-4 fv-row mb-4'>
                            <TextField
                              label='First Name'
                              required={true}
                              type='text'
                              placeholder='First name'
                              {...formik.getFieldProps('firstName')}
                            />
                            {formik.touched.firstName && formik.errors.firstName && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.firstName}</div>
                              </div>
                            )}
                          </div>
                          <div className='col-lg-4 fv-row mb-4'>
                            <TextField
                              label='Middle Name'
                              type='text'
                              placeholder='Middle name'
                              {...formik.getFieldProps('middleName')}
                            />
                          </div>
                          <div className='col-lg-4 fv-row mb-4'>
                            <TextField
                              label='Last Name'
                              required={true}
                              type='text'
                              placeholder='Last name'
                              {...formik.getFieldProps('lastName')}
                            />
                            {formik.touched.lastName && formik.errors.lastName && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.lastName}</div>
                              </div>
                            )}
                          </div>
                          <div className='col-lg-4'>
                            <label className='form-label fw-bold text-dark fs-6 text-nowrap'>Contact Phone</label>
                            <div>
                              <ReactInputMask
                                mask="999-999-9999"
                                maskChar="_"
                                type='tel'
                                className='form-control form-control-lg form-control-solid default-input'
                                placeholder='Phone number'
                                {...formik.getFieldProps('cellPhone')}
                              />
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <label className='form-label fw-bold text-dark fs-6 text-nowrap'>Business Phone</label>
                            <div>
                              <ReactInputMask
                                mask="999-999-9999"
                                maskChar="_"
                                type='tel'
                                className='form-control form-control-lg form-control-solid default-input'
                                placeholder='Phone number'
                                {...formik.getFieldProps('businessPhone')}
                              />
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <label className='form-label fw-bold text-dark fs-6 text-nowrap'>Business Ext</label>
                            <div>
                              <ReactInputMask
                                mask="9999"
                                maskChar="_"
                                type='tel'
                                className='form-control form-control-lg form-control-solid default-input'
                                placeholder='Business Ext'
                                {...formik.getFieldProps('businessExt')}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-md-12 mt-10">
                      <div className='border border-3 p-5 position-relative'>
                        <h2 className='position-absolute bg-light py-2 px-3 rounded' style={{ top: '-15px' }}>Home Address</h2>
                        <div className="row">
                          <div className="col-lg-12 mt-5">
                            <label className='fs-5 fw-bold mb-2 required'>
                              {labelKey.findAddress}
                            </label>
                            {isLoaded && (
                              <Autocomplete
                                onLoad={(autocomplete) => {
                                  originAutocompleteRef.current = autocomplete;
                                  autocomplete.addListener('place_changed', handleStartPlaceChanged);
                                }}
                                onPlaceChanged={() => {
                                }}>
                                <input type='text'
                                  className='form-control form-control-lg form-control-solid default-input'
                                  placeholder='Find a Address'
                                  ref={originRef}
                                  onKeyDown={(event) => handleKeyDown(event, originRef)}
                                  onChange={(event) => handleLocationChange(event, originRef)}
                                  value={formik.values.findAddress}
                                />
                              </Autocomplete>
                            )}
                            {formik.touched.findAddress && formik.errors.findAddress && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.findAddress}</div>
                              </div>
                            )}
                          </div>
                          <div className="col-md-7 col-lg-4 mt-5">
                            <TextField
                              label={labelKey.addressLine1}
                              type='text'
                              placeholder='Enter Address Line 1'
                              {...formik.getFieldProps('addressLine1')}
                              value={formik.values.addressLine1 || selectedAddress}
                              disabled={true}
                            />
                          </div>
                          <div className="col-md-5 col-lg-2 mt-5">
                            <SearchSelect
                              label={labelKey.country}
                              options={[
                                ...(getAllcountries?.result || []).map((option: any, index: any) => ({
                                  value: option.id,
                                  label: option?.title,
                                })),
                              ]}
                              placeholder='Select Country'
                              value={
                                formik.values.country?.id
                                  ? {
                                    value: formik.values.country?.id,
                                    label: formik.values.country?.title || '',
                                  }
                                  : null
                              }
                              onChange={(selectedOption: any) => {
                                formik.setFieldValue(`country.id`, selectedOption?.value || null);
                                formik.setFieldValue(`country.title`, selectedOption?.label || null);
                              }}
                              disabled={true}
                            />
                          </div>
                          <div className="col-md-4 col-lg-2 mt-5">
                            <TextField
                              label={labelKey.city}
                              type='text'
                              placeholder='Enter city'
                              {...formik.getFieldProps('city')}
                              value={formik.values.city || selectedCity}
                              disabled={true}
                            />
                          </div>
                          <div className='col-md-4 col-lg-2 mt-5'>
                            <TextField
                              label={labelKey.state}
                              type='text'
                              placeholder='Enter State'
                              {...formik.getFieldProps('state')}
                              value={formik.values.state || selectedState}
                              disabled={true}
                            />

                          </div>
                          <div className='col-md-4 col-lg-2 mt-5'>
                            <TextField
                              label={labelKey.postalCode}
                              type='text'
                              placeholder='Enter Postal Code'
                              {...formik.getFieldProps('postalCode')}
                              value={formik.values.postalCode || selectedPostalCode}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <HomeAddress
                      formik={formik}
                      isLoaded={isLoaded}
                      originRef={originRef}
                      labelKey={labelKey}
                      handleKeyDown={handleKeyDown}
                      handleLocationChange={handleLocationChange}
                      selectedAddress={selectedAddress}
                      selectedCity={selectedCity}
                      selectedState={selectedState}
                      selectedPostalCode={selectedPostalCode}
                      getAllcountries={getAllcountries}
                      originAutocompleteRef={originAutocompleteRef}
                      handleStartPlaceChanged={handleStartPlaceChanged}
                    />

                    <OfficeAddress
                      formik={formik}
                      officeLocationsData={officeLocationsData}
                    />

                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={`card-footer d-flex justify-content-end py-6 ${showOnlyHomeAddress ? 'px-0' : 'px-9'}`}>
            <button type='submit' className='btn btn-primary' disabled={isLoading}>
              {!isLoading && (showOnlyHomeAddress ? 'Update Address' : 'Save Changes')}
              {isLoading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export { ProfileDetails }


export const HomeAddress = ({ formik, isLoaded, originRef, labelKey, handleKeyDown, handleLocationChange, selectedAddress, selectedCity, selectedState, selectedPostalCode, getAllcountries, originAutocompleteRef, handleStartPlaceChanged }: any) => {
  return (
    <div className="col-md-12 mt-10">
      <div className='border border-3 p-5 position-relative'>
        <h2 className='position-absolute bg-light py-2 px-3 rounded' style={{ top: '-15px' }}>Home Address</h2>
        <div className="row">
          <div className="col-lg-12 mt-5">
            <label className='fs-5 fw-bold mb-2 required'>
              {labelKey.findAddress}
            </label>
            {isLoaded && (
              <Autocomplete
                onLoad={(autocomplete) => {
                  originAutocompleteRef.current = autocomplete;
                  autocomplete.addListener('place_changed', handleStartPlaceChanged);
                }}
                onPlaceChanged={() => { }}>
                <input type='text'
                  className='form-control form-control-lg form-control-solid default-input'
                  placeholder='Find a Address'
                  ref={originRef}
                  onKeyDown={(event) => handleKeyDown(event, originRef)}
                  onChange={(event) => handleLocationChange(event, originRef)}
                  value={formik.values.findAddress}
                />
              </Autocomplete>
            )}
            {formik.touched.findAddress && formik.errors.findAddress && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.findAddress}</div>
              </div>
            )}
          </div>
          <div className="col-md-7 col-lg-4 mt-5">
            <TextField
              label={labelKey.addressLine1}
              type='text'
              placeholder='Enter Address Line 1'
              {...formik.getFieldProps('addressLine1')}
              value={formik.values.addressLine1 || selectedAddress}
              disabled={true}
            />
          </div>
          <div className="col-md-7 col-lg-4 mt-5">
            <TextField
              label={labelKey.addressLine2}
              type='text'
              placeholder='Enter Address Line 2'
              {...formik.getFieldProps('addressLine2')}
              value={formik.values.addressLine2}
            />
          </div>
          <div className="col-md-5 col-lg-4 mt-5">
            <SearchSelect
              label={labelKey.country}
              options={[
                ...(getAllcountries?.result || []).map((option: any) => ({
                  value: option.id,
                  label: option?.title,
                })),
              ]}
              placeholder='Select Country'
              value={
                formik.values.country?.id
                  ? {
                    value: formik.values.country?.id,
                    label: formik.values.country?.title || '',
                  }
                  : null
              }
              onChange={(selectedOption) => {
                formik.setFieldValue(`country.id`, selectedOption?.value || null);
                formik.setFieldValue(`country.title`, selectedOption?.label || null);
              }}
              disabled={true}
            />
          </div>
          <div className="col-md-4 col-lg-4 mt-5">
            <TextField
              label={labelKey.city}
              type='text'
              placeholder='Enter city'
              {...formik.getFieldProps('city')}
              value={formik.values.city || selectedCity}
              disabled={true}
            />
          </div>
          <div className='col-md-4 col-lg-4 mt-5'>
            <TextField
              label={labelKey.state}
              type='text'
              placeholder='Enter State'
              {...formik.getFieldProps('state')}
              value={formik.values.state || selectedState}
              disabled={true}
            />
          </div>
          <div className='col-md-4 col-lg-4 mt-5'>
            <TextField
              label={labelKey.postalCode}
              type='text'
              placeholder='Enter Postal Code'
              {...formik.getFieldProps('postalCode')}
              value={formik.values.postalCode || selectedPostalCode}
              disabled={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const OfficeAddress = ({ formik, officeLocationsData }: any) => {
  return (
    <div className='col-md-12 b-5 mt-10'>
      <div className='border border-3 p-5 position-relative'>
        {/* <hr className='text-muted' /> */}
        <h2 className='position-absolute bg-light py-2 px-3 rounded' style={{ top: '-15px' }}>Office Address</h2>
        <SearchSelect
          label={labelKey.officeLocation}
          labelClass='mt-5'
          required
          options={(officeLocationsData?.result || []).map((option: any, index: any) => ({
            value: option.id,
            label: option?.value,
            key: option.id,
          }))}

          value={
            formik.values.location?.id
              ? {
                value: formik.values.location?.id,
                label: formik.values.location?.value || '',
              }
              : null
          }
          onChange={(selectedOption: any) => {
            formik.setFieldValue(`location.id`, selectedOption?.value || null);
            formik.setFieldValue(`location.value`, selectedOption?.label || null);
          }}
        />
        {formik.touched.location?.id && formik.errors.location?.id && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{formik.errors.location?.id}</div>
          </div>
        )}
        <p className='mt-2 fw-6 text-primary fw-semibold'><span className='fw-bold text-dark'>Note:</span> This is your office Location. It will be default charge location on all expenses. This location is also used for mileage calculation.</p>
      </div>
    </div>
  );
};

