/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { Header } from './Header'
import { Topbar } from './Topbar'
import { DefaultTitle } from './page-title/DefaultTitle'
import { useAppSelector } from '../../../../hooks'
import { selectAuth } from '../../../../features/authSlice'
import { constraintConfig } from '../../../../constraintConfig'

export function HeaderWrapper() {
  const { config, classes, attributes } = useLayout()
  const { header, aside } = config
  const { roleID } = useAppSelector(selectAuth);
  const themeModeMenuValue = localStorage.getItem('kt_theme_mode_menu');


  // Helper to determine the link based on roleID
  const getLinkPath = (roleID: number | null) => {
    switch (roleID) {
      case constraintConfig.roleID.role10:
        return '/ap-approved';
      case constraintConfig.roleID.roleIDPortalSupport:
        return '/portal-support-requests';
      case constraintConfig.roleID.roleExecutive:
        return '/approval-queue';
      default:
        return '/'; // Fallback for null or unrecognized roleID
    }
  };
  

  // Component to render the logo dynamically
  const renderLogo = (linkPath: string) => (
    <div className="header-logo me-5 me-md-5 flex-grow-1 flex-xl-grow-0">
      <Link to={linkPath}>
        <img
          alt="Logo"
          src={toAbsoluteUrl('/media/logos/logomain-white.png')}
          className="logo-default h-40px"
        />
        <img
          alt="Logo"
          src={toAbsoluteUrl(
            themeModeMenuValue === 'dark'
              ? '/media/logos/logomain-white.png'
              : '/media/logos/logomain.png'
          )}
          className="logo-sticky h-40px"
        />
      </Link>
    </div>
  );

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      data-kt-sticky='true'
      data-kt-sticky-name='header'
      data-kt-sticky-offset="{default: '200px', xl: '300px'}"
      {...attributes.headerMenu}
    >
      <div className={clsx(
        // classes.headerContainer.join(' '),
        'd-flex align-items-center container-fluid ')}>
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-xl-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}

        <div
          className='d-flex topbar align-items-center d-xl-none ms-n2 me-3'
          title='Show aside menu'
        >
          <div
            className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x' />
          </div>
        </div>

        {/* {roleID === 10 ?
          <div className='header-logo me-5 me-md-5 flex-grow-1 flex-xl-grow-0'>
            <Link to='/ap-approved'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/logomain-white.png')}
                className='logo-default h-40px'
              />
              {themeModeMenuValue === 'dark' ?
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/logomain-white.png')}
                  className='logo-sticky h-40px'
                /> :
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/logomain.png')}
                  className='logo-sticky h-40px'
                />
              }
            </Link>
          </div>
          : roleID === 13 ?
            <div className='header-logo me-5 me-md-5 flex-grow-1 flex-xl-grow-0'>
              <Link to='/portal-support-requests'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/logomain-white.png')}
                  className='logo-default h-40px'
                />
                {themeModeMenuValue === 'dark' ?
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/logomain-white.png')}
                    className='logo-sticky h-40px'
                  /> :
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/logomain.png')}
                    className='logo-sticky h-40px'
                  />
                }
              </Link>
            </div>
            : roleID === constraintConfig.roleID.roleExecutive ?
              <div className='header-logo me-5 me-md-5 flex-grow-1 flex-xl-grow-0'>
                <Link to='/approval-queue'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/logomain-white.png')}
                    className='logo-default h-40px'
                  />
                  {themeModeMenuValue === 'dark' ?
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/logos/logomain-white.png')}
                      className='logo-sticky h-40px'
                    /> :
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/logos/logomain.png')}
                      className='logo-sticky h-40px'
                    />
                  }
                </Link>
              </div>
              :
              <div className='header-logo me-5 me-md-5 flex-grow-1 flex-xl-grow-0'>
                <Link to='/'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/logomain-white.png')}
                    className='logo-default h-40px'
                  />
                  {themeModeMenuValue === 'dark' ?
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/logos/logomain-white.png')}
                      className='logo-sticky h-40px'
                    /> :
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/logos/logomain.png')}
                      className='logo-sticky h-40px'
                    />
                  }
                </Link>
              </div>
        } */}

        {/* Render the Logo */}
        {renderLogo(getLinkPath(roleID))}

        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch justify-content-between flex-xl-grow-1'>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
