/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import { useEffect, useState } from 'react';
import DateField from '../../../../../components/textFields/DateField';
import TextAreaField from '../../../../../components/textFields/TextAreaField';
import TextField from '../../../../../components/textFields/TextField';
import { constraintConfig } from '../../../../../constraintConfig';
import { selectAuth } from '../../../../../features/authSlice';
import { useAppSelector } from '../../../../../hooks';
import { useGetRemaningBudgetQuery } from '../../../../../services/BudgetApi';
import labelKey from "../../../../localization/label.json";

type Props = {
  formik: any
  expenseItems?: any
  getAllExpenseType?: any
}
const Step1 = ({ formik, expenseItems, getAllExpenseType }: Props) => {
  const currentDate = moment().format('YYYY-MM-DD')
  const [wordCount, setWordCount] = useState(0);
  const { roleID, userAccountID } = useAppSelector(selectAuth)
  // const { data: getAllExpenseType, refetch: expenseTypeRefetch } = useGetAllExpenseTypeQuery('')
  const { data: flexRemaingBudget, refetch } = useGetRemaningBudgetQuery({
    userAccountId: userAccountID,
    expenseDate: formik.values.reportDate,
  })
  useEffect(() => {
    refetch()
    // expenseTypeRefetch()
  }, [])
  const handleTextAreaChange = (event: any) => {
    const text = event.target.value;
    const words = text.trim().split("");
    const wordCount = words.reduce((count: any, word: any) => count + word.length, 0);
    setWordCount(wordCount);
  };
  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100' style={{ height: "75vh" }}>

        {/*begin::Form Group */}
        <div className="row m-0">
          {/* <div className='col-md-6 mb-10'>
            <SearchSelect
              label={labelKey.expenseType}
              required
              options={[
                { value: '', label: 'Select Type' },  // Default 'Select Type' option
                ...(Array.isArray(getAllExpenseType?.result)
                  ? getAllExpenseType.result.map((option: any) => ({
                    value: option?.id,
                    label: option?.value,
                    key: option?.id,
                  }))
                  : []
                )
              ]}
              placeholder="Select Type"
              value={
                formik.values?.expenseType?.id
                  ? {
                    value: formik.values?.expenseType?.id,
                    label: formik.values?.expenseType?.title || '',
                  }
                  : null
              }
              onChange={(selectedOption: any) => {
                formik.setFieldValue('expenseType.id', selectedOption.value);
                formik.setFieldValue('expenseType.title', selectedOption.label);
              }}
            />

          </div> */}
          <div className='col-md-6 mb-10'>
            <TextField
              label={labelKey.reportName}
              required={true}
              type='text'
              placeholder='Enter Report Name'
              {...formik.getFieldProps('title')}
              value={formik.values.title}
            />
          </div>
          <div className='col-md-6 mb-10'>
            {/* <TextField
              label={labelKey.reportingPeriod}
              required={true}
              type='date'
              {...formik.getFieldProps('reportDate')}
              value={formik.values.reportDate ? moment(formik.values.reportDate).format('YYYY-MM-DD') : ''}
              max={currentDate}
              disabled={expenseItems?.some((itemData: any) => itemData?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard)}
            /> */}
            <DateField
              label={labelKey.reportingPeriod}
              // required={true}
              selected={formik.values.reportDate ? new Date(formik.values.reportDate) : null}
              onChange={(date) => formik.setFieldValue('reportDate', date)}
              max={new Date()}
              disabled={expenseItems?.some((itemData: any) => itemData?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard)} />
          </div>
          {/* {roleID !== constraintConfig.roleID.role2 &&
            <>
              {parseInt(formik.values?.expenseType?.id) === constraintConfig.expenseType.flex && (
                <div className='col-md-6'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>{flexRemaingBudget?.result === 0 ? <>{labelKey.budget}</> : <>{labelKey.remainingBudget}</>}</label>
                  <p className='fs-4 fw-bold mb-0 form-control form-control-lg form-control-solid' style={{ border: '1px solid #f0f0f0' }}>${flexRemaingBudget?.result ? flexRemaingBudget?.result : "0"}</p>
                </div>
              )}
            </>
          } */}
          <div className='col-md-12 mb-10'>
            <div>
              <TextAreaField
                label={labelKey.purposeOfExpense}
                {...formik.getFieldProps('expenseDetail')}
                cols={20}
                rows={7}
                placeholder='Enter Expense Detail'
                value={formik.values.expenseDetail || ''}
                onChange={(e) => {
                  formik.handleChange(e);
                  handleTextAreaChange(e);
                }}
              />
              <p className="text-muted text-end">Characters: ({wordCount}/500)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Step1 };

