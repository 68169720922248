

import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { KTSVG } from '../../../_metronic/helpers'
import { IoIosCloudUpload } from 'react-icons/io'
import tosterKey from '../../localization/toster.json'
import labelKey from '../../localization/label.json'
import { useReadDraftInvoicesMutation } from '../../../services/BackgroundJobApi'
import { useUploadOCRInvoicesMutation } from '../../../services/InvoiceDataDraftApi'

type Props = {
    show: boolean
    handleClose: () => void
    data?: any
}

const AddInvoiceModal = ({ show, handleClose, data }: Props) => {
    const [uploadFile, { isLoading: uploadFileIsLoading, isSuccess: uploadFileIsSuccess, isError, error }] = useUploadOCRInvoicesMutation()
    // const [readDraftInvoices, { isLoading: readLoading }] = useReadDraftInvoicesMutation()

    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const [imagePreviews, setImagePreviews] = useState<(string | null)[]>([]) // Array of image previews
    const [isPdfs, setIsPdfs] = useState<boolean[]>([]) // Array to keep track of file types (PDF or image)

    const [selectedFiles, setSelectedFiles] = useState<File[]>([]) // Store multiple selected files

    const handleImageChangeFirst = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files
        if (files) {
            const newPreviews: (string | null)[] = []
            const newPdfs: boolean[] = []
            const selectedFilesArray: File[] = Array.from(files)

            setSelectedFiles(selectedFilesArray) // Store all selected files in state

            selectedFilesArray.forEach((file) => {
                newPdfs.push(file.type === 'application/pdf') // Check if each file is a PDF
                const reader = new FileReader()
                reader.onload = () => {
                    newPreviews.push(reader.result as string) // Set image preview for each file
                    if (newPreviews.length === selectedFilesArray.length) {
                        setImagePreviews(newPreviews) // Set previews after all files are processed
                    }
                }
                reader.readAsDataURL(file)
            })
            setIsPdfs(newPdfs) // Set PDF type flags
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (selectedFiles.length > 0) {
            const formData = new FormData()

            selectedFiles.forEach((file) => {
                formData.append('files', file) // Append each file to the FormData object
            })

            const response = await uploadFile(formData)
            // const uploadedFileUrls = (response as { data?: any })?.data?.result

            // if (uploadedFileUrls) {
            //     // Assuming the response contains file IDs for each uploaded file
            //     for (const fileId of uploadedFileUrls) {
            //         await readDraftInvoices({ fileId })
            //     }
            // }
        }
    }

    const handleRemoveFile = (index: number) => {
        const updatedFiles = selectedFiles.filter((_, i) => i !== index)
        const updatedPreviews = imagePreviews.filter((_, i) => i !== index)
        const updatedIsPdfs = isPdfs.filter((_, i) => i !== index)

        setSelectedFiles(updatedFiles)
        setImagePreviews(updatedPreviews)
        setIsPdfs(updatedIsPdfs)

        if (fileInputRef.current) {
            fileInputRef.current.value = ''
        }
    }

    useEffect(() => {
        if (uploadFileIsSuccess) {
            toast.success(tosterKey.receiptAddedSuccessfully)
            handleClose()
            setImagePreviews([])
            setSelectedFiles([])
        }
    }, [uploadFileIsSuccess])

    useEffect(() => {
        if (isError && error) {
            let errorMessage = 'Something went wrong'

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message
            }
            toast.error(errorMessage)
        }
    }, [isError, error])

    const handleModalClose = () => {
        setImagePreviews([]) // Clear image previews
        setSelectedFiles([]) // Clear selected files
        handleClose()
    }

    return (
        <Modal
            aria-hidden='true'
            dialogClassName='modal-dialog modal-md modal-dialog-centered'
            show={show}
            onHide={handleModalClose}
        >
            <form className='form w-100' onSubmit={handleSubmit}>
                <div className='modal-header'>
                    <h2 className='mb-0'>Add Receipt</h2>
                    <div className='d-flex gap-3'>
                        <span className='btn btn-light btn-sm' onClick={handleModalClose}>
                            {labelKey.cancel}
                        </span>
                        <button
                            type='submit'
                            className='btn btn-primary btn-sm'
                            disabled={uploadFileIsLoading}
                        >
                            {!uploadFileIsLoading && (
                                <span className='indicator-label'>{labelKey.submit}</span>
                            )}
                            {(uploadFileIsLoading) && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>

                <div className='modal-body py-lg-10 px-lg-10'>
                    <div className='row'>
                        <div className='col-md-12 mb-5'>
                            {!imagePreviews.length && (
                                <div className='position-relative'>
                                    <div className='border border-dashed border-secondary border-4 w-100 h-100px d-flex flex-column justify-content-center align-items-center'>
                                        <IoIosCloudUpload className='text-muted' style={{ fontSize: '40px' }} />
                                        <h3 className='text-muted text-center'>Drag & Drop Images Here</h3>
                                    </div>
                                    <input
                                        type='file'
                                        id='file'
                                        name='file'
                                        accept='.png, .jpg, .jpeg, .pdf'
                                        ref={fileInputRef}
                                        onChange={handleImageChangeFirst}
                                        className='form-control form-control-solid default-input h-100px position-absolute top-0 opacity-0'
                                        multiple
                                    />
                                </div>
                            )}

                            {imagePreviews.map((preview, index) => (
                                <div key={index} className='position-relative mb-3'>
                                    <div
                                        className='btn btn-icon btn-bg-light hover-danger btn-sm position-absolute m-4 '
                                        style={{ left: 10, zIndex: 12, top: '13px' }}
                                        onClick={() => handleRemoveFile(index)}
                                    >
                                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 text-hover-danger' />
                                    </div>

                                    {isPdfs[index] ? (
                                        <embed
                                            src={`${preview}#toolbar=0&navpanes=0&scrollbar=0`}
                                            type='application/pdf'
                                            className='w-100 p-2'
                                            style={{ height: '400px' }}
                                        />
                                    ) : (
                                        <img
                                            src={preview as string}
                                            alt='Preview'
                                            className='w-100 p-2'
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    )
}

export default AddInvoiceModal


// import React, { useEffect, useRef, useState } from 'react'
// import { Modal } from 'react-bootstrap'
// import { toast } from 'react-toastify'
// import { KTSVG } from '../../../_metronic/helpers'
// import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent'
// import { useReadDraftInvoicesMutation } from '../../../services/BackgroundJobApi'
// import { useUploadOCRInvoiceMutation, useUploadOCRInvoicesMutation } from '../../../services/InvoiceDataDraftApi'

// import { IoIosCloudUpload } from 'react-icons/io'
// import tosterKey from '../../localization//toster.json'
// import labelKey from '../../localization/label.json'

// type Props = {
//     show: boolean
//     handleClose: () => void
//     data?: any
// }

// const AddInvoiceModal = ({ show, handleClose, data }: Props) => {
//     const [uploadFile, { isLoading: uploadFileIsLoading, isSuccess: uploadFileIsSuccess, isError, error }] = useUploadOCRInvoicesMutation()
//     const [readDraftInvoices, { isLoading: readLoading }] = useReadDraftInvoicesMutation()

//     const fileInputRef = useRef<HTMLInputElement | null>(null)
//     const [imagePreviewFirst, setImagePreviewFirst] = useState<string | null>(null)
//     const [isPdf, setIsPdf] = useState<boolean>(false)

    
//     const [selectedFile, setSelectedFile] = useState<File | null>(null) // Store the selected file

//     const handleImageChangeFirst = (event: React.ChangeEvent<HTMLInputElement>) => {
//         const file = event.target.files?.[0]
//         if (file) {
//             setSelectedFile(file) // Store the file in state
//             setIsPdf(file.type === 'application/pdf')


//             const reader = new FileReader()
//             reader.onload = () => {
//                 setImagePreviewFirst(reader.result as string) // Set the image preview
//             }
//             reader.readAsDataURL(file) // Read file as Data URL
//         }
//     }

//     const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
//         event.preventDefault()

//         if (selectedFile) {
//             const formData = new FormData()
//             formData.append('file', selectedFile)

//             const response = await uploadFile(formData)

//             const uploadedFileUrl = (response as { data?: any })?.data?.result
//             if (uploadedFileUrl) {
//                 await readDraftInvoices({ fileId: uploadedFileUrl })
//             }
//         }
//     }

//     const [fileRemoved, setFileRemoved] = useState(false);
//     const handleRemoveFile = () => {
//         // Reset the uploaded file and related states
//         setFileRemoved(true);

//         // Reset the file input value
//         const fileInput = fileInputRef.current;
//         if (fileInput) {
//             fileInput.value = ''; // Clear the input value
//         }
//     };
//     useEffect(() => {
//         // Check if the file was removed and update UI accordingly
//         if (fileRemoved) {
//             // Reset file-related states
//             setImagePreviewFirst(null);
//             // Reset file removed state
//             setFileRemoved(false);
//         }
//     }, [fileRemoved]);

//     useEffect(() => {
//         if (uploadFileIsSuccess) {
//             const timeoutId = setTimeout(() => {
//                 toast.success(tosterKey.receiptAddedSuccessfully)
//                 handleClose();
//                 setImagePreviewFirst(null);
//                 setSelectedFile(null); // Reset the file after successful upload
//             }, 5000); // 2000 milliseconds (2 seconds) delay

//             // Clear timeout if the component unmounts before the timeout completes
//             return () => clearTimeout(timeoutId);
//         }
//     }, [uploadFileIsSuccess])

//     useEffect(() => {
//         if (isError && error) {
//             let errorMessage = 'Something went wrong'

//             if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
//                 errorMessage = (error.data as { message: string }).message
//             }
//             toast.error(errorMessage)
//         }
//     }, [isError, error])
//     const handleModalClose = () => {
//         setImagePreviewFirst(null); // Clear image preview
//         handleClose();
//       };
//     return (
//         <Modal
//             aria-hidden='true'
//             dialogClassName='modal-dialog modal-md modal-dialog-centered'
//             show={show}
//             onHide={handleModalClose}
//         >
//             <form className='form w-100' onSubmit={handleSubmit}>

//                 <div className='modal-header'>
//                     <h2 className='mb-0'>Add Receipt</h2>
//                     <div className='d-flex gap-3'>
//                         <span className='btn btn-light btn-sm' onClick={handleModalClose}>
//                             {labelKey.cancel}
//                         </span>
//                         <button
//                             type='submit'
//                             className='btn btn-primary btn-sm'
//                             disabled={readLoading || uploadFileIsLoading}
//                         >
//                             {!readLoading && !uploadFileIsLoading && (
//                                 <span className='indicator-label'>{labelKey.submit}</span>
//                             )}
//                             {(readLoading || uploadFileIsLoading) && (
//                                 <span className='indicator-progress' style={{ display: 'block' }}>
//                                     {labelKey.pleaseWait}...
//                                     <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
//                                 </span>
//                             )}
//                         </button>
//                     </div>
//                 </div>

//                 <div className='modal-body py-lg-10 px-lg-10'>
//                     <div className='row'>
//                         <div className='col-md-12 mb-5'>
//                             {!imagePreviewFirst && (
//                                 <div className='position-relative'>
//                                     <div className='border border-dashed border-secondary border-4 w-100 h-100px d-flex flex-column justify-content-center align-items-center'>
//                                         <IoIosCloudUpload className='text-muted' style={{ fontSize: '40px' }} />
//                                         <h3 className='text-muted text-center'>Drag & Drop Image Here</h3>
//                                     </div>
//                                     <input
//                                         type='file'
//                                         id='file'
//                                         name='file'
//                                         accept='.png, .jpg, .jpeg, .pdf'
//                                         ref={fileInputRef}
//                                         onChange={handleImageChangeFirst}
//                                         className='form-control form-control-solid default-input h-100px position-absolute top-0 opacity-0'
//                                         multiple
//                                     />
//                                 </div>
//                             )}
//                             {imagePreviewFirst && (
//                                 <div className='position-relative'>
//                                     <div
//                                         className='btn btn-icon btn-bg-light hover-danger btn-sm position-absolute m-4 '
//                                         style={{ left: 10, zIndex: 12 ,top:'13px'}}
//                                         // onClick={() => deleteAttachmentHandler(index)}
//                                         onClick={handleRemoveFile}
//                                     >
//                                         <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 text-hover-danger' svgClassName='svg-danger' />
//                                     </div>
//                                     {isPdf ? (
//                                         <embed
//                                             // src={imagePreviewFirst}
//                                             src={`${imagePreviewFirst}#toolbar=0&navpanes=0&scrollbar=0`}
//                                             type='application/pdf'
//                                             className='w-100 p-2'
//                                             style={{ height: '400px' }}
//                                         />
//                                     ) : (
//                                         <img
//                                             src={imagePreviewFirst}
//                                             alt='Preview'
//                                             className='w-100 p-2'
//                                         />
//                                     )}
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//             </form>
//         </Modal>
//     )
// }

// export default AddInvoiceModal