import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../../../_metronic/helpers';
import labelKey from '../../../localization/label.json';
import SearchSelect from '../../../../components/textFields/SearchSelect';
import { constraintConfig } from '../../../../constraintConfig';
import { FaLock } from 'react-icons/fa6';
import Lightbox from 'react-image-lightbox';
import TextField from '../../../../components/textFields/TextField';
import { FaPercentage } from 'react-icons/fa';
import moment from 'moment-timezone';
import TextAreaField from '../../../../components/textFields/TextAreaField';
import { LoadingComponent } from '../../../../components/loadingComponent/LoadingComponent';
import { Tooltip } from 'react-tooltip';

type Props = {
    show: boolean
    handleClose: () => void
    data: any
    isFetching?: any
    viewOnlyData?: any
}
interface LightboxData {
    imageURL: string;
}

const SingleItemDetailModal = ({ show, handleClose, data, isFetching, viewOnlyData }: Props) => {

    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
    const isPdfFile = (filePath: string | undefined) => {
        if (!filePath) return false;
        const fileExtension = filePath.toLowerCase().split('.').pop();
        return fileExtension === 'pdf';
    };
    const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
        if (attachmentFile) {
            if (isPdfFile(attachmentFile)) {
                // For PDF files, open in the browser
                window.open(attachmentFile, '_blank');
            } else {
                // For image files, open in the Lightbox
                setLightboxData({ imageURL: attachmentFile });
                setLightboxOpen(true);
            }
        }
    };
    const mileageCol = data?.expenseCategory?.id === constraintConfig.expenseCategoryID.mileage;
    return (
        <>
        <Tooltip id="clickReceipt" place="top" variant='light' className='rounded' />

        <Modal
            aria-hidden='true'
            dialogClassName='modal-dialog custom-modal-size modal-dialog-centered'
            show={show}
            onHide={handleClose}
        >

            <div className='modal-header py-4'>
                <h2 className='mb-0'>{labelKey.expenseItemDetail}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
            <div className='modal-body py-lg-10 px-lg-10'>
                {isFetching ? <LoadingComponent /> :
                    <div className="row m-0 px-4">
                        <div className={`col-lg-8 col-xl-9 mb-5`}>
                            <div className="row">
                            <div className={`${mileageCol ? 'col-lg-3 col-md-6' : 'col-md-6 col-lg-3'}  mb-5`}>
                                    <SearchSelect
                                        label={labelKey.expenseType}
                                        value={
                                            data?.expenseType?.id
                                                ? {
                                                    value: data?.expenseType?.id,
                                                    label: data?.expenseType?.title || '',
                                                }
                                                : null
                                        }
                                        disabled={viewOnlyData}
                                    />
                                </div>
                                <div className={`${mileageCol ? 'col-lg-3 col-md-6' : 'col-md-6 col-lg-3'}  mb-5`}>
                                    <SearchSelect
                                        label={labelKey.category}
                                        value={
                                            data?.expenseCategory?.id
                                                ? {
                                                    value: data?.expenseCategory?.id,
                                                    label: data?.expenseCategory?.title || '',
                                                }
                                                : null
                                        }
                                        disabled={viewOnlyData}
                                    />
                                </div>

                                <div className={`${mileageCol ? 'col-lg-3 col-md-6' : 'col-md-6 col-lg-3'}  mb-5`}>
                                    <TextField
                                        label={labelKey.date}
                                        type='date'
                                        value={data?.itemDate ? moment(data?.itemDate).format('YYYY-MM-DD') : ''}
                                        disabled={viewOnlyData}
                                    />
                                </div>
                                <div className={`${mileageCol ? 'col-lg-3 col-md-6' : 'col-md-6 col-lg-3'}  mb-5`}>
                                    <SearchSelect
                                        label={labelKey.currency}
                                        value={
                                            data?.currency?.id
                                                ? {
                                                    value: data?.currency.id,
                                                    label: data?.currency.title || 'USD',
                                                }
                                                : null
                                        }

                                        disabled={viewOnlyData}
                                    />

                                </div>
                                <div className='col-md-6 col-lg-4 mb-5'>
                                    <SearchSelect
                                        label={labelKey.paymentMethod}
                                        value={
                                            data?.paymentMethod?.id
                                                ? {
                                                    value: data?.paymentMethod.id,
                                                    label: data?.paymentMethod.title,
                                                }
                                                : null
                                        }
                                        disabled={viewOnlyData}
                                    />

                                </div>
                                {data?.isSplitLineItem !== true &&
                                    <div className={`${mileageCol ? 'col-lg-4 col-md-6' : 'col-md-6 col-lg-4'}  mb-5`}>
                                        <SearchSelect
                                            label={labelKey.chargeLocation}
                                            value={
                                                data?.expItemLocation?.id
                                                    ? {
                                                        value: data?.expItemLocation?.id,
                                                        label: data?.expItemLocation?.title || '',
                                                    }
                                                    : null
                                            }
                                            disabled={viewOnlyData}
                                        />

                                    </div>
                                }
                                {parseInt(data?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage ?
                                    <>
                                        <div className={`${mileageCol ? 'col-lg-4 col-md-6' : 'col-md-6'} mb-5`}>
                                            <SearchSelect
                                                label={labelKey.tripType}
                                                value={
                                                    data?.tripType?.id
                                                        ? {
                                                            value: data?.tripType.id,
                                                            label: data?.tripType.title || 'One Way',
                                                        }
                                                        : null
                                                }
                                                disabled={viewOnlyData}
                                            />

                                        </div>

                                        <div className='col-md-6 col-lg-4 col-xl-4 mb-5'>
                                            <TextField
                                                label={labelKey.startLocation}
                                                type='text'
                                                value={data?.startLocation}
                                                disabled={viewOnlyData}
                                            />
                                        </div>
                                        <div className='col-md-6 col-lg-4 col-xl-4 mb-5'>
                                            <TextField
                                                label={labelKey.endLocation}
                                                type='text'
                                                value={data?.endLocation}
                                                disabled={viewOnlyData}
                                            />
                                        </div>
                                        <div className='col-lg-4 col-xl-2 col-md-3 mb-5'>
                                            <TextField
                                                label={labelKey.distance}
                                                type='number'
                                                placeholder='Enter Distance'
                                                value={data?.milage}
                                                readOnly={true}
                                                disabled={viewOnlyData}

                                            />
                                        </div>
                                        <div className='col-lg-4 col-xl-2 col-md-3 mb-5'>
                                            <TextField
                                                label={labelKey.reimbursableDistance}
                                                type='number'
                                                value={data?.reimbursableDistance}
                                                readOnly={true}
                                                disabled={viewOnlyData}
                                            />
                                        </div>
                                        <div className='col-lg-4 col-xl-2 col-md-3 mb-5'>
                                            <TextField
                                                label="Rate"
                                                type='text'
                                                // value={getMileageByDate?.result?.ratePerKM ? getMileageByDate?.result?.ratePerKM : getMileageByDate?.result?.ratePerMile}
                                                value={data?.mileageRate || 0}
                                                readOnly={true}
                                                disabled={viewOnlyData}
                                            />
                                        </div>
                                        <div className='col-lg-4 col-xl-2 col-md-3 mb-5'>
                                            <TextField
                                                label={labelKey.amount}
                                                type='number'
                                                value={data?.amount?.toString() || ''}
                                                readOnly={true}
                                                disabled={viewOnlyData || data?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard}
                                            />
                                        </div>

                                    </>
                                    :
                                    <div className='col-md-6 col-lg-4 mb-5'>
                                        <TextField
                                            label={labelKey.amount}
                                            type='number'
                                            placeholder='Enter Amount'
                                            min={0}
                                            value={data?.amount || ''}
                                            disabled={viewOnlyData}
                                        />
                                    </div>
                                }
                                {parseInt(data?.expenseCategory?.id) !== constraintConfig.expenseCategoryID.mileage &&
                                    <>
                                        <div className='col-md-6 col-lg-4 mb-5'>
                                            <SearchSelect
                                                label={labelKey.vendor}
                                                placeholder='Select Vendor'
                                                disabled={viewOnlyData}
                                                value={
                                                    data?.vendor?.vendorId
                                                        ? {
                                                            value: data?.vendor?.vendorId,
                                                            label: data?.vendor?.name || '',
                                                        }
                                                        : null
                                                }
                                            />
                                        </div>
                                    </>}
                                <div className='col-md-12'>
                                    <div>
                                        <TextAreaField
                                            label={labelKey.description}
                                            placeholder='Enter Expense Description'
                                            value={data?.itemDetail || ''}
                                            disabled={viewOnlyData}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-12 col-lg-12 col-xl-8 mb-5'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <label className='form-label fs-5 fw-bold mt-5'>
                                                Split Amount to Locations
                                            </label>
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input cursor-pointer"
                                                    type="checkbox"
                                                    role="switch"
                                                    id={`flexSwitchCheckChecked`}
                                                    checked={data?.isSplitLineItem}
                                                    disabled={viewOnlyData}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-xl-4"></div>
                                {data?.isSplitLineItem === true &&
                                    <div className='col-md-12'>

                                        {data?.splitLineItems?.map((item: any, index: any) => (
                                            <div key={index} className="row me-5">
                                                <div className='col-md-6 col-lg-6 col-xl-6'>
                                                    <div>
                                                        <SearchSelect
                                                            label={labelKey.splitLocation}
                                                            labelClass='mt-5'
                                                            value={
                                                                item.expItemLocation?.id
                                                                    ? {
                                                                        value: item.expItemLocation.id,
                                                                        label: item.expItemLocation.title || '',
                                                                    }
                                                                    : null
                                                            }
                                                            selectClass='w-100'
                                                            disabled={viewOnlyData}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-lg-3 col-xl-3">
                                                    <TextField
                                                        label={labelKey.splitAmount}
                                                        labelClass='mt-5'
                                                        type='number'
                                                        placeholder='Enter Amount'
                                                        min={0}
                                                        value={data?.splitLineItems[index].amount}
                                                        disabled={viewOnlyData}
                                                    />
                                                </div>
                                                <div className="col-md-3 col-lg-3 col-xl-3">
                                                    <div className='d-flex gap-2 align-items-center w-100 position-relative'>
                                                        <span className='position-absolute mt-13 ps-3 pe-2 h-35px d-flex align-items-center justify-content-center' style={{ borderRight: '1px solid #a1a5b7' }}>
                                                            <FaPercentage className='h-20px w-20px text-muted' />
                                                        </span>
                                                        <TextField
                                                            label="Amount Percentage"
                                                            labelClass='mt-5'
                                                            type='number'
                                                            placeholder='Enter Percentage'
                                                            fieldClass='ps-15'
                                                            value={data?.splitLineItems[index].percentage}
                                                            disabled={viewOnlyData}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>}
                            </div>
                        </div>
                        {/* {parseInt(data?.expenseCategory?.id) !== constraintConfig.expenseCategoryID.mileage && */}
                            <div className='col-lg-4 col-xl-3 mb-5'>
                                <div className='d-flex align-items-baseline'>
                                    <label className={`d-flex align-items-center fs-5 fw-bold mb-2`}>
                                        {labelKey.receiptDocument}
                                    </label>
                                </div>

                                <div className='d-flex align-items-center gap-2 w-100' data-tooltip-content='Click to view the receipt.' data-tooltip-id="clickReceipt">
                                    {/* {data?.attachmentFile && ( */}
                                    {/* // <div className="mt-2 cursor-pointer"> */}
                                    {data?.attachmentFile?.endsWith('.pdf') ? (
                                        <div
                                            onClick={() => openPdfInBrowserOrLightbox(data?.attachmentFile)}
                                            style={{ cursor: "pointer", position: "relative", width: "100%", height: "100%" }}
                                        >
                                            <embed
                                                src={`${data?.attachmentFile}#toolbar=0&navpanes=0&scrollbar=0`}
                                                type="application/pdf"
                                                width="100%"
                                                height="500px"
                                                className="cursor-pointer"
                                                onClick={() => openPdfInBrowserOrLightbox(data?.attachmentFile)}
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: "96%",
                                                    height: "100%",
                                                    backgroundColor: "transparent",
                                                }}
                                            ></div>
                                        </div>
                                    ) :
                                        <img
                                            alt='rcp'
                                            src={data?.attachmentFile?.endsWith('.pdf') ? 'media/svg/files/pdf.svg' : data?.attachmentFile ? data?.attachmentFile: '/media/svg/files/blank-image.svg'}
                                            // style={{ maxWidth: '100%', maxHeight:400 }}
                                            className='h-500px w-100 cursor-pointer rounded'
                                            onClick={() => openPdfInBrowserOrLightbox(data?.attachmentFile)}
                                        />
                                    }
                                    {/* // </div> */}
                                    {/* )} */}

                                    {lightboxOpen && (
                                        <Lightbox
                                            mainSrc={lightboxData.imageURL}
                                            onCloseRequest={() => setLightboxOpen(false)}
                                            imageCaption="Attachment"
                                            enableZoom={true}
                                            imagePadding={50}
                                        />
                                    )}
                                </div>
                            </div>
                        {/* } */}





                    </div>
                }
            </div>
            <div className='modal-footer py-4'>
                <button className='btn btn-light' onClick={handleClose}>{labelKey.close}</button>
            </div>
        </Modal>
        </>
    )
}

export default SingleItemDetailModal