import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Tooltip } from 'react-tooltip';
import { KTSVG } from '../../../../_metronic/helpers';
import Pagination from '../../../../components/pagination/Pagination';
import SearchBarComponent from '../../../../components/searchBar/SearchBarComponent';
import TableHeading from '../../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../../components/tableHeading/TableSettingMenu';
import TextField from '../../../../components/textFields/TextField';
import { useGetAllLocationQuery } from '../../../../services/OrgSettingsApi';
import labelKey from '../../../localization/label.json';
import AddUpdateLocationModal from './AddUpdateLocationModal';
import LocationActiveConfirmationModal from './LocationActiveConfirmationModal';
import NoRecordFound from '../../../../components/noRecord/NoRecordFound';
import { LoadingComponent } from '../../../../components/loadingComponent/LoadingComponent';
import useColumnVisibility from '../../../customHooks/useColumnVisibility';

const LocationPage = () => {
    const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'locations' });
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [sortBy, setSortBy] = useState("createDT");
    const [sortOrder, setSortOrder] = useState("desc");
    const [sortedColumn, setSortedColumn] = useState("createDT");
    const [filterByStatus, setFilterByStatus] = useState("");
    const [isChecked, setIsChecked] = useState(false)


    // Debounced search state
    // const [searchStr, setSearchStr] = useState('');

    const { data, isLoading, refetch } = useGetAllLocationQuery({
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchStr: searchStr,
        sortBy: sortBy,
        sortOrder: sortOrder,
        filterByStatus: filterByStatus
    })
    const [showAddUpdateLocationModal, setShowAddUpdateLocationModal] = useState(false)
    const [showLocationEdit, setShowLocationEdit] = useState({})
    const locationModalHandler = () => {
        setShowAddUpdateLocationModal((prev) => !prev);
    };
    const [showLocationActiveConfirmationModal, setShowLocationActiveConfirmationModal] = useState(false)
    const departmentActiveConfirmationHandler = () => {
        setShowLocationActiveConfirmationModal((prev) => !prev);
    };
    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPageNumber(1); // Reset page number to 1
    };
    // pagination
    const handlePageClick = (e: any) => {
        setPageNumber(e.selected + 1);
    };
    //per page record
    const handlePageRecords = (e: any) => {
        setPageNumber(1); // Reset the page to 1
    };
    const handleSort = (property: string) => {
        setSortBy(property);
        if (sortedColumn === property) {
            setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortedColumn(property);
            setSortOrder("asc");
        }
        // Perform additional sorting logic...
    };

    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state

        delayedSearch(value); // Update debounced search state
        setPageNumber(1)
    };

    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );

    useEffect(() => {
        if (searchStr !== "" && sortOrder !== "" && sortBy !== "") {
            // Trigger the API call after the debounce time
            refetch();
        }
    }, [searchStr, sortOrder, sortBy, refetch]);

    
    return (
        <>
            <AddUpdateLocationModal
                show={showAddUpdateLocationModal}
                handleClose={() => setShowAddUpdateLocationModal(false)}
                data={showLocationEdit}
                refetch={refetch} />
            <LocationActiveConfirmationModal
                show={showLocationActiveConfirmationModal}
                handleClose={() => setShowLocationActiveConfirmationModal(false)}
                data={showLocationEdit}
                isChecked={isChecked}
                refetch={refetch}
            />

            <div className='card mb-5'>
                <div className='card-header border-0 pt-6'>
                    <SearchBarComponent
                        placeholder='Location'
                        value={immediateSearchStr}
                        onChange={handleSearchChange} // Use immediate search handler
                    />

                    <div className='d-flex align-items-center ms-auto'>
                        <div className='card-toolbar'>
                            <button className='btn btn-sm btn-light-primary'
                                onClick={() => {
                                    setShowLocationEdit(true);
                                    locationModalHandler();
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                {labelKey.addLocation}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='card-body py-8'>
                    <div className="col-md-12">
                        <div className='table-responsive table-height'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className='fw-bold text-muted'>
                                        {hiddenColumns.includes('sr') && (
                                            <TableHeading
                                                label={labelKey.sr}
                                                columnId='sr'
                                                className="ps-4"
                                            />
                                        )}
                                        {hiddenColumns.includes('locationCode') && (
                                            <TableHeading
                                                label={labelKey.locationCode}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='locationCode'
                                            />
                                        )}
                                        {hiddenColumns.includes('locationName') && (
                                            <TableHeading
                                                label={labelKey.name}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='locationName'
                                            />
                                        )}

                                        {hiddenColumns.includes('address') && (
                                            <TableHeading
                                                label={labelKey.address}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='address'
                                            />
                                        )}
                                        {hiddenColumns.includes('latitude') && (
                                            <TableHeading
                                                label={labelKey.latitude}
                                                columnId='latitude'
                                            />
                                        )}
                                        {hiddenColumns.includes('longitude') && (
                                            <TableHeading
                                                label={labelKey.longitude}
                                                columnId='longitude'
                                            />
                                        )}
                                        {hiddenColumns.includes('description') && (
                                            <TableHeading
                                                label={labelKey.description}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='description'
                                            />
                                        )}
                                        {hiddenColumns.includes('isActive') && (
                                            <TableHeading
                                                label={labelKey.status}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='isActive'
                                            />
                                        )}
                                        {hiddenColumns.includes('updatedOn') && (
                                            <TableHeading
                                                label={labelKey.modifiedOn}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='updatedOn'
                                            />
                                        )}

                                        {hiddenColumns.includes('createdAt') && (
                                            <TableHeading
                                                label={labelKey.createdAt}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='createdAt'
                                            />
                                        )}
                                        {hiddenColumns.includes('createdBy') && (
                                            <TableHeading
                                                label={labelKey.createdBy}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='createdBy'
                                            />
                                        )}
                                        {hiddenColumns.includes('updatedBy') && (
                                            <TableHeading
                                                label={labelKey.modifiedBy}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='updatedBy'
                                            />
                                        )}

                                        <th className='text-end rounded-end pe-2 border-0'>
                                            {hiddenColumns.includes('actions') && (
                                                <>
                                                    {labelKey.actions}
                                                </>
                                            )}
                                            <TableSettingMenu>
                                                <TextField
                                                    rightLabel={labelKey.sr}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("sr")}
                                                    checked={hiddenColumns.includes('sr')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.locationCode}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("locationCode")}
                                                    checked={hiddenColumns.includes('locationCode')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.name}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("locationName")}
                                                    checked={hiddenColumns.includes('locationName')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.address}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("address")}
                                                    checked={hiddenColumns.includes('address')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.latitude}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("latitude")}
                                                    checked={hiddenColumns.includes('latitude')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.longitude}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("longitude")}
                                                    checked={hiddenColumns.includes('longitude')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.description}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("description")}
                                                    checked={hiddenColumns.includes('description')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.status}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("isActive")}
                                                    checked={hiddenColumns.includes('isActive')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.modifiedOn}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("updatedOn")}
                                                    checked={hiddenColumns.includes('updatedOn')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.createdAt}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("createdAt")}
                                                    checked={hiddenColumns.includes('createdAt')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.createdBy}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("createdBy")}
                                                    checked={hiddenColumns.includes('createdBy')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.modifiedBy}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("updatedBy")}
                                                    checked={hiddenColumns.includes('updatedBy')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.actions}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("actions")}
                                                    checked={hiddenColumns.includes('actions')}
                                                    fieldClass='mb-4'
                                                />
                                            </TableSettingMenu>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {!isLoading && (<>
                                        {data?.result?.data?.length > 0 ?
                                            <>
                                                {data?.result?.data?.map((data: any, index: any) => (
                                                    <tr key={index}>
                                                        {hiddenColumns.includes('sr') && (
                                                            <td className='ps-5'>{(pageNumber - 1) * pageSize + index + 1}</td>
                                                        )}
                                                        {hiddenColumns.includes('locationCode') && (
                                                            <td>{data?.locationCode}</td>
                                                        )}
                                                        {hiddenColumns.includes('locationName') && (
                                                            <td>{data?.locationName}</td>
                                                        )}
                                                        {hiddenColumns.includes('address') && (
                                                            <td className='w-300px text-wrap'>
                                                                {/* {data && data.address && <ReactReadMoreReadLess
                                                                    charLimit={30}
                                                                    readMoreText={"Read more"}
                                                                    readLessText={"Read less"}
                                                                    readMoreClassName="readMore"
                                                                    readLessClassName="readLess"
                                                                >
                                                                    {data.address}
                                                                </ReactReadMoreReadLess>} */}
                                                                {data.address}
                                                            </td>
                                                        )}
                                                        {hiddenColumns.includes('latitude') && (
                                                            <td>{data?.latitude}</td>
                                                        )}
                                                        {hiddenColumns.includes('longitude') && (
                                                            <td>{data?.longitude}</td>
                                                        )}
                                                        {hiddenColumns.includes('description') && (
                                                            <td className='w-300px text-wrap'>
                                                                {data && data.description && <ReactReadMoreReadLess
                                                                    charLimit={30}
                                                                    readMoreText={"Read more"}
                                                                    readLessText={"Read less"}
                                                                    readMoreClassName="readMore"
                                                                    readLessClassName="readLess"
                                                                >
                                                                    {data.description}
                                                                </ReactReadMoreReadLess>}
                                                            </td>
                                                        )}
                                                        {hiddenColumns.includes('isActive') && (
                                                            <td>
                                                                <div className='d-flex align-items-center gap-2'>
                                                                    {data?.isActive === true ? <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.active}</span> :
                                                                        <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.inActive}</span>}
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            className="form-check-input cursor-pointer"
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            id={`flexSwitchCheckChecked-${data?.dptLocId}`}
                                                                            checked={data?.isActive}
                                                                            onChange={(e) => {
                                                                                setIsChecked(e.target.checked);
                                                                                setShowLocationEdit(data);
                                                                                departmentActiveConfirmationHandler();
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={`flexSwitchCheckChecked-${data?.dptLocId}`}></label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        )}
                                                        {hiddenColumns.includes('updatedOn') && (
                                                            <td>{data?.updatedOn}</td>
                                                        )}
                                                        {hiddenColumns.includes('createdAt') && (
                                                            <td>{data?.createdAt}</td>
                                                        )}
                                                        {hiddenColumns.includes('createdBy') && (
                                                            <td>{data?.createdByName}</td>
                                                        )}
                                                        {hiddenColumns.includes('updatedBy') && (
                                                            <td>{data?.updatedByName}</td>
                                                        )}
                                                        {hiddenColumns.includes('actions') && (
                                                            <td>
                                                                <div className='d-flex align-items-center justify-content-end gap-2' >
                                                                    <Tooltip id="update-location" place="bottom" />
                                                                    <div
                                                                        data-tooltip-id="update-location" data-tooltip-content={labelKey.updateLocation}
                                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                        onClick={() => {
                                                                            setShowLocationEdit(data);
                                                                            locationModalHandler();
                                                                        }}
                                                                    >
                                                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))}
                                            </>
                                            :
                                            <tr>
                                                <td colSpan={13} className='text-center'>
                                                    <NoRecordFound />
                                                </td>
                                            </tr>
                                        }
                                    </>)}
                                    {isLoading && (
                                        <tr>
                                            <td colSpan={13} className="text-center">
                                                <LoadingComponent />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                                {/* end::Table body */}
                            </table>
                        </div>
                        <Pagination
                            totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                            toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                            ofResult={data?.result?.totalRecords}
                            onChange={handlePageSizeChange}
                            pageSize={pageSize}
                            onPageChange={handlePageClick}
                            pageCount={data?.result?.totalPages || 0}
                            currentPage={pageNumber}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default LocationPage