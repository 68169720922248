import React from 'react'
import { useAddExpenseItemPendinCCMutation } from '../../../../services/ExpenseApi';
import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import labelKey from "../../../localization/label.json"
import tosterKey from "../../../localization/toster.json"
import { KTSVG } from '../../../../_metronic/helpers'

type Props = {
    show: boolean
    handleClose: () => void
    selectePendingLineItemData: any
    refetch: any
}

const CreatePendingLineItemModal = ({ show, handleClose, selectePendingLineItemData, refetch }: Props) => {
    
    const [createPendingLineItem, { data, isSuccess, isError, error, isLoading }] = useAddExpenseItemPendinCCMutation()

    const handleCreatePendingLineItem = async () => {
        try {
            // Correctly access the userAccountId and expenseItemId from selectedDraftData and selectePendingLineItemData
            const response = await createPendingLineItem({
                financialTransactionEntityId: selectePendingLineItemData?.financialTransactionEntityId, // Correctly access selectePendingLineItemData
            });
        } catch (error) {
            console.error(error); // Log any errors if they occur
        }
        handleClose();
    };


    useEffect(() => {
        if (isSuccess) {
            const responseData = (data as any)?.message;
            const successMessage = responseData;
            toast.success(successMessage);
            refetch()
            handleClose()

        }
    }, [isSuccess, data]);

    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header bg-light py-4'>
                    <h2 className='mb-0'>{labelKey.createPendingLineItem}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>


                <div className='modal-body py-lg-6 px-lg-6'>

                    <p className='fs-5 fw-semibold'>Are you sure you want to create this line item</p>
                </div>
                <div className='modal-footer justify-content-center py-4'>
                    <button className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</button>
                    <button
                        type='submit'
                        className='btn btn-primary'
                        onClick={() => handleCreatePendingLineItem()}
                        disabled={isLoading}
                    >

                        {!isLoading && <span className='indicator-label'>{labelKey.yes}</span>}
                        {isLoading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                {labelKey.pleaseWait}...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default CreatePendingLineItemModal