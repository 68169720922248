import React, { useRef, useState } from 'react';
import {
    useJsApiLoader,
    GoogleMap,
    Marker,
    Autocomplete,
    DirectionsRenderer,
} from '@react-google-maps/api';

const center = { lat: 48.8584, lng: 2.2945 };

const GoogleMapComponent: React.FC = () => {

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyDRamKOTuCnMPw7b6BP2qdRWfuSJnIFpfI',
        libraries: ['places'],
    });

    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [directionsResponse, setDirectionsResponse] = useState<google.maps.DirectionsResult | null>(null);
    const [distance, setDistance] = useState<string>('');
    const [duration, setDuration] = useState<string>('');

    const originRef = useRef<HTMLInputElement>(null);
    const destinationRef = useRef<HTMLInputElement>(null);

    if (!isLoaded) {
        return <p>isloading</p>
    }


    async function calculateRoute() {
        if (!originRef.current || !destinationRef.current || originRef.current.value === '' || destinationRef.current.value === '') {
            return;
        }

        const directionsService = new window.google.maps.DirectionsService();
        const results = await directionsService.route({
            origin: originRef.current.value,
            destination: destinationRef.current.value,
            travelMode: window.google.maps.TravelMode.DRIVING,
        });

        setDirectionsResponse(results);
        setDistance(results.routes[0].legs[0].distance?.text || '');
        setDuration(results.routes[0].legs[0].duration?.text || '');
    }

    function clearRoute() {
        setDirectionsResponse(null);
        setDistance('');
        setDuration('');
        if (originRef.current) originRef.current.value = '';
        if (destinationRef.current) destinationRef.current.value = '';
    }

    return (
        <div className="position-relative d-flex flex-column align-items-center vh-100 w-100">
            {/* Google Map Box */}
            <div className="position-absolute top-0 start-0 h-100 w-100">
                <div id="map-container" style={{ width: '100%', height: '100%' }}>
                    <GoogleMap
                        center={center}
                        zoom={15}
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                        options={{
                            zoomControl: false,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                        }}
                        onLoad={(map) => setMap(map)}
                    >
                        <Marker position={center} />
                        {directionsResponse && (
                            <DirectionsRenderer directions={directionsResponse} />
                        )}
                    </GoogleMap>
                </div>
            </div>

            {/* Controls Box */}
            <div className="p-4 bg-white shadow-lg rounded m-4" style={{ minWidth: '768px', zIndex: 1 }}>
                <div className="d-flex justify-content-between mb-2">
                    <div className="flex-grow-1 me-2">
                        <div className="form-group">
                            <Autocomplete>
                                <input type="text" className="form-control" placeholder="Origin" ref={originRef} />
                            </Autocomplete>
                        </div>
                    </div>
                    <div className="flex-grow-1 me-2">
                        <div className="form-group">
                            <Autocomplete>
                                <input type="text" className="form-control" placeholder="Destination" ref={destinationRef} />
                            </Autocomplete>
                        </div>
                    </div>
                    <div className="d-flex">
                        <button className="btn btn-success me-2" type="submit" onClick={calculateRoute}>
                            Calculate Route
                        </button>
                        <button className="btn btn-outline-secondary" aria-label="clear" onClick={clearRoute}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                </div>

                <div className="d-flex justify-content-between mt-4">
                    <div>
                        <span>Distance: {distance}</span>
                    </div>
                    <div>
                        <span>Duration: {duration}</span>
                    </div>
                    <button
                        className="btn btn-outline-secondary rounded-circle"
                        aria-label="center back"
                        onClick={() => {
                            map?.panTo(center);
                            map?.setZoom(15);
                        }}
                    >
                        <i className="fa fa-location-arrow"></i>
                    </button>
                    <button
                        className="btn btn-secondary"
                    >
                        capture
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GoogleMapComponent;
