import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { KTSVG } from '../../../_metronic/helpers'
import { useDeleteExpenseItemMutation, useDeleteExpenseMutation } from '../../../services/ExpenseApi'
import labelKey from "../../localization/label.json"
import tosterKey from "../../localization/toster.json"
import { useAppSelector } from '../../../hooks'
import { selectAuth } from '../../../features/authSlice'

type Props = {
    show: boolean
    handleClose: () => void
    data: any
    refetch?: any
    getCardExpenseItemTrigger?: any
}
const DeleteExpenseModal = ({ show, handleClose, data, refetch, getCardExpenseItemTrigger }: Props) => {
    const { userAccountID, } = useAppSelector(selectAuth);
    const queryHook = data?.expenseId
        ? useDeleteExpenseMutation
        : useDeleteExpenseItemMutation;

    const [deleteExpenseItem, { isSuccess, isError, error, isLoading }] = queryHook()

    const handleDelete = async (data: any) => {
        await deleteExpenseItem(data?.expenseId ? data?.expenseId : data)
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success(tosterKey.expenseDeletedSuccessfully);
            handleClose();
            if (refetch) {
                refetch();
            }
            if (getCardExpenseItemTrigger) {
                getCardExpenseItemTrigger({
                    userAccountId: userAccountID,
                    pageNumber: 1,
                    pageSize: 1000000,
                    sortBy: 'itemDate',
                    sortOrder: 'sortOrder',
                    searchStr: '',
                    filterByCategory: '',
                    startDate: '',
                    endDate: '',
                    filterBySubCategory: '',
                })
            }
        }
    }, [isSuccess])

    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header bg-light py-4'>
                    <h2 className='mb-0'>{labelKey.deleteConfirmation}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>


                <div className='modal-body py-lg-6 px-lg-6'>

                    <p className='fs-5 fw-semibold'>{labelKey.areYouSureYouDelete} <span className='text-danger'>{data?.trackingId ? `Expense ${data?.trackingId}`:labelKey.expenseItem }?</span></p>
                </div>
                <div className='modal-footer justify-content-center py-4'>
                    <button className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</button>
                    <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn-danger'
                        onClick={() => handleDelete(data)}
                        disabled={isLoading}
                    >

                        {!isLoading && <span className='indicator-label'>{labelKey.delete}</span>}
                        {isLoading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                {labelKey.pleaseWait}...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default DeleteExpenseModal