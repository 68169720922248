import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaEye } from 'react-icons/fa6'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { KTSVG } from '../../../_metronic/helpers'
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp'
import DateComp from '../../../components/dateComponent/DateComp'
import { constraintConfig } from '../../../constraintConfig'
import { selectAuth } from '../../../features/authSlice'
import { useAppSelector } from '../../../hooks'
import { API_END_POINTS } from '../../../services/apiEndpoints'
import labelKey from "../../localization/label.json"
import ConfirmationExpenseModal from './ConfirmationExpenseModal'
import ExpenseLogModal from './ExpenseLogModal'
import { Tooltip } from 'react-tooltip'
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent'
import UpdateAprroverStatusModal from '../workFlow/UpdateAprroverStatusModal'
import ApproveAndRejectReportSummaryModal from './ApproveAndRejectReportSummaryModal'
import { IoIosWarning } from 'react-icons/io'
import ExpenseItemViolationModal from './ExpenseItemViolationModal'
import ExpenseStatusComp from '../../../components/StatusComp/ExpenseStatusComp'

type Props = {
    show: boolean
    handleClose: () => void
    data: any
    expenseItems?: any
    isFetching?: any
    refetch?: any
    itsApprover: any
    expenseItemByIdTrigger?: any
    setShowInnerTable?: any

}
interface LightboxData {
    imageURL: string;
}
const ExpenseDetailModalForApproval = ({ show, handleClose, data, expenseItems, isFetching, refetch, itsApprover, expenseItemByIdTrigger, setShowInnerTable }: Props) => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
    const { roleID, } = useAppSelector(selectAuth);
    const [showExpenseConfirmationModal, setShowExpenseConfirmationModal] = useState(false)
    const [expenseConfirmationData, setExpenseConfirmationData] = useState({});

    const isPdfFile = (filePath: string | undefined) => {
        if (!filePath) return false;
        const fileExtension = filePath.toLowerCase().split('.').pop();
        return fileExtension === 'pdf';
    };
    const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
        if (attachmentFile) {
            if (isPdfFile(attachmentFile)) {
                // For PDF files, open in the browser
                window.open(attachmentFile, '_blank');
            } else {
                // For image files, open in the Lightbox
                setLightboxData({ imageURL: attachmentFile });
                setLightboxOpen(true);
            }
        }
    };

    const [showExpenseLogModal, setShowExpenseLogModal] = useState(false)
    const [expenseLogData, setExpenseLogData] = useState({});
    const expenseLogHandler = () => {
        setShowExpenseLogModal((prev) => !prev);
    };

    const isDarkGreen = data?.expenseStatus?.id === constraintConfig.expenseStatus.apApproved;


    const [isTableHeadChecked, setIsTableHeadChecked] = useState(false);
    const [isAnyNotChecked, setIsAnyNotChecked] = useState(false);
    

    const [checkedExpenseIds, setCheckedExpenseIds] = useState('');
    const [selectedExpenseItemIds, setSelectedExpenseItemIds] = useState<number[]>([]);
    const [unSelectedExpenseItemIds, setUnSelectedExpenseItemIds] = useState<number[]>([]);
    const [selectedExpenseItemArray, setSelectedExpenseItemArray] = useState<any[]>([]);
    const [unSelectedExpenseItemArray, setUnSelectedExpenseItemArray] = useState<any[]>([]);
    // console.log('selectedExpenseItemArray', selectedExpenseItemArray);
    // console.log('unSelectedExpenseItemArray', unSelectedExpenseItemArray);
    // console.log('selectedExpenseItemIds', selectedExpenseItemIds);
    // console.log('unSelectedExpenseItemIds', unSelectedExpenseItemIds);

    const handleTableHeadChange = (e: any) => {
        const isChecked = e.target.checked;
        setIsTableHeadChecked(isChecked);
        const allExpenseItems = expenseItems || [];

        if (isChecked) {
            const allExpenseItemIds = allExpenseItems.map((item: any) => item.expenseItemId);

            // setSelectedExpenseItemIds((prevIds) => {
            //     const updatedIds = Array.from(new Set([...prevIds, ...allExpenseItemIds]));
            //     return updatedIds;
            // });
            setSelectedExpenseItemIds((prevIds) => {
                const updatedIds = Array.from(new Set([...prevIds, ...allExpenseItemIds]));
                setSelectedExpenseItemArray(
                    allExpenseItems.filter((item: any) => updatedIds.includes(item.expenseItemId))
                );
                setUnSelectedExpenseItemArray([]);
                setIsAnyNotChecked(false);
                return updatedIds;
            });
            // Set the `checkedExpenseIds` as the last item's `expenseId` or a specific logic if needed
            if (allExpenseItems.length > 0) {
                setCheckedExpenseIds(allExpenseItems[allExpenseItems.length - 1]?.expenseId);
            }
        } else {
            setSelectedExpenseItemIds([]);
            setCheckedExpenseIds('');
            setSelectedExpenseItemArray([]);
            setUnSelectedExpenseItemArray(allExpenseItems);
            setIsAnyNotChecked(true);
        }

        // Update isTableHeadChecked state
        //  setIsTableHeadChecked(isChecked);

        setIsTableHeadChecked(isChecked && allExpenseItems.length);

    };

    const handleCheckItem = (e: any, expenseItemId: any) => {
        const isChecked = e.target.checked;

        setSelectedExpenseItemIds((prevItems) => {
            const updatedIds = [...prevItems];
            const allExpenseItems = expenseItems || [];
            const allExpenseItemIds = allExpenseItems.map((item: any) => item.expenseItemId);

            if (isChecked) {
                // Add the expenseItemId to the selected array
                updatedIds.push(expenseItemId?.expenseItemId);
                setCheckedExpenseIds(expenseItemId?.expenseId);
            } else {
                // Remove the expenseItemId from the selected array
                const index = updatedIds.indexOf(expenseItemId?.expenseItemId);
                if (index !== -1) {
                    updatedIds.splice(index, 1);
                }
                setCheckedExpenseIds('');
            }

            // Calculate unselectedExpenseItemIds
            const updatedUnselectedIds = allExpenseItemIds.filter(
                (id: any) => !updatedIds.includes(id)
            );
            setUnSelectedExpenseItemIds(updatedUnselectedIds);


            // Derive the selected and unselected arrays
            const updatedSelectedArray = allExpenseItems.filter((item: any) =>
                updatedIds.includes(item.expenseItemId)
            );
            const updatedUnselectedArray = allExpenseItems.filter((item: any) =>
                updatedUnselectedIds.includes(item.expenseItemId)
            );

            setSelectedExpenseItemArray(updatedSelectedArray);
            setUnSelectedExpenseItemArray(updatedUnselectedArray);

            // Update the table head checkbox state
            setIsTableHeadChecked(updatedIds.length === allExpenseItemIds.length);
            setIsAnyNotChecked(updatedIds.length !== allExpenseItems.length);
            return updatedIds;
        });
    };
    const resetCheckboxState = () => {
        setIsTableHeadChecked(false);
        setSelectedExpenseItemIds([]);
        setCheckedExpenseIds('')
    };
    useEffect(() => {
        if (show) {
            if (expenseItems) {
                const allExpenseItemIds = expenseItems.map((item: any) => item.expenseItemId);

                // Select all items
                setSelectedExpenseItemIds(allExpenseItemIds);
                setSelectedExpenseItemArray(expenseItems);

                // Clear unselected items
                setUnSelectedExpenseItemIds([]);
                setUnSelectedExpenseItemArray([]);

                // Mark the table head checkbox as checked
                setIsTableHeadChecked(true);
                setCheckedExpenseIds(
                    expenseItems.length > 0 ? expenseItems[expenseItems.length - 1]?.expenseId : ''
                );
            }
        }
    }, [show, expenseItems]);





    const isAnyCheckboxChecked = selectedExpenseItemIds.length > 0 || isTableHeadChecked;
    const isAnyUnChecked = unSelectedExpenseItemIds.length > 0;

    const [showUpdateApproverStatusModal, setShowUpdateApproverStatusModal] = useState(false)
    const [editData, setEditData] = useState({});
    const [rejectExpense, setRejectExpense] = useState('')
    const [approvedExpense, setApprovedExpense] = useState('')
    const editApproverStatusHandler = () => {
        setShowUpdateApproverStatusModal((prev) => !prev);
    };

    const [showSummaryApprovalModal, setShowSummaryApprovalModal] = useState(false)
    const summaryApprovalModalHandler = () => {
        setShowSummaryApprovalModal((prev) => !prev);
    };
    const [showExpenseItemViolationsModal, setShowExpenseItemViolationsModal] = useState(false)
    const [expenseItemId, setExpenseItemId] = useState();
    const expenseItemViolationsHandler = () => {
        setShowExpenseItemViolationsModal((prev) => !prev);
    };
    const handleCloseModals = () => {
        setShowExpenseConfirmationModal(false);
        setIsAnyNotChecked(false);
        handleClose();
    };
    return (
        <>
            <ConfirmationExpenseModal show={showExpenseConfirmationModal}
                handleClose={() => setShowExpenseConfirmationModal(false)}
                data={expenseConfirmationData}
                handleCloseModals={handleCloseModals}
                refetch={refetch} />
            <ExpenseLogModal show={showExpenseLogModal}
                handleClose={() => setShowExpenseLogModal(false)}
                data={expenseLogData} />
            <UpdateAprroverStatusModal
                show={showUpdateApproverStatusModal}
                handleClose={() => setShowUpdateApproverStatusModal(false)}
                data={editData}
                handleCloseModals={handleCloseModals}
                approvedExpense={approvedExpense}
                rejectExpense={rejectExpense}
                refetch={refetch}
                setShowInnerTable={setShowInnerTable}
            />
            <ApproveAndRejectReportSummaryModal
                show={showSummaryApprovalModal}
                handleClose={() => setShowSummaryApprovalModal(false)}
                data={editData}
                selectedExpenseItemIds={selectedExpenseItemIds}
                unSelectedExpenseItemIds={unSelectedExpenseItemIds}
                selectedExpenseItemArray={selectedExpenseItemArray}
                unSelectedExpenseItemArray={unSelectedExpenseItemArray}
                openPdfInBrowserOrLightbox={openPdfInBrowserOrLightbox}
                lightboxOpen={lightboxOpen}
                setLightboxOpen={setLightboxOpen}
                lightboxData={lightboxData}
                isAnyUnChecked={isAnyUnChecked}
                handleCloseModals={handleCloseModals}
                refetch={refetch}
                setShowInnerTable={setShowInnerTable} />
            <ExpenseItemViolationModal
                show={showExpenseItemViolationsModal}
                handleClose={() => setShowExpenseItemViolationsModal(false)}
                data={editData}
                expenseItemId={expenseItemId}
                refetch={refetch}
                expenseItemByIdTrigger={expenseItemByIdTrigger} />

            <Tooltip id="toogleToolTip" place="top" />

            <Modal
                aria-hidden='true'
                // dialogClassName='modal-dialog custom-modal-size modal-dialog-centered'
                fullscreen
                show={show}
                onHide={handleCloseModals}
            >
                <div className='modal-header py-4'>
                    <h2 className='mb-0'>{labelKey.expenseDetails} - ({data?.title})</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleCloseModals}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
                <div className='modal-body py-lg-10 px-lg-10'>
                    <div className="row mt-5">
                        <div className="col-md-9">
                            {(roleID === constraintConfig.roleID.role1 || roleID === constraintConfig.roleID.role2) && (
                                <div className='d-flex align-items-center'>
                                    <div className='symbol symbol-50px me-5'>
                                        <div className="symbol-label fs-3 bg-light-info text-info text-capitalize"
                                        >
                                            {data?.userAccount?.firstName.charAt(0)}
                                        </div>

                                    </div>
                                    <div className='d-flex justify-content-start flex-column'>
                                        <p className='text-dark fw-bold mb-0 fs-6 text-capitalize'>
                                            {data?.userAccount?.firstName} {data?.userAccount?.lastName}
                                        </p>
                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                            {data?.userAccount?.email}
                                        </span>
                                    </div>
                                </div>
                            )}
                            <div>
                                <p className='fw-bold mt-3 mb-1'>{labelKey.reportId}: <span className='fw-semibold'>{data?.trackingId}</span></p>
                                <p className='fw-bold'>{labelKey.totalAmount}: <span className='fw-semibold'>
                                    <CurrencyComp amount={data?.amount} /></span></p>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <p className='fw-bold'>{labelKey.month}: <span className='fw-semibold'>
                                <DateComp formattedDate={data?.reportDate} /></span></p>
                            <p className='fw-bold'>{labelKey.status}: &nbsp;
                                {!itsApprover ? <>
                                    <ExpenseStatusComp expenseStatusId={data?.expenseStatus?.id} expenseStatusTitle={data?.expenseStatus?.title} />
                                </> :
                                    <>
                                        {data?.approverStatus?.id === constraintConfig.approverStatus.pending ?
                                            <span className="badge badge-light-primary fs-7 fw-semibold">{data?.approverStatus?.title}</span> :
                                            data?.approverStatus?.id === constraintConfig.approverStatus.approved ?
                                                <span className="badge badge-light-success fs-7 fw-semibold">{data?.approverStatus?.title}</span> :
                                                data?.approverStatus?.id === constraintConfig.approverStatus.rejected ?
                                                    <span className="badge badge-light-danger fs-7 fw-semibold">{data?.approverStatus?.title}</span> :
                                                    // data?.approverStatus?.id === "Clarification" ?
                                                    //     <span className="badge badge-light-warning fs-7 fw-semibold">{data?.approverStatus?.title}</span> :
                                                    data?.approverStatus?.id === constraintConfig.approverStatus.accountsPayable ?
                                                        <span className="badge badge-light-success fs-7 fw-semibold">{data?.approverStatus?.title}</span> :
                                                        <span className="badge badge-light-primary fs-7 fw-semibold">{data?.approverStatus?.title}</span>
                                        }
                                    </>
                                }
                            </p>

                        </div>

                        <div className="col-md-8 mt-3">
                            <p className='fw-bold mb-0'>{labelKey.description}:</p>
                            <p className='mh-100px overflow-auto'>{data?.expenseDetail}</p>
                        </div>

                        <div className='table-responsive table-height'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr>
                                        {/* <th className='ps-4 rounded-start w-50px'>{labelKey.sr}</th> */}
                                        {roleID === constraintConfig.roleID.role10 || data?.approverStatus?.id === constraintConfig.approverStatus.pending ? <>
                                            {expenseItems?.length > 1 ? (
                                                <th className="ps-4">
                                                    {/* <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                                        <input
                                                            type="checkbox"
                                                            className='form-check-input cursor-pointer lineItem-checkboxes'
                                                            checked={isTableHeadChecked} onChange={handleTableHeadChange}
                                                        />
                                                    </div> */}
                                                    {unSelectedExpenseItemArray.length > 0 ? <button className='btn btn-sm btn-primary' onClick={(e) => handleTableHeadChange({ target: { checked: true } })}>Check All</button> :
                                                        <div className="form-check form-switch">
                                                            <input
                                                                className="form-check-input cursor-pointer approveAndRejectSwitch"
                                                                type="checkbox"
                                                                role="switch"
                                                                id={`flexSwitchCheckChecked-approveAndRejectSwitch`}
                                                                checked={isTableHeadChecked} onChange={handleTableHeadChange}
                                                            />
                                                            <label className="form-check-label" htmlFor={`flexSwitchCheckChecked-approveAndRejectSwitch`}></label>
                                                        </div>
                                                    }
                                                </th>
                                            ) : null}
                                        </> : null}
                                        <th className='ps-4'>{labelKey.expenseType}</th>
                                        <th>{labelKey.category}</th>
                                        <th>{labelKey.currency}</th>
                                        <th className='text-end'>{labelKey.amount}</th>
                                        <th className='w-150px text-end'>{labelKey.reimbursableDistance}</th>
                                        <th>{labelKey.vendor}</th>
                                        <th>{labelKey.paymentMethod}</th>
                                        <th className='w-100px'>{labelKey.itemDate}	</th>
                                        <th>{labelKey.chargeLocation}</th>
                                        <th className='w-100px'>{labelKey.attachment}</th>
                                        <th>{labelKey.source}</th>
                                        <th className='w-200px rounded-end'>{labelKey.description}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isFetching ?
                                        <tr>
                                            <td colSpan={22}>
                                                <LoadingComponent />
                                            </td>
                                        </tr>
                                        :
                                        <>
                                            {expenseItems?.map((itemData: any, index: any) => (
                                                <>
                                                    <tr key={index}>
                                                        {/* <td className=' ps-4 w-50px'>
                                                            {index + 1}
                                                        </td> */}
                                                        {roleID === constraintConfig.roleID.role10 || data?.approverStatus?.id === constraintConfig.approverStatus.pending ? <>
                                                            {expenseItems?.length > 1 ? (
                                                                <td className='ps-4'>
                                                                    {/* <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                                                        <input
                                                                            type="checkbox"
                                                                            className='form-check-input cursor-pointer lineItem-checkboxes'
                                                                            checked={isTableHeadChecked || selectedExpenseItemIds.includes(itemData.expenseItemId)}
                                                                            onChange={(e) => handleCheckItem(e, itemData)}
                                                                        />
                                                                    </div> */}
                                                                    <div className="form-check form-switch"
                                                                        data-tooltip-id="toogleToolTip" data-tooltip-content='Click here to Reject'>
                                                                        <input
                                                                            className="form-check-input cursor-pointer approveAndRejectSwitch"
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            id={`flexSwitchCheckChecked-${data?.expenseItemId}`}
                                                                            checked={isTableHeadChecked || selectedExpenseItemIds.includes(itemData.expenseItemId)}
                                                                            onChange={(e) => handleCheckItem(e, itemData)}
                                                                        />
                                                                        <label className="form-check-label text-dark" htmlFor={`flexSwitchCheckChecked-${data?.expenseItemId}`}>
                                                                            {selectedExpenseItemIds.includes(itemData.expenseItemId) ? 'Approved' : 'Rejected'}
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                            ) : null}
                                                        </> : null}
                                                        <td className='ps-4'>
                                                            <div className='d-flex gap-2 align-items-center'>
                                                                {itemData?.isMgrViolation === true &&
                                                                    <div
                                                                        data-tooltip-id="expense-violation" data-tooltip-content='Expense violation'
                                                                        className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                                                        onClick={() => {
                                                                            setEditData(data);
                                                                            setExpenseItemId(itemData?.expenseItemId)
                                                                            expenseItemViolationsHandler();
                                                                        }}
                                                                    >
                                                                        <IoIosWarning className='text-danger h-15px w-15px' />
                                                                    </div>}
                                                                {itemData?.expenseType?.title}
                                                            </div>
                                                        </td>
                                                        <td className='ps-5'>{itemData?.expenseCategory?.title}</td>
                                                        <td>{itemData?.currency?.title}</td>
                                                        <td className='text-end'> <CurrencyComp amount={itemData?.amount} /></td>
                                                        <td className='text-end'>
                                                            {itemData?.expenseCategory?.id === constraintConfig.categoriesId.mileage ?
                                                                <>
                                                                    {itemData?.reimbursableDistance}
                                                                </>
                                                                : null
                                                            }
                                                        </td>
                                                        <td>{itemData?.vendor?.name !== " " ? itemData?.vendor?.name : "N/A"}</td>
                                                        <td>{itemData?.paymentMethod?.title}</td>
                                                        <td><DateComp formattedDate={itemData.itemDate} /></td>
                                                        <td>{itemData?.expItemLocation?.title ? itemData?.expItemLocation?.title : <span>N/A</span>}</td>
                                                        <td className="text-center">
                                                            {itemData?.attachmentFile === "undefined" || itemData?.attachmentFile === "" || itemData?.attachmentFile === "null" || itemData?.attachmentFile === null ?
                                                                <span>N/A</span> :
                                                                <FaEye onClick={() => openPdfInBrowserOrLightbox(itemData?.attachmentFile)} />
                                                            }
                                                            {lightboxOpen && (
                                                                <Lightbox
                                                                    mainSrc={lightboxData.imageURL}
                                                                    onCloseRequest={() => setLightboxOpen(false)}
                                                                    imageCaption="Attachment"
                                                                    enableZoom={true}
                                                                    imagePadding={50}
                                                                />
                                                            )}
                                                        </td>
                                                        <td>
                                                            <Tooltip id="web-source" place="top" />
                                                            <Tooltip id="mobile-source" place="top" />
                                                            <Tooltip id="bank-source" place="top" />
                                                            {itemData?.recordSourceId === constraintConfig.recordSourceId.WebApp ?
                                                                <KTSVG path="/media/icons/figmaIcons/Web.svg" className='svg-icon-1 ms-4'
                                                                    svgClassName='w-20px h-20px'
                                                                    data-tooltip-id="web-source" data-tooltip-content='Website' />
                                                                :
                                                                itemData?.recordSourceId === constraintConfig.recordSourceId.Mobile ?
                                                                    <KTSVG path="/media/icons/figmaIcons/Mobile.svg" className='svg-icon-1 ms-4'
                                                                        data-tooltip-id="mobile-source" data-tooltip-content='Mobile' /> :
                                                                    itemData?.recordSourceId === constraintConfig.recordSourceId.BankFeed ?
                                                                        <KTSVG path="/media/icons/figmaIcons/Bank.svg" className='svg-icon-1 ms-4'
                                                                            data-tooltip-id="bank-source" data-tooltip-content='Bank' />
                                                                        : null}
                                                        </td>
                                                        <td>
                                                            <div className='mh-100px overflow-auto text-wrap'>
                                                                {itemData?.itemDetail}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {itemData.splitLineItems && itemData.splitLineItems.map((splitItem: any, splitIndex: any) => (
                                                        <>

                                                            {splitIndex === 0 && splitItem.amount > 0 && (
                                                                <tr className="p-2">
                                                                    <td></td>
                                                                    <td className="text-start fw-bold bg-light" colSpan={2}>{labelKey.splitLocations} ( {itemData.splitLineItems.length} )</td>
                                                                    <td className="text-end fw-bold bg-light pe-5">{labelKey.splitAmount} </td>
                                                                    {/* <td className="text-start fw-bold bg-light" colSpan={11}></td> */}
                                                                </tr>
                                                            )}
                                                            {splitItem.amount > 0 &&
                                                                <tr key={`${index}-${splitIndex}`} className="split-item-row">
                                                                    <td></td>
                                                                    <td className="text-start bg-light" colSpan={2}>
                                                                        {splitItem.expItemLocation?.title ? splitItem.expItemLocation.title : <span>N/A</span>}
                                                                    </td>
                                                                    <td className="text-end bg-light pe-5">
                                                                        <CurrencyComp amount={splitItem.amount} />
                                                                    </td>
                                                                    {/* <td className="text-end bg-light" colSpan={11}></td> */}
                                                                </tr>
                                                            }
                                                        </>
                                                    ))}
                                                </>

                                            ))}
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='modal-footer py-4'>
                    <button className='btn btn-light' onClick={handleCloseModals}>{labelKey.close}</button>
                    {(data?.expenseStatus?.id !== constraintConfig.expenseStatus.drafted &&
                        <button className='btn btn-light-primary'
                            onClick={() => {
                                setExpenseLogData(data);
                                expenseLogHandler();
                            }}>{labelKey.expenseLogs}</button>
                    )}
                    {roleID === constraintConfig.roleID.role10 || data?.approverStatus?.id === constraintConfig.approverStatus.pending ? (
                        <>
                            {expenseItems?.length > 1 && (isAnyNotChecked || !isTableHeadChecked) ? (
                                // Case: Multiple items with unchecked
                                <button
                                    className="btn btn-light-primary"
                                    onClick={() => {
                                        setEditData(data);
                                        summaryApprovalModalHandler();
                                    }}
                                >
                                    Approve and Reject Report with Individual Items
                                </button>
                            ) : (
                                // Case: Single item or all items checked
                                <>
                                    <button
                                        className="btn btn-light-danger"
                                        onClick={() => {
                                            setEditData(data);
                                            setRejectExpense('rejected');
                                            setApprovedExpense('');
                                            editApproverStatusHandler();
                                        }}
                                    >
                                        Reject Expense Report
                                    </button>
                                    <button
                                        className="btn btn-light-success"
                                        // disabled={data?.isMgrViolation}
                                        onClick={() => {
                                            setEditData(data);
                                            setApprovedExpense('approved');
                                            setRejectExpense('');
                                            editApproverStatusHandler();
                                        }}
                                    >
                                        Approve Expense Report
                                    </button>
                                </>
                            )}
                        </>
                    ) : null}
                </div>

            </Modal >
        </>
    )
}

export default ExpenseDetailModalForApproval