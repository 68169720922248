


import ReactApexChart from 'react-apexcharts';

const BudgetPieChart = ({ getUserDashboard }: any) => {

  const totalAllocatedBudget = getUserDashboard?.result?.totalAllocatedBudget || 0;
  const totalUtilizedBudget = getUserDashboard?.result?.totalUtilizedBudget || 0;
  // const remaningBudget = totalAllocatedBudget - totalUtilizedBudget || 0;
  const totalRemaningBudget = getUserDashboard?.result?.totalRemainingBudget || 0;

  // console.log('totalRemaningBudget', totalRemaningBudget);


  // Chart options
  const pieChartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'donut' as 'donut',
    },
    labels: ['Remaning Budget', 'Utilized Budget'],
    colors: ['#5BCA7A', '#0057CB'],
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      offsetX: 10,
      offsetY: 0,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    ],
    tooltip: {
      y: {
        formatter: function (value: any) {
          return `$${value.toLocaleString('en-US')}`;
        },
      },
    },
  };

  // Chart series data
  const pieChartSeries = [totalRemaningBudget, totalUtilizedBudget];

  return (
    <div className='d-flex justify-content-center w-100'>
      {totalRemaningBudget === 0 && totalUtilizedBudget === 0 ? (
        <p className='mt-15'>Chart does not have any values to display.</p>
      ) : (
        <div className='legend-center'>
          <ReactApexChart options={pieChartOptions} series={pieChartSeries} type="donut" height={300} />
        </div>
      )}
    </div>
  )
}

export default BudgetPieChart