import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { KTSVG } from '../../../../_metronic/helpers';
import { LoadingComponent } from '../../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../../components/noRecord/NoRecordFound';
import Pagination from '../../../../components/pagination/Pagination';
import RefreshComponent from '../../../../components/refreshComponent/RefreshComponent';
import SearchBarComponent from '../../../../components/searchBar/SearchBarComponent';
import TableHeading from '../../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../../components/tableHeading/TableSettingMenu';
import SearchSelect from '../../../../components/textFields/SearchSelect';
import TextField from '../../../../components/textFields/TextField';
import { constraintConfig } from '../../../../constraintConfig';
import { selectAuth } from '../../../../features/authSlice';
import { useAppSelector } from '../../../../hooks';
import { useGetAllCreditCardInfoQuery } from '../../../../services/CreditCardApi';
import { useGetAllUsersManagerAdminQuery } from '../../../../services/GeneralApi';
import useColumnVisibility from '../../../customHooks/useColumnVisibility';
import labelKey from "../../../localization/label.json";
import tosterKey from "../../../localization/toster.json";
import AddCardModal from './AddCardModal';

const Card = () => {
    const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'cards' });
    const { roleID } = useAppSelector(selectAuth);
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [sortBy, setSortBy] = useState("createDT");
    const [sortOrder, setSortOrder] = useState("desc");
    const [sortedColumn, setSortedColumn] = useState("createDT");
    const [filterByUserId, setFilterByUserId] = useState(0);

    const { data: getUserByManager } = useGetAllUsersManagerAdminQuery('');
    const { data, isFetching: isLoading, refetch, isSuccess } = useGetAllCreditCardInfoQuery({
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchStr: searchStr,
        sortBy: sortBy,
        sortOrder: sortOrder,
        userId: filterByUserId,
    })
    const [showAddUpdateCardModal, setShowAddUpdateCardModal] = useState(false)
    const [showCardEdit, setShowCardEdit] = useState({})
    const cardModalHandler = () => {
        setShowAddUpdateCardModal((prev) => !prev);
    };
    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPageNumber(1); // Reset page number to 1
    };
    // pagination
    const handlePageClick = (e: any) => {
        setPageNumber(e.selected + 1);
    };
    //per page record
    const handlePageRecords = (e: any) => {
        setPageNumber(1); // Reset the page to 1
    };
    const handleSort = (property: string) => {
        setSortBy(property);
        if (sortedColumn === property) {
            setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortedColumn(property);
            setSortOrder("asc");
        }
        // Perform additional sorting logic...
    };
    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state

        delayedSearch(value); // Update debounced search state
        setPageNumber(1)
    };

    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );
    useEffect(() => {
        if (searchStr === "" ||
            sortOrder === "" ||
            sortBy === "") {
            refetch();
        }
    }, [searchStr, sortOrder, sortBy]);

    // const [prevModalState, setPrevModalState] = useState({
    //     showAddUpdateCardModal: false,
    // });
    // // Update previous modal state whenever the modals change
    // useEffect(() => {
    //     setPrevModalState({
    //         showAddUpdateCardModal: showAddUpdateCardModal,
    //     });
    // }, [showAddUpdateCardModal]);
    // // Check for modal closing and trigger refetch
    // useEffect(() => {
    //     if (
    //         (prevModalState.showAddUpdateCardModal && !showAddUpdateCardModal)
    //     ) {
    //         refetch();
    //     }
    // }, [
    //     showAddUpdateCardModal,
    //     prevModalState.showAddUpdateCardModal,
    //     refetch,
    // ]);

    const maskCardNumber = (cardNumber: any) => {
        if (!cardNumber) return '';
        const maskedSection = 'x'.repeat(12);
        const visibleSection = cardNumber.slice(-4);
        return `${maskedSection}${visibleSection}`;
    };

    const handleFilterByUser = (selectedOption: any) => {
        setFilterByUserId(selectedOption.value);
    };
    const handleRefresh = () => {
        refetch()
            .then(() => {
                if (isSuccess) {
                    toast.success(tosterKey.dataRefreshedSuccesfully);
                }
            });
    };
    return (
        <>
            <AddCardModal show={showAddUpdateCardModal}
                handleClose={() => setShowAddUpdateCardModal(false)} data={showCardEdit} refetch={refetch} />
            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header align-items-end border-0 pt-5'>
                    <div className='d-md-flex align-items-center gap-2'>
                        <div>
                            <label className='form-label fw-bold text-dark fs-6 text-nowrap'>Card No:</label>
                            <SearchBarComponent
                                placeholder='Card No'
                                value={immediateSearchStr}
                                onChange={handleSearchChange}
                            />
                        </div>
                        {roleID === constraintConfig.roleID.role2 &&
                            <SearchSelect
                                label='User:'
                                options={[
                                    { value: 0, label: 'Select User' }, // Add the empty option
                                    ...(getUserByManager?.result || []).map((option: any) => ({
                                        value: option.id,
                                        label: option?.value,
                                    })),
                                ]}
                                placeholder='Select User'
                                value={filterByUserId && filterByUserId !== 0
                                    ? {
                                        value: filterByUserId,
                                        label: getUserByManager?.result.find((option: any) => option.id === filterByUserId)?.value
                                    }
                                    : null
                                }
                                onChange={handleFilterByUser}
                                selectClass='w-md-200px'
                            />
                        }
                        <div className='w-35px mt-9'>
                            <RefreshComponent onClick={handleRefresh} />
                        </div>
                    </div>
                    <div className='d-flex align-items-center ms-auto'>
                        <div className='card-toolbar'>
                            <button className='btn btn-sm btn-light-primary'
                                onClick={() => {
                                    setShowCardEdit(true);
                                    cardModalHandler();
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                {labelKey.addCard}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive table-height'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {hiddenColumns.includes('sr') && (
                                        <TableHeading
                                            label={labelKey.sr}
                                            columnId='sr'
                                            className="ps-4 w-60px"
                                        />
                                    )}
                                    {roleID === constraintConfig.roleID.role2 &&
                                        <>
                                            {hiddenColumns.includes('user') && (
                                                <TableHeading
                                                    label={labelKey.user}
                                                    columnId='user'
                                                />
                                            )}
                                        </>
                                    }
                                    {hiddenColumns.includes('creditCardCatagory') && (
                                        <TableHeading
                                            label={labelKey.ownership}
                                            columnId='creditCardCatagory'
                                        />
                                    )}
                                    {hiddenColumns.includes('creditCardType') && (
                                        <TableHeading
                                            label={labelKey.cardType}
                                            columnId='creditCardType'
                                        />
                                    )}
                                    {hiddenColumns.includes('cardNumber') && (
                                        <TableHeading
                                            label={labelKey.cardno}
                                            columnId='cardNumber'
                                        />
                                    )}
                                    {hiddenColumns.includes('expiryDate') && (
                                        <TableHeading
                                            label={labelKey.validThru}
                                            columnId='expiryDate'
                                        />
                                    )}
                                    {hiddenColumns.includes('isDeleted') && (
                                        <TableHeading
                                            label={labelKey.status}
                                            columnId='isDeleted'
                                        />
                                    )}
                                    <th className='text-end rounded-end pe-2 border-0'>
                                        {hiddenColumns.includes('actions') && (
                                            <>
                                                {labelKey.actions}
                                            </>
                                        )}
                                        <TableSettingMenu>
                                            <TextField
                                                rightLabel={labelKey.sr}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("sr")}
                                                checked={hiddenColumns.includes('sr')}
                                                fieldClass='mb-4'
                                            />
                                            {roleID === constraintConfig.roleID.role2 &&
                                                <>
                                                    <TextField
                                                        rightLabel={labelKey.user}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("user")}
                                                        checked={hiddenColumns.includes('user')}
                                                        fieldClass='mb-4'
                                                    />
                                                </>
                                            }
                                            <TextField
                                                rightLabel={labelKey.ownership}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("creditCardCatagory")}
                                                checked={hiddenColumns.includes('creditCardCatagory')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.cardType}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("creditCardType")}
                                                checked={hiddenColumns.includes('creditCardType')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.cardno}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("cardNumber")}
                                                checked={hiddenColumns.includes('cardNumber')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.validThru}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("expiryDate")}
                                                checked={hiddenColumns.includes('expiryDate')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.status}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("isDeleted")}
                                                checked={hiddenColumns.includes('isDeleted')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.action}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("actions")}
                                                checked={hiddenColumns.includes('actions')}
                                                fieldClass='mb-4'
                                            />
                                        </TableSettingMenu>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading && (
                                    <>
                                        {data?.result?.data?.length > 0 ? (
                                            <>
                                                {data?.result?.data?.map((data: any, index: any) => (
                                                    <tr key={data?.creditCardId}>
                                                        {hiddenColumns.includes('sr') && (
                                                            <td className='ps-4'> {(pageNumber - 1) * pageSize + index + 1}</td>
                                                        )}
                                                        {roleID === constraintConfig.roleID.role2 &&
                                                            <>
                                                                {hiddenColumns.includes('user') && (
                                                                    <td>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                                <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.userAccount?.firstName?.charAt(0)}</div>
                                                                            </div>
                                                                            <div className="d-flex flex-column">
                                                                                <p className="text-gray-800 fw-bold text-hover-primary mb-1 text-capitalize">{data?.userAccount?.firstName} {data?.userAccount?.middleName} {data?.userAccount?.lastName}</p>
                                                                                <span className='text-lowercase'>{data?.userAccount?.email}</span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                )}
                                                            </>
                                                        }
                                                        {hiddenColumns.includes('creditCardCatagory') && (
                                                            <td>{data?.creditCardCatagory?.name}</td>
                                                        )}
                                                        {hiddenColumns.includes('creditCardType') && (
                                                            <td>{data?.creditCardType?.name}</td>
                                                        )}
                                                        {hiddenColumns.includes('cardNumber') && (
                                                            // <td><span className='text-lowercase'>{data?.cardNumber}</span></td>
                                                            <td><span className='text-lowercase'>{maskCardNumber(data?.cardNumber)}</span></td>
                                                        )}
                                                        {hiddenColumns.includes('expiryDate') && (
                                                            <td>
                                                                {data?.expiryDate}
                                                                {/* <DateComp formattedDate={data?.expiryDate} /> */}
                                                            </td>
                                                        )}
                                                        {hiddenColumns.includes('isDeleted') && (
                                                            <td>
                                                                <span className={`badge badge-light-${data?.creditCardStatus?.id === 1 ? "success" : "danger"} fs-7 fw-semibold`}>{data?.creditCardStatus?.name}</span>
                                                            </td>
                                                        )}
                                                        {hiddenColumns.includes('actions') && (
                                                            <td>
                                                                <div className='d-flex align-items-center justify-content-end gap-2' >
                                                                    <Tooltip id="update-Card" place="bottom" />
                                                                    <div
                                                                        data-tooltip-id="update-Card" data-tooltip-content=' Update Card'
                                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                        onClick={() => {
                                                                            setShowCardEdit(data);
                                                                            cardModalHandler();
                                                                        }}
                                                                    >
                                                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))}
                                            </>
                                        ) : (
                                            <tr>
                                                <td colSpan={10}>
                                                    <NoRecordFound />
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                )}
                                {isLoading && (
                                    <tr>
                                        <td colSpan={8} className="text-center">
                                            <LoadingComponent />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                        toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                        ofResult={data?.result?.totalRecords}
                        onChange={handlePageSizeChange}
                        pageSize={pageSize}
                        onPageChange={handlePageClick}
                        pageCount={data?.result?.totalPages || 0}
                        currentPage={pageNumber}
                    />
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
        </>
    )
}

export default Card