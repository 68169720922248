
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Dropdown, Modal } from 'react-bootstrap'
import { FaCheck, FaInfoCircle } from 'react-icons/fa'
import { IoIosWarning } from 'react-icons/io'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import ReactReadMoreReadLess from "react-read-more-read-less"
import { Tooltip } from 'react-tooltip'
import { KTSVG } from '../../../_metronic/helpers'
import { selectAuth } from '../../../features/authSlice'
import { useAppSelector } from '../../../hooks'
import { API_END_POINTS } from '../../../services/apiEndpoints'
import labelKey from "../../localization/label.json"
import ConfirmationModalForViolationOCRResolve from './ConfirmationModalForViolationOCRResolve'
import ConfirmationModalForViolationResolve from './ConfirmationModalForViolationResolve'
import DeleteExpenseModal from './DeleteExpenseModal'
import { UpdateExpenseItemModal } from './update-single-Item/UpdateExpenseItemModal'
type Props = {
    show: boolean
    handleClose: () => void
    data: any
    expenseItemId?: any
    refetch?: any
    setShowInnerTable?: any
    expenseItemByIdTrigger?: any
}
interface LightboxData {
    imageURL: string;
}
const ExpenseItemViolationModal = ({ show, handleClose, data, expenseItemId, refetch, setShowInnerTable, expenseItemByIdTrigger }: Props) => {

    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
    const { token, baseURL } = useAppSelector(selectAuth);
    const [getOcrData, setGetOcrData] = useState<any>(null); // Initialize with null
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        if (show && data?.expenseId) {
            setIsLoading(true); // Set loading state to true
            try {
                const headers = {
                    Authorization: `Bearer ${token}`, // Add your authorization token here
                };
                const expenseOcrDetailResponse = await axios.get(`${baseURL}/api/ExpenseViolation${API_END_POINTS.getExpenseViolationsByExpenseId}?expenseId=${data?.expenseId}&expenseItemId=${expenseItemId || 0}`, {
                    headers, // Include the headers in the request
                });
                const getOcrData = expenseOcrDetailResponse.data;
                setGetOcrData(getOcrData);

            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false); // Set loading state to false after fetching data
            }
        }
    };


    useEffect(() => {
        if (show) {
            fetchData();
        }
        return () => {
            setGetOcrData(null);
        }
    }, [show, data?.expenseId, token, expenseItemId]); // Include token in the dependency array


    const handleError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.src = "https://media.istockphoto.com/id/1354776457/vector/default-image-icon-vector-missing-picture-page-for-website-design-or-mobile-app-no-photo.jpg?s=612x612&w=0&k=20&c=w3OW0wX3LyiFRuDHo9A32Q0IUMtD4yjXEvQlqyYk9O4=";
    };


    const [showResolveViolationConfirmationModal, setShowResolveViolationConfirmationModal] = useState(false)
    const [resolveViolationConfirmationData, setResolveViolationConfirmationData] = useState({});

    const resolveViolationConfirmationModalHandler = () => {
        setShowResolveViolationConfirmationModal((prev) => !prev);
    };
    const [showResolveViolationOCRConfirmationModal, setShowResolveViolationOCRConfirmationModal] = useState(false)
    const [resolveViolationOCRConfirmationData, setResolveViolationOCRConfirmationData] = useState({});

    const resolveViolationOCRConfirmationModalHandler = () => {
        setShowResolveViolationOCRConfirmationModal((prev) => !prev);
    };

    const [showUpdateExpenseItemModal, setShowUpdateExpenseItemModal] = useState(false)
    const [expenseLineItemId, setExpenseLineItemId] = useState(0)
    const expenseItemModalHandler = () => {
        setShowUpdateExpenseItemModal((prev) => !prev);
    };

    const [showExpenseDeleteModal, setShowExpenseDeleteModal] = useState(false)
    const deleteHandler = () => {
        setShowExpenseDeleteModal((prev) => !prev);
    };

    const isPdfFile = (filePath: string | undefined) => {
        if (!filePath) return false;
        const fileExtension = filePath.toLowerCase().split('.').pop();
        return fileExtension === 'pdf';
    };
    const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
        if (attachmentFile) {
            if (isPdfFile(attachmentFile)) {
                // For PDF files, open in the browser
                window.open(attachmentFile, '_blank');
            } else {
                // For image files, open in the Lightbox
                setLightboxData({ imageURL: attachmentFile });
                setLightboxOpen(true);
            }
        }
    };
    
    return (
        <>
            <ConfirmationModalForViolationResolve
                show={showResolveViolationConfirmationModal}
                handleClose={() => setShowResolveViolationConfirmationModal(false)}
                data={resolveViolationConfirmationData}
                fetchData={fetchData}
                refetch={refetch}
                setShowInnerTable={setShowInnerTable}
                expenseItemByIdTrigger={expenseItemByIdTrigger} />
            <ConfirmationModalForViolationOCRResolve
                show={showResolveViolationOCRConfirmationModal}
                handleClose={() => setShowResolveViolationOCRConfirmationModal(false)}
                data={resolveViolationOCRConfirmationData}
                fetchData={fetchData}
                refetch={refetch}
                setShowInnerTable={setShowInnerTable}
                expenseItemByIdTrigger={expenseItemByIdTrigger} />
            <UpdateExpenseItemModal
                show={showUpdateExpenseItemModal}
                handleClose={() => setShowUpdateExpenseItemModal(false)}
                violationLineItem={expenseLineItemId}
                viewOnlyData={false}
                refetch={fetchData} />
            <DeleteExpenseModal
                show={showExpenseDeleteModal}
                handleClose={() => setShowExpenseDeleteModal(false)}
                data={expenseLineItemId}
                refetch={fetchData} />
            <Tooltip id="violation-resolved" place="top" />
            <Tooltip id="violation-occured" place="top" />

            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog custom-modal-size modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header py-4'>
                    <h2 className='mb-0'>Violations</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
                <div className='modal-body py-lg-10 px-lg-10'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-9">
                                    <div>
                                        <p className='fw-bold mt-3 mb-1'>{labelKey.expenseType}: <span className='fw-semibold'>{data?.expenseType?.title}</span></p>
                                        <p className='fw-bold mt-3 mb-1'>{labelKey.reportId}: <span className='fw-semibold'>{data?.trackingId}</span></p>
                                        <p className='fw-bold mt-3 mb-1 text-capitalize'>{labelKey.expenseName}: <span className='fw-semibold'>{data?.title}</span></p>
                                    </div>
                                </div>
                            </div>

                            <div className='overflow-auto overflow-x-hidden h-400px table-responsive'>
                                <div className='table-responsive h-100'>
                                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                        {!isLoading && <>
                                            {getOcrData?.result?.length > 0 ? <>
                                                {getOcrData?.result?.map((itemData: any, index: any) => (<>
                                                    <thead className='bg-transparent'>
                                                        <tr className='fw-bold text-muted bg-transparent'>
                                                            <th colSpan={4} className='bg-transparent'>
                                                                <div className='d-flex gap-2 align-items-center mt-5'>
                                                                    <div>
                                                                        <img src={itemData?.attachmentFile || "https://media.istockphoto.com/id/1354776457/vector/default-image-icon-vector-missing-picture-page-for-website-design-or-mobile-app-no-photo.jpg?s=612x612&w=0&k=20&c=w3OW0wX3LyiFRuDHo9A32Q0IUMtD4yjXEvQlqyYk9O4="} alt=""
                                                                            width="40px" height="40px" className='rounded' onError={handleError}
                                                                            onClick={() => openPdfInBrowserOrLightbox(itemData?.attachmentFile)}
                                                                            data-tooltip-id="img-preview"
                                                                            data-tooltip-content='Click here to Preview Recipt'
                                                                        />
                                                                        {lightboxOpen && (
                                                                            <Lightbox
                                                                                mainSrc={lightboxData.imageURL}
                                                                                onCloseRequest={() => setLightboxOpen(false)}
                                                                                imageCaption="Attachment"
                                                                                enableZoom={true}
                                                                                imagePadding={50}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                    <span className='fw-bold text-dark fs-4'>{itemData?.expenseItemName}</span>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <thead>
                                                        <tr className='fw-bold text-muted'>
                                                            <th className='ps-4 rounded-start'>Violation</th>
                                                            <th>Provided Values</th>
                                                            <th className='fw-bold'>
                                                                Receipt Values
                                                                <span>
                                                                    <FaInfoCircle className='ms-2'
                                                                        data-tooltip-id="ocr-values"
                                                                        data-tooltip-content='Data provided is generated by OCR( Optical Character Recognition )' />
                                                                </span>
                                                            </th>
                                                            <th className='text-end pe-6 rounded-end'>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    {itemData?.itemsViolations?.map((ocrData: any, index: any) => (
                                                        <tbody>
                                                            <tr>
                                                                <td className='text-wrap  ps-4'>
                                                                    <div className='d-flex gap-2 align-items-center'>
                                                                        <div className='d-flex align-items-center gap-1'>
                                                                            {ocrData?.violationStatus === 1 ?
                                                                                <IoIosWarning className='text-danger'
                                                                                    data-tooltip-id="violation-occured"
                                                                                    data-tooltip-content='Violation-occured' />
                                                                                :
                                                                                <FaCheck className='text-success'
                                                                                    data-tooltip-id="violation-resolved"
                                                                                    data-tooltip-content='Violation-Resolved'
                                                                                />}
                                                                        </div>
                                                                        {ocrData && ocrData.violationMessage && <ReactReadMoreReadLess
                                                                            charLimit={50}
                                                                            readMoreText={"Read more"}
                                                                            readLessText={"Read less"}
                                                                            readMoreClassName="readMore"
                                                                            readLessClassName="readLess"
                                                                        >
                                                                            {ocrData.violationMessage}
                                                                        </ReactReadMoreReadLess>}
                                                                    </div>
                                                                </td>
                                                                <td>{ocrData?.violationPolicyFieldValue}</td>
                                                                <td>{ocrData?.ocrFieldValue}</td>

                                                                <td>
                                                                    <div className='d-flex align-items-center justify-content-end gap-2 pe-4'>
                                                                        {ocrData?.violationStatus === 1 &&
                                                                            <>
                                                                                {ocrData?.violationPolicyFieldName === "AddAllowedDaysLimit" ?
                                                                                    <button
                                                                                        className="btn btn-primary btn-xsm"
                                                                                        onClick={() => {
                                                                                            setResolveViolationConfirmationData({ itemData, ocrData });
                                                                                            resolveViolationConfirmationModalHandler();
                                                                                        }}
                                                                                    >Accecpt violation</button>
                                                                                    :
                                                                                    <Dropdown>
                                                                                        <Dropdown.Toggle className="btn btn-primary btn-xsm" id="dropdown-basic">
                                                                                            Resolve
                                                                                        </Dropdown.Toggle>

                                                                                        <Dropdown.Menu>
                                                                                            {ocrData?.violationPolicyFieldName === "ReceiptDuplicate" ?
                                                                                                <>
                                                                                                    <Dropdown.Item
                                                                                                        onClick={() => {
                                                                                                            setExpenseLineItemId(itemData?.expenseItemId)
                                                                                                            expenseItemModalHandler();
                                                                                                        }}>
                                                                                                        Edit Line Item
                                                                                                    </Dropdown.Item>
                                                                                                    <Dropdown.Item
                                                                                                        onClick={() => {
                                                                                                            setExpenseLineItemId(itemData?.expenseItemId)
                                                                                                            deleteHandler();
                                                                                                        }}>
                                                                                                        Delete line Item
                                                                                                    </Dropdown.Item>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <Dropdown.Item
                                                                                                        // onClick={() => handleDropdownItemClick('submitProvidedValue', itemData?.expenseItemId, itemData?.expenseId, ocrData?.ocrFieldValue)}
                                                                                                        onClick={() => {
                                                                                                            setResolveViolationConfirmationData({ itemData, ocrData });
                                                                                                            resolveViolationConfirmationModalHandler();
                                                                                                        }}

                                                                                                    >Submit Provided Value</Dropdown.Item>
                                                                                                    <Dropdown.Item
                                                                                                        // onClick={() => handleDropdownItemClick('submitOCRValue', itemData?.expenseItemId, itemData?.expenseId, ocrData?.ocrFieldValue)}
                                                                                                        onClick={() => {
                                                                                                            setResolveViolationOCRConfirmationData({ itemData, ocrData });
                                                                                                            resolveViolationOCRConfirmationModalHandler();
                                                                                                        }}

                                                                                                    >Submit Receipt Value</Dropdown.Item>
                                                                                                </>
                                                                                            }
                                                                                            {/* <Dropdown.Item>Add Custom Value</Dropdown.Item> */}
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    ))}
                                                </>))}
                                            </>
                                                :
                                                <tr>
                                                    <td colSpan={10}>
                                                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                            {labelKey.noMatchingRecordsFound}
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </>}
                                        {isLoading && (
                                            <h3 className="text-center text-gray">{labelKey.loading} ....</h3>
                                        )}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='modal-footer'>
                    <button className='btn btn-light' onClick={handleClose}>{labelKey.close}</button>
                </div>
            </Modal>
        </>
    )
}

export default ExpenseItemViolationModal