/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { toast } from 'react-toastify'
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../_metronic/helpers'
import { selectAuth } from '../../../../features/authSlice'
import { useAppSelector } from '../../../../hooks'
import { useAddUpdateExpenseItemV2WebMutation, useCreateExpenseForWebMutation, useLazyGetCardExpenseItemQuery, useLazyGetExpenseItemByExpenseIdQuery, useLazyGetExpenseItemsByExpenseItemIdQuery } from '../../../../services/ExpenseApi'

import { useSelector } from 'react-redux'
import { constraintConfig } from '../../../../constraintConfig'
import { useUploadOCRInvoiceMutation } from '../../../../services/BackgroundJobApi'
import { useGetAllExpenseTypeQuery } from '../../../../services/GeneralApi'
import { useUploadFileToAzureMutation } from '../../../../services/IntegrartionApi'
import { useGetSingleOrganizationQuery } from '../../../../services/OrganizationApi'
import { RootState } from '../../../../store'
import labelKey from "../../../localization/label.json"
import tosterKey from "../../../localization/toster.json"
import { Step1 } from '../create-app-stepper/steps/Step1'
import Step2NewItems from '../create-app-stepper/steps/Step2NewItems'
import { Step5 } from '../create-app-stepper/steps/Step5'
type Props = {
  show: boolean
  handleClose: () => void
  data: any
  // expenseItems: any
  refetch?: any
  setShowInnerTable?: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const UpdateExpenseModal = ({ show, handleClose, data, refetch, setShowInnerTable }: Props) => {


  const { userAccountID, userInOrgId, expenseType, location, locationName } = useAppSelector(selectAuth);

  const uploadReceiptStep2 = useSelector((state: RootState) => state.uploadReceiptStep2);

  const [isStep2Valid, setIsStep2Valid] = useState(false);
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  const [addExpense, { data: addExpenseData, isLoading, isError, error, isSuccess: expenseIsSuccess }] = useCreateExpenseForWebMutation();
  const [addUpdateExpenseItemV2, { data: addUpdateExpenseItemData, isSuccess: addUpdateExpenseItemV2IsSuccess, error: addUpdateExpenseItemV2Error, isError: addUpdateExpenseItemV2IsError, isLoading: addUpdateExpenseItemIsLoading }] = useAddUpdateExpenseItemV2WebMutation();

  // const { data: userAccountDetail, refetch: userDetailRefetch } = useGetUserAccountDetailQuery(userAccountID)
  const { data: orgDetail, refetch: orgDetailRefetch } = useGetSingleOrganizationQuery(userInOrgId)
  const { data: getAllExpenseType, refetch: expenseTypeRefetch } = useGetAllExpenseTypeQuery('')
  const [expenseItemByIdTrigger, { data: expenseItemData, }] = useLazyGetExpenseItemsByExpenseItemIdQuery({})
  const [expenseItemsDataCheck, setExpenseItemsDataCheck] = useState(false);
  const [getCardExpenseItemTrigger, { data: AllItems, isFetching: AllItemsIsLoading, isSuccess }] = useLazyGetCardExpenseItemQuery({})
  const [expenseAllItemsByIdTrigger, { data: expenseAllItemsData, isFetching }] = useLazyGetExpenseItemByExpenseIdQuery({})

  const expenseItems = expenseAllItemsData?.result

  const [selectedExpenseItemIds, setSelectedExpenseItemIds] = useState<number[]>([]);
  const [selectedExpenseItemData, setSelectedExpenseItemData] = useState<any[]>([]);
  const [isEditValues, setIsEditValues] = useState(false);
  const [isLineItemApiTrigger, setIsLineItemApiTrigger] = useState(false);
  useEffect(() => {
    if (show) {
      expenseAllItemsByIdTrigger({ ExpenseId: data?.expenseId });
    }
  }, [show])


  useEffect(() => {
    const ids = expenseItems?.map((item: any) => item.expenseItemId);
    setSelectedExpenseItemIds(ids);
    setSelectedExpenseItemData(expenseItems)
  }, [expenseItems, show]);




  const currentDate = new Date();
  const expenseInitialValues = {
    expenseId: 0,
    expenseType: {
      id: 0,
      title: ''
    },
    title: '',
    expenseDetail: '',
    reportDate: currentDate.toISOString().split('T')[0],
    expenseStatus: {
      id: 1,
      title: 'Draft'
    },
    userAccount: {
      orgUserID: userAccountID,
      bcEmployeeID: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      imageUrl: ''
    },
    itemIds: [0]
  }

  const expenseItemsInitialValues = {
    expenseItemId: 0,
    expenseId: 0,
    userAccountId: 0,
    invoiceDataDraftId: 0,
    recordSourceId: 0,
    expenseCategory: {
      id: 1,
      title: 'Please Select Category'
    },
    itemDetail: '',
    startLocation: '',
    endLocation: '',
    vendor: {
      vendorId: 0,
      name: '',
      disc: '',
      address: '',
      active: true,
      email: '',
      phone: ''
    },
    isMile: false,
    isKM: false,
    ocrPassed: true,
    paymentMethod: {
      id: 1,
      title: ''
    },
    currency: {
      id: 2,
      title: ''
    },
    reimbursableDistance: 0,
    amount: '0',
    milage: 0,
    isViolation: true,
    isMgrViolation: true,
    isCatagoryBudget: true,
    attachmentFile: '',
    mileageRate: '',
    itemDate: currentDate.toISOString().split('T')[0],
    startLatLngs: {
      lat: '',
      lng: ''
    },
    endLatLngs: {
      lat: '',
      lng: ''
    },
    tripType: {
      id: 1,
      title: 'One Way'
    },
    expenseType: {
      id: expenseType === 3 ? 1 : expenseType,
      title: expenseType === 1 ? 'Regular' : expenseType === 2 ? 'Flex' : 'Regular'
    },
    expItemLocation: {
      id: location || 0,
      title: locationName || '',
      locationCode: ''
    },
    isSplitLineItem: false,
    debitOrCreditIndicator: '',
    // createdDate: currentDate.toISOString().split('T')[0],
    // updatedDate: currentDate.toISOString().split('T')[0],
    splitLineItems: [
      {
        splitLineItemId: 0,
        expItemLocation: {
          id: 0,
          title: '',
          locationCode: ''
        },
        amount: '',
        percentage: 0
      }
    ]
  }
  const [checkExpenseItemId, setcheckExpenseItemId] = useState('')
  useEffect(() => {
    if (show && data) {
      expenseFormik.setValues(data);
    }
    if (expenseItemData?.result && isEditValues === true) {
      formikLineItems.setValues({ ...expenseItemData?.result });
      setcheckExpenseItemId(expenseItemData?.result?.expenseItemId);
    } else if (isEditValues === false) {
      formikLineItems.resetForm();
    } else {
      formikLineItems.resetForm();
      setExpenseItemsDataCheck(false);
    }

  }, [show, data, expenseItemData, isEditValues, expenseItemsDataCheck]);

  const [validationPassed, setValidationPassed] = useState(false);

  const validateForm = (values: any) => {
    const selectedDate = new Date(values.itemDate);
    const totalSplitAmount = values.splitLineItems.reduce((total: any, item: any) => total + Number(item.amount), 0);

    if (!values.expenseType.id) return tosterKey.expenseTypeIsRequired;
    if (!values.expenseCategory.id || values.expenseCategory.id === constraintConfig.categoriesId.other)
      return tosterKey.categoryIsRequired;
    if (values.expenseCategory.id !== constraintConfig.categoriesId.mileage &&
      orgDetail?.result?.receiptReqMinAmnt < values.amount &&
      !values.attachmentFile)
      return tosterKey.recieptIsRequired;
    if (!values.itemDate) return tosterKey.dateIsRequired;
    if (selectedDate > currentDate) return tosterKey.dateCannotBeInFuture;
    if (!values.currency.id) return tosterKey.currencyIsRequired;
    if (values.expenseCategory.id.toString() !== "14" && !values.amount) return tosterKey.amountIsRequired;
    if (values.expenseCategory.id.toString() !== "14" && orgDetail?.result?.expPolicyMaxLimit < values.amount)
      return tosterKey.AmountExceedAccordingToExpensePolicyLimit;
    if (values.expenseCategory.id.toString() === "14" && !values.tripType?.id) return tosterKey.tripTypeIsRequired;
    if (values.expenseCategory.id.toString() === "14" && !values.startLocation) return tosterKey.startLocationIsRequired;
    if (values.expenseCategory.id.toString() === "14" && !values.endLocation) return tosterKey.endLocationIsRequired;
    if (values.expenseCategory.id.toString() === "14" && !values.milage) return tosterKey.startAndEndLocationIsRequired;
    if (values.expenseCategory.id.toString() !== "14" && !values.vendor.vendorId) return tosterKey.vendorIsRequired;
    if (values.expenseCategory.id.toString() !== "14" && !values.expItemLocation.id) return tosterKey.locationIsRequired;
    if (!values.paymentMethod.id) return tosterKey.paymentMethodIsRequired;
    if (values?.itemDetail?.trim()?.length > 500) return tosterKey.expenseItemDetailMustNotExceed500Characters;

    if (values.splitLineItems.length > 0) {
      for (let i = 0; i < values.splitLineItems.length; i++) {
        const splitItem = values.splitLineItems[i];
        if (splitItem.amount && !splitItem.expItemLocation?.id && values.isSplitLineItem) {
          return `Location is required for split line item ${i + 1}`;
        }
      }
    }

    if (values.isSplitLineItem) {
      for (let i = 0; i < values.splitLineItems.length; i++) {
        const splitItem = values.splitLineItems[i];
        if (!splitItem.expItemLocation?.id) return `Location is required for split line item ${i + 1}`;
        if (!splitItem.amount || Number(splitItem.amount) <= 0) return `Amount must be greater than 0 for split line item ${i + 1}`;
      }

      const totalSplitAmount = values.splitLineItems.reduce((sum: any, lineItem: any) => sum + (parseFloat(lineItem.amount) || 0), 0);
      const roundedTotalSplitAmount = Math.round(totalSplitAmount * 100) / 100;
      const totalAmount = Number(values.amount);
      const remainingAmount = totalAmount - roundedTotalSplitAmount;
      if (Math.round(remainingAmount * 100) / 100 !== 0) {
        return tosterKey.splitAmountMustMatchTotalAmount;
      }
    }

    return null;
  };
  const [isSubmitted, setIsSubmitted] = useState(false);

  const formikLineItems = useFormik({
    initialValues: expenseItemsInitialValues,
    // validationSchema: validateForm,
    onSubmit: (values, { resetForm }) => {
      setIsSubmitted(true);

      const validationError = validateForm(values); // Call validateForm with the form values

      if (validationError) {
        toast.error(validationError); // Show the error message
        setValidationPassed(false); // Set validationPassed to false
        return; // Prevent submission if there is a validation error
      }

      // If validation passed, you can proceed with your logic
      setValidationPassed(true);



      if (isEditValues === true && expenseItemsDataCheck === false && isLineItemApiTrigger === true && checkExpenseItemId) {
        addUpdateExpenseItemV2(values)
      }
      else {
        addUpdateExpenseItemV2(values)
      }
      // resetForm()
    },
  })

  const expenseFormik = useFormik({
    initialValues: expenseInitialValues,
    onSubmit: (values, { resetForm }) => {
      if (isEditValues === false && expenseItemsDataCheck === false && isLineItemApiTrigger === true) {
        addExpense({ ...values, itemIds: selectedExpenseItemIds });
      }
    },
  })

  const queryUploadToAzureHook = formikLineItems.values?.expenseCategory?.id === constraintConfig.expenseCategoryID.mileage
    ? useUploadFileToAzureMutation
    : useUploadOCRInvoiceMutation;

  // const [uploadFileToAzureForMilage] = useUploadFileToAzureMutation();
  // const [uploadFileToAzure, { data: azurefileData, isLoading: fileIsloading, error: fileError, isError: fileIsError }] = useUploadOCRInvoiceMutation();

  const [uploadFileToAzure, { data: azurefileData, isLoading: fileIsloading, error: fileError, isError: fileIsError }] = queryUploadToAzureHook();

  const [imagePreviewFirst, setImagePreviewFirst] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [isPdf, setIsPdf] = useState<boolean>(false)


  const handleImageChangeFirst = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsPdf(file.type === 'application/pdf');
      const reader = new FileReader();
      reader.onload = async () => {
        setImagePreviewFirst(reader.result as string);
        const formData = new FormData();
        formData.append('file', file);
        const fileName = file.name.length > 20 ? file.name.substring(0, 20) + '...' : file.name;

        try {
          let response;
          if (formikLineItems.values?.expenseCategory?.id === constraintConfig.expenseCategoryID?.mileage) {
            response = await uploadFileToAzure(formData);
          } else {
            response = await uploadFileToAzure(formData);
          }

          const successMessage = (response as { data?: { message?: string } })?.data?.message;
          if (successMessage) {
            toast.success(`${successMessage}`);
          }

          if (formikLineItems.values?.expenseCategory?.id === constraintConfig.expenseCategoryID?.mileage) {
            const uploadedFileUrl = (response as { data?: any })?.data?.result;
            setFileName(fileName);

            formikLineItems.setFieldValue('attachmentFile', uploadedFileUrl);
            formikLineItems.setFieldValue('itemDate', currentDate.toISOString().split('T')[0]);
            formikLineItems.setFieldValue('amount', '0');
            formikLineItems.setFieldValue('vendor.vendorId', 0);
            formikLineItems.setFieldValue('vendor.name', '');
            formikLineItems.setFieldValue('invoiceDataDraftId', 0);
          } else {
            const result = (response as { data?: any })?.data?.result;

            // Set the form values only if result is truthy
            if (result) {
              const uploadedFileUrl = result.fileURL;
              const invoiceDate = result.invoiceDate;
              const amount = result.amount;
              const vendorId = result.vendorInfo?.vendorId;
              const vendorName = result.vendorInfo?.name;
              const categoryId = result.catagoryId;
              const categoryName = result.catagoryName;
              const paymentMethodId = result.paymentMethodId;
              const paymentMethodName = result.paymentMethodName;
              const invoiceDataDraftId = result.invoiceDataDraftId;

              // Set the file name in the state
              setFileName(fileName);
              // formikLineItems.setFieldValue('attachmentFile', uploadedFileUrl);
              // formikLineItems.setFieldValue('itemDate', invoiceDate);
              // formikLineItems.setFieldValue('amount', String(amount));
              // formikLineItems.setFieldValue('vendor.vendorId', vendorId);
              // formikLineItems.setFieldValue('vendor.name', vendorName);
              // formikLineItems.setFieldValue('expenseCategory.id', categoryId);
              // formikLineItems.setFieldValue('expenseCategory.title', categoryName);
              // formikLineItems.setFieldValue('paymentMethod.id', paymentMethodId);
              // formikLineItems.setFieldValue('paymentMethod.title', paymentMethodName);
              // formikLineItems.setFieldValue('invoiceDataDraftId', invoiceDataDraftId);
              formikLineItems.setFieldValue('attachmentFile', uploadedFileUrl);
              formikLineItems.setFieldValue('itemDate', formikLineItems.values?.recordSourceId === constraintConfig.recordSourceId.BankFeed ? formikLineItems.values?.itemDate : invoiceDate);
              formikLineItems.setFieldValue('amount', formikLineItems.values?.recordSourceId === constraintConfig.recordSourceId.BankFeed ? formikLineItems.values?.amount : String(amount));
              formikLineItems.setFieldValue('vendor.vendorId', formikLineItems.values?.recordSourceId === constraintConfig.recordSourceId.BankFeed ? formikLineItems.values?.vendor.vendorId : vendorId);
              formikLineItems.setFieldValue('vendor.name', formikLineItems.values?.recordSourceId === constraintConfig.recordSourceId.BankFeed ? formikLineItems.values?.vendor.name : vendorName);
              formikLineItems.setFieldValue('expenseCategory.id', categoryId);
              formikLineItems.setFieldValue('expenseCategory.title', categoryName);
              formikLineItems.setFieldValue('paymentMethod.id', formikLineItems.values?.recordSourceId === constraintConfig.recordSourceId.BankFeed ? formikLineItems.values?.paymentMethod.id : paymentMethodId);
              formikLineItems.setFieldValue('paymentMethod.title', formikLineItems.values?.recordSourceId === constraintConfig.recordSourceId.BankFeed ? formikLineItems.values?.paymentMethod.title : paymentMethodName);
              formikLineItems.setFieldValue('invoiceDataDraftId', invoiceDataDraftId);
            }
          }
        } catch (error) {
          console.error(error);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const [fileRemoved, setFileRemoved] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleRemoveFile = () => {
    // Reset the uploaded file and related states
    formikLineItems.setFieldValue('attachmentFile', null);
    setFileRemoved(true);

    // Reset the file input value
    const fileInput = fileInputRef.current;
    if (fileInput) {
      fileInput.value = ''; // Clear the input value
    }
  };
  useEffect(() => {
    if (fileIsError && fileError) {
      let errorMessage: string = 'Something went wrong';
      if ('data' in fileError && fileError.data && typeof fileError.data === 'object' && 'message' in fileError.data) {
        errorMessage = (fileError.data as { message: string }).message;
      }
      toast.error(errorMessage);
      formikLineItems.setFieldValue('attachmentFile', null);
      setFileRemoved(true);
      // Clear the file input
      const fileInput = fileInputRef.current;
      if (fileInput) {
        fileInput.value = ''; // Clear the input value
      }
    }
  }, [fileIsError, fileError]);

  useEffect(() => {
    // Check if the file was removed and update UI accordingly
    if (fileRemoved) {
      // Reset file-related states
      setFileName(null);
      setImagePreviewFirst(null);
      // Reset file removed state
      setFileRemoved(false);
    }
  }, [fileRemoved]);

  useEffect(() => {
    if (expenseIsSuccess) {
      const responseData = (addExpenseData as any)?.message;
      const successMessage = responseData || tosterKey.expenseAddSuccessfully;
      toast.success(successMessage);
      handleModalClose();
      if (setShowInnerTable) {
        setShowInnerTable(null)
      }
      refetch();

      // navigate(allRoutes.expenseReports);
    }
  }, [expenseIsSuccess, addExpenseData]);
  useEffect(() => {
    if (addUpdateExpenseItemV2IsSuccess) {
      setIsLineItemApiTrigger(false)
      setExpenseItemsDataCheck(false)
      setIsEditValues(false)
      setIsSubmitted(false);
      setFileName(null);

      const responseData = (addUpdateExpenseItemData as any)?.message;
      const successMessage = responseData || tosterKey.expenseAddSuccessfully;
      toast.success(successMessage);
      expenseAllItemsByIdTrigger({ ExpenseId: data?.expenseId });
      getCardExpenseItemTrigger({
        userAccountId: userAccountID,
        pageNumber: 1,
        pageSize: 1000000,
        sortBy: 'itemDate',
        sortOrder: 'sortOrder',
        searchStr: '',
        filterByCategory: '',
        startDate: '',
        endDate: '',
        filterBySubCategory: '',
      });
    }
  }, [addUpdateExpenseItemV2IsSuccess, addUpdateExpenseItemData])

  useEffect(() => {
    if (fileName !== null && imagePreviewFirst !== null) {
      setFileName(null);
      setImagePreviewFirst(null);
    }
  }, []);

  useEffect(() => {
    if (isError && error) {
      let errorMessage: string = 'Something went wrong';

      if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
        errorMessage = (error.data as { message: string }).message;
      }
      toast.error(errorMessage);
    }
  }, [isError, error]);
  useEffect(() => {
    if (addUpdateExpenseItemV2IsError && addUpdateExpenseItemV2Error) {
      let errorMessage: string = 'Something went wrong';

      if ('data' in addUpdateExpenseItemV2Error && addUpdateExpenseItemV2Error.data && typeof addUpdateExpenseItemV2Error.data === 'object' && 'message' in addUpdateExpenseItemV2Error.data) {
        errorMessage = (addUpdateExpenseItemV2Error.data as { message: string }).message;
      }

      toast.error(errorMessage);
    }
  }, [addUpdateExpenseItemV2IsError, addUpdateExpenseItemV2Error]);
  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
  }

  const nextStep = () => {
    if (!stepper.current) {
      return;
    }

    if (stepper.current?.currentStepIndex === 1) {
      // Perform validations
      if (!expenseFormik.values?.title || expenseFormik.values.title.trim() === "") {
        toast.error(tosterKey.reportNameIsRequired);
        return;
      }

      if (expenseFormik.values.title.trim().length > 80) {
        toast.error(tosterKey.reportNameNotExceed80Characters);
        return;
      }

      if (!expenseFormik.values?.reportDate) {
        toast.error(tosterKey.enterAValidReportingPeriod);
        return;
      }

      if (expenseFormik.values?.expenseDetail?.trim().length > 500) {
        toast.error(tosterKey.purposeOfExpenseMustNotExceed500Characters);
        return;
      }

      getCardExpenseItemTrigger({
        userAccountId: userAccountID,
        pageNumber: 1,
        pageSize: 1000000,
        sortBy: 'itemDate',
        sortOrder: 'sortOrder',
        searchStr: '',
        filterByCategory: '',
        startDate: '',
        endDate: '',
        filterBySubCategory: '',
      });
    }
    if (stepper.current?.currentStepIndex === 2) {
      setIsLineItemApiTrigger(true);
      if (selectedExpenseItemIds?.length === 0) {
        toast.error('At least one line item should be selected');
        return; // Prevent further execution if validation fails
      }
    }

    stepper.current.goNext();
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter' && stepper.current?.currentStepIndex !== 3) {  // assuming 4 steps, index of last step is 3
      event.preventDefault();
    }
  }

  const handleNavClick = (index: any) => {
    stepper.current?.goto(index)
  }
  const handleModalClose = () => {
    handleClose();
    setIsLineItemApiTrigger(false);
    setIsEditValues(false);
    setExpenseItemsDataCheck(false);
    setIsSubmitted(false);
    setFileName(null);
  };

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog  modal-dialog-centered'
      show={show}
      onHide={handleModalClose}
      onEntered={loadStepper}
      backdrop="static"
      fullscreen
    >
      <div className='modal-header'>
        <h2>Update Expense ({data ? data?.trackingId : null}) </h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleModalClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          {/* begin::Aside*/}
          <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
            {/* begin::Nav*/}
            <div className='stepper-nav ps-lg-10'>
              {/* begin::Step 1*/}
              <div className='stepper-item cursor-pointer current' data-kt-stepper-element='nav' onClick={() => handleNavClick(1)}>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>{labelKey.expenseReport}</h3>

                    <div className='stepper-desc'>{labelKey.addExpenseDetails}</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className='stepper-item cursor-pointer' data-kt-stepper-element='nav' onClick={() => handleNavClick(2)}>
                <div className='stepper-wrapper'>
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>

                  <div className='stepper-label'>
                    <h3 className='stepper-title'>{labelKey.expenseItems}</h3>
                    <div className='stepper-desc'>{labelKey.addExpenseItems}</div>
                  </div>
                </div>
                <div className='stepper-line h-40px'></div>
              </div>
              {/* end::Step 2*/}

              {/* begin::Step 5*/}
              <div className='stepper-item cursor-pointer' data-kt-stepper-element='nav' onClick={() => handleNavClick(3)}>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>3</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>{labelKey.overview}</h3>

                    <div className='stepper-desc'>{labelKey.reviewAndSubmit}</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
              </div>
              {/* end::Step 5*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* begin::Aside*/}

          {/*begin::Content */}
          <div className='flex-row-fluid pb-lg-5'>
            {/*begin::Form */}
            <form
              noValidate id='kt_modal_create_app_form'
              // onSubmit={expenseFormik.handleSubmit}
              onSubmit={(event) => {
                event.preventDefault();
                expenseFormik.handleSubmit();
              }}
              onKeyDown={handleKeyDown}
              className='position-relative'>

              <Step1 formik={expenseFormik} getAllExpenseType={getAllExpenseType} />
              {/* <Step2 formik={formik} isStep2Valid={isStep2Valid} data={id} orgDetail={orgDetail} getAllExpenseType={getAllExpenseType} show={show} /> */}


              <Step2NewItems
                formik={formikLineItems}
                expenseFormik={expenseFormik}
                handleImageChangeFirst={handleImageChangeFirst}
                imagePreviewFirst={imagePreviewFirst}
                fileName={fileName}
                fileIsloading={fileIsloading}
                handleRemoveFile={handleRemoveFile}
                fileInputRef={fileInputRef}
                orgDetail={orgDetail}
                isPdf={isPdf}
                addUpdateExpenseItemV2IsSuccess={addUpdateExpenseItemV2IsSuccess}
                selectedExpenseItemIds={selectedExpenseItemIds}
                setSelectedExpenseItemIds={setSelectedExpenseItemIds}
                AllItems={AllItems}
                getCardExpenseItemTrigger={getCardExpenseItemTrigger}
                expenseItemByIdTrigger={expenseItemByIdTrigger}
                expenseItemsDataCheck={expenseItemsDataCheck}
                setExpenseItemsDataCheck={setExpenseItemsDataCheck}
                setSelectedExpenseItemData={setSelectedExpenseItemData}
                AllItemsIsLoading={AllItemsIsLoading}
                isEditValues={isEditValues}
                setIsEditValues={setIsEditValues}
                expenseItems={expenseItems}
                viewOnlyData={true}
                setIsLineItemApiTrigger={setIsLineItemApiTrigger}
                addUpdateExpenseItemIsLoading={addUpdateExpenseItemIsLoading}
                addUpdateExpenseItemData={addUpdateExpenseItemData}
                handleSubmitLineItems={formikLineItems.handleSubmit}
                isSubmitted={isSubmitted}
                setIsSubmitted={setIsSubmitted}
                setFileName={setFileName}
              />
              <Step5 formik={expenseFormik}
                selectedExpenseItemData={selectedExpenseItemData} />

              {/*begin::Actions */}
              {!expenseItemsDataCheck && !isEditValues &&
                <div className='d-flex flex-stack pt-10'>
                  <div className='me-2'>
                    <button
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                      onClick={prevStep}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-3 me-1'
                      />{' '}
                      {labelKey.back}
                    </button>
                  </div>
                  <div>
                    {/* <button
                    type='submit'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='submit'
                    disabled={isLoading}
                  >
                    <span className='indicator-label'>{labelKey.save} </span>
                  </button> */}
                    {!isLoading ?
                      <>
                        <button
                          type='submit'
                          className='btn btn-lg btn-primary me-3'
                          data-kt-stepper-action='submit'
                          disabled={isLoading}
                          onClick={() => {
                            expenseFormik.setFieldValue('expenseStatus.id', 1);
                            expenseFormik.handleSubmit();
                          }}
                        >
                          <span className='indicator-label'>{labelKey.saveAsDraft}</span>
                        </button>
                        <button
                          className='btn btn-lg btn-primary'
                          data-kt-stepper-action='submit'
                          onClick={() => {
                            expenseFormik.setFieldValue('expenseStatus.id', 2);
                            expenseFormik.handleSubmit();
                          }}
                          disabled={isLoading}
                        >
                          <span className='indicator-label'>{labelKey.submitForApproval}</span>
                        </button>
                      </> :
                      <button className='btn btn-lg btn-primary' disabled={isLoading}>
                        {isLoading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            {labelKey.pleaseWait}{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    }
                    {/* <div className='d-flex align-items-center gap-4'>
                      {stepper.current && stepper.current?.currentStepIndex === 2 ?
                        <button
                          type='button'
                          className='btn btn-lg btn-primary'
                          onClick={() => {
                            setIsEditValues(false);
                            setExpenseItemsDataCheck(true);
                          }}
                        >
                          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                          {labelKey.addNewItem}{' '}

                        </button>
                        : null
                      } */}
                    <button
                      type='button'
                      className='btn btn-lg btn-primary'
                      data-kt-stepper-action='next'
                      onClick={nextStep}
                    >
                      {labelKey.nextStep}{' '}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-1 me-0'
                      />
                    </button>
                    {/* </div> */}
                  </div>
                </div>
              }
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export { UpdateExpenseModal }

