import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../../_metronic/layout/core';
import ExpenseByCategory from '../ExpenseByCategory';
import ExpenseDetail from '../ExpenseDetail';
import ExpenseSummary from '../ExpenseSummary';
import ExpenseViolationReport from '../ExpenseViolationReport';
import QueryReportTabs from './QueryReportTabs';
import { useAppSelector } from '../../../../hooks';
import { selectAuth } from '../../../../features/authSlice';
import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import CategoryBudgetReport from '../CategoryBudgetReport';
import { allRoutes } from '../../../routing/All_Routes';
import ExportExpenseReports from '../ExportExpenseReports';


const QueryReportPage = () => {
    const { roleID } = useAppSelector(selectAuth)
    const [show, setShow] = useState(true);
    const queryReportBreadCrumbs: Array<PageLink> = [
        ...(roleID !== 10
            ? [{
                title: 'Analytics',
                path: `${allRoutes.analytics}/org-report/expense-summary`,
                isSeparator: false,
                isActive: true,
            },
            {
                title: '',
                path: '',
                isSeparator: true,
                isActive: false,
            },]
            : []
        ),
        {
            title: 'Org Reports',
            path: `${allRoutes.analytics}/org-report/expense-summary`,
            isSeparator: false,
            isActive: true,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }, {
            title: '',
            path: '',
            isSeparator: false,
            isActive: false,
        },
    ]
    return (
        <>
            <Routes>
                <Route
                    element={
                        <>
                            {show &&
                                <Alert variant="primary" onClose={() => setShow(false)} dismissible>
                                    <p className='mb-0 fs-6'>By default, data is displayed from the last 7 days.</p>
                                </Alert>}
                            <QueryReportTabs />
                            <Outlet />
                        </>
                    }
                >
                    <Route
                        path='expense-summary'
                        element={
                            <>
                                <PageTitle breadcrumbs={queryReportBreadCrumbs}>Expense Summary</PageTitle>
                                <ExpenseSummary />
                            </>
                        }
                    />
                    <Route
                        path='expense-detail'
                        element={
                            <>
                                <PageTitle breadcrumbs={queryReportBreadCrumbs}>Expense Details</PageTitle>
                                <ExpenseDetail />
                            </>
                        }
                    />
                    <Route
                        path='expense-by-category'
                        element={
                            <>
                                <PageTitle breadcrumbs={queryReportBreadCrumbs}>Expense Categories</PageTitle>
                                <ExpenseByCategory />
                            </>
                        }
                    />
                    <Route
                        path='expense-violations'
                        element={
                            <>
                                <PageTitle breadcrumbs={queryReportBreadCrumbs}>Expense Violations</PageTitle>
                                <ExpenseViolationReport />
                            </>
                        }
                    />
                     <Route
                        path='category-budget'
                        element={
                            <>
                                <PageTitle breadcrumbs={queryReportBreadCrumbs}>Category Budget</PageTitle>
                                <CategoryBudgetReport />
                            </>
                        }
                    />
                    <Route
                        path='export-expense'
                        element={
                            <>
                                <PageTitle breadcrumbs={queryReportBreadCrumbs}>Export Report</PageTitle>
                                <ExportExpenseReports />
                            </>
                        }
                    />
                    <Route index element={<Navigate to={`${allRoutes.analytics}/org-report/expense-summary`} />} />

                </Route>
            </Routes>
        </>
    )
}

export default QueryReportPage