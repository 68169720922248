import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { logout, selectAuth } from "../features/authSlice";
import { RootState } from "../store";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";

const rawBaseQuery = fetchBaseQuery({
    baseUrl: "", // Base URL will be dynamically set
});

type ExtraOptions = {
    headers?: Record<string, string>;
};
let isToastShown = false;
export const DynamicBaseQueryHeader = (
    apiPath: string,
    urlType: "baseURL" | "microServiceURL" = "baseURL" // Default to baseURL
): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, ExtraOptions> => {
    return async (args, api, extraOptions) => {
        const authState = selectAuth(api.getState() as RootState);
        const orgBaseUrl = authState[urlType]; // Default is baseURL unless microServiceURL is specified

        if (!orgBaseUrl) {
            return {
                error: {
                    status: 400,
                    statusText: "Bad Request",
                    data: "Dynamic route not found",
                },
            };
        }

        const urlEnd = typeof args === "string" ? args : args.url;
        const adjustedUrl = `${orgBaseUrl}/api/${apiPath}${urlEnd}`;
        const adjustedArgs =
            typeof args === "string" ? { url: adjustedUrl } : { ...args, url: adjustedUrl };

        const token = localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user")!).token
            : null;

        const headers = token ? { Authorization: `Bearer ${token}` } : {};
        const mergedHeaders = { ...headers, ...extraOptions?.headers };

        const result = await rawBaseQuery(
            { ...adjustedArgs, headers: mergedHeaders },
            api,
            extraOptions
        );

        // if (result.error && result.error.status === 401) {
        //     api.dispatch(logout());
        //     toast.error('Session Expired, Please login again');
        // }
        if (result.error && result.error.status === 401) {
            if (!isToastShown) {
                isToastShown = true;
                toast.error('Session Expired, Please login again');
                setTimeout(() => {
                    isToastShown = false; // Reset the flag after a delay
                }, 3000); // Reset the flag after 3 seconds (or any desired duration)
            }
            api.dispatch(logout());
        }

        return result;
    };
};
