import { useFormik } from 'formik';
import { useEffect } from 'react';
import { FaCircleCheck } from "react-icons/fa6";
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { selectAuth, stopImpersonating } from '../../../features/authSlice';
import { handleSetUser } from '../../../helpers/SetUserHelper';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useImpersonateUserMutation } from '../../../services/authApi';
import labelKey from "../../localization/label.json";
import tosterKey from "../../localization/toster.json";

const RevertImpersonateUser = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const auth = useAppSelector(selectAuth);
    const impersonatePath = localStorage.getItem("impersonatePath");

    const [revertImpersonateUser, { data: imersonateData, isLoading, isSuccess, isError, error }] = useImpersonateUserMutation()
    const initialValues = {
        email: ''
    }
    const formik = useFormik({
        initialValues,
        onSubmit: (values, { resetForm }) => {

            const originalEmail = localStorage.getItem("originalEmail");
            if (originalEmail) {
                revertImpersonateUser({ email: originalEmail });

            } else {
                // console.log("Original email not found in localStorage!");
            }
        }
    })
    useEffect(() => {
        if (isSuccess) {
            handleSetUser({
                dispatch,
                data: imersonateData,
                overrides: {}
            });
            dispatch(stopImpersonating()); // Set isImpersonating to false
            // navigate("/expense-users")
            setTimeout(() => {
                navigate(impersonatePath ?? "/expense-users");
            }, 0);
            toast.success(tosterKey.impersonateRevertSuccessfully);
            localStorage.removeItem('originalEmail');
            localStorage.removeItem('impersonatePath');

        }
    }, [isSuccess, isError, auth, dispatch]);
    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';
            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    return (
        <>
            {auth.isImpersonating && localStorage.getItem("originalEmail") && (
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-5 col-md-7">
                            <div className='position-absolute mt-2'>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className='bg-light-success text-center px-3 rounded mt-2'>
                                        <FaCircleCheck className="text-success" />
                                        <span className='ms-2'>
                                            {/* {labelKey.impersoatingOtherUser} */}
                                            You are currently impersonating <span className='fw-bold text-primary'>{auth?.firstName} {auth?.lastName} - {auth?.userAccountID}.</span>
                                        </span>
                                        <button type='submit' className='badge badge-light-success fs-7 fw-semibold border-0 ms-3'>
                                            {!isLoading && <>{labelKey.revertIdentity}</>}
                                            {isLoading && (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    {labelKey.pleaseWait}...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default RevertImpersonateUser