import { useCallback, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { KTSVG } from '../../../../_metronic/helpers';
import Pagination from '../../../../components/pagination/Pagination';
import labelKey from "../../../localization/label.json";
import AddUpdateVendorModal from './AddUpdateVendorModal';
import ReactReadMoreReadLess from "react-read-more-read-less";
import VendorActiveConfirmationModal from './VendorActiveConfirmationModal';
import { useGetAllVendorQuery } from '../../../../services/VendorApi';
import SearchBarComponent from '../../../../components/searchBar/SearchBarComponent';
import { debounce } from 'lodash';
import TableSettingMenu from '../../../../components/tableHeading/TableSettingMenu';
import TextField from '../../../../components/textFields/TextField';
import TableHeading from '../../../../components/tableHeading/TableHeading';
import NoRecordFound from '../../../../components/noRecord/NoRecordFound';
import { LoadingComponent } from '../../../../components/loadingComponent/LoadingComponent';
import useColumnVisibility from '../../../customHooks/useColumnVisibility';

const VendorPage = () => {
    const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'vendor' });
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [isChecked, setIsChecked] = useState(false)

    const { data, isLoading, refetch } = useGetAllVendorQuery({
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchStr: searchStr,
    })
    const [showAddUpdateVendorModal, setShowAddUpdateVendorModal] = useState(false)
    const [showVendorEdit, setShowVendorEdit] = useState({})
    const vendorModalHandler = () => {
        setShowAddUpdateVendorModal((prev) => !prev);
    };
    const [showVendorActiveConfirmationModal, setShowVendorActiveConfirmationModal] = useState(false)
    const vendorActiveConfirmationHandler = () => {
        setShowVendorActiveConfirmationModal((prev) => !prev);
    };
    // pagination
    const handlePageClick = (e: any) => {
        setPageNumber(e.selected + 1);
    };
    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPageNumber(1); // Reset page number to 1
    };
    //per page record
    const handlePageRecords = (e: any) => {
        setPageNumber(1); // Reset the page to 1
    };
    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state

        delayedSearch(value); // Update debounced search state
        setPageNumber(1)
    };

    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );

    useEffect(() => {
        if (searchStr === "") {
            refetch();
        }
    }, [searchStr]);
    // const [prevModalState, setPrevModalState] = useState({
    //     addUpdateVendorModal: false,
    //     vendorActiveConfirmationModal: false,
    // });

    // // Update previous modal state whenever the modals change
    // useEffect(() => {
    //     setPrevModalState({
    //         addUpdateVendorModal: showAddUpdateVendorModal,
    //         vendorActiveConfirmationModal: showVendorActiveConfirmationModal,
    //     });
    // }, [showAddUpdateVendorModal, showVendorActiveConfirmationModal]);

    // // Check for modal closing and trigger refetch
    // useEffect(() => {
    //     if (
    //         prevModalState.addUpdateVendorModal && !showAddUpdateVendorModal ||
    //         prevModalState.vendorActiveConfirmationModal && !showVendorActiveConfirmationModal
    //     ) {
    //         refetch();
    //     }
    // }, [
    //     showAddUpdateVendorModal,
    //     showVendorActiveConfirmationModal,
    //     prevModalState.addUpdateVendorModal,
    //     prevModalState.vendorActiveConfirmationModal,
    //     refetch,
    // ]);
    return (
        <>
            <AddUpdateVendorModal
                show={showAddUpdateVendorModal}
                handleClose={() => setShowAddUpdateVendorModal(false)}
                data={showVendorEdit}
                refetch={refetch} />
            <VendorActiveConfirmationModal
                show={showVendorActiveConfirmationModal}
                handleClose={() => setShowVendorActiveConfirmationModal(false)}
                data={showVendorEdit}
                isChecked={isChecked}
                refetch={refetch}
            />

            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <SearchBarComponent
                        placeholder='Vendor'
                        value={immediateSearchStr}
                        onChange={handleSearchChange}
                    />
                    <div className='d-flex align-items-center ms-auto'>
                        <div className='card-toolbar'>
                            <button className='btn btn-sm btn-light-primary'
                                onClick={() => {
                                    setShowVendorEdit(true);
                                    vendorModalHandler();
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                {labelKey.addVendor}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive table-height'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {hiddenColumns.includes('sr') && (
                                        <TableHeading
                                            label={labelKey.sr}
                                            columnId='sr'
                                            className="ps-3"
                                        />
                                    )}
                                    {hiddenColumns.includes('name') && (
                                        <TableHeading
                                            label={labelKey.name}
                                            columnId='name'
                                        />
                                    )}
                                    {hiddenColumns.includes('description') && (
                                        <TableHeading
                                            label={labelKey.description}
                                            columnId='description'
                                            className="w-400px"
                                        />
                                    )}
                                    {hiddenColumns.includes('address') && (
                                        <TableHeading
                                            label={labelKey.address}
                                            columnId='address'
                                        />
                                    )}
                                    {hiddenColumns.includes('phone') && (
                                        <TableHeading
                                            label={labelKey.phone}
                                            columnId='phone'
                                        />
                                    )}
                                    {hiddenColumns.includes('status') && (
                                        <TableHeading
                                            label={labelKey.status}
                                            columnId='status'
                                        />
                                    )}
                                    <th className='text-end rounded-end pe-2 border-0'>
                                        {hiddenColumns.includes('actions') && (
                                            <>
                                                {labelKey.actions}
                                            </>
                                        )}
                                        <TableSettingMenu>
                                            <TextField
                                                rightLabel={labelKey.sr}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("sr")}
                                                checked={hiddenColumns.includes('sr')}
                                                fieldClass='mb-4'
                                            />

                                            <TextField
                                                rightLabel={labelKey.name}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("name")}
                                                checked={hiddenColumns.includes('name')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.description}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("description")}
                                                checked={hiddenColumns.includes('description')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.address}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("address")}
                                                checked={hiddenColumns.includes('address')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.phone}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("phone")}
                                                checked={hiddenColumns.includes('phone')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.status}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("status")}
                                                checked={hiddenColumns.includes('status')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.actions}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("actions")}
                                                checked={hiddenColumns.includes('actions')}
                                                fieldClass='mb-4'
                                            />
                                        </TableSettingMenu>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading && (
                                    <>
                                        {data?.result?.data?.length > 0 ? (
                                            <>
                                                {data?.result?.data?.map((data: any, index: any) => (
                                                    <tr key={data?.vendorId}>
                                                        {hiddenColumns.includes('sr') && (
                                                            <td className='ps-4'> {(pageNumber - 1) * pageSize + index + 1}</td>
                                                        )}
                                                        {hiddenColumns.includes('name') && (
                                                            <td>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='symbol symbol-50px symbol-circle me-5'>
                                                                        <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.name?.charAt(0)}</div>
                                                                    </div>
                                                                    <div className='d-flex justify-content-start flex-column'>
                                                                        <p className='text-capitalize mb-0'>
                                                                            {data?.name}
                                                                        </p>
                                                                        <span className='text-muted fw-semibold text-muted d-block fs-7 text-lowercase'>
                                                                            {data?.email}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        )}
                                                        {hiddenColumns.includes('description') && (
                                                            <td className='text-wrap'>
                                                                {data && data.disc && <ReactReadMoreReadLess
                                                                    charLimit={30}
                                                                    readMoreText={"Read more"}
                                                                    readLessText={"Read less"}
                                                                    readMoreClassName="readMore"
                                                                    readLessClassName="readLess"
                                                                >
                                                                    {data.disc}
                                                                </ReactReadMoreReadLess>}
                                                            </td>
                                                        )}
                                                        {hiddenColumns.includes('address') && (
                                                            <td>{data?.address}</td>
                                                        )}
                                                        {hiddenColumns.includes('phone') && (
                                                            <td>{data?.phone}</td>
                                                        )}
                                                        {hiddenColumns.includes('status') && (
                                                            <td>
                                                                <div className='d-flex align-items-center gap-2'>
                                                                    {data?.active === true ? <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.active}</span> :
                                                                        <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.inActive}</span>}
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            className="form-check-input cursor-pointer"
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            id={`flexSwitchCheckChecked-${data?.vendorId}`}
                                                                            checked={data?.active}
                                                                            onChange={(e) => {
                                                                                setIsChecked(e.target.checked);
                                                                                setShowVendorEdit(data);
                                                                                vendorActiveConfirmationHandler();
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={`flexSwitchCheckChecked-${data?.vendorId}`}></label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        )}
                                                        {hiddenColumns.includes('actions') && (
                                                            <td>
                                                                <div className='d-flex align-items-center justify-content-end gap-2' >
                                                                    <Tooltip id="update-vendor" place="bottom" />
                                                                    <div
                                                                        data-tooltip-id="update-vendor" data-tooltip-content=' Update Vendor'
                                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                        onClick={() => {
                                                                            setShowVendorEdit(data);
                                                                            vendorModalHandler();
                                                                        }}
                                                                    >
                                                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))}
                                            </>
                                        ) : (
                                            <tr>
                                                <td colSpan={10}>
                                                    <NoRecordFound />
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                )}
                                {isLoading && (
                                    <tr>
                                        <td colSpan={8} className="text-center">
                                            <LoadingComponent />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <Pagination
                        totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                        toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                        ofResult={data?.result?.totalRecords}
                        onChange={handlePageSizeChange}
                        pageSize={pageSize}
                        onPageChange={handlePageClick}
                        pageCount={data?.result?.totalPages || 0}
                        currentPage={pageNumber}
                    />
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
        </>
    )
}

export default VendorPage