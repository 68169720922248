import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../../_metronic/helpers';
import labelKey from "../../localization/label.json";
import tosterKey from "../../localization/toster.json";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useUpsertExpenseViolationMutation } from '../../../services/ExpenseVoilationApi';
import { toast } from 'react-toastify';

type Props = {
    show: boolean
    handleClose: () => void
    data: any
    refetch?: any
    fetchData?: any
    setShowInnerTable?: any
    expenseItemByIdTrigger?: any
}

const ConfirmationModalForViolationResolve = ({ show, handleClose, data, refetch, fetchData, setShowInnerTable, expenseItemByIdTrigger }: Props) => {
    const [updateExpenseViolation, { isLoading, isError, error, isSuccess }] = useUpsertExpenseViolationMutation();

    const initialValues = [
        {
            expenseItemId: 0,
            expenseId: 0,
            userAccountId: 0,
            upsertViolations: [
                {
                    expenseViolationId: 0,
                    ocrInvalidRead: false,
                    violationPolicyFieldId: 0,
                    violationPolicyFieldValue: ''
                }
            ]
        }
    ];


    const formik = useFormik({
        initialValues,
        // validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            // if () {
            //     values[0].upsertViolations[0].ocrInvalidRead = true;
            // }
            updateExpenseViolation(values)
        }
    })
    const handleDropdownItemClick = (action: string,
        expenseItemId: number,
        expenseId: number,
        ocrFieldValue: string,
        expenseViolationId: number,
        violationPolicyFieldId: number
    ) => {
        if (action === 'submitProvidedValue') {
            const updatedValues = {
                expenseItemId: expenseItemId || 0, // Get the expenseItemId from the fetched data
                expenseId: expenseId || 0, // Get the expenseId from the fetched data
                userAccountId: 0, // Assuming userAccountId
                upsertViolations: [
                    {
                        expenseViolationId: expenseViolationId || 0,
                        ocrInvalidRead: true, // Update ocrInvalidRead to true for "Submit Provided Value"
                        violationPolicyFieldId: violationPolicyFieldId || 0,
                        violationPolicyFieldValue: ''
                    }
                ]
            };
            formik.setValues([updatedValues]);
            formik.handleSubmit();
        }
    };
    useEffect(() => {
        if (isSuccess) {
            toast.success(tosterKey.statusUpdatedSuccessfully);
            if (refetch) {
                refetch();
            }
            if (fetchData) {
                fetchData();
            }
            if (setShowInnerTable) {
                setShowInnerTable(null)
            }
            if (expenseItemByIdTrigger) {
                expenseItemByIdTrigger({ ExpenseId: data?.itemData?.expenseId });
            }
            handleClose()
        }
    }, [isSuccess]);
    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header bg-light py-4'>
                    <h2 className='mb-0'>{labelKey.confirmation}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>


                <div className='modal-body py-lg-6 px-lg-6'>

                    <p className='fs-5 fw-semibold'>Are you sure you want to proceed with Provided Values?</p>
                </div>
                <div className='modal-footer justify-content-center py-4'>
                    <button className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</button>
                    <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn-primary'
                        onClick={() => handleDropdownItemClick('submitProvidedValue',
                            data?.itemData?.expenseItemId,
                            data?.itemData?.expenseId,
                            data?.ocrData?.ocrFieldValue,
                            data?.ocrData?.expenseViolationId,
                            data?.ocrData?.violationPolicyFieldId
                        )}
                        disabled={isLoading}
                    >
                        {!isLoading && <span className='indicator-label'>{labelKey.yesIConfirm}</span>}
                        {isLoading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                {labelKey.pleaseWait}...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default ConfirmationModalForViolationResolve
