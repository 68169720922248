import React from 'react'
import { constraintConfig } from '../../constraintConfig'
type Props = {
    expenseStatusId: any
    expenseStatusTitle: any

}
const ExpenseStatusComp = ({ expenseStatusId, expenseStatusTitle }: Props) => {
    return (
        <>
            {expenseStatusId === constraintConfig.expenseStatus.drafted ?
                <span className="badge badge-light-primary fs-7 fw-semibold">{expenseStatusTitle}</span> :
                expenseStatusId === constraintConfig.expenseStatus.pendingForApproval ?
                    <span className="badge badge-light-info fs-7 fw-semibold">{expenseStatusTitle}</span> :
                    // expenseStatusId === constraintConfig.expenseStatus.clarification ?
                    //     <span className="badge badge-light-warning fs-7 fw-semibold">{expenseStatusTitle}</span> :
                    expenseStatusId === constraintConfig.expenseStatus.approved ?
                        <span className="badge badge-light-success fs-7 fw-semibold">{expenseStatusTitle}</span> :
                        expenseStatusId === constraintConfig.expenseStatus.rejected ?
                            <span className="badge badge-light-danger fs-7 fw-semibold">{expenseStatusTitle}</span> :
                            expenseStatusId === constraintConfig.expenseStatus.apApproved ?
                                <span className="badge badge-light-success fs-7 fw-semibold">{expenseStatusTitle}</span> :
                                expenseStatusId === constraintConfig.expenseStatus.paid ?
                                    <span className="badge badge-light-success fs-7 fw-semibold">{expenseStatusTitle}</span> :
                                    expenseStatusId === constraintConfig.expenseStatus.RejectedAPReview ?
                                        <span className="badge badge-light-danger fs-7 fw-semibold">{expenseStatusTitle}</span> :
                                        expenseStatusId === constraintConfig.expenseStatus.pendingForAP ?
                                            <span className="badge badge-light-primary fs-7 fw-semibold">{expenseStatusTitle}</span> :
                                            <span className="badge badge-light-primary fs-7 fw-semibold">{expenseStatusTitle}</span>
            }
        </>
    )
}

export default ExpenseStatusComp