import ReactApexChart from 'react-apexcharts';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import labelKey from "../../localization/label.json";

const ExpenseDeptChart = (expenseDeptChartData: any) => {

  const barChartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false
      },
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    colors: ['#F6C000', '#323268'],
    // legend: {
    //   show: true,
    // },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: expenseDeptChartData?.expenseDeptChartData?.result?.map((department: any) => department?.departmentName) || [],
      labels: {
        style: {
          colors: 'var(--bs-heading-color)', // Use the CSS variable
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: 'var(--bs-heading-color)', // Use the CSS variable for y-axis labels
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '10%',
        borderRadius: 8,
        dataLabels: {
          position: 'top', // Display labels on top of each bar
        },
      },

    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right', // Align legend to the right
      offsetX: 0,
      offsetY: 0,
    },
    // tooltip: {
    //   shared: true,
    //   intersect: false,
    // },
    annotations: {
      // Vertical Line
      xaxis: [
        {
          x: 0, // x-axis index where the line will be drawn
          borderColor: '#8c8c8c',
        },
      ],
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: [{
        formatter: function (value: any) {
          return `${Math.round(value)}`;
        },
      },
      {
        formatter: function (value: any) {
          return `$${value.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
        },
      },
      ],
    },
  };

  const barChartSeries = [
    {
      name: 'No of Expenses',
      data: expenseDeptChartData?.expenseDeptChartData?.result?.map((department: any) => department?.departmentExpenseCount) || [],
    },
    {
      name: 'Total Expense Amount',
      data: expenseDeptChartData?.expenseDeptChartData?.result?.map((department: any) => department?.departmentTotalExpenseAmount) || [],
    },
  ];
  return (
    <>
      <Tooltip id="chart" place="top" />
      <div className='d-flex justify-content-between align-items-center'>
        <h3>{labelKey.expenseByDepartment} <span data-tooltip-id="chart" data-tooltip-content={labelKey.theChartDisplaysDataForTheLast7DaysByDefault}>
          <FaInfoCircle className='text-gray cursor-pointer' />
        </span> </h3>
      </div>
      <div className="mt-5">
        <div className='d-flex d-md-inline justify-content-center'>
          <ReactApexChart options={barChartOptions} series={barChartSeries} type='bar' height={400} className="card" />
        </div>
      </div>
    </>
  )
}

export default ExpenseDeptChart