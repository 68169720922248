/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { toast } from 'react-toastify'
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../_metronic/helpers'
import { selectAuth } from '../../../../features/authSlice'
import { useAppSelector } from '../../../../hooks'
import { useCreateExpenseWithItemMutation } from '../../../../services/ExpenseApi'
import { Step1 } from './steps/Step1'
import { Step2 } from './steps/Step2'
import { Step5 } from './steps/Step5'
type Props = {
  show: boolean
  handleClose: () => void
  refetch?:any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateAppModal = ({ show, handleClose ,refetch}: Props) => {
  const [isStep2Valid, setIsStep2Valid] = useState(false);
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }


  const [addExpense, { data, isLoading, isError, error, isSuccess }] = useCreateExpenseWithItemMutation();

  const { userAccountID } = useAppSelector(selectAuth);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const initialValues = {
    expenseId: 0,
    expenseType: {
      id: 0,
      title: ''
    },
    project: {
      projectId: 0,
      name: ''
    },
    title: '',
    expenseDetail: '',
    createdDate: currentDate.toISOString().split('T')[0],
    expenseStatus: {
      id: 1,
      title: ''
    },
    userAccount: {
      orgUserID: userAccountID,
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      imageUrl: ''
    },
    expenseItem: [
      {
        expenseItemId: 0,
        expenseId: 0,
        // expenseSubCategory: {
        //   id: 0,
        //   title: ''
        // },
        expenseCategory: {
          id: 0,
          title: ''
        },
        itemDetail: '',
        vendor: {
          vendorId: 0,
          name: '',
          disc: '',
          address: '',
          active: true,
          email: '',
          phone: ''
        },
        paymentMethod: {
          id: 0,
          title: ''
        },
        currency: {
          id: 2,
          title: ''
        },
        amount: 0,
        attachmentFile: '',
        file: '',
        itemDate: currentDate.toISOString().split('T')[0]
      }
    ]
  }
  
  const formik = useFormik({
    initialValues,
    // validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      
      if (formik.submitCount === 1) { // Only proceed with the API call if it's the first form submission
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('expenseType.id', String(values.expenseType.id));
        formData.append('project.projectId', String(values.project.projectId));
        formData.append('createdDate', values.createdDate);
        formData.append('expenseDetail', values.expenseDetail);
        formData.append('userAccount.orgUserID', String(values.userAccount.orgUserID));
        formData.append('expenseStatus.id', String(values.expenseStatus.id));
        values.expenseItem.forEach((item, index) => {
          const keyPrefix = `expenseItem[${index}].`;
          formData.append(`${keyPrefix}expenseCategory.id`, String(item.expenseCategory.id));
          formData.append(`${keyPrefix}itemDate`, item.itemDate);
          formData.append(`${keyPrefix}currency.id`, String(item.currency.id));
          formData.append(`${keyPrefix}amount`, String(item.amount));
          formData.append(`${keyPrefix}vendor.vendorId`, String(item.vendor.vendorId));
          formData.append(`${keyPrefix}paymentMethod.id`, String(item.paymentMethod.id));
          formData.append(`${keyPrefix}itemDetail`, item.itemDetail);
          formData.append(`${keyPrefix}file`, item.file);
        });
        addExpense(formData);
        resetForm()
      }
    },

  })


  useEffect(() => {
    if (isSuccess) {
      toast.success('Expense Add Successfully');
      handleClose();
      refetch();
    }
  }, [isSuccess]);


  useEffect(() => {
    if (isError && error) {
      let errorMessage: string = 'Something went wrong';

      if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
        errorMessage = (error.data as { message: string }).message;
      }
      toast.error(errorMessage);
    }
  }, [isError, error]);

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
  }
  useEffect(() => {
    let isValid = true;
    for (const item of formik.values.expenseItem) {
        if (!item?.expenseCategory?.id || !item?.itemDate || !item?.currency?.id || !item?.amount || !item?.vendor?.vendorId || !item?.paymentMethod?.id) {
            isValid = false;
            break;
        }
    }
    setIsStep2Valid(isValid);
}, [formik.values.expenseItem]);
  const nextStep = () => {
    if (!stepper.current) {
      return;
    }

    if (stepper.current.currentStepIndex === 1) {
      // Check if Step 1 fields are valid
      if (!formik.values?.expenseType.id) {
        toast.error('Expense Type is required');
        return;
      } else if (!formik.values?.title) {
        toast.error('Expense Title is required');
        return;
      } else if (!formik.values?.createdDate) {
        toast.error('Reporting Period is required');
        return;
      }
    }
    else if (stepper.current.currentStepIndex === 2) {
      // Iterate over each expense item
      for (const item of formik.values.expenseItem) {
        if (!item?.expenseCategory?.id) {
          toast.error('Category is required');
          return;
        }
        else if (!item?.itemDate) {
          toast.error('Date is required');
          return;
        } else if (!item?.currency?.id) {
          toast.error('Currency is required');
          return;
        } else if (!item?.amount) {
          toast.error('Amount is required');
          return;
        } else if (!item?.vendor?.vendorId) {
          toast.error('Vendor is required');
          return;
        }
        else if (!item?.paymentMethod?.id) {
          toast.error('PaymentMethod is required');
          return;
        }
      }
    }
    else if (stepper.current.currentStepIndex === 2) {
      // Check if all fields in expenseItem array are valid
      let isValid = true;
      formik.values.expenseItem.forEach((item) => {
        if (!item?.expenseCategory?.id || !item?.itemDate || !item?.currency?.id || !item?.amount || !item?.vendor?.vendorId || !item?.paymentMethod?.id) {
          isValid = false;
          return;
        }
      });

      setIsStep2Valid(isValid);

      if (!isValid) {
        // Show toaster if validation fails
        toast.error('Please fill in all fields for each expense item.');
        return;
      }
    }

    stepper.current.goNext();
  };

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-xl modal-dialog-centered'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
    >
      <div className='modal-header'>
        <h2>Create Expense</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          {/* begin::Aside*/}
          <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
            {/* begin::Nav*/}
            <div className='stepper-nav ps-lg-10'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Expence Details</h3>

                    <div className='stepper-desc'>Define your Expence Detail</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-wrapper'>
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Expense Item</h3>

                    <div className='stepper-desc'>Define your Item Detail</div>
                  </div>
                </div>
                <div className='stepper-line h-40px'></div>
              </div>




              {/* begin::Step 5*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-wrapper'>
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>3</span>
                  </div>

                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Completed</h3>

                    <div className='stepper-desc'>Review and Submit</div>
                  </div>
                </div>
              </div>
            </div>
            {/* end::Nav*/}
          </div>
          {/* begin::Aside*/}

          {/*begin::Content */}
          <div className='flex-row-fluid px-lg-15'>
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_app_form' onSubmit={formik.handleSubmit}>
              <Step1 formik={formik} />
              <Step2 formik={formik} isStep2Valid={isStep2Valid} />
              <Step5 formik={formik} />

              {/*begin::Actions */}
              <div className='d-flex flex-stack pt-10'>
                <div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                    onClick={prevStep}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 me-1'
                    />{' '}
                    Previous
                  </button>
                </div>
                <div>
                  <button
                    type='submit'
                    className='btn btn-lg btn-primary me-3'
                    data-kt-stepper-action='submit'
                  // onClick={submit}
                  >
                    {!isLoading && <span className='indicator-label'>Save
                    </span>}
                    {isLoading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <button
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='submit'
                    onClick={() => {
                      formik.setFieldValue('expenseStatus.id', 2);
                    }}
                  >
                    {!isLoading && <span className='indicator-label'>Save & Pending for Approval

                    </span>}
                    {isLoading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>

                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='next'
                    onClick={nextStep}
                  >
                    Next Step{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-1 me-0'
                    />
                  </button>
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export { CreateAppModal }

