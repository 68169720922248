import { useCallback, useEffect, useState } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { PageTitle } from '../../../_metronic/layout/core';
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp';
import DateComp from '../../../components/dateComponent/DateComp';
import Pagination from '../../../components/pagination/Pagination';
import { selectAuth } from '../../../features/authSlice';
import { useAppSelector } from '../../../hooks';
import { useLazyGetBudgetSubHistoryQuery, useManagerTeamBudgetQuery } from '../../../services/BudgetApi';
import labelKey from "../../localization/label.json";
import AddBudgetModal from './AddBudgetModal';
import SearchBarComponent from '../../../components/searchBar/SearchBarComponent';
import { Tooltip } from 'react-tooltip';
import { debounce } from 'lodash';
import TableHeading from '../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../components/tableHeading/TableSettingMenu';
import TextField from '../../../components/textFields/TextField';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../components/noRecord/NoRecordFound';
import useColumnVisibility from '../../customHooks/useColumnVisibility';
import RefreshComponent from '../../../components/refreshComponent/RefreshComponent';
import { toast } from 'react-toastify';
import tosterKey from '../../localization/toster.json'
import FilterMenu from '../../../components/filterMenu/FilterMenu';
import DateRangeComp from '../../../components/dateComponent/DateRangeComp';
import moment from 'moment-timezone';
import { useGetAllManagersLookupQuery } from '../../../services/GeneralApi';
import SearchSelect from '../../../components/textFields/SearchSelect';
import { constraintConfig } from '../../../constraintConfig';
import CategoriesWithIcons from '../expenseItems/CategoriesWithIcons';
import Lightbox from 'react-image-lightbox';
import { FaEye } from 'react-icons/fa6';

interface LightboxData {
    imageURL: string;
}
const BudgetPage = () => {
    const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'flexBudget' });
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
    const { userAccountID, role } = useAppSelector(selectAuth);
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [filterByStartDate, setFilterByStartDate] = useState("");
    const [filterByEndDate, setFilterByEndDate] = useState("");
    const [localFilterByStartDate, setLocalFilterByStartDate] = useState("");
    const [localFilterByEndDate, setLocalFilterByEndDate] = useState("");
    const [filterBySSId, setFilterBySSId] = useState("");
    const [localFilterBySSId, setLocalFilterBySSId] = useState("");
    const [filterByManagerId, setFilterByManagerId] = useState(0);
    const [localFilterByManagerId, setLocalFilterByManagerId] = useState(0);
    const [showInnerTable, setShowInnerTable] = useState(null);



    const [isApplyingFilters, setIsApplyingFilters] = useState(false);

    const { data: getAllManagers } = useGetAllManagersLookupQuery('')
    const [budgetHistoryTrigger, { data: getBudgetSubHistory, isFetching: subHistoryIsLoading }] = useLazyGetBudgetSubHistoryQuery({})
    const { data, isLoading, refetch, isSuccess } = useManagerTeamBudgetQuery({
        // managerId: userAccountID,
        pageNumber: page,
        pageSize: pageSize,
        searchstr: searchStr,
        startDate: filterByStartDate,
        endDate: filterByEndDate,
        ssId: filterBySSId,
        managerId: filterByManagerId
    })

    const handleRowClick = async (rowId: any, budgetId: any) => {
        if (showInnerTable === rowId) {
            // If the clicked row is already expanded, collapse it
            setShowInnerTable(null);
        } else {
            // Expand the clicked row
            if (budgetId) {
                setShowInnerTable(rowId);
                try {
                    budgetHistoryTrigger({ budgetId: budgetId });
                } catch (error) {
                }
            }
        }
    };


    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPage(1); // Reset page number to 1
    };
    // pagination
    const handlePageClick = (e: any) => {
        setPage(e.selected + 1);
    };
    //per page record
    const handlePageRecords = (e: any) => {
        setPage(1); // Reset the page to 1
    };
    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state

        delayedSearch(value); // Update debounced search state
        setPage(1);
    };

    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );
    useEffect(() => {
        refetch();
        if (searchStr === "") {
            refetch();
        }

    }, [searchStr]);
    const [showAddBudgetModal, setShowAddBudgetModal] = useState(false)
    const [editBudgetData, setEditBudgetData] = useState({})


    const addBudgetModalHandler = () => {
        setShowAddBudgetModal((prev) => !prev);
    };

    const [prevModalState, setPrevModalState] = useState({
        showAddBudgetModal: false,
    });

    // Update previous modal state whenever the modals change
    useEffect(() => {
        setPrevModalState({
            showAddBudgetModal: showAddBudgetModal,
        });
    }, [showAddBudgetModal]);

    // Check for modal closing and trigger refetch
    useEffect(() => {
        if (
            (prevModalState.showAddBudgetModal && !showAddBudgetModal)
        ) {
            refetch();
        }
    }, [
        showAddBudgetModal,
        prevModalState.showAddBudgetModal,
        refetch,
    ]);

    const handleRefresh = () => {
        refetch()
            .then(() => {
                if (isSuccess) {
                    toast.success(tosterKey.dataRefreshedSuccesfully);
                }
            });
    };
    const handleDateRangeChange = (event: any, picker: any) => {
        const startDateFormatted = moment(picker.startDate).format('MM-DD-YYYY');
        const endDateFormatted = moment(picker.endDate).format('MM-DD-YYYY');

        setLocalFilterByStartDate(startDateFormatted);
        setLocalFilterByEndDate(endDateFormatted);
    };
    const handleCancelDateSelection = (event: any, picker: any) => {
        // Reset the state to empty strings
        setFilterByStartDate("");
        setFilterByEndDate("");
        setLocalFilterByStartDate("");
        setLocalFilterByEndDate("");
    };
    const handleFilterBySSId = (e: any) => {
        setLocalFilterBySSId(e.target.value);
    };
    const handleManagerChange = (selectedOption: any) => {
        setLocalFilterByManagerId(selectedOption.value);
    };

    const debouncedRefetch = debounce(() => {
        refetch().finally(() => {
            setIsApplyingFilters(false);
        });
    }, 300);
    useEffect(() => {
        if (isApplyingFilters) {
            debouncedRefetch();
        }
    }, [isApplyingFilters]);
    const applyFilters = () => {
        setIsApplyingFilters(true);
        setFilterByStartDate(localFilterByStartDate);
        setFilterByEndDate(localFilterByEndDate);
        setFilterBySSId(localFilterBySSId);
        setFilterByManagerId(localFilterByManagerId)
        setPage(1);
    };
    const resetFilters = () => {
        setFilterByStartDate('');
        setFilterByEndDate('');
        setLocalFilterByStartDate('');
        setLocalFilterByEndDate('');
        setFilterBySSId('');
        setLocalFilterBySSId('');
        setFilterByManagerId(0);
        setLocalFilterByManagerId(0);
    }
    const isPdfFile = (filePath: string | undefined) => {
        if (!filePath) return false;
        const fileExtension = filePath.toLowerCase().split('.').pop();
        return fileExtension === 'pdf';
    };
    const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
        if (attachmentFile) {
            if (isPdfFile(attachmentFile)) {
                // For PDF files, open in the browser
                window.open(attachmentFile, '_blank');
            } else {
                // For image files, open in the Lightbox
                setLightboxData({ imageURL: attachmentFile });
                setLightboxOpen(true);
            }
        }
    };
    return (
        <>
            <AddBudgetModal show={showAddBudgetModal}
                handleClose={() => setShowAddBudgetModal(false)} budgetData={editBudgetData} />
            <PageTitle breadcrumbs={[]}>{labelKey.flexBudgetAllocation}</PageTitle>
            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <div className='card-title'>
                        <div className='d-flex align-items-center'>
                            <SearchBarComponent
                                placeholder='Budget'
                                value={immediateSearchStr}
                                onChange={handleSearchChange}
                            />
                            <RefreshComponent onClick={handleRefresh} />
                        </div>
                    </div>
                    <div className='d-flex align-items-center ms-auto'>
                        <FilterMenu
                            onclickApplyFilters={applyFilters}
                            onclickResetFilters={resetFilters}
                            isApplyingFiltersLoading={isApplyingFilters}
                        >
                            <>
                                <div className='mb-5'>
                                    <DateRangeComp
                                        startDate={localFilterByStartDate || moment()}
                                        endDate={localFilterByEndDate || moment()}
                                        onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}
                                        value={localFilterByStartDate && localFilterByEndDate ? `${localFilterByStartDate} - ${localFilterByEndDate}` : "MM/DD/YYYY"} />
                                </div>
                                <div className='mb-5'>
                                    {/* {role !== constraintConfig.roleID.role4 && */}
                                    <SearchSelect
                                        label={`${labelKey.manager}:`}
                                        options={[
                                            // { value: 0, label: 'All' }, // Add the empty option
                                            ...(Array.isArray(getAllManagers?.result) ? getAllManagers.result.map((option: any) => ({
                                                value: option.id,
                                                label: option?.value,
                                            })) : [])
                                        ]}
                                        placeholder='Select Manager...'
                                        value={localFilterByManagerId && localFilterByManagerId !== 0
                                            ? {
                                                value: localFilterByManagerId,
                                                label: getAllManagers?.result.find((option: any) => option.id === localFilterByManagerId)?.value
                                            }
                                            : null
                                        }
                                        onChange={handleManagerChange}
                                    />
                                    {/* } */}
                                </div>
                                <div className='mb-5'>
                                    <TextField
                                        label='SS ID'
                                        placeholder='Search By SSID'
                                        value={localFilterBySSId}
                                        onChange={handleFilterBySSId}
                                    />
                                </div>
                            </>
                        </FilterMenu>
                        <div className='card-toolbar'>
                            <button className='btn btn-sm btn-light-primary'
                                onClick={() => {
                                    setEditBudgetData(true);
                                    addBudgetModalHandler();
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                {labelKey.addFlexBudget}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive table-height'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {hiddenColumns.includes('sr') && (
                                        <TableHeading
                                            label={labelKey.sr}
                                            columnId='sr'
                                            className="ps-4"
                                        />
                                    )}
                                    {hiddenColumns.includes('ssId') && (
                                        <TableHeading
                                            label={labelKey.ssId}
                                            columnId='ssId'
                                        />
                                    )}
                                    {hiddenColumns.includes('bcEmployeeId') && (
                                        <TableHeading
                                            label={labelKey.bcEmployeeId}
                                            columnId='bcEmployeeId'
                                        />
                                    )}
                                    {hiddenColumns.includes('user') && (
                                        <TableHeading
                                            label={labelKey.user}
                                            columnId='user'
                                        />
                                    )}
                                    {hiddenColumns.includes('userType') && (
                                        <TableHeading
                                            label={labelKey.userType}
                                            columnId='userType'
                                        />
                                    )}
                                    {hiddenColumns.includes('allocatedBudget') && (
                                        <TableHeading
                                            label={labelKey.allocatedBudget}
                                            columnId='allocatedBudget'
                                            className="w-200px"
                                        />
                                    )}
                                    {hiddenColumns.includes('remainingBudget') && (
                                        <TableHeading
                                            label={labelKey.remainingBudget}
                                            columnId='remainingBudget'
                                        />
                                    )}
                                    {hiddenColumns.includes('startDate') && (
                                        <TableHeading
                                            label={labelKey.startDate}
                                            columnId='startDate'
                                        />
                                    )}
                                    {hiddenColumns.includes('endDate') && (
                                        <TableHeading
                                            label={labelKey.endDate}
                                            columnId='endDate'
                                        />
                                    )}

                                    <th className='text-end rounded-end pe-2 border-0'>
                                        {hiddenColumns.includes('actions') && (
                                            <>
                                                {labelKey.actions}
                                            </>
                                        )}
                                        <TableSettingMenu>
                                            <TextField
                                                rightLabel={labelKey.sr}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("sr")}
                                                checked={hiddenColumns.includes('sr')}
                                                fieldClass='mb-4'
                                            />

                                            <TextField
                                                rightLabel={labelKey.ssId}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("ssId")}
                                                checked={hiddenColumns.includes('ssId')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.bcEmployeeId}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("bcEmployeeId")}
                                                checked={hiddenColumns.includes('bcEmployeeId')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.user}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("user")}
                                                checked={hiddenColumns.includes('user')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.userType}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("userType")}
                                                checked={hiddenColumns.includes('userType')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.allocatedBudget}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("allocatedBudget")}
                                                checked={hiddenColumns.includes('allocatedBudget')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.remainingBudget}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("remainingBudget")}
                                                checked={hiddenColumns.includes('remainingBudget')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.startDate}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("startDate")}
                                                checked={hiddenColumns.includes('startDate')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.endDate}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("endDate")}
                                                checked={hiddenColumns.includes('endDate')}
                                                fieldClass='mb-4'
                                            />

                                            <TextField
                                                rightLabel={labelKey.actions}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("actions")}
                                                checked={hiddenColumns.includes('actions')}
                                                fieldClass='mb-4'
                                            />
                                        </TableSettingMenu>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading &&
                                    <>
                                        {data?.result?.data?.length > 0 ?
                                            (
                                                <>
                                                    {data?.result?.data?.map((data: any, index: any) => (
                                                        <>
                                                            <tr key={index}
                                                                data-tooltip-id="my-tooltip" data-tooltip-content={labelKey.clickHereToExpand}
                                                                className={`cursor-pointer ${showInnerTable === index ? 'highlighted-row' : ''}`}
                                                                onClick={() => {
                                                                    handleRowClick(index, data?.budgetId);
                                                                }}>
                                                                {hiddenColumns.includes('sr') && (
                                                                    <td className='ps-4'> {(page - 1) * 10 + index + 1}</td>
                                                                )}
                                                                {hiddenColumns.includes('ssId') && (
                                                                    <td> {data?.userAccount?.orgUserID}</td>
                                                                )}
                                                                {hiddenColumns.includes('bcEmployeeId') && (
                                                                    <td> {data?.userAccount?.bcEmployeeID}</td>
                                                                )}
                                                                {hiddenColumns.includes('user') && (
                                                                    <td>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                                {/* {data?.userAccount?.imageUrl ? <img src={data?.userAccount?.imageUrl} alt="" /> : */}
                                                                                <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.userAccount?.firstName?.charAt(0)}</div>
                                                                                {/* } */}
                                                                            </div>
                                                                            <div className="d-flex flex-column">
                                                                                <span className="text-gray-800 fw-bold mb-1 text-capitalize">{data?.userAccount?.firstName} {data?.userAccount?.middleName} {data?.userAccount?.lastName}</span>
                                                                                <span className='text-lowercase'>{data?.userAccount?.email}</span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('userType') && (
                                                                    <td> {data?.userType}</td>
                                                                )}
                                                                {hiddenColumns.includes('allocatedBudget') && (
                                                                    <td><CurrencyComp amount={data?.allocateBudget} /></td>
                                                                )}
                                                                {hiddenColumns.includes('remainingBudget') && (
                                                                    <td><CurrencyComp amount={data?.remainingBudget} /></td>
                                                                )}
                                                                {hiddenColumns.includes('startDate') && (
                                                                    <td><DateComp formattedDate={data.startDate} /></td>
                                                                )}
                                                                {hiddenColumns.includes('endDate') && (
                                                                    <td><DateComp formattedDate={data.endDate} /></td>
                                                                )}

                                                                {hiddenColumns.includes('actions') && (
                                                                    <td>
                                                                        <div className='d-flex align-items-center justify-content-end gap-2' >
                                                                            <Tooltip id="update-budget" place="bottom" />
                                                                            <div
                                                                                data-tooltip-id="update-budget" data-tooltip-content={labelKey.updateBudget}
                                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                                onClick={() => {
                                                                                    setEditBudgetData(data);
                                                                                    addBudgetModalHandler();
                                                                                }}
                                                                            >
                                                                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                            {showInnerTable === index && (
                                                                <tr className={`inner-row ${showInnerTable === index ? 'highlighted-row' : ''}`}>
                                                                    <td className='pt-0 px-3' colSpan={20}>
                                                                        <div className="table-responsive sticky-responsive">
                                                                            <table className="table align-middle gs-0 gy-4">
                                                                                <thead>
                                                                                    <tr className='fw-bold text-muted bg-light'>
                                                                                        <th>{labelKey.reportId}</th>
                                                                                        <th className='ps-4'>{labelKey.itemDate}</th>
                                                                                        <th>{labelKey.expenseType}</th>
                                                                                        <th>{labelKey.category}</th>
                                                                                        <th className='w-100px text-end pe-15'>{labelKey.amount}</th>
                                                                                        <th className='w-150px pe-15 text-end'>{labelKey.reimbursableDistance}</th>
                                                                                        <th>{labelKey.vendor}</th>
                                                                                        <th>{labelKey.paymentMethod}</th>
                                                                                        <th>{labelKey.attachment}</th>
                                                                                        <th>{labelKey.description}</th>
                                                                                        <th>{labelKey.source}</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {!subHistoryIsLoading && <>
                                                                                        {getBudgetSubHistory?.result?.length > 0 ? <>
                                                                                            {getBudgetSubHistory?.result?.map((itemData: any, index: any) => (
                                                                                                <tr key={index}>
                                                                                                    <td>{itemData.trackingId}</td>
                                                                                                    <td><DateComp formattedDate={itemData.itemDate} /></td>
                                                                                                    <td>{itemData?.expenseType?.title}</td>
                                                                                                    <td className='ps-5'>
                                                                                                        <CategoriesWithIcons itemData={itemData} />
                                                                                                    </td>
                                                                                                    <td className={`text-end pe-15 ${itemData?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator ? 'text-danger' : null}`}>
                                                                                                        <CurrencyComp amount={itemData?.amount} />
                                                                                                    </td>
                                                                                                    <td className='text-end pe-15'>
                                                                                                        {itemData?.expenseCategory?.id === constraintConfig.categoriesId.mileage ?
                                                                                                            <>
                                                                                                                {itemData?.reimbursableDistance}
                                                                                                            </>
                                                                                                            : null
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td>{itemData?.vendor?.name !== " " ? itemData?.vendor?.name : "N/A"}</td>
                                                                                                    <td>{itemData?.paymentMethod?.title}</td>
                                                                                                    <td>
                                                                                                        {itemData?.attachmentFile === "undefined" ||
                                                                                                            itemData?.attachmentFile === "null" ||
                                                                                                            itemData?.attachmentFile === null ||
                                                                                                            itemData?.attachmentFile === "" ||
                                                                                                            itemData?.attachmentFile === "Receipt is not available" ?
                                                                                                            <span className='ps-9'>N/A</span> :
                                                                                                            <FaEye className='ms-10' onClick={() => openPdfInBrowserOrLightbox(itemData?.attachmentFile)} />
                                                                                                        }
                                                                                                        {lightboxOpen && (
                                                                                                            <Lightbox
                                                                                                                mainSrc={lightboxData.imageURL}
                                                                                                                onCloseRequest={() => setLightboxOpen(false)}
                                                                                                                imageCaption="Attachment"
                                                                                                                enableZoom={true}
                                                                                                                imagePadding={50}
                                                                                                            />
                                                                                                        )}
                                                                                                    </td>
                                                                                                    <td>{itemData?.itemDetail?.length > 60 ? `${itemData?.itemDetail.substring(0, 40)}...` : itemData?.itemDetail}</td>
                                                                                                    <td>
                                                                                                        {itemData?.recordSourceId === constraintConfig.recordSourceId.WebApp ?
                                                                                                            <KTSVG path="/media/icons/figmaIcons/Web.svg" className='svg-icon-1 ms-4'
                                                                                                                svgClassName='w-20px h-20px'
                                                                                                                data-tooltip-id="web-source" data-tooltip-content='Website' />
                                                                                                            :
                                                                                                            itemData?.recordSourceId === constraintConfig.recordSourceId.Mobile ?
                                                                                                                <KTSVG path="/media/icons/figmaIcons/Mobile.svg" className='svg-icon-1 ms-4'
                                                                                                                    data-tooltip-id="mobile-source" data-tooltip-content='Mobile' /> :
                                                                                                                itemData?.recordSourceId === constraintConfig.recordSourceId.BankFeed ?
                                                                                                                    <KTSVG path="/media/icons/figmaIcons/Bank.svg" className='svg-icon-1 ms-4'
                                                                                                                        data-tooltip-id="bank-source" data-tooltip-content='Bank' />
                                                                                                                    : null}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </>
                                                                                            :
                                                                                            <tr>
                                                                                                <td colSpan={13}>
                                                                                                    <NoRecordFound />
                                                                                                </td>
                                                                                            </tr>
                                                                                        }
                                                                                    </>}
                                                                                    {subHistoryIsLoading && (
                                                                                        <tr>
                                                                                            <td colSpan={13} className="text-center">
                                                                                                <LoadingComponent />
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </>
                                                    ))}
                                                </>)
                                            :
                                            (
                                                <tr>
                                                    <td colSpan={12}>
                                                        <NoRecordFound />
                                                    </td>
                                                </tr>
                                            )}
                                    </>
                                }
                                {isLoading && (
                                    <tr>
                                        <td colSpan={12} className="text-center">
                                            <LoadingComponent />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                        toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                        ofResult={data?.result?.totalRecords}
                        onChange={handlePageSizeChange}
                        pageSize={pageSize}
                        onPageChange={handlePageClick}
                        pageCount={data?.result?.totalPages || 0}
                        currentPage={page}
                    />
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
        </>
    )
}

export default BudgetPage
