

import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { KTSVG } from '../../../../_metronic/helpers';
import TextAreaField from '../../../../components/textFields/TextAreaField';
import TextField from '../../../../components/textFields/TextField';
import { useAddActiveExpenseViolationsMutation, useUpdateActiveExpenseViolationsMutation } from '../../../../services/OrgSettingsApi';
import labelKey from "../../../localization/label.json";
import tosterKey from "../../../localization/toster.json";
import { useAddViolationPolicyFieldMutation, useUpdateViolationPolicyFieldMutation } from '../../../../services/ExpenseVoilationApi';
import { useAppSelector } from '../../../../hooks';
import { selectAuth } from '../../../../features/authSlice';
import { API_END_POINTS } from '../../../../services/apiEndpoints';
import axios from 'axios';
import { Tooltip } from 'react-tooltip';
import { useGetViolationPolicyTypeLookupQuery } from '../../../../services/GeneralApi';
import Select from 'react-select';


type Props = {
  show: boolean
  handleClose: () => void
  data?: any
  refetch?: any
}

const AddUpdateExpenseVoilation = ({ show, handleClose, data, refetch }: Props) => {
  const [addExpenseViolation, { data: addData, isLoading, isSuccess, isError, error }] = useAddViolationPolicyFieldMutation()
  const [updateExpenseVioloation, { data: updateData, isLoading: updateIsLoading, isSuccess: updateIsSuccess, isError: updateIsError, error: updateError }] = useUpdateViolationPolicyFieldMutation()
  const { data: violationType } = useGetViolationPolicyTypeLookupQuery('')

  const initialValues = {
    violationPolicyFieldId: 0,
    fieldName: '',
    description: '',
    violationMessage: '',
    violationLevelVM: {
      id: 0,
      title: ''
    },
    isActive: true
  }

  const [updateId, setUpdateId] = useState(0);
  const [formValues, setFormValues] = useState(initialValues);
  // Store form values when the modal is closed
  const handleModalClose = () => {
    setFormValues(formik.values);
    handleClose();
  };
  useEffect(() => {
    // Restore form values when the modal reopens
    if (show && !data && Object.keys(formValues).length !== 0) {
      formik.setValues(formValues);
    }
    // Update form values based on data received
    if (data) {
      setUpdateId(data?.violationPolicyFieldId);
      formik.setValues({ ...data });
    } else if (!show) {
      // Reset form values only when closing and it's not an update action
      setUpdateId(0);
      formik.resetForm();
    }
  }, [show, data]);

  const validationSchema = Yup.object().shape({
    fieldName: Yup.string().required('Field Name is required'),
    violationLevelVM: Yup.object().shape({
      id: Yup.number().min(1, 'Expense Policy Type is required').required('Expense Policy Type is required'),
    }),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (updateId) {
        updateExpenseVioloation(values)
      } else {
        addExpenseViolation(values)
      }
    }
  })
  const { resetForm } = formik;


  useEffect(() => {
    if (isSuccess) {
      const responseData = (addData as any)?.message;
      const successMessage = responseData;
      toast.success(successMessage);
      handleClose();
      resetForm();
      refetch();
    }
  }, [isSuccess, addData]);

  useEffect(() => {

    if (updateIsSuccess) {
      const responseData = (updateData as any)?.message;
      const successMessage = responseData;
      toast.success(successMessage);
      handleClose();
      refetch();
    }
  }, [updateIsSuccess, updateData]);

  useEffect(() => {
    if (isError && error) {
      let errorMessage: string = 'Something went wrong';

      if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
        errorMessage = (error.data as { message: string }).message;
      }
      toast.error(errorMessage);
    }
  }, [isError, error]);
  useEffect(() => {
    if (updateIsError && updateError) {
      let errorMessage: string = 'Something went wrong';

      if ('data' in updateError && updateError.data && typeof updateError.data === 'object' && 'message' in updateError.data) {
        errorMessage = (updateError.data as { message: string }).message;
      }
      toast.error(errorMessage);
    }
  }, [updateIsError, updateError]);

  const { token, baseURL, userInOrgId } = useAppSelector(selectAuth);
  const [detailData, setDetailData] = useState<any>(null); // Initialize with null
  // const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      if (show && data) {
        // setIsLoading(true);
        try {
          const headers = {
            Authorization: `Bearer ${token}`,
          };

          const getExpenseLogResponse = await axios.get(
            `${baseURL}/api/Organization${API_END_POINTS.getOrganizationDetailByID}?id=${userInOrgId}`,
            {
              headers,
            }
          );
          const detailData = getExpenseLogResponse.data;
          setDetailData(detailData);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          // setIsLoading(false); 
        }
      }
    };
    fetchData();
  }, [show, data, token]);

  const tooltip = () => {
    return "Expenses must be submitted within 30 days of the date they were incurred.";
  }

  return (
    <>
      <Modal
        aria-hidden='true'
        dialogClassName='modal-dialog modal-md modal-dialog-centered'
        show={show}
        onHide={handleModalClose}
      >
        <div className='modal-header'>
          <h2 className='mb-0'>{updateId ? <>{labelKey.updateExpenseViolation}</> : <>{labelKey.addExpenseViolation}</>}</h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
          {/* end::Close */}
        </div>

        <form
          className='form w-100'
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            formik.setTouched({
              fieldName: true,
            }, true); // Set touched for all fields to trigger validation display
          }}
        >
          <div className='modal-body py-lg-10 px-lg-10'>
            <div className="row">
              <div className='col-md-12 mb-5'>
                <TextField
                  label={labelKey.fieldName}
                  required={true}
                  type='text'
                  placeholder='Enter Field Name'
                  labelClass={`${formik.touched.fieldName && formik.errors.fieldName ? 'is-invalid' : ''}`}
                  {...formik.getFieldProps('fieldName')}
                  value={formik.values.fieldName || ''}
                  readOnly={!!updateId}
                />
                {formik.touched.fieldName && formik.errors.fieldName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.fieldName}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-md-12 mb-5'>
                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                  <span className='required'>{labelKey.expensePolicyType}</span>
                </label>
                <Select
                  options={violationType?.result?.map((option: any) => ({
                    label: option?.value,
                    value: option?.id
                  }))}
                  value={
                    formik.values?.violationLevelVM?.id
                      ? {
                        value: formik.values?.violationLevelVM?.id,
                        label: formik.values?.violationLevelVM?.title || '',
                      }
                      : null
                  }
                  onChange={(selectedOption) => {
                    formik.setFieldValue(`violationLevelVM.id`, selectedOption?.value || null);
                    formik.setFieldValue(`violationLevelVM.title`, selectedOption?.label || null);
                  }}
                  placeholder='Select Category'
                />
                {formik.touched.violationLevelVM?.id && formik.errors.violationLevelVM?.id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.violationLevelVM?.id}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-md-12 mb-5'>
                <TextAreaField
                  label={labelKey.description}
                  placeholder='Enter Description'
                  rows={5}
                  {...formik.getFieldProps('description')}
                  value={formik.values.description || ''}
                />
              </div>
              <div className='col-md-12 mb-5'>
                <div>
                  <label className={`form-label fw-bold text-dark fs-6 text-nowrap`}>
                    {labelKey.violationMessage}
                    {data?.fieldName === "AddAllowedDaysLimit" &&
                      <>
                        <i className="fa fa-info-circle ms-2 cursor-pointer"
                          data-tooltip-id='allowdays-tooltip'>
                        </i>
                        <Tooltip id='allowdays-tooltip' place="top" className='text-center'>
                          <span className='text-center'>Expenses must be submitted within <br />
                            ${detailData?.result?.expAllowedOldDays} days of the date they were incurred.</span>
                        </Tooltip>
                      </>
                    }
                  </label>
                  <textarea
                    className={`form-control form-control-solid default-input`}
                    placeholder='Enter Violation Message'
                    rows={5}
                    {...formik.getFieldProps('violationMessage')}
                    value={formik.values.violationMessage || ''}
                    readOnly={data?.fieldName === "AddAllowedDaysLimit" ? true : false}
                  ></textarea>
                </div>
                {/* <TextAreaField
                  label={labelKey.violationMessage}
                  placeholder='Enter Violation Message'
                  rows={5}
                  {...formik.getFieldProps('violationMessage')}
                  value={formik.values.violationMessage || ''}
                  readOnly={data?.fieldName === "AddAllowedDaysLimit"}
                  iconTooltip={`Expenses must be submitted within ${detailData?.result?.expAllowedOldDays} days of the date they were incurred. `}
                /> */}
              </div>

            </div>
          </div>
          <div className='modal-footer justify-content-center'>
            <span className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</span>
            <button
              type='submit'
              className='btn btn-primary mb-5 mt-5'
              disabled={isLoading}
            >
              {!isLoading && !updateIsLoading && <span className='indicator-label'>{updateId ? <>{labelKey.update}</> : <>{labelKey.submit}</>}</span>}

              {updateId ?
                <>
                  {updateIsLoading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      {labelKey.pleaseWait}...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </> :
                <>
                  {isLoading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      {labelKey.pleaseWait}...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </>
              }


            </button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default AddUpdateExpenseVoilation