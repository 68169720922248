import React from 'react'
import { constraintConfig } from '../../constraintConfig'
type Props = {
    approverStatusId: any
    approverStatusTitle: any

}
const ApproverStatusComp = ({ approverStatusId, approverStatusTitle }: Props) => {
    return (
        <>
            {approverStatusId === constraintConfig.approverStatus.submitted ?
                <span className="badge badge-light-success fs-7 fw-semibold">{approverStatusTitle}</span> :
                approverStatusId === constraintConfig.approverStatus.pending ?
                    <span className="badge badge-light-primary fs-7 fw-semibold">{approverStatusTitle}</span> :
                    // approverStatusTitle == "Clarification" ?
                    //     <span className="badge badge-light-warning fs-7 fw-semibold">{labelKey.clarification}</span> :
                    approverStatusId === constraintConfig.approverStatus.approved ?
                        <span className="badge badge-light-success fs-7 fw-semibold">{approverStatusTitle}</span> :
                        approverStatusId === constraintConfig.approverStatus.rejected ?
                            <span className="badge badge-light-danger fs-7 fw-semibold">{approverStatusTitle}</span> :
                            approverStatusId === constraintConfig.approverStatus.accountsPayable ?
                                <span className="badge badge-light-success fs-7 fw-semibold">{approverStatusTitle}</span> :
                                approverStatusId === constraintConfig.approverStatus.userRecall ?
                                    <span className="badge badge-light-primary fs-7 fw-semibold">{approverStatusTitle}</span> : <>{approverStatusTitle}</>
            }
        </>
    )
}

export default ApproverStatusComp

