import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";
import { API_END_POINTS } from './apiEndpoints';
import { baseUrl } from './baseUrl';
import { logout, selectAuth } from '../features/authSlice';
import { DynamicBaseQueryHeader } from './DynamicBaseQueryHeader';

// const rawBaseQuery = fetchBaseQuery({
//   baseUrl: baseUrl.MAIN_BASE_URL,
// })

// type ExtraOptions = {
//   headers?: Record<string, string>;
//   // Define other optional properties here if needed
// };

// const dynamicBaseQuery: BaseQueryFn<
//   string | FetchArgs,
//   unknown,
//   FetchBaseQueryError,
//   ExtraOptions
// > = async (args, api, extraOptions) => {
//   const orgBaseUrl = selectAuth(api.getState() as RootState).baseURL;
//   // gracefully handle scenarios where data to generate the URL is missing
//   if (!orgBaseUrl) {
//     return {
//       error: {
//         status: 400,
//         statusText: 'Bad Request',
//         data: 'dynamic route not found',
//       },
//     }
//   }

//   const urlEnd = typeof args === 'string' ? args : args.url

//   // construct a dynamically generated portion of the url
//   const adjustedUrl = `${orgBaseUrl}/api/Account${urlEnd}`
//   const adjustedArgs = typeof args === 'string' ? { url: adjustedUrl } : { ...args, url: adjustedUrl };
//   // Get the token from localStorage
//   const token = localStorage.getItem("user")
//     ? JSON.parse(localStorage.getItem("user")!).token
//     : null;
//   // Set headers if token is available
//   const headers = token ? { Authorization: `Bearer ${token}` } : {};
//   // Merge headers with existing headers
//   const mergedHeaders = { ...headers, ...extraOptions?.headers };
//   // provide the amended url and other params to the raw base query
//   // return rawBaseQuery({ ...adjustedArgs, headers: mergedHeaders }, api, extraOptions)
//   const result = await rawBaseQuery(
//     { ...adjustedArgs, headers: mergedHeaders },
//     api,
//     extraOptions
//   );

//   if (result.error && result.error.status === 401) {
//     // Dispatch the logout action when a 401 error occurs
//     api.dispatch(logout());
//   }

//   return result;

// }
export const authApi = createApi({
  reducerPath: 'authApi',
  // baseQuery: dynamicBaseQuery,
  baseQuery: DynamicBaseQueryHeader("Account"),
  tagTypes: ['Auth'],
  endpoints: (builder) => ({
    // loginUser: builder.mutation({
    //   query: (body: { username: string, password: string }) => {
    //     return {
    //       url: API_END_POINTS.login,
    //       method: "POST",
    //       body,
    //     }
    //   },
    // }),
    // forgotPassword: builder.mutation({
    //   query: (body: { email: string }) => {
    //     return {
    //       url: API_END_POINTS.forgotPassword,
    //       method: "PUT",
    //       body,
    //     }
    //   },
    // }),
    createUser: builder.mutation({
      query: createUser => {
        return {
          url: API_END_POINTS.createUser,
          method: "POST",
          body: createUser,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      invalidatesTags: ['Auth']
    }),
    updateUser: builder.mutation({
      query: ({ id, ...rest }) => {
        return {
          url: API_END_POINTS.updateUser,
          method: "PUT",
          body: rest,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
    }),

    getAllUsers: builder.query({
      query: (params) => {
        return {
          url: `${API_END_POINTS.getAllUsers}`,
          method: "GET",
          params
        }
      },
      providesTags: ['Auth']
    }),
    getSingleUser: builder.query({
      query: (id) => {
        return {
          url: API_END_POINTS.getSingleUser,
          method: "GET",
          params: { id }
        }
      },
    }),
    accountActiveAndDeActive: builder.mutation({
      query: ({ id, isActive }) => {
        return {
          url: `${API_END_POINTS.activeAndInActiveUser}?id=${id}&isActive=${isActive}`,
          method: "PUT",
          params: { id, isActive }
        }
      },
    }),

    changePassword: builder.mutation({
      query: ({ ...rest }) => {
        return {
          url: API_END_POINTS.changePassword,
          method: "PUT",
          body: rest,
        }
      },
    }),
    updatePassword: builder.mutation({
      query: ({ ...rest }) => {
        return {
          url: API_END_POINTS.updatePassword,
          method: "PUT",
          body: rest,
        }
      },
    }),
    passwordTokenValidate: builder.query({
      query: (token) => {
        return {
          url: API_END_POINTS.passwordTokenValidate,
          method: "GET",
          params: { token },
        }
      },
    }),


    getAllUserName: builder.query({
      query: () => {
        return {
          url: API_END_POINTS.getAllUserWithName,
          method: "GET",
        }
      },
    }),
    getUsersDepAndDesig: builder.query({
      query: ({ departmentId, designationId }) => {
        return {
          url: `${API_END_POINTS.userFilterByDepAndDesig}?departmentId=${departmentId}&designationId=${designationId}`,
          method: "GET",
        }
      },
    }),

    getAllManager: builder.query({
      query: () => {
        return {
          url: API_END_POINTS.getAllManagers,
          method: "GET",
        }
      },
    }),

    getManagerTeam: builder.query({
      query: ({ pageNumber, pageSize, sortBy, sortOrder, searchStr, managerId, filterByDepartment, filterByDesignation, filterByStatus }) => {
        return {
          url: `${API_END_POINTS.getManagerTeam}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchStr=${searchStr}&managerId=${managerId}&filterByDepartment=${filterByDepartment}&filterByDesignation=${filterByDesignation}&filterByStatus=${filterByStatus}`,
          method: "GET"
        }
      },
      providesTags: ['Auth']
    }),

    updateProfileImage: builder.mutation({
      query: (body) => {
        return {
          url: API_END_POINTS.updateProfileImage,
          method: "POST",
          body,
        }
      },
      invalidatesTags: ['Auth']
    }),
    impersonateUser: builder.mutation({
      query: (body) => {
        return {
          url: API_END_POINTS.ImpersonateUser,
          method: "POST",
          body,
        }
      },
      invalidatesTags: ['Auth']
    }),
    managerUsers: builder.query({
      query: ({ managerId }) => {
        return {
          url: `${API_END_POINTS.managerUsers}?managerId=${managerId}`,
          method: "GET"
        }
      },
    }),
    renewToken: builder.mutation({
      query: (body) => {
        return {
          url: API_END_POINTS.renewToken,
          method: "POST",
          body,
        }
      },
      invalidatesTags: ['Auth']
    }),
    userPrefferences: builder.mutation({
      query: (body) => {
        return {
          url: API_END_POINTS.userPreferences,
          method: "POST",
          body,
        }
      },
    }),
    updateUserPreferences: builder.mutation({
      query: (body) => {
        return {
          url: API_END_POINTS.updateUserPreferences,
          method: "PUT",
          body,
        }
      },
    }),
    getPreferences: builder.query({
      query: ({ userAccountId }) => {
        return {
          url: `${API_END_POINTS.getUserPreferences}?userAccountId=${userAccountId}`,
          method: "GET"
        }
      },
    }),
    getUserAccountDetail: builder.query({
      query: (params) => {
        return {
          url: API_END_POINTS.userAccountDetailById,
          method: "GET",
          params
        }
      },
    }),
    logoutApi: builder.mutation({
      query: (body) => {
        return {
          url: API_END_POINTS.logout,
          method: "PUT",
          body,
        }
      },
    }),
    allManagersAndAdmins: builder.query({
      query: () => {
        return {
          url: API_END_POINTS.allManagersAndAdmins,
          method: "GET"
        }
      },
    }),
    getOnlyUser: builder.query({
      query: () => {
        return {
          url: API_END_POINTS.getOnlyUser,
          method: "GET"
        }
      },
    }),
    approvalUserDraft: builder.mutation({
      query: (body) => {
        return {
          url: API_END_POINTS.approvalUserDraft,
          method: "PUT",
          body,
        }
      },
    }),
    getAllUsersLookupFilteredWorkFlowUsers: builder.query({
      query: ({ wokrPolicyMode }) => {
        return {
          url: `${API_END_POINTS.getAllUsersLookupFilteredWorkFlowUsers}?wokrPolicyMode=${wokrPolicyMode}`,
          method: "GET"
        }
      },
    }),
    deleteUserAccountImg: builder.mutation({
      query: (body) => {
        return {
          url: API_END_POINTS.deleteUserAccountImg,
          method: "DELETE",
          body,
        }
      },
    }),
    updateUserBasicInfo: builder.mutation({
      query: (body) => {
        return {
          url: API_END_POINTS.updateUserBasicInfo,
          method: "PUT",
          body,
        }
      },
    }),
  }),
})


export const {
  // useLoginUserMutation,
  // useForgotPasswordMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
  useUpdateUserBasicInfoMutation,
  useGetAllUsersQuery,
  useGetSingleUserQuery,
  useAccountActiveAndDeActiveMutation,
  useChangePasswordMutation,
  useUpdatePasswordMutation,
  usePasswordTokenValidateQuery,
  useGetAllUserNameQuery,
  useGetUsersDepAndDesigQuery,
  useGetAllManagerQuery,
  useGetManagerTeamQuery,
  useUpdateProfileImageMutation,
  useImpersonateUserMutation,
  useManagerUsersQuery,
  useRenewTokenMutation,
  useUserPrefferencesMutation,
  useGetPreferencesQuery,
  useUpdateUserPreferencesMutation,
  useGetUserAccountDetailQuery,
  useLazyGetUserAccountDetailQuery,
  useLogoutApiMutation,
  useAllManagersAndAdminsQuery,
  useGetOnlyUserQuery,
  useApprovalUserDraftMutation,
  useGetAllUsersLookupFilteredWorkFlowUsersQuery,
  useDeleteUserAccountImgMutation
} = authApi