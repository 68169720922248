export const allRoutes = {
    analytics: '/analytics',
    expenseReports: '/expense-reports',
    expenseWizard: '/expense-wizard',
    backupApprovers: '/re-assign-expenses',
    requestLogs: '/request-logs',
    automationCCFeedsStatsEnhancements:'/automation-cc-feeds-stats-enhancements',
    mergeLineItems:'/merge-line-items',
    userCCFeeds:'/user-cc-feeds',
    bankCCDetail:'/bank-cc-detail/:id',
    bankCCFeeds:`/bank-cc-feeds`,
    searchExpense:`/search-expense`
}