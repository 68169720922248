import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { KTSVG } from '../../../../_metronic/helpers'
import CurrencyComp from '../../../../components/currencyComponent/CurrencyComp'
import DateComp from '../../../../components/dateComponent/DateComp'
import SearchBarComponent from '../../../../components/searchBar/SearchBarComponent'
import { constraintConfig } from '../../../../constraintConfig'
import { useReAssignExpenseToOtherManagerMutation } from '../../../../services/ExpenseApi'
import { useGetAllManagersLookupQuery } from '../../../../services/GeneralApi'
import labelKey from '../../../localization/label.json'
import tosterKey from '../../../localization/toster.json'
type Props = {
    show: boolean
    handleClose: () => void
    selectedTrackingIds: any
    expenseReportData: any
    refetch?: any
}
const AssignToNewManagerModal = ({ show, handleClose, selectedTrackingIds, expenseReportData, refetch }: Props) => {
    const [assignToNewManager, { data, isLoading, isSuccess, isError, error }] = useReAssignExpenseToOtherManagerMutation()
    const { data: existingExpsnes, refetch: existingExpenseRefetch } = useGetAllManagersLookupQuery('');
    const initialValues = {
        toManagerId: 0,
        reportTrackingIDs: [
            0
        ]

    }
    const assignToExistingExpense = async (values: any) => {
        try {
            const response = await assignToNewManager({
                toManagerId: values.toManagerId,
                reportTrackingIDs: selectedTrackingIds
            });
            // Handle success if needed
        } catch (err) {
            console.error('API Error:', err);
            // Handle error if needed
        }
    };
    const formik = useFormik({
        initialValues,
        onSubmit: (values, { resetForm }) => {
            if (!values.toManagerId) {
                toast.error(tosterKey.pleaseSelectAnyManager);
            } else {
                assignToExistingExpense(values);
            }
        }
    })
    const { resetForm } = formik;

    useEffect(() => {
        if (isSuccess) {
            const responseData = (data as any)?.message;
            const successMessage = responseData;
            toast.success(successMessage);
            resetForm();
            handleClose();
            if (refetch) {
                refetch();
            }
        }
    }, [isSuccess, data]);
    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);

    useEffect(() => {
        if (show) {
            existingExpenseRefetch()
        }
    }, [show])

    // State for search input
    const [searchTerm, setSearchTerm] = useState('');

    // Handler for search input change
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    // Filtered data based on search term
    const filteredData = existingExpsnes?.result?.filter((data: any) =>
        data.value.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleModalClose = () => {
        handleClose();
        resetForm();
    };

    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-lg modal-dialog-centered'
                show={show}
                onHide={handleModalClose}
                backdrop="static"
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{labelKey.assignToNewManager}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleModalClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>

                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='modal-body py-lg-5 px-lg-10'>
                        <div className="row">
                            <div className="col-md-9">
                                <div className='d-flex align-items-center'>
                                    <div className='symbol symbol-50px me-5'>
                                        <div className="symbol-label fs-3 bg-light-info text-info text-capitalize"
                                        >
                                            {expenseReportData?.userAccount?.firstName.charAt(0)}
                                        </div>

                                    </div>
                                    <div className='d-flex justify-content-start flex-column'>
                                        <p className='text-dark fw-bold mb-0 fs-6 text-capitalize'>
                                            {expenseReportData?.userAccount?.firstName} {expenseReportData?.userAccount?.lastName}
                                        </p>
                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                            {expenseReportData?.userAccount?.email}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <p className='fw-bold mt-3 mb-1'>{labelKey.reportId}: <span className='fw-semibold'>{expenseReportData?.trackingId}</span></p>
                                    <p className='fw-bold mb-1'>{labelKey.totalAmount}: <span className='fw-semibold'>
                                        <CurrencyComp amount={expenseReportData?.amount} /></span></p>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <p className='fw-bold'>{labelKey.month}: <span className='fw-semibold'>
                                    <DateComp formattedDate={expenseReportData?.reportDate} /></span></p>
                                <p className='fw-bold'>{labelKey.status}: {expenseReportData?.expenseStatus?.id === constraintConfig.expenseStatus.drafted ?
                                    <span className="badge badge-light-primary fs-7 fw-semibold">{labelKey.draft}</span> :
                                    expenseReportData?.expenseStatus?.id === constraintConfig.expenseStatus.pendingForApproval ?
                                        <span className="badge badge-light-info fs-7 fw-semibold">{labelKey.pendingApproval}</span> :
                                        // expenseReportData?.expenseStatus?.id === constraintConfig.expenseStatus.clarification ?
                                        //   <span className="badge badge-light-warning fs-7 fw-semibold">{labelKey.clarification}</span> :
                                        expenseReportData?.expenseStatus?.id === constraintConfig.expenseStatus.approved ?
                                            <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.managerApproved}</span> :
                                            expenseReportData?.expenseStatus?.id === constraintConfig.expenseStatus.rejected ?
                                                <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.rejected}</span> :
                                                expenseReportData?.expenseStatus?.id === constraintConfig.expenseStatus.apApproved ?
                                                    <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.aPApproved}</span> : ""
                                }</p>

                            </div>

                            <div className="col-md-8">
                                <p className='fw-bold mb-0'>{labelKey.description}:</p>
                                <p className='mh-100px overflow-auto'>{expenseReportData?.expenseDetail}</p>
                            </div>
                        </div>
                        <div className="row">
                            <SearchBarComponent
                                placeholder='Managers'
                                value={searchTerm}
                                onChange={handleSearchChange}
                                className='w-100 me-5'
                            />
                            <div className='table-responsive table-height mt-5'>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{labelKey.managers}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData?.map((data: any, index: number) => (
                                            <tr key={index}>
                                                <td className='ps-4'>
                                                    <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                                        {/* <input type="radio" className='form-check-input'
                                                            {...formik.getFieldProps('toManagerId')}
                                                            value={data.id} /> */}
                                                        <input
                                                            type="radio"
                                                            className='form-check-input'
                                                            name="toManagerId"
                                                            value={data.id}
                                                            checked={formik.values.toManagerId === data.id}
                                                            onChange={() => formik.setFieldValue('toManagerId', data.id)}
                                                        />
                                                    </div>
                                                </td>
                                                <td>{data?.value}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center'>
                        <span className='btn btn-light' onClick={handleModalClose}>{labelKey.cancel}</span>
                        <button
                            type='submit'
                            className='btn btn-primary mb-5 mt-5'
                            disabled={isLoading}
                        >

                            {!isLoading && <span className='indicator-label'>{labelKey.submit}</span>}
                            {isLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}


                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AssignToNewManagerModal