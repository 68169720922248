import { Col, Nav, Row, Tab } from 'react-bootstrap'
import AccountManager from '../accountManager/AccountManager'
import UsersPage from './UserPage'
import { useAppSelector } from '../../../hooks';
import { constraintConfig } from '../../../constraintConfig';
import { selectAuth } from '../../../features/authSlice';
import { useState } from 'react';

const UsersPageWrapper = () => {
  const { roleID } = useAppSelector(selectAuth);
  const [tabChange, setTabChange] = useState('');

  const handleTabSelect = (selectedKey:any) => {
    // Set TabChange to true whenever a new tab is selected
    setTabChange(selectedKey);
  };
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{labelKey.userList}</PageTitle> */}
      <div className='card mb-5'>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first" onSelect={handleTabSelect}>
          <Row>
            {/* <div className='card-header border-0 pt-6'> */}
            <Col sm={12}>
              <Nav variant="pills" className='p-5 border-bottom'>
                <Nav.Item>
                  <Nav.Link eventKey="first">Users List</Nav.Link>
                </Nav.Item>
                {roleID === constraintConfig.roleID.role2 &&
                  <Nav.Item>
                    <Nav.Link eventKey="second">Onboarding</Nav.Link>
                  </Nav.Item>}
              </Nav>
            </Col>
            {/* </div> */}
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <UsersPage tabChange={tabChange} />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <AccountManager tabChange={tabChange} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  )
}

export default UsersPageWrapper