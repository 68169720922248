import React from 'react'
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp';
import ReactApexChart from 'react-apexcharts';
import labelKey from "../../localization/label.json"
import { Tooltip } from 'react-tooltip';
import { FaInfoCircle } from 'react-icons/fa';


type props = {
    data: any
}
const ExpensesAmountChart = ({ data }: props) => {

    const generateColors = (count: number) => {
        const colors = [];
        for (let i = 0; i < count; i++) {
            const hue = (i * 360) / count; // Distribute hues evenly
            colors.push(`hsl(${hue}, 70%, 50%)`); // Use HSL for vibrant colors
        }
        return colors; // Return the colors array
    };
    const sortedData = data?.result?.expenseReportStatus?.expenseByStatusReponse
        ?.filter((expenseByStatus: any) => expenseByStatus.totalExpenseAmount > 0) // Filter out zero amounts
        ?.map((expenseByStatus: any) => ({
            ...expenseByStatus,
            percentage: (expenseByStatus?.totalExpenseAmount || 0) /
                (data?.result?.expenseReportStatus?.expenseByStatusReponse?.reduce(
                    (total: number, cat: any) => total + (cat?.totalExpenseAmount || 0),
                    0
                ) || 1),
        }))
        ?.sort((a: any, b: any) => b.percentage - a.percentage);

    const pieChartAmountData = {
        series: sortedData?.map((expenseByStatus: any) => expenseByStatus.totalExpenseAmount) || [],
        options: {
            labels: sortedData?.map(
                (expenseByStatus: any) =>
                    `${expenseByStatus?.statusName} (${(expenseByStatus?.percentage * 100).toFixed(2)}%)`
            ) || [],
            legend: {
                show: true,
                labels: {
                    colors: 'var(--bs-heading-color)', // Apply CSS variable to legend labels
                },
            },
            colors: generateColors(sortedData?.length || 100),
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 250,
                        },
                        legend: {
                            position: 'bottom',
                            labels: {
                                colors: 'var(--bs-heading-color)',
                            },
                        },
                    },
                },
            ],
            tooltip: {
                y: {
                    formatter: function (value: any) {
                        return `$${value.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}`;
                    },
                },
            },
        },
    };

    // Check if data is available
    const isDataAvailable = sortedData?.length > 0;

    return (
        <>
            <Tooltip id="chart" place="top" />
            <div className="col-md-12 col-lg-6 mt-5 mb-5 mb-xl-10 h-100">
                <div className='p-9 h-100' style={{ boxShadow: "0px 1px 4px 0px #d6e1e6", borderRadius: "10px" }}>
                    <h3>{labelKey.expenseByStatus}
                        <FaInfoCircle className='text-gray cursor-pointer ms-2'
                            data-tooltip-id="chart"
                            data-tooltip-content={labelKey.theChartDisplaysDataForTheLast7DaysByDefault} />
                    </h3>
                    {isDataAvailable ? (
                        <>
                            <div className="d-lg-flex align-items-center gap-3">
                                <div className='d-flex align-items-center gap-2'>
                                    <p className='fs-5 fw-bold'>{labelKey.totalExpenses}:</p>
                                    <p className='fs-5 fw-semibold'>
                                        {data?.result?.expenseReportStatus?.totalExpenseCount?.toLocaleString('en-US')}
                                    </p>
                                </div>
                                <div className='d-flex align-items-center gap-2'>
                                    <p className='fs-5 fw-bold'>{labelKey.totalExpenseAmount}:</p>
                                    <p className='fs-5 fw-semibold'>
                                        <CurrencyComp amount={data?.result?.expenseReportStatus?.totalExpenseAmount} />
                                    </p>
                                </div>
                            </div>
                            <div className='d-flex d-md-inline justify-content-center'>
                                <ReactApexChart
                                    options={pieChartAmountData.options}
                                    series={pieChartAmountData.series}
                                    type="pie"
                                    height={300}
                                />
                            </div>
                        </>
                    ) : (
                        <p className='text-center mt-5'>No data available for the chart.</p>
                    )}
                </div>
            </div>
        </>
    )
}

export default ExpensesAmountChart