import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import { useIntl } from 'react-intl';
import { KTSVG } from '../../../_metronic/helpers';
import { PageTitle } from '../../../_metronic/layout/core';
import { constraintConfig } from '../../../constraintConfig';
import { selectAuth } from '../../../features/authSlice';
import { useAppSelector } from '../../../hooks';
import { useGetAdminDashboardQuery, useGetManagersDashboardQuery, useGetSupperAdminDashboardDataQuery, useGetUserDashboardQuery } from '../../../services/DashboardApi';
import { useExpenseApproverSummeryQuery, useExpenseSummaryQuery, useGetExpenseReportByDepartmentWithAmountQuery, useGetExpenseReportByUserWithAmountQuery } from '../../../services/ReportAndAnalyticsApi';
import labelKey from "../../localization/label.json";
import ExpenseDeptChart from '../reportAnalytics/ExpenseDeptChart';
import ApproverSummaryChart from './ApproverSummaryChart';
import DashboardByAdmin from './DashboardByAdmin';
import DashboardByAp from './DashboardByAp';
import DashboardByManager from './DashboardByManager';
import DashboardBySuperAdmin from './DashboardBySuperAdmin';
import DashboardByUser from './DashboardByUser';
import ExpenseByCategoryChart from './ExpenseByCategoryChart';
import ExpenseByUsersChart from './ExpenseByUsersChart';
import { debounce } from 'lodash';
import FilterMenu from '../../../components/filterMenu/FilterMenu';
import DateRangeComp from '../../../components/dateComponent/DateRangeComp';

const DashboardPage = () => {
  const currentDate = moment().format('MM/DD/YYYY');
  const threeDaysAgo = moment().subtract(3, 'days').format('MM/DD/YYYY');
  const { roleID, userAccountID } = useAppSelector(selectAuth);
  const [filterByStartDate, setFilterByStartDate] = useState(threeDaysAgo);
  const [filterByEndDate, setFilterByEndDate] = useState(currentDate);
  const [localFilterByStartDate, setLocalFilterByStartDate] = useState("");
  const [localFilterByEndDate, setLocalFilterByEndDate] = useState("");
  const [isApplyingFilters, setIsApplyingFilters] = useState(false);

  const { data: expenseDeptChartData, refetch: expenseDeptChartDataRefetch } = useGetExpenseReportByDepartmentWithAmountQuery({
    startDate: filterByStartDate,
    endDate: filterByEndDate,
  })
  const { data: approverSummaryData, refetch: approverSummaryDataRefetch } = useExpenseApproverSummeryQuery({
    managerId: userAccountID,
    startDate: filterByStartDate,
    endDate: filterByEndDate,
  })
  const { data: expensecategoryData, refetch: expensecategoryDataRefetch } = useExpenseSummaryQuery({
    userAccountId: userAccountID,
    startDate: filterByStartDate,
    endDate: filterByEndDate,
  })
  const { data: expenseUserData, refetch: expenseUserDataRefetch } = useGetExpenseReportByUserWithAmountQuery({
    startDate: filterByStartDate,
    endDate: filterByEndDate,
  })
  const { data: getAdminDashboard, refetch: getAdminDashboardRefetch } = useGetAdminDashboardQuery({
    startDate: filterByStartDate,
    endDate: filterByEndDate,
  });
  const { data: getManagerDashboard, refetch: getManagerDashboardRefetch } = useGetManagersDashboardQuery({
    startDate: filterByStartDate,
    endDate: filterByEndDate,
  });
  const { data: getUserDashboard, refetch: getUserDashboardRefetch } = useGetUserDashboardQuery({
    startDate: filterByStartDate,
    endDate: filterByEndDate,
  });
  const { data: getSuperAdminDashboard, refetch: getSuperAdminDashboardRefetch } = useGetSupperAdminDashboardDataQuery({
    startDate: filterByStartDate,
    endDate: filterByEndDate,
  });
  // const handleStartDate = (e: any) => {
  //   setFilterByStartDate(e.target.value);
  // };
  // const handleEndDate = (e: any) => {
  //   setFilterByEndDate(e.target.value);
  // };
  useEffect(() => {
    if (roleID === constraintConfig.roleID.role1) {
      getSuperAdminDashboardRefetch();
    } else if (roleID === constraintConfig.roleID.role2 || roleID === constraintConfig.roleID.roleIDAdmin) {
      expenseDeptChartDataRefetch();
      approverSummaryDataRefetch();
      expensecategoryDataRefetch();
      getAdminDashboardRefetch();
      expenseUserDataRefetch();
    } else if (roleID === constraintConfig.roleID.role4) {
      getManagerDashboardRefetch();
      approverSummaryDataRefetch();
    }
    else if (roleID === constraintConfig.roleID.role5) {
      getUserDashboardRefetch();
    }
    // Only fetch APIs related to date filters if there's an active filter
    if ((filterByStartDate || filterByEndDate) && roleID) {
      if (roleID === constraintConfig.roleID.role1) {
        getSuperAdminDashboardRefetch();
      } else if (roleID === constraintConfig.roleID.role2 || roleID === constraintConfig.roleID.roleIDAdmin) {
        expenseDeptChartDataRefetch();
        approverSummaryDataRefetch();
        expensecategoryDataRefetch();
        expenseUserDataRefetch();
        getAdminDashboardRefetch();
      } else if (roleID === constraintConfig.roleID.role4) {
        getManagerDashboardRefetch();
        approverSummaryDataRefetch();
      } else if (roleID === constraintConfig.roleID.role5) {
        getUserDashboardRefetch();
      }
    }

  }, [roleID, filterByStartDate, filterByEndDate]);

  const handleDateRangeChange = (event: any, picker: any) => {
    const startDateFormatted = moment(picker.startDate).format('MM-DD-YYYY');
    const endDateFormatted = moment(picker.endDate).format('MM-DD-YYYY');

    setFilterByStartDate(startDateFormatted);
    setFilterByEndDate(endDateFormatted);

    // setLocalFilterByStartDate(startDateFormatted);
    // setLocalFilterByEndDate(endDateFormatted);
  };
  const handleCancelDateSelection = (event: any, picker: any) => {
    // Reset the state to empty strings
    setFilterByStartDate(threeDaysAgo);
    setFilterByEndDate(currentDate);
    setLocalFilterByStartDate("");
    setLocalFilterByEndDate("");
  };

  const applyFilters = () => {
    setIsApplyingFilters(true);
    setFilterByStartDate(localFilterByStartDate);
    setFilterByEndDate(localFilterByEndDate);
  };
  const resetFilters = () => {
    setFilterByStartDate('');
    setFilterByEndDate('');
    setLocalFilterByStartDate('');
    setLocalFilterByEndDate('');
  }
  const debouncedRefetch = debounce(() => {
    // Set loading state if required
    setIsApplyingFilters(true);

    // Call all refetch functions
    Promise.all([
      expenseDeptChartDataRefetch(),
      approverSummaryDataRefetch(),
      expensecategoryDataRefetch(),
      expenseUserDataRefetch(),
      getAdminDashboardRefetch(),
      getManagerDashboardRefetch(),
      getUserDashboardRefetch(),
      getSuperAdminDashboardRefetch()
    ])
      .finally(() => {
        // Set loading state to false once all refetches are complete
        setIsApplyingFilters(false);
      });
  }, 300);

  useEffect(() => {
    if (isApplyingFilters) {
      debouncedRefetch();
    }
  }, [isApplyingFilters]);

  return (
    <>
      <div className="row mb-10">
        <div className="col-md-12 position-relative">
          <div className='d-flex justify-content-end'>
            <div className='position-absolute' style={{marginTop:-25}}>
              <DateRangeComp
              label={false}
                startDate={filterByStartDate || moment()}
                endDate={filterByEndDate || moment()}
                onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}
                value={filterByStartDate && filterByEndDate ? `${filterByStartDate} - ${filterByEndDate}` : "MM/DD/YYYY"} />
            </div>
            {/* <FilterMenu
              onclickApplyFilters={applyFilters}
              onclickResetFilters={resetFilters}
              isApplyingFiltersLoading={isApplyingFilters}
            >
              <>

                <label className='form-label fw-bold text-dark fs-6'>Date:</label>
                <DateRangePicker onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}>
                  <input type="text"
                    className="form-control form-control-lg form-control-solid default-input"
                    value={localFilterByStartDate && localFilterByEndDate ? `${localFilterByStartDate} - ${localFilterByEndDate}` : "mm/dd/yyyy"} />
                </DateRangePicker>
              </>
            </FilterMenu> */}
          </div>
        </div>
      </div>
      {(roleID === constraintConfig.roleID.role1) && (
        <DashboardBySuperAdmin getSuperAdminDashboard={getSuperAdminDashboard} />
      )}
      {(roleID === constraintConfig.roleID.role2 || roleID === constraintConfig.roleID.roleIDAdmin) && (
        <>
          <div className='row'>
            <div className="col-md-12 col-lg-8 col-xl-9">
              <div className='card mb-5 mb-xl-10'>
                <div className='card-body p-9'>
                  <ExpenseDeptChart expenseDeptChartData={expenseDeptChartData} />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 col-xl-3">
              <div className='card mb-5 mb-xl-10'>
                <div className='card-body p-9'>
                  <ApproverSummaryChart approverSummaryData={approverSummaryData} />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className='card mb-5 mb-xl-10'>
                <div className='card-body p-9'>
                  <ExpenseByCategoryChart expensecategoryData={expensecategoryData} />
                </div>
              </div>
            </div>
          </div>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body p-9'>
              <ExpenseByUsersChart expenseUserData={expenseUserData} />
            </div>
          </div>
          <DashboardByAdmin getAdminDashboard={getAdminDashboard} />
        </>

      )}
      {(roleID === constraintConfig.roleID.role4) && (
        <>
          <DashboardByManager getManagerDashboard={getManagerDashboard} />
          <div className="row">
            <div className="col-md-6 col-lg-4 mt-3">
              <div className='card mb-5 mb-xl-10'>
                <div className='card-body p-9'>
                  <ApproverSummaryChart approverSummaryData={approverSummaryData} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {(roleID === constraintConfig.roleID.role5) && (
        <DashboardByUser getUserDashboard={getUserDashboard} />
      )}
      {(roleID === constraintConfig.roleID.role10) && (
        <DashboardByAp />
      )}
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper };

