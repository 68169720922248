import { useEffect, useState } from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { IoIosRepeat } from 'react-icons/io';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../components/noRecord/NoRecordFound';
import Pagination from '../../../components/pagination/Pagination';
import SearchBarComponent from '../../../components/searchBar/SearchBarComponent';
import { useGetAllUserDraftQuery } from '../../../services/DraftApi';
import labelKey from "../../localization/label.json";
import UserActiveModal from './UserActiveModal';
import DateTimeComp from '../../../components/dateComponent/DateTimeComp';

const AccountManager = ({ tabChange }: any) => {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState('');
    const [sortBy, setSortBy] = useState("userName");
    const [sortOrder, setSortOrder] = useState("desc");
    const [sortedColumn, setSortedColumn] = useState("userName");
    const [filterByStatus, setFilterByStatus] = useState("InActive");

    const { data, isFetching: isLoading, isSuccess, refetch } = useGetAllUserDraftQuery(
        {
            pageNumber: page,
            pageSize: pageSize,
            sortBy: sortBy,
            sortOrder: sortOrder,
            searchStr: searchStr,
            filterByStatus: filterByStatus
        }
    )
    const [showConfirmationActiveUserModal, setShowConfirmationActiveUserModal] = useState(false)
    const [editData, setEditData] = useState({});
    const expenseConfirmationModalHandler = () => {
        setShowConfirmationActiveUserModal((prev) => !prev);
    };
    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPage(1); // Reset page number to 1
    };
    // pagination
    const handlePageClick = (e: any) => {
        setPage(e.selected + 1);
    };
    //per page record
    const handlePageRecords = (e: any) => {
        setPage(1); // Reset the page to 1
    };
    const handleSearchChange = (e: any) => {
        setSearchStr(e.target.value);
    }
    const handleSort = (property: string) => {
        setSortBy(property);
        if (sortedColumn === property) {
            setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortedColumn(property);
            setSortOrder("asc");
        }
        // Perform additional sorting logic...
    };
    useEffect(() => {
        refetch();
        if (tabChange === 'second' && searchStr === "" || filterByStatus === "" || showConfirmationActiveUserModal === false) {
            refetch();
        }

    }, [tabChange, searchStr, filterByStatus, showConfirmationActiveUserModal]);
    // Tab state and handler
    const [activeTab, setActiveTab] = useState('InActive');

    const handleTabChange = (status: string) => {
        setActiveTab(status);
        setFilterByStatus(status);
    };

    const handleRefresh = () => {
        refetch()
            .then(() => {
                if (isSuccess) {
                    toast.success('Data Refresh successfully!');
                }
            });
    };
    return (
        <>
            <UserActiveModal show={showConfirmationActiveUserModal} handleClose={() => setShowConfirmationActiveUserModal(false)} data={editData} />
            <Tooltip id="refreshData" place="top" />

            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header align-items-center border-0 '>
                    <div className='d-flex gap-1 align-items-center'>
                        <div className='btn-group'>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === 'InActive' ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange('InActive')}
                            >
                                {labelKey.pendingForApproval}
                            </button>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === 'Active' ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange('Active')}
                            >
                                {labelKey.approved}
                            </button>
                        </div>
                        <div
                            data-tooltip-id="refreshData" data-tooltip-content='Refresh Data'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2' onClick={handleRefresh}>
                            <IoIosRepeat className='h-20px w-20px' />
                        </div>
                    </div>
                    <div className='card-title'>
                        <SearchBarComponent
                            className='me-4'
                            placeholder='User'
                            value={searchStr}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive table-height'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='rounded-start ps-4'>{labelKey.sr}</th>
                                    <th onClick={() => handleSort("firstName")}
                                    >
                                        <div className='table-arrow'>
                                            {labelKey.user}
                                            {sortedColumn === "firstName" ? (
                                                <>
                                                    {sortOrder === "desc" ? <FaArrowDown /> : <FaArrowUp />}
                                                </>
                                            ) :
                                                ''
                                            }
                                        </div>
                                    </th>
                                    <th>{labelKey.ssId}</th>
                                    <th onClick={() => handleSort("apiOrgName")}
                                    >
                                        <div className='table-arrow'>
                                            {labelKey.organizationName}
                                            {sortedColumn === "apiOrgName" ? (
                                                <>
                                                    {sortOrder === "desc" ? <FaArrowDown /> : <FaArrowUp />}
                                                </>
                                            ) :
                                                ''
                                            }
                                        </div>
                                    </th>
                                    <th onClick={() => handleSort("mgrActionStatus")}>
                                        <div className='table-arrow'>
                                            {labelKey.status}
                                            {sortedColumn === "mgrActionStatus" ? (
                                                <>
                                                    {sortOrder === "desc" ? <FaArrowDown /> : <FaArrowUp />}
                                                </>
                                            ) :
                                                ''
                                            }
                                        </div>
                                    </th>
                                    <th>
                                        {labelKey.createdDate}
                                    </th>
                                    <th className='rounded-end'>
                                        {labelKey.approvalDate}
                                    </th>
                                </tr>
                            </thead>
                            {/* <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='ps-4 rounded-start'>{labelKey.sr}</th>
                                    <th>{labelKey.user}</th>
                                    <th>{labelKey.organizationName}</th>
                                    <th>{labelKey.status}</th>
                                </tr>
                            </thead> */}
                            <tbody>

                                {!isLoading &&
                                    <>
                                        {data?.result?.data?.length > 0 ?
                                            (
                                                <>
                                                    {data?.result?.data?.map((data: any, index: any) => (
                                                        <tr key={index}>
                                                            <td className='ps-4'> {(page - 1) * pageSize + index + 1}</td>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                        {data?.userAccount?.imageUrl ? <img src={data?.imageUrl} alt="" /> :
                                                                            <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.firstName?.charAt(0)}</div>
                                                                        }
                                                                    </div>
                                                                    <div className="d-flex flex-column">
                                                                        <span className="text-gray-800 fw-bold mb-1 text-capitalize">{data?.firstName} {data?.lastName}</span>
                                                                        <span className='text-lowercase'>{data?.userEmail}</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{data?.id}</td>
                                                            <td>{data?.apiOrgName}</td>
                                                            <td>
                                                                <div className='d-flex gap-2'>
                                                                    {data?.mgrActionStatus === true ? (
                                                                        <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.approved}</span>
                                                                    ) : (
                                                                        <span className="badge badge-light-info  fs-7 fw-semibold">{labelKey.pendingForApproval}</span>
                                                                    )}
                                                                    {data?.mgrActionStatus !== true &&
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input cursor-pointer"
                                                                                type="checkbox"
                                                                                role="switch"
                                                                                id={`flexSwitchCheckChecked-${data?.id}`}
                                                                                checked={data?.mgrActionStatus}
                                                                                // onChange={() => handleToggle(data?.id, data?.mgrActionStatus)}
                                                                                onChange={() => {
                                                                                    setEditData(data);
                                                                                    expenseConfirmationModalHandler();
                                                                                }}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={`flexSwitchCheckChecked-${data?.id}`}></label>
                                                                        </div>
                                                                    }
                                                                    {/* )} */}
                                                                </div>

                                                            </td>
                                                            <td>
                                                                <DateTimeComp formattedDate={data?.createdDT} />
                                                            </td>
                                                            <td>
                                                                <DateTimeComp formattedDate={data?.approvelDT} />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </>)
                                            :
                                            (
                                                <tr>
                                                    <td colSpan={7}>
                                                        <NoRecordFound />
                                                    </td>
                                                </tr>
                                            )}
                                    </>
                                }
                                {isLoading && (
                                    <tr>
                                        <td colSpan={7} className="text-center">
                                            <LoadingComponent />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                        toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                        ofResult={data?.result?.totalRecords}
                        onChange={handlePageSizeChange}
                        pageSize={pageSize}
                        onPageChange={handlePageClick}
                        pageCount={data?.result?.totalPages || 0}
                    />
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
        </>
    )
}

export default AccountManager