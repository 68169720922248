import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { KTSVG } from '../../../../_metronic/helpers';
import TextField from '../../../../components/textFields/TextField';
import { useAddDepartmentMutation, useUpdateDepartmentMutation } from '../../../../services/OrgSettingsApi';
import labelKey from "../../../localization/label.json";
import tosterKey from "../../../localization/toster.json";

type Props = {
    show: boolean
    handleClose: () => void
    data?: any
    refetch?: any
}
const AddUpdateDepartmentModal = ({ show, handleClose, data, refetch }: Props) => {
    const [addCard, { data: addDptData, isLoading, isSuccess, isError, error }] = useAddDepartmentMutation()
    const [updateCard, { data: updateDptData, isLoading: updateIsLoading, isSuccess: updateIsSuccess, isError: updateIsError, error: updateError }] = useUpdateDepartmentMutation()


    const initialValues = {
        departmentId: 0,
        departmentName: '',
        isActive: true,
        code: '',
    }

    const [updateId, setUpdateId] = useState(0);
    const [formValues, setFormValues] = useState(initialValues);
    // Store form values when the modal is closed
    const handleModalClose = () => {
        setFormValues(formik.values);
        handleClose();
    };
    useEffect(() => {
        // Restore form values when the modal reopens
        if (show && !data && Object.keys(formValues).length !== 0) {
            formik.setValues(formValues);
        }
        // Update form values based on data received
        if (data) {
            setUpdateId(data?.departmentId);
            formik.setValues({ ...data });
        } else if (!show) {
            // Reset form values only when closing and it's not an update action
            setUpdateId(0);
            formik.resetForm();
        }
    }, [show, data]);

    const validationSchema = Yup.object().shape({
        departmentName: Yup.string().required('Department Name is required'),
        code: Yup.string().required('Department Code is required'),
    })
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (updateId) {
                updateCard(values)
            } else {
                addCard(values)
            }
        }
    })
    const { resetForm } = formik;

    useEffect(() => {
        if (isSuccess) {
            const responseData = (addDptData as any)?.message;
            const successMessage = responseData;
            toast.success(successMessage);
            handleClose();
            resetForm();
            refetch();
        }
    }, [isSuccess, addDptData]);

    useEffect(() => {

        if (updateIsSuccess) {
            const responseData = (updateDptData as any)?.message;
            const successMessage = responseData;
            toast.success(successMessage);
            handleClose();
            refetch();
        }
    }, [updateIsSuccess, updateDptData]);

    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    useEffect(() => {
        if (updateIsError && updateError) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in updateError && updateError.data && typeof updateError.data === 'object' && 'message' in updateError.data) {
                errorMessage = (updateError.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [updateIsError, updateError]);
    return (
        <Modal
            aria-hidden='true'
            dialogClassName='modal-dialog modal-md modal-dialog-centered'
            show={show}
            onHide={handleModalClose}
        >
            <div className='modal-header'>
                <h2 className='mb-0'>{updateId ? <>{labelKey.updateDepartment}</> : <>{labelKey.addDepartment}</>}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <form
                className='form w-100'
                onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                    formik.setTouched({
                        departmentName: true,
                        code: true,
                    }, true); // Set touched for all fields to trigger validation display
                }}
            >
                <div className='modal-body py-lg-10 px-lg-10'>
                    <div className="row">


                        <div className='col-md-12 mb-5'>
                            <TextField
                                label={labelKey.department}
                                required={true}
                                type='text'
                                placeholder='Enter Department'
                                // labelClass={`${formik.touched.departmentName && formik.errors.departmentName ? 'is-invalid' : ''}`}
                                {...formik.getFieldProps('departmentName')}
                                value={formik.values.departmentName || ''}
                            />
                            {formik.touched.departmentName && formik.errors.departmentName && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.departmentName}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='col-md-12 mb-5'>
                            <TextField
                                label={labelKey.departmentCode}
                                required={true}
                                type='text'
                                placeholder='Enter Code'
                                // labelClass={`${formik.touched.departmentName && formik.errors.departmentName ? 'is-invalid' : ''}`}
                                {...formik.getFieldProps('code')}
                                value={formik.values.code || ''}
                            />
                            {formik.touched.code && formik.errors.code && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.code}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='modal-footer justify-content-center'>
                    <span className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</span>
                    <button
                        type='submit'
                        className='btn btn-primary mb-5 mt-5'
                        disabled={isLoading}
                    >
                        {!isLoading && !updateIsLoading && <span className='indicator-label'>{updateId ? <>{labelKey.update}</> : <>{labelKey.submit}</>}</span>}

                        {updateId ?
                            <>
                                {updateIsLoading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        {labelKey.pleaseWait}...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </> :
                            <>
                                {isLoading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        {labelKey.pleaseWait}...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </>
                        }


                    </button>
                </div>
            </form>
        </Modal>
    )
}

export default AddUpdateDepartmentModal