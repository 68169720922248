import {
    Autocomplete,
    DirectionsRenderer,
    GoogleMap,
    Libraries,
    useJsApiLoader
} from '@react-google-maps/api';
import axios from 'axios';
import * as htmlToImage from 'html-to-image';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { FaPercentage, FaUnlink } from 'react-icons/fa';
import { FaLock, FaTrash, FaUpload } from 'react-icons/fa6';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { KTSVG } from '../../../../../_metronic/helpers';
import CurrencyComp from '../../../../../components/currencyComponent/CurrencyComp';
import BGBlur from '../../../../../components/loadingComponent/BGBlur';
import SearchSelect from '../../../../../components/textFields/SearchSelect';
import TextAreaField from '../../../../../components/textFields/TextAreaField';
import TextField from '../../../../../components/textFields/TextField';
import { constraintConfig } from '../../../../../constraintConfig';
import { selectAuth } from '../../../../../features/authSlice';
import { useAppSelector } from '../../../../../hooks';
import { useGetRemainingBudgetOfUserV2Query, useGetRemainingCatagoryBudgetOfUserQuery } from '../../../../../services/BudgetApi';
import { useAllCurrencyLookupQuery, useGetAllExpenseCategoryLookupV2Query, useGetAllExpenseTypeQuery, useGetAllLocationsQuery, useGetAllTripTypeQuery, useGetPaymentMethodLookupQuery } from '../../../../../services/GeneralApi';
import { useGetVendorLookUpQuery } from '../../../../../services/VendorApi';
import { API_END_POINTS } from '../../../../../services/apiEndpoints';
import { baseUrl } from '../../../../../services/baseUrl';
import labelKey from "../../../../localization/label.json";
import AddUpdateVendorModal from '../../../vendorAndCard/vendor/AddUpdateVendorModal';
import DeleteExpenseModal from '../../DeleteExpenseModal';

import 'react-loading-skeleton/dist/skeleton.css';
import { Tooltip } from 'react-tooltip';
import DateComp from '../../../../../components/dateComponent/DateComp';
import DateField from '../../../../../components/textFields/DateField';

type Props = {
    formik: any
    expenseFormik: any
    handleImageChangeFirst?: any
    imagePreviewFirst?: any
    fileName: any
    fileIsloading: any
    handleRemoveFile: () => void;
    fileInputRef: React.RefObject<HTMLInputElement>;
    orgDetail?: any
    isPdf?: any
    viewOnlyData?: any
    show?: any
    addUpdateExpenseItemV2IsSuccess?: any
    selectedExpenseItemIds?: any
    setSelectedExpenseItemIds?: any
    setSelectedExpenseItemData?: any
    AllItems?: any
    getCardExpenseItemTrigger?: any
    expenseItemByIdTrigger?: any
    expenseItemsDataCheck?: any
    setExpenseItemsDataCheck?: any
    AllItemsIsLoading?: any
    isEditValues?: any
    setIsEditValues?: any
    expenseItems?: any
    handleSubmitLineItems?: any
    setIsLineItemApiTrigger?: any
    addUpdateExpenseItemIsLoading?: any
    addUpdateExpenseItemData?: any
    isSubmitted?: any
    setIsSubmitted?: any
    setFileName?: any
}
interface LightboxData {
    imageURL: string;
}
const Step2NewItems = ({ formik, selectedExpenseItemIds, AllItemsIsLoading, setSelectedExpenseItemData, expenseItemByIdTrigger, setSelectedExpenseItemIds, AllItems, getCardExpenseItemTrigger, addUpdateExpenseItemV2IsSuccess, expenseFormik, handleImageChangeFirst, imagePreviewFirst, fileName, fileIsloading, handleRemoveFile, fileInputRef, orgDetail, isPdf, viewOnlyData, show, expenseItemsDataCheck, setExpenseItemsDataCheck, isEditValues, setIsEditValues, expenseItems, handleSubmitLineItems, setIsLineItemApiTrigger, addUpdateExpenseItemIsLoading, addUpdateExpenseItemData, isSubmitted, setIsSubmitted, setFileName }: Props) => {
    const currentDate = moment().format('YYYY-MM-DD')
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [wordCount, setWordCount] = useState(0);
    const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
    const { roleID, token, locationType, baseURL, userAccountID } = useAppSelector(selectAuth);
    const { data: currency } = useAllCurrencyLookupQuery('')
    const { data: vendor, refetch: vendorRefetch } = useGetVendorLookUpQuery('')
    const { data: getPaymentMethod } = useGetPaymentMethodLookupQuery('')
    const { data: locationsData } = useGetAllLocationsQuery('')
    const { data: tripTypeData, refetch: tripTypeRefetch } = useGetAllTripTypeQuery('')
    const { data: getAllExpenseType, refetch: expenseTypeRefetch } = useGetAllExpenseTypeQuery('')
    const [activeKeys, setActiveKeys] = useState<string[]>([]);

    const { data: expenseCategory, refetch: refetchCategories } = useGetAllExpenseCategoryLookupV2Query({
        expenseTypeId: formik?.values?.expenseType?.id
    })
    useEffect(() => {
        if (formik?.values?.expenseType?.id) {
            refetchCategories();
        }
    }, [formik?.values?.expenseType?.id])

    const { data: flexRemaingBudget, refetch: flexRemaingBudgetRefetch } = useGetRemainingBudgetOfUserV2Query({
        userAccountId: userAccountID,
        expenseDate: formik.values?.itemDate,
    })
    useEffect(() => {
        if (formik?.values?.expenseType?.id === constraintConfig.expenseType.flex && formik.values?.itemDate) {
            flexRemaingBudgetRefetch();
        }
    }, [formik?.values?.expenseType?.id, formik.values?.itemDate]);

    useEffect(() => {
        expenseTypeRefetch()
    }, [])
    const { data: getCategoryBudget, refetch: categoryBudgetRefetch } = useGetRemainingCatagoryBudgetOfUserQuery({
        catagoryId: formik.values?.expenseCategory?.id,
        itemDate: formik.values?.itemDate,
    })

    useEffect(() => {
        if (formik.values.expenseCategory?.id) {
            categoryBudgetRefetch()
        }
    }, [formik.values.expenseCategory?.id])


    const [getMileageByDate, setGetMileageByDate] = useState<any>(null); // Initialize with null
    const [milageRateIsError, setMilageRateIsError] = useState(false); // Initialize error state

    const mileageRefetch = async () => {
        if (parseInt(formik.values?.expenseCategory?.id) === constraintConfig.expenseCategoryID?.mileage) {
            try {
                const headers = {
                    Authorization: `Bearer ${token}`,
                };
                const getExpenseLogResponse = await axios.get(
                    `${baseURL}/api/Expense${API_END_POINTS.getMileagebyDate}?date=${formik.values?.itemDate || ''}`,
                    {
                        headers,
                    }
                );
                const getMileageByDate = getExpenseLogResponse.data;
                setGetMileageByDate(getMileageByDate);
            } catch (error) {
                console.error(`Error fetching data for : `, error);
                setMilageRateIsError(true);
            } finally {
            }
        }
    };
    // const [distanceCal, setDistanceCal] = useState<any>(null); 
    const [distanceError, setDistanceError] = useState(false);
    const [distanceIsLoading, setDistanceIsLoading] = useState(false);
    const [selectedByMouse, setSelectedByMouse] = useState(false);

    // const tripTypeDistanceRefetch = async () => {
    //     if (parseInt(formik.values?.expenseCategory?.id) === constraintConfig.expenseCategoryID?.mileage) {
    //         const { startLocation, endLocation, tripType, itemDate } = formik.values;
    //         if (!startLocation || !endLocation || !tripType?.id || !itemDate) {
    //             // toast.error('Please fill in all required fields.');
    //             return;
    //         }
    //         setDistanceIsLoading(true)
    //         try {
    //             const headers = {
    //                 Authorization: `Bearer ${token}`,
    //             };;
    //             const getTripDistanceResponse = await axios.get(
    //                 `${baseURL}/api/General${API_END_POINTS.getDistanceDataByTripType}?source=${formik.values?.startLocation || ''}&destinaiton=${formik.values?.endLocation || ''}&tripTypeid=${formik.values?.tripType.id || ''}&itemDate=${formik.values?.itemDate || ''}`,
    //                 {
    //                     headers,
    //                 }
    //             );
    //             const distanceCal = getTripDistanceResponse.data;
    //             const mileageDistance = (distanceCal?.result?.distance).toFixed(2);
    //             const reimbursableDistance = (distanceCal?.result?.reimburable).toFixed(2);
    //             const mileageRate = (distanceCal?.result?.rate).toFixed(2);
    //             const amount = (distanceCal?.result?.amount).toFixed(2);


    //             formik.setFieldValue(`milage`, mileageDistance);
    //             formik.setFieldValue(`reimbursableDistance`, reimbursableDistance);
    //             formik.setFieldValue(`mileageRate`, mileageRate);
    //             formik.setFieldValue(`amount`, amount);

    //             // setDistanceCal({ ...distanceCal, distanceInKilometers, distanceInMiles, distanceResult });

    //         } catch (error) {
    //             console.error(`Error fetching data: `, error);
    //             setDistanceError(true);
    //             formik.setFieldValue(`milage`, 0);
    //             formik.setFieldValue(`reimbursableDistance`, 0);
    //             formik.setFieldValue(`mileageRate`, 0);
    //             formik.setFieldValue(`amount`, 0);

    //             const errorMessage = (error as { response?: { data?: { message?: string } } })?.response?.data?.message;
    //             if (errorMessage !== undefined) {
    //                 toast.error(`${errorMessage}`);
    //             }
    //         } finally {
    //             setDistanceIsLoading(false)
    //         }
    //     }
    // };
    const tripTypeDistanceRefetch = async () => {
        const { expenseCategory, startLocation, endLocation, tripType, itemDate } = formik.values;
        if (
            parseInt(expenseCategory?.id) === constraintConfig.expenseCategoryID?.mileage &&
            startLocation &&
            endLocation &&
            tripType?.id &&
            itemDate
        ) {
            setDistanceIsLoading(true);
            try {
                const headers = { Authorization: `Bearer ${token}` };
                const getTripDistanceResponse = await axios.get(
                    `${baseURL}/api/General${API_END_POINTS.getDistanceDataByTripType}`,
                    {
                        params: {
                            source: startLocation,
                            destinaiton: endLocation,
                            tripTypeid: tripType.id,
                            itemDate,

                        },
                        headers,
                    }
                );
                const distanceCal = getTripDistanceResponse.data;
                formik.setFieldValue(`milage`, distanceCal?.result?.distance?.toFixed(2) || 0);
                formik.setFieldValue(`reimbursableDistance`, distanceCal?.result?.reimburable?.toFixed(2) || 0);
                formik.setFieldValue(`mileageRate`, distanceCal?.result?.rate?.toFixed(2) || 0);
                formik.setFieldValue(`amount`, distanceCal?.result?.amount?.toFixed(2) || 0);

            } catch (error) {

                setDistanceError(true);
                formik.setFieldValue(`milage`, 0);
                formik.setFieldValue(`reimbursableDistance`, 0);
                formik.setFieldValue(`mileageRate`, 0);
                formik.setFieldValue(`amount`, 0);
                const errorMessage = (error as { response?: { data?: { message?: string } } })?.response?.data?.message;
                if (errorMessage !== undefined) {
                    toast.error(`${errorMessage}`);
                }
            } finally {
                setDistanceIsLoading(false);
            }
        }
    };
    useEffect(() => {
        if (selectedByMouse) {
            tripTypeDistanceRefetch();
            setSelectedByMouse(false);
        }
    }, [selectedByMouse, formik.values.startLocation, formik.values.endLocation]);


    useEffect(() => {
        if (formik.values?.tripType?.id) {
            tripTypeDistanceRefetch()
        }
    }, [formik.values?.tripType?.id])
    useEffect(() => {
        if (formik.values?.expenseCategory?.id === constraintConfig.expenseCategoryID?.mileage || formik.values?.itemDate) {
            mileageRefetch();
        }
    }, [formik.values?.itemDate, formik.values?.expenseCategory?.id])

    const [showAddUpdateVendorModal, setShowAddUpdateVendorModal] = useState(false)
    useEffect(() => {
        if (showAddUpdateVendorModal === false) {
            vendorRefetch()
        }
    }, [showAddUpdateVendorModal])

    const isPdfFile = (filePath: string | undefined) => {
        if (!filePath) return false;
        const fileExtension = filePath.toLowerCase().split('.').pop();
        return fileExtension === 'pdf';
    };
    const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
        if (attachmentFile) {
            if (isPdfFile(attachmentFile)) {
                // For PDF files, open in the browser
                window.open(attachmentFile, '_blank');
            } else {
                // For image files, open in the Lightbox
                setLightboxData({ imageURL: attachmentFile });
                setLightboxOpen(true);
            }
        }
    };

    const defaultCenter = { lat: 40.689249, lng: -74.044500 };
    const [center, setCenter] = useState(defaultCenter);

    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [directionsResponse, setDirectionsResponse] = useState<google.maps.DirectionsResult | null>(null);
    const libraries: Libraries = useMemo(() => ['places'], []);
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: baseUrl.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: libraries,
    });

    // const [distance, setDistance] = useState<number>(0);

    const originRef = useRef<HTMLInputElement>(null);
    const destinationRef = useRef<HTMLInputElement>(null);

    const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>, ref: React.RefObject<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (ref.current && ref.current.value !== '') {
                if (ref === originRef) {

                    formik.setFieldValue(`startLocation`, ref.current.value);
                } else if (ref === destinationRef) {
                    formik.setFieldValue(`endLocation`, ref.current.value);
                    // calculateRoute();
                    await calculateAndSetRoute();
                }
            }
        }
    };

    const handleLocationChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        ref: React.RefObject<HTMLInputElement>
    ) => {
        // Update Formik values based on the location type (startLocation or endLocation)
        const updatedLocation = event.target.value;

        if (ref === originRef) {
            formik.setFieldValue(`startLocation`, updatedLocation);
        } else if (ref === destinationRef) {
            formik.setFieldValue(`endLocation`, updatedLocation);
        }
    };
    const handleSelectLocationChange = async (
        event: any,
        ref: React.RefObject<HTMLInputElement>
    ) => {
        // Update Formik values based on the location type (startLocation or endLocation)
        const updatedLocation = event.label;

        if (ref === originRef) {
            formik.setFieldValue(`startLocation`, updatedLocation);
            setSelectedByMouse(true);
            await calculateAndSetRoute();

        } else if (ref === destinationRef) {
            formik.setFieldValue(`endLocation`, updatedLocation);
            setSelectedByMouse(true);
            await calculateAndSetRoute();
        }
        await calculateAndSetRoute();

    };
    const originAutocompleteRef = useRef<google.maps.places.Autocomplete>();
    const destinationAutocompleteRef = useRef<google.maps.places.Autocomplete>();
    const handleStartPlaceChanged = async () => {
        setSelectedByMouse(true);
        // tripTypeDistanceRefetch();
        const place = originAutocompleteRef.current?.getPlace(); // Get the selected place

        if (place && place.geometry && place.geometry.location) {
            const startLocationLat = place.geometry.location.lat();
            const startLocationLng = place.geometry.location.lng();
            formik.setFieldValue(`startLocation`, place?.formatted_address);
            formik.setFieldValue(`startLatLngs.lat`, startLocationLat.toString());
            formik.setFieldValue(`startLatLngs.lng`, startLocationLng.toString());
            await calculateAndSetRoute();
        }
    };
    const handleEndPlaceChanged = async () => {
        setSelectedByMouse(true);
        // tripTypeDistanceRefetch();
        const place = destinationAutocompleteRef.current?.getPlace(); // Get the selected place
        if (place && place.geometry && place.geometry.location) {
            const endLocationLat = place.geometry.location.lat();
            const endLocationLng = place.geometry.location.lng();
            formik.setFieldValue(`endLocation`, place?.formatted_address);
            formik.setFieldValue(`endLatLngs.lat`, endLocationLat.toString());
            formik.setFieldValue(`endLatLngs.lng`, endLocationLng.toString());
            await calculateAndSetRoute();
        }
    };

    const calculateAndSetRoute = async () => {
        if (!originRef.current?.value || !destinationRef.current?.value) return;

        const directionsService = new google.maps.DirectionsService();

        try {
            const result = await directionsService.route({
                origin: originRef.current.value,
                destination: destinationRef.current.value,
                travelMode: google.maps.TravelMode.DRIVING, // Adjust mode if needed (e.g., WALKING, BICYCLING)
            });

            setDirectionsResponse(result);

            // Optionally update the map center based on the route
            const leg = result.routes[0]?.legs[0];
            if (leg && leg.start_location && leg.end_location) {
                setCenter({
                    lat: (leg.start_location.lat() + leg.end_location.lat()) / 2,
                    lng: (leg.start_location.lng() + leg.end_location.lng()) / 2,
                });
            }

            // Optionally, extract distance and duration
            const distance = result.routes[0]?.legs[0]?.distance?.text;
            const duration = result.routes[0]?.legs[0]?.duration?.text;

            // console.log(`Distance: ${distance}, Duration: ${duration}`);


            // await captureMapImage();
        } catch (error) {
            console.error("Error fetching directions:", error);
        }
    };
    useEffect(() => {
        if (expenseItemsDataCheck === true &&
            isEditValues === false) {
            setDirectionsResponse(null)
        }
        if (expenseItemsDataCheck === false &&
            isEditValues === true) {
            calculateAndSetRoute();
        }
    }, [expenseItemsDataCheck, isEditValues])
    // useEffect(() => {
    //     if (show && (formik.values.startLocation || formik.values.endLocation) && !directionsResponse) {
    //         calculateAndSetRoute();
    //     }
    // }, [show, formik.values.startLocation, formik.values.endLocation, directionsResponse, calculateAndSetRoute]);

    const captureMapImage = async () => {
        if (!map) return;

        const mapContainer = document.getElementById("test-ss");

        if (mapContainer) {
            try {
                const width = 400; // Set desired width
                const height = 400; // Set desired height

                const dataUrl = await htmlToImage.toPng(mapContainer);

                // Display the captured image
                const img = new Image();
                img.src = dataUrl;
                document.body.appendChild(img);

                // Update the Formik field with the captured image
                formik.setFieldValue("attachmentFile", dataUrl);

            } catch (error) {
                console.error("Error capturing map image:", error);
            }
        }
    };


    const mileageCol = formik.values?.expenseCategory?.id === constraintConfig.expenseCategoryID.mileage;
    const handleTextAreaChange = (event: any) => {
        const text = event.target.value;
        const words = text.trim().split('');
        const wordCount = words.reduce((count: any, word: any) => count + word.length, 0);
        setWordCount(wordCount);
    };
    const handleBlur = (e: any) => {
        const value = e.target.value;
        if (value) {
            const formattedValue = parseFloat(value).toFixed(2);
            formik.setFieldValue('amount', formattedValue);
        }
    };

    const totalSplitAmount = parseFloat(
        (formik.values.splitLineItems?.reduce((total: number, item: any) => {
            const validAmount = parseFloat(item.amount?.toString() || '0');
            return total + (isNaN(validAmount) ? 0 : validAmount);
        }, 0) || 0).toFixed(2)
    );


    // Round the total split amount
    const roundedTotalSplitAmount = Math.round(totalSplitAmount * 100) / 100;

    // Calculate totalAmount from formik and handle fallback
    const totalAmount = parseFloat(formik.values.amount || '0');

    // Calculate remaining amount and round
    const remainingAmount = totalAmount - roundedTotalSplitAmount;
    const roundedRemainingAmount = Math.round(remainingAmount * 100) / 100;

    // Determine class for remaining amount text based on whether it's positive or negative
    const remainingAmountClass = roundedRemainingAmount >= 0 ? 'text-success' : 'text-danger';

    // Format the remaining amount to two decimal places
    const formattedRemainingAmount = roundedRemainingAmount.toFixed(2);


    const handleSplitLocationChange = (selectedOption: any, index: number) => {
        const selectedLocationId = selectedOption?.value;
        const selectedLocationTitle = selectedOption?.label;

        // Check for duplicate locations
        const isDuplicate = formik.values.splitLineItems.some(
            (item: any, i: number) =>
                item.expItemLocation?.id === selectedLocationId && i !== index
        );

        if (isDuplicate) {
            toast.error("This location is already selected. Please choose a different location.");
        } else {
            // Update the form field if no duplicate is found
            formik.setFieldValue(`splitLineItems[${index}].expItemLocation.id`, selectedLocationId || null);
            formik.setFieldValue(`splitLineItems[${index}].expItemLocation.title`, selectedLocationTitle || null);
        }
    };

    // Event handler for manual change of split amount
    const handleSplitAmountChange = (index: number, newAmount: number | string) => {
        const totalAmount = parseFloat(formik.values.amount || '0');

        // Update amount
        formik.setFieldValue(`splitLineItems[${index}].amount`, newAmount);

        // Calculate percentage based on the new amount
        const calculatedPercentage = totalAmount ? (parseFloat(newAmount?.toString()) / totalAmount) * 100 : 0;
        formik.setFieldValue(`splitLineItems[${index}].percentage`, calculatedPercentage.toFixed(2));
    };

    // Event handler for manual change of split percentage
    const handleSplitPercentageChange = (index: number, newPercentage: number | string) => {
        const totalAmount = parseFloat(formik.values.amount || '0');

        // Update percentage
        formik.setFieldValue(`splitLineItems[${index}].percentage`, newPercentage);

        // Calculate amount based on the new percentage
        const calculatedAmount = totalAmount ? (parseFloat(newPercentage?.toString()) / 100) * totalAmount : 0;
        formik.setFieldValue(`splitLineItems[${index}].amount`, calculatedAmount.toFixed(2));
    };


    const handleSubmit = () => {
        formik?.handleSubmit();
        if (addUpdateExpenseItemV2IsSuccess) {
            expenseFormik?.handleSubmit();
        }
    }
    useEffect(() => {
        if (addUpdateExpenseItemV2IsSuccess) {
            expenseFormik?.handleSubmit();
        }
    }, [addUpdateExpenseItemV2IsSuccess])



    // handleCheckbox
    // const handleCheckItem = (e: any, itemId: any, itemData: any) => {
    //     const isChecked = e.target.checked;

    //     setSelectedExpenseItemIds((prevItems: any) => {
    //         // Create a copy of the previous selectedExpenseItemIds array
    //         const updatedIds = [...prevItems];

    //         if (isChecked) {
    //             // If the checkbox is checked, add the itemId to the selectedExpenseItemIds array
    //             updatedIds.push(itemId);
    //         } else {
    //             // If the checkbox is unchecked, remove the itemId from the selectedExpenseItemIds array
    //             const index = updatedIds.indexOf(itemId);
    //             if (index !== -1) {
    //                 updatedIds.splice(index, 1);
    //             }
    //         }

    //         return updatedIds;
    //     });

    //     // Update selectedExpenseItemData
    //     setSelectedExpenseItemData((prevData: any) => {
    //         const updatedData = [...prevData];
    //         if (isChecked) {
    //             updatedData.push(itemData); // Add the item data
    //         } else {
    //             const index = updatedData.findIndex((data) => data.expenseItemId === itemId);
    //             if (index !== -1) {
    //                 updatedData.splice(index, 1); // Remove the item data
    //             }
    //         }
    //         return updatedData;
    //     });
    // };
    const handleCheckItem = (e: any, itemId: any, itemData: any) => {
        const isChecked = e.target.checked;

        setSelectedExpenseItemIds((prevItems: any) => {
            const updatedIds = isChecked
                ? [...prevItems, itemId]
                : prevItems.filter((id: any) => id !== itemId);
            return updatedIds;
        });

        setSelectedExpenseItemData((prevData: any) => {
            const updatedData = isChecked
                ? [...prevData, itemData]
                : prevData.filter((data: any) => data.expenseItemId !== itemId);
            return updatedData;
        });
    };


    const [showExpenseDeleteModal, setShowExpenseDeleteModal] = useState(false)
    const [expenseDeleteData, setExpenseDeleteData] = useState({});

    const deleteHandler = () => {
        setShowExpenseDeleteModal((prev) => !prev);
    };
    const combinedItems = useMemo(() => [
        ...(expenseItems || []),
        ...(AllItems?.result?.data || []),
    ], [expenseItems, AllItems]);


    const preselectedItemIds = addUpdateExpenseItemData?.result
        ? [addUpdateExpenseItemData.result]
        : [];

    // Set initial selected states based on preselectedItemIds
    useEffect(() => {
        if (preselectedItemIds.length > 0 && combinedItems.length > 0) {
            // console.log('Setting Selected IDs:', preselectedItemIds);
            // console.log('Combined Items:', combinedItems);

            setSelectedExpenseItemIds((prevIds: any = []) => {
                const uniqueIds = Array?.from(new Set([...prevIds, ...preselectedItemIds]));
                // console.log('Updated Selected IDs:', uniqueIds);
                return uniqueIds;
            });

            setSelectedExpenseItemData((prevData: any = []) => {
                const newItems = combinedItems.filter(item =>
                    preselectedItemIds.includes(item.expenseItemId)
                );
                // console.log('New Items:', newItems);
                const mergedData = Array.from(
                    new Map([...prevData, ...newItems].map(item => [item.expenseItemId, item])).values()
                );
                // console.log('Updated Selected Data:', mergedData);
                return mergedData;
            });
        }
    }, [addUpdateExpenseItemData, combinedItems]);


    return (
        <>
            <AddUpdateVendorModal show={showAddUpdateVendorModal}
                handleClose={() => setShowAddUpdateVendorModal(false)} />
            <DeleteExpenseModal
                show={showExpenseDeleteModal}
                handleClose={() => setShowExpenseDeleteModal(false)}
                data={expenseDeleteData}
                getCardExpenseItemTrigger={getCardExpenseItemTrigger} />
            <Tooltip id="tooltip" place="top" />
            {fileIsloading &&
                <BGBlur />
            }
            {distanceIsLoading &&
                <BGBlur />
            }
            <div data-kt-stepper-element='content'>
                <div className="row w-100 ">
                    {!isEditValues && !expenseItemsDataCheck ?
                        <>
                            {AllItemsIsLoading ?
                                <div className='w-100' style={{ height: "75vh" }}>
                                    {[...Array(10)].map((_, index) => (
                                        <div key={index} className='mb-1'>
                                            <SkeletonTheme baseColor="#c1c1c1" highlightColor="#444">
                                                <Skeleton height={40} />
                                            </SkeletonTheme>
                                        </div>
                                    ))}
                                </div> :
                                <Accordion className='w-100 stepper-lineitems-accordian' alwaysOpen
                                    activeKey={activeKeys}>
                                    <div className='d-flex gap-2 justify-content-end'>
                                        {activeKeys.length > 0 &&
                                            <button
                                                type='button'
                                                className='btn btn-sm btn-light-primary mb-4'
                                                onClick={() => setActiveKeys([])}
                                            >
                                                {labelKey.collapseAll}{' '}
                                            </button>
                                        }
                                        <button
                                            type='button'
                                            className='btn btn-sm btn-light-primary mb-4'
                                            onClick={() => {
                                                setIsEditValues(false);
                                                setExpenseItemsDataCheck(true);
                                            }}
                                        >
                                            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                            {labelKey.addNewItem}{' '}

                                        </button>
                                    </div>
                                    <div className='overflow-auto' style={{ height: '70vh' }}>
                                        {combinedItems?.length > 0 ? (<>
                                            {combinedItems?.map((item: any, index: any) => (
                                                <div className="col-md-12 w-100" key={index}>
                                                    <Accordion.Item
                                                        eventKey={index.toString()}
                                                        id={`accordion-item-${index}`}
                                                    >
                                                        <Accordion.Header
                                                            onClick={() => {
                                                                setActiveKeys((prev) => {
                                                                    if (prev.includes(index.toString())) {
                                                                        // If already open, close it
                                                                        return prev.filter(
                                                                            (key) => key !== index.toString()
                                                                        );
                                                                    } else {
                                                                        // Otherwise, open it
                                                                        return [...prev, index.toString()];
                                                                    }
                                                                });
                                                            }}>
                                                            <div className='d-flex justify-content-between align-items-center w-100'>
                                                                <div className='d-flex gap-2 align-items-center'>
                                                                    <div onClick={(e) => e.stopPropagation()} data-tooltip-id="tooltip" data-tooltip-content='Checked line item will be be attached to this report'>
                                                                        <TextField
                                                                            type="checkbox"
                                                                            fieldClass='form-check-input cursor-pointer'
                                                                            checked={selectedExpenseItemIds?.includes(item?.expenseItemId)}
                                                                            onChange={(e) => handleCheckItem(e, item?.expenseItemId, item)}
                                                                            disabled={item?.recordSourceId === constraintConfig.recordSourceId.BankFeed}
                                                                        // disabled={data?.ocrPassed === false}
                                                                        />
                                                                    </div>
                                                                    <span>
                                                                        {index + 1}. {item?.expenseType?.title} &nbsp; - &nbsp;
                                                                        {item?.expenseCategory?.title} &nbsp; - &nbsp;
                                                                        <DateComp formattedDate={item?.itemDate} /> &nbsp; - &nbsp;
                                                                        <CurrencyComp amount={item?.amount} /> &nbsp; - &nbsp;
                                                                        <Tooltip id="web-source" place="top" />
                                                                        <Tooltip id="mobile-source" place="top" />
                                                                        <Tooltip id="bank-source" place="top" />
                                                                        {item?.recordSourceId === constraintConfig.recordSourceId.WebApp ?
                                                                            <KTSVG path="/media/icons/figmaIcons/Web.svg" className='svg-icon-1 svg-gray'
                                                                                data-tooltip-id="web-source" data-tooltip-content='Website' />
                                                                            :
                                                                            item?.recordSourceId === constraintConfig.recordSourceId.Mobile ?
                                                                                <KTSVG path="/media/icons/figmaIcons/Mobile.svg" className='svg-icon-1 svg-gray'
                                                                                    data-tooltip-id="mobile-source" data-tooltip-content='Mobile' /> :
                                                                                item?.recordSourceId === constraintConfig.recordSourceId.BankFeed ?
                                                                                    <KTSVG path="/media/icons/figmaIcons/Bank.svg" className='svg-icon-1 svg-gray'
                                                                                        data-tooltip-id="bank-source" data-tooltip-content='Bank' />
                                                                                    : null}
                                                                    </span>
                                                                </div>
                                                                <div className='d-flex gap-2 align-items-center me-3'>
                                                                    {/* {constraintConfig.paymentMethod.cash === item?.paymentMethod?.id ||
                                                                    constraintConfig.paymentMethod.personalCreditCard === item?.paymentMethod?.id ?
                                                                    !selectedExpenseItemIds?.includes(item?.expenseItemId) && (
                                                                        <div
                                                                            data-tooltip-id="tooltip" data-tooltip-content='Delete LineItem'
                                                                            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setExpenseDeleteData(item?.expenseItemId);
                                                                                deleteHandler();
                                                                            }}
                                                                        >
                                                                            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 text-danger' svgClassName='svg-danger' />
                                                                        </div>
                                                                    ) : null} */}
                                                                    {selectedExpenseItemIds?.includes(item?.expenseItemId) && item?.recordSourceId !== constraintConfig.recordSourceId.BankFeed &&
                                                                        <div onClick={(e) => e.stopPropagation()}>
                                                                            <div
                                                                                data-tooltip-id="tooltip" data-tooltip-content='UnLink LineItem'
                                                                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                                                                onClick={(e) => handleCheckItem(e, item?.expenseItemId, item)}
                                                                            >
                                                                                <FaUnlink className='svg-icon-3 text-danger' />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <div
                                                                        data-tooltip-id="tooltip" data-tooltip-content='Update LineItem'
                                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            setIsEditValues(true);
                                                                            setExpenseItemsDataCheck(false);
                                                                            setIsLineItemApiTrigger(true);
                                                                            expenseItemByIdTrigger({ expenseItemId: item?.expenseItemId });
                                                                        }}
                                                                    >
                                                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3 text-primary' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="row">
                                                                <div className={` col-lg-8 col-xl-9 mb-5`}>
                                                                    <div className="row m-0 px-4">
                                                                        <div className={`${mileageCol ? 'col-lg-4 col-md-6' :
                                                                            parseInt(item?.expenseType?.id) === constraintConfig.expenseType.regular ? 'col-xl-4' :
                                                                                // getCategoryBudget?.result === -1 ? 'col-md-6 col-lg-6 col-xl-3' :
                                                                                'col-md-6 col-lg-4 col-xl-4'}  mb-5`}>
                                                                            <SearchSelect
                                                                                label={labelKey.expenseType}
                                                                                required
                                                                                options={[
                                                                                    // { value: '', label: 'Select Type' },  // Default 'Select Type' option
                                                                                    ...(Array.isArray(getAllExpenseType?.result)
                                                                                        ? getAllExpenseType.result.map((option: any) => ({
                                                                                            value: option?.id,
                                                                                            label: option?.value,
                                                                                            key: option?.id,
                                                                                        }))
                                                                                        : []
                                                                                    )
                                                                                ]}
                                                                                placeholder="Select Type"
                                                                                value={
                                                                                    item?.expenseType?.id
                                                                                        ? {
                                                                                            value: item?.expenseType?.id,
                                                                                            label: item?.expenseType?.title || '',
                                                                                        }
                                                                                        : null
                                                                                }
                                                                                disabled={viewOnlyData}
                                                                            />
                                                                        </div>
                                                                        <div className={`${mileageCol ? 'col-lg-4 col-md-6' : 'col-md-6 col-lg-4'}  mb-5`}>
                                                                            <TextField
                                                                                label={labelKey.date}
                                                                                required={true}
                                                                                type='date'
                                                                                max={currentDate}
                                                                                value={item?.itemDate ? moment(item?.itemDate).format('YYYY-MM-DD') : ''}
                                                                                disabled={viewOnlyData}
                                                                            />
                                                                        </div>
                                                                        {/* {roleID !== constraintConfig.roleID.role2 &&
                                                                            <>
                                                                                {parseInt(item?.expenseType?.id) === constraintConfig.expenseType.flex && (
                                                                                    <div className={`${mileageCol ? 'col-lg-4 col-md-6' :
                                                                                        // getCategoryBudget?.result === -1 ? 'col-md-6 col-lg-6 col-xl-3' :
                                                                                        'col-md-6 col-lg-4 col-xl-4'}  mb-5`}>
                                                                                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>{flexRemaingBudget?.result === 0 ? <>{labelKey.budget}</> : <>{labelKey.remainingBudget}</>}</label>
                                                                                        <p className='fs-4 fw-bold mb-0 form-control form-control-lg form-control-solid' style={{ border: '1px solid #f0f0f0' }}>${flexRemaingBudget?.result ? flexRemaingBudget?.result : "0"}</p>
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        } */}
                                                                        <div className={`${mileageCol ? 'col-lg-4 col-md-6' :
                                                                            // getCategoryBudget?.result === -1 ? 'col-md-6 col-lg-6 col-xl-4' :
                                                                            parseInt(item?.expenseType?.id) === constraintConfig.expenseType.flex ? 'col-xl-4' :
                                                                                'col-md-6 col-lg-4 col-xl-4'}  mb-5`}>
                                                                            <SearchSelect
                                                                                label={labelKey.category}
                                                                                required
                                                                                options={expenseCategory?.result?.map((option: any) => ({
                                                                                    label: option?.value,
                                                                                    value: option?.id
                                                                                }))}
                                                                                value={
                                                                                    item?.expenseCategory?.id
                                                                                        ? {
                                                                                            value: item?.expenseCategory?.id,
                                                                                            label: item?.expenseCategory?.title || '',
                                                                                        }
                                                                                        : null
                                                                                }
                                                                                placeholder='Select Category'
                                                                                disabled={viewOnlyData}
                                                                            />
                                                                        </div>
                                                                        {/* {getCategoryBudget?.result === -1 ? '' :
                                <div className={`${mileageCol ? 'col-md-6 col-lg-4 col-xl-4' : getCategoryBudget?.result === -1 ? 'col-md-6 col-lg-6 col-xl-4' : 'col-md-6 col-lg-4 col-xl-3'}  mb-5`}
                                >
                                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>{labelKey.remainingCategoryBudget} {viewOnlyData === true && <FaLock className='fs-7 ms-2' />}</label>
                                    <p className={`fs-4 fw-bold mb-0 form-control form-control-lg form-control-solid  ${viewOnlyData === true ? 'bg-secondary' : null}`} style={{ border: '1px solid rgb(240, 240, 240)' }}>${getCategoryBudget?.result ? getCategoryBudget?.result : "0"}</p>
                                </div>
                            } */}


                                                                        <div className={`${mileageCol ? 'col-lg-4 col-md-6' : 'col-md-6 col-lg-4'}  mb-5`}>
                                                                            <SearchSelect
                                                                                label={labelKey.currency}
                                                                                required={true}
                                                                                value={
                                                                                    item?.currency?.id
                                                                                        ? {
                                                                                            value: item?.currency.id,
                                                                                            label: item?.currency.title || 'USD',
                                                                                        }
                                                                                        : null
                                                                                }
                                                                                options={
                                                                                    currency?.result.map((option: any) => ({
                                                                                        value: option.id,
                                                                                        label: option.value,
                                                                                        key: option.id,
                                                                                    }))
                                                                                }
                                                                                placeholder={labelKey.selectCurrency}
                                                                                disabled={viewOnlyData}
                                                                            />

                                                                        </div>
                                                                        <div className='col-md-6 col-lg-4 mb-5'>
                                                                            <SearchSelect
                                                                                label={labelKey.paymentMethod}
                                                                                required={true}
                                                                                value={
                                                                                    item?.paymentMethod?.id
                                                                                        ? {
                                                                                            value: item?.paymentMethod.id,
                                                                                            label: item?.paymentMethod.title || 'Cash',
                                                                                        }
                                                                                        : null
                                                                                }
                                                                                options={
                                                                                    parseInt(item?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage
                                                                                        ? getPaymentMethod?.result
                                                                                            .filter((option: any) => option.id !== 2 && option.id !== 3) // Exclude options with id 2 or 3
                                                                                            .map((option: any) => ({
                                                                                                value: option.id,
                                                                                                label: option.value,
                                                                                                key: option.id,
                                                                                            }))
                                                                                        : getPaymentMethod?.result
                                                                                            .filter((option: any) => item?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard || option.id !== 3) // Exclude option with id 3 unless it's corporate credit card
                                                                                            .map((option: any) => ({
                                                                                                value: option.id,
                                                                                                label: option.value,
                                                                                                key: option.id,
                                                                                            }))
                                                                                }
                                                                                placeholder={labelKey.selectPayment}
                                                                                selectClass={`${parseInt(item?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage && 'bg-secondary'}`}
                                                                                disabled={viewOnlyData}
                                                                            />

                                                                        </div>
                                                                        {item?.isSplitLineItem !== true &&
                                                                            <div className={`${mileageCol ? 'col-lg-4 col-md-6' : 'col-md-6 col-lg-4'}  mb-5`}>
                                                                                <SearchSelect
                                                                                    label={labelKey.chargeLocation}
                                                                                    required
                                                                                    options={(locationsData?.result || []).map((option: any, index: any) => ({
                                                                                        value: option.id,
                                                                                        label: option?.value,
                                                                                        key: option.id,
                                                                                    }))}
                                                                                    value={
                                                                                        item?.expItemLocation?.id
                                                                                            ? {
                                                                                                value: item?.expItemLocation?.id,
                                                                                                label: item?.expItemLocation?.title || '',
                                                                                            }
                                                                                            : null
                                                                                    }
                                                                                    disabled={viewOnlyData}
                                                                                />

                                                                            </div>
                                                                        }
                                                                        {parseInt(item?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage ?
                                                                            <>
                                                                                <div className={`${mileageCol ? 'col-lg-4 col-md-6' : 'col-md-6'} mb-5`}>
                                                                                    <SearchSelect
                                                                                        label={labelKey.tripType}
                                                                                        required={true}
                                                                                        value={
                                                                                            item?.tripType?.id
                                                                                                ? {
                                                                                                    value: item?.tripType.id,
                                                                                                    label: item?.tripType.title || 'One Way',
                                                                                                }
                                                                                                : null
                                                                                        }
                                                                                        options={
                                                                                            tripTypeData?.result.map((option: any) => ({
                                                                                                value: option.id,
                                                                                                label: option.value,
                                                                                                key: option.id,
                                                                                            }))
                                                                                        }
                                                                                        placeholder="Select Trip Type"
                                                                                        disabled={viewOnlyData}
                                                                                    />

                                                                                </div>
                                                                                {isLoaded && (
                                                                                    <>
                                                                                        <div className={`${locationType === 1 ? 'd-block' : 'd-none'} col-lg-8 mb-5`}>
                                                                                            <div className="row">
                                                                                                <div className='col-md-6 mb-5'>
                                                                                                    <label className='fs-5 fw-bold mb-2 required'>
                                                                                                        {labelKey.startLocation}
                                                                                                    </label>
                                                                                                    {viewOnlyData === true && <FaLock className='fs-7 ms-1' />}
                                                                                                    <Autocomplete
                                                                                                        onLoad={(autocomplete) => {
                                                                                                            originAutocompleteRef.current = autocomplete;
                                                                                                            autocomplete.addListener('place_changed', handleStartPlaceChanged);
                                                                                                        }}
                                                                                                    // onPlaceChanged={() => {
                                                                                                    //     // calculateRoute();
                                                                                                    //     tripTypeDistanceRefetch();
                                                                                                    // }}
                                                                                                    >
                                                                                                        <input type='text'
                                                                                                            className='form-control form-control-lg form-control-solid default-input'
                                                                                                            placeholder='Origin'
                                                                                                            ref={originRef}
                                                                                                            // onKeyDown={handleKeyDown}
                                                                                                            onKeyDown={(event) => handleKeyDown(event, originRef)}
                                                                                                            onChange={(event) => handleLocationChange(event, originRef)}
                                                                                                            value={item?.startLocation || ''}
                                                                                                            disabled={viewOnlyData}
                                                                                                        />
                                                                                                    </Autocomplete>
                                                                                                </div>
                                                                                                <div className='col-md-6'>
                                                                                                    <label className='fs-5 fw-bold mb-2 required'>
                                                                                                        {labelKey.endLocation}
                                                                                                    </label>
                                                                                                    {viewOnlyData === true && <FaLock className='fs-7 ms-1' />}
                                                                                                    <div className='d-flex gap-2'>
                                                                                                        <Autocomplete className='w-100'
                                                                                                            onLoad={(autocomplete) => {
                                                                                                                destinationAutocompleteRef.current = autocomplete;
                                                                                                                autocomplete.addListener('place_changed', handleEndPlaceChanged);
                                                                                                            }}
                                                                                                        >
                                                                                                            <input
                                                                                                                type='text' className='form-control form-control-lg form-control-solid default-input'
                                                                                                                placeholder='Destination'
                                                                                                                ref={destinationRef}
                                                                                                                // onKeyDown={handleKeyDown}
                                                                                                                onKeyDown={(event) => handleKeyDown(event, destinationRef)}
                                                                                                                onChange={(event) => handleLocationChange(event, destinationRef)}
                                                                                                                value={item?.endLocation || ''}
                                                                                                                disabled={viewOnlyData}
                                                                                                            />
                                                                                                        </Autocomplete>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                                <div className={`${locationType === 2 ? 'd-block' : 'd-none'} col-lg-8 mb-5`}>
                                                                                    <div className="row">
                                                                                        <div className='col-lg-6'>
                                                                                            <label className='fs-5 fw-bold mb-2 required'>
                                                                                                {labelKey.startLocation}
                                                                                            </label>
                                                                                            <Select
                                                                                                options={(locationsData?.result || []).map((option: any, index: any) => ({
                                                                                                    value: option.id,
                                                                                                    label: option?.value,
                                                                                                    key: option.id,
                                                                                                }))}
                                                                                                placeholder={
                                                                                                    item?.startLocation
                                                                                                        ? item?.startLocation
                                                                                                        : 'Select Origin'
                                                                                                }
                                                                                                value={item?.startLocation}
                                                                                                isDisabled={viewOnlyData}
                                                                                            />

                                                                                        </div>
                                                                                        <div className='col-lg-6'>
                                                                                            <label className='fs-5 fw-bold mb-2 required'>
                                                                                                {labelKey.endLocation}
                                                                                            </label>
                                                                                            <div className='d-flex gap-2 w-100'>
                                                                                                <Select
                                                                                                    options={(locationsData?.result || []).map((option: any, index: any) => ({
                                                                                                        value: option.id,
                                                                                                        label: option?.value,
                                                                                                        key: option.id,
                                                                                                    }))}
                                                                                                    placeholder={
                                                                                                        item?.endLocation
                                                                                                            ? item?.endLocation
                                                                                                            : 'Select Destination'
                                                                                                    }
                                                                                                    getOptionLabel={(option) => option?.label || ''}
                                                                                                    value={item?.endLocation}
                                                                                                    className='w-100'
                                                                                                    isDisabled={viewOnlyData}

                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-lg-2 col-md-3 mb-5'>
                                                                                    <div className='position-relative'>
                                                                                        {distanceIsLoading &&
                                                                                            <div className="spinner-border spinner-border-sm position-absolute me-5" style={{ right: 0, marginTop: '36px' }} role="status">
                                                                                                <span className="sr-only">{labelKey.loading}...</span>
                                                                                            </div>}
                                                                                    </div>
                                                                                    <TextField
                                                                                        label={labelKey.distance}
                                                                                        required={true}
                                                                                        type='number'
                                                                                        placeholder='Enter Distance'
                                                                                        min={0}
                                                                                        readOnly={true}
                                                                                        value={item?.milage}
                                                                                        disabled={viewOnlyData}

                                                                                    />
                                                                                </div>
                                                                                <div className='col-lg-2 col-md-3 mb-5'>
                                                                                    <TextField
                                                                                        label={labelKey.reimbursableDistance}
                                                                                        type='number'
                                                                                        readOnly={true}
                                                                                        value={item?.milage}
                                                                                        disabled={viewOnlyData}
                                                                                    />
                                                                                </div>
                                                                                <div className='col-lg-2 col-md-3 mb-5'>
                                                                                    <TextField
                                                                                        label={getMileageByDate?.result?.ratePerKM ? "Rate Per KM" : "Rate Per MI"}
                                                                                        type='text'
                                                                                        value={item?.mileageRate || 0}
                                                                                        readOnly={true}
                                                                                        disabled={viewOnlyData}
                                                                                    />
                                                                                </div>
                                                                                <div className='col-lg-2 col-md-3 mb-5'>
                                                                                    <TextField
                                                                                        label={labelKey.amount}
                                                                                        required={true}
                                                                                        type='number'
                                                                                        value={item?.amount || 0}
                                                                                        readOnly={true}
                                                                                        disabled={viewOnlyData}
                                                                                    />
                                                                                </div>

                                                                            </>
                                                                            :
                                                                            <div className='col-md-6 col-lg-4 mb-5'>
                                                                                <TextField
                                                                                    label={labelKey.amount}
                                                                                    required={true}
                                                                                    type='number'
                                                                                    placeholder='Enter Amount'
                                                                                    min={0}
                                                                                    value={item?.amount || ''}
                                                                                    disabled={viewOnlyData}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        {parseInt(item?.expenseCategory?.id) !== constraintConfig.expenseCategoryID.mileage &&
                                                                            <>
                                                                                <div className='col-md-6 col-lg-4 mb-5'>
                                                                                    <SearchSelect
                                                                                        label={labelKey.vendor}
                                                                                        customLabel={labelKey.addNew}
                                                                                        customLabelClick={() => setShowAddUpdateVendorModal(true)}
                                                                                        customLabelClass={`cursor-pointer ${viewOnlyData ? 'd-none' : 'd-block'}`}
                                                                                        required
                                                                                        options={vendor?.result?.map((option: any) => ({
                                                                                            label: option?.value,
                                                                                            value: option?.id
                                                                                        }))}
                                                                                        value={
                                                                                            item?.vendor?.vendorId
                                                                                                ? {
                                                                                                    value: item?.vendor?.vendorId,
                                                                                                    label: item?.vendor?.name || '',
                                                                                                }
                                                                                                : null
                                                                                        }
                                                                                        placeholder='Select Vendor'
                                                                                        disabled={viewOnlyData}
                                                                                    />
                                                                                </div>
                                                                            </>}




                                                                        <div className='col-md-12'>
                                                                            <div>
                                                                                <TextAreaField
                                                                                    label={labelKey.description}
                                                                                    placeholder='Enter Expense Description'
                                                                                    value={item?.itemDetail || ''}
                                                                                    disabled={viewOnlyData}
                                                                                />
                                                                                <p className="text-muted text-end mb-0">Characters: ({wordCount}/500)</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-md-12 col-lg-12 col-xl-10 mb-5'>
                                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                                <div>
                                                                                    <label className='form-label fs-5 fw-bold'>
                                                                                        Split Amount to Locations
                                                                                    </label>
                                                                                    <div className="form-check form-switch">
                                                                                        <input
                                                                                            className="form-check-input cursor-pointer"
                                                                                            type="checkbox"
                                                                                            role="switch"
                                                                                            id={`flexSwitchCheckChecked`}
                                                                                            checked={item?.isSplitLineItem}
                                                                                            disabled={viewOnlyData}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                {/* {item?.isSplitLineItem === true &&
                                                                <div className='pe-10'>
                                                                    <div className='d-flex gap-2 align-items-center'>
                                                                        <p className='mb-0 fs-4'><CurrencyComp amount={totalSplitAmount.toString()} />  of </p>
                                                                        <p className='mb-0 fs-4'> <CurrencyComp amount={item?.amount} />  </p>
                                                                    </div>
                                                                    <p className={`${remainingAmountClass} fs-4 mb-0`}>
                                                                        <CurrencyComp amount={formattedRemainingAmount} />
                                                                        &nbsp; {roundedRemainingAmount >= 0 ? 'left' : 'Over'}
                                                                    </p>
                                                                </div>
                                                            } */}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-2"></div>
                                                                        {item?.isSplitLineItem === true &&
                                                                            <div className='col-md-12 col-lg-12 col-xl-10'>

                                                                                {item?.splitLineItems?.map((item: any, index: any) => (
                                                                                    <div key={index} className="row">
                                                                                        <div className='col-md-6 col-lg-6 col-xl-6'>
                                                                                            <div>
                                                                                                <SearchSelect
                                                                                                    label={labelKey.splitLocation}
                                                                                                    required
                                                                                                    labelClass='mt-5'
                                                                                                    options={(locationsData?.result || []).map((option: any) => ({
                                                                                                        value: option.id,
                                                                                                        label: option?.value,
                                                                                                        key: option.id,
                                                                                                    }))}
                                                                                                    value={
                                                                                                        item.expItemLocation?.id
                                                                                                            ? {
                                                                                                                value: item.expItemLocation.id,
                                                                                                                label: item.expItemLocation.title || '',
                                                                                                            }
                                                                                                            : null
                                                                                                    }
                                                                                                    selectClass='w-100'
                                                                                                    disabled={viewOnlyData}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-3 col-lg-3 col-xl-3">
                                                                                            <div className='d-flex gap-2 align-items-center w-100'>
                                                                                                <TextField
                                                                                                    label={labelKey.splitAmount}
                                                                                                    mainClass='w-100'
                                                                                                    required
                                                                                                    labelClass='mt-5'
                                                                                                    type='number'
                                                                                                    placeholder='Enter Amount'
                                                                                                    min={0}
                                                                                                    value={item?.amount !== undefined && item?.amount !== null ? Number(item.amount).toFixed(2) : ''}
                                                                                                    // value={
                                                                                                    //     item?.splitLineItems && item.splitLineItems.length > index
                                                                                                    //         ? item.splitLineItems[index]?.amount || ''
                                                                                                    //         : ''
                                                                                                    // }
                                                                                                    onChange={(e) => handleSplitAmountChange(index, e.target.value)}
                                                                                                    disabled={viewOnlyData}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-3 col-lg-3 col-xl-3">
                                                                                            <div className='d-flex gap-2 align-items-center w-100 position-relative'>
                                                                                                <span className='position-absolute mt-13 ps-3 pe-2 h-35px d-flex align-items-center justify-content-center' style={{ borderRight: '1px solid #a1a5b7' }}>
                                                                                                    <FaPercentage className='h-20px w-20px text-muted' />
                                                                                                </span>
                                                                                                <TextField
                                                                                                    label={labelKey.splitAmountWithPer}
                                                                                                    required
                                                                                                    labelClass='mt-5'
                                                                                                    type='number'
                                                                                                    placeholder='Enter Percentage'
                                                                                                    fieldClass='ps-15'
                                                                                                    min={0}
                                                                                                    max={100}
                                                                                                    value={item?.percentage || ''}
                                                                                                    // value={
                                                                                                    //     item?.splitLineItems && item.splitLineItems.length > index
                                                                                                    //         ? item.splitLineItems[index]?.percentage || ''
                                                                                                    //         : ''
                                                                                                    // }
                                                                                                    disabled={viewOnlyData}
                                                                                                />
                                                                                                {viewOnlyData === false &&
                                                                                                    <KTSVG path='/media/icons/duotune/general/gen027.svg'
                                                                                                        className='svg-icon-3 text-hover-danger mt-12' svgClassName='svg-danger cursor-pointer'
                                                                                                        onClick={() => {
                                                                                                            const updatedSplitLineItems = item?.splitLineItems.filter((_: any, i: any) => i !== index);
                                                                                                            item?.setFieldValue('splitLineItems', updatedSplitLineItems);
                                                                                                            if (updatedSplitLineItems.length === 1) {
                                                                                                                item?.setFieldValue(`isSplitLineItem`, false);
                                                                                                            }
                                                                                                        }}
                                                                                                    />}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                                {viewOnlyData === false &&
                                                                                    <div className='d-flex justify-content-end w-100'>
                                                                                        <button
                                                                                            type="button"
                                                                                            className='btn btn-sm text-primary ps-0 pe-10'
                                                                                            onClick={() => {
                                                                                                const currentSplitLineItems = Array.isArray(item?.splitLineItems)
                                                                                                    ? item?.splitLineItems
                                                                                                    : []; // Ensure it's an array

                                                                                                item?.setFieldValue('splitLineItems', [
                                                                                                    ...currentSplitLineItems,
                                                                                                    {
                                                                                                        splitLineItemId: 0,
                                                                                                        expenseItemId: 0,
                                                                                                        expItemLocation: { id: 0, title: '' },
                                                                                                        amount: '',
                                                                                                    },
                                                                                                ]);
                                                                                            }}
                                                                                        >
                                                                                            Add More
                                                                                        </button>
                                                                                    </div>}

                                                                            </div>}

                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 col-xl-3">
                                                                    {/* {parseInt(item?.expenseCategory?.id) !== constraintConfig.expenseCategoryID.mileage ? */}
                                                                    <div>
                                                                        <div className='d-flex align-items-baseline'>
                                                                            <label className={`d-flex align-items-center fs-5 fw-bold mb-2 ${item?.amount <= orgDetail?.result?.receiptReqMinAmnt ? null : 'required'}`}>
                                                                                {labelKey.receiptDocument}
                                                                            </label>
                                                                            {viewOnlyData === true && <FaLock className='fs-7 ms-2 position-relative' />}
                                                                        </div>
                                                                        <div className='gap-2 w-100'>

                                                                            {item?.attachmentFile ?
                                                                                <div className='d-flex align-items-center gap-2 w-100' data-tooltip-content='Click to view the receipt.' data-tooltip-id="clickReceipt">
                                                                                    {/* {data?.attachmentFile && ( */}
                                                                                    {/* // <div className="mt-2 cursor-pointer"> */}
                                                                                    {item?.attachmentFile?.endsWith('.pdf') ? (
                                                                                        <div
                                                                                            onClick={() => openPdfInBrowserOrLightbox(item?.attachmentFile)}
                                                                                            style={{ cursor: "pointer", position: "relative", width: "100%", height: "100%" }}
                                                                                        >
                                                                                            <embed
                                                                                                src={`${item?.attachmentFile}#toolbar=0&navpanes=0&scrollbar=0`}
                                                                                                type="application/pdf"
                                                                                                width="100%"
                                                                                                height="500px"
                                                                                                className="cursor-pointer"
                                                                                                onClick={() => openPdfInBrowserOrLightbox(item?.attachmentFile)}
                                                                                            />
                                                                                            <div
                                                                                                style={{
                                                                                                    position: "absolute",
                                                                                                    top: 0,
                                                                                                    left: 0,
                                                                                                    width: "96%",
                                                                                                    height: "100%",
                                                                                                    backgroundColor: "transparent",
                                                                                                }}
                                                                                            ></div>
                                                                                        </div>
                                                                                    ) :
                                                                                        item?.attachmentFile === null ||
                                                                                            item?.attachmentFile === "null" ||
                                                                                            item?.attachmentFile === "undefined" ||
                                                                                            item?.attachmentFile === "" ||
                                                                                            item?.attachmentFile === "Receipt is not available" ? <p className='py-5 m-auto'>Receipt Not Attached</p> :
                                                                                            <img
                                                                                                alt='rcp'
                                                                                                src={item?.attachmentFile?.endsWith('.pdf') ? 'media/svg/files/pdf.svg' : item?.attachmentFile ? item?.attachmentFile : '/media/svg/files/blank-image.svg'}
                                                                                                // style={{ maxWidth: '100%', maxHeight:400 }}
                                                                                                className='h-500px w-100 cursor-pointer rounded'
                                                                                                onClick={() => openPdfInBrowserOrLightbox(item?.attachmentFile)}
                                                                                            />
                                                                                    }
                                                                                    {/* // </div> */}
                                                                                    {/* )} */}

                                                                                    {lightboxOpen && (
                                                                                        <Lightbox
                                                                                            mainSrc={lightboxData.imageURL}
                                                                                            onCloseRequest={() => setLightboxOpen(false)}
                                                                                            imageCaption="Attachment"
                                                                                            enableZoom={true}
                                                                                            imagePadding={50}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                                :
                                                                                <>
                                                                                    {/* {parseInt(item?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage &&
                                                                                    <>
                                                                                        <div className='position-relative d-flex flex-column align-items-center h-500px w-100 mt-3 overflow-hidden' id='test-ss'>
                                                                                            <div className="position-absolute top-0 start-0 h-100 w-100">
                                                                                                {isLoaded && (
                                                                                                    <div id="map-container" style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                                                                                                        <GoogleMap
                                                                                                            center={center}
                                                                                                            zoom={15}
                                                                                                            mapContainerStyle={{ width: '100%', height: '100%', overflow: 'hidden' }}
                                                                                                            options={{
                                                                                                                zoomControl: false,
                                                                                                                streetViewControl: false,
                                                                                                                mapTypeControl: false,
                                                                                                                fullscreenControl: false,
                                                                                                            }}
                                                                                                            onLoad={(map) => setMap(map)}
                                                                                                        >
                                                                                                            {directionsResponse && (
                                                                                                                <DirectionsRenderer directions={directionsResponse} />
                                                                                                            )}
                                                                                                        </GoogleMap>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                } */}
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    {/* } */}
                                                                </div>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </div>
                                            ))}
                                        </>) :
                                            <>
                                                <h3 className='text-gray-600 text-center'>Please add a Line Item...</h3>
                                                {/* <NoRecordFound /> */}
                                            </>
                                        }
                                    </div>
                                </Accordion>
                            }
                        </>
                        :
                        <Accordion activeKey="0" className='w-100' alwaysOpen>
                            <div className="col-md-12 w-100">
                                <Accordion.Item eventKey="0" id={`accordion-item-1`}>
                                    <Accordion.Header>
                                        {formik?.values?.expenseCategory?.title}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className={` col-lg-8 col-xl-9 mb-5`}>
                                                <div className="row m-0 px-4">
                                                    <div className={`${mileageCol ? 'col-lg-4 col-md-6' :
                                                        parseInt(formik.values?.expenseType?.id) === constraintConfig.expenseType.regular ? 'col-xl-4' :
                                                            // getCategoryBudget?.result === -1 ? 'col-md-6 col-lg-6 col-xl-3' :
                                                            'col-md-6 col-lg-4 col-xl-4'}  mb-5`}>
                                                        <SearchSelect
                                                            label={labelKey.expenseType}
                                                            required
                                                            options={[
                                                                // { value: '', label: 'Select Type' },  // Default 'Select Type' option
                                                                ...(Array.isArray(getAllExpenseType?.result)
                                                                    ? getAllExpenseType.result.map((option: any) => ({
                                                                        value: option?.id,
                                                                        label: option?.value,
                                                                        key: option?.id,
                                                                    }))
                                                                    : []
                                                                )
                                                            ]}
                                                            placeholder="Select Type"
                                                            value={
                                                                formik.values?.expenseType?.id
                                                                    ? {
                                                                        value: formik.values?.expenseType?.id,
                                                                        label: formik.values?.expenseType?.title || '',
                                                                    }
                                                                    : null
                                                            }
                                                            onChange={(selectedOption: any) => {
                                                                formik.setFieldValue(`expenseType.id`, selectedOption.value);
                                                                formik.setFieldValue(`expenseType.title`, selectedOption.label);
                                                                formik.setFieldValue(`expenseCategory.id`, 1);
                                                                formik.setFieldValue(`expenseCategory.title`, 'Please Select Category');
                                                            }}
                                                        />
                                                    </div>
                                                    <div className={`${mileageCol ? 'col-lg-4 col-md-6' : 'col-md-6 col-lg-4'}  mb-5`}>
                                                        {/* <TextField
                                                            label={labelKey.date}
                                                            required={true}
                                                            type='date'
                                                            max={currentDate}
                                                            {...(formik.values.itemDate && formik.getFieldProps('itemDate'))}
                                                            value={formik.values.itemDate ? moment(formik.values.itemDate).format('YYYY-MM-DD') : ''}
                                                            onChange={(e) => {
                                                                const selectedDate = e.target.value;
                                                                const formattedDate = selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : null;
                                                                formik.setFieldValue('itemDate', formattedDate);
                                                            }}
                                                            disabled={
                                                                formik.values?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard
                                                            }
                                                            fieldClass={formik.values.itemDate || !isSubmitted ? '' : 'border-red'}
                                                        /> */}
                                                        <DateField
                                                            label={labelKey.date}
                                                            required={true}
                                                            max={new Date()} // Pass max as a Date object
                                                            {...formik.getFieldProps('itemDate')} // Get formik props
                                                            selected={formik.values.itemDate ? new Date(formik.values.itemDate) : null} // Convert string to Date
                                                            onChange={(date: Date | null) => {
                                                                // Convert Date back to a string before setting it in Formik
                                                                const formattedDate = date ? moment(date).format('YYYY-MM-DD') : null;
                                                                formik.setFieldValue('itemDate', formattedDate);
                                                            }}
                                                            disabled={formik.values?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard}
                                                            fieldClass={formik.values.itemDate || !isSubmitted ? '' : 'border-red'}
                                                        />

                                                    </div>
                                                    {roleID !== constraintConfig.roleID.role2 &&
                                                        <>
                                                            {parseInt(formik.values?.expenseType?.id) === constraintConfig.expenseType.flex && (
                                                                <div className={`${mileageCol ? 'col-lg-4 col-md-6' :
                                                                    'col-md-6 col-lg-4 col-xl-4'}  mb-5`}>
                                                                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>{flexRemaingBudget?.result?.remainingBudget === 0 ? <>{labelKey.budget}</> : <>{labelKey.remainingBudget}</>}</label>
                                                                    <p className='fs-4 fw-bold mb-0 form-control form-control-lg form-control-solid' style={{ border: '1px solid #f0f0f0' }}>${flexRemaingBudget?.result?.remainingBudget ? flexRemaingBudget?.result?.remainingBudget : "0"}</p>
                                                                </div>
                                                            )}
                                                        </>
                                                    }
                                                    <div className={`${mileageCol ? 'col-lg-4 col-md-6' :
                                                        parseInt(formik.values?.expenseType?.id) === constraintConfig.expenseType.flex ? 'col-xl-4' :
                                                            'col-md-6 col-lg-4 col-xl-4'}  mb-5`}>
                                                        <SearchSelect
                                                            label={labelKey.category}
                                                            required
                                                            options={expenseCategory?.result?.map((option: any) => ({
                                                                label: option?.value,
                                                                value: option?.id
                                                            }))}
                                                            value={
                                                                formik.values.expenseCategory?.id
                                                                    ? {
                                                                        value: formik.values.expenseCategory?.id,
                                                                        label: formik.values.expenseCategory?.title || '',
                                                                    }
                                                                    : null
                                                            }
                                                            onChange={(selectedOption: any) => {
                                                                formik.setFieldValue(`expenseCategory.id`, selectedOption?.value || null);
                                                                formik.setFieldValue(`expenseCategory.title`, selectedOption?.label || null);
                                                            }}
                                                            placeholder='Select Category'
                                                            selectClass={isSubmitted && formik.values?.expenseCategory?.id === 1 ? 'border-red' : ''}
                                                        />
                                                    </div>


                                                    <div className={`${mileageCol ? 'col-lg-4 col-md-6' : 'col-md-6 col-lg-4'}  mb-5`}>
                                                        <SearchSelect
                                                            label={labelKey.currency}
                                                            required={true}
                                                            value={
                                                                formik.values.currency?.id
                                                                    ? {
                                                                        value: formik.values.currency.id,
                                                                        label: formik.values.currency.title || 'USD',
                                                                    }
                                                                    : null
                                                            }
                                                            onChange={(selectedOption: any) => {
                                                                formik.setFieldValue(`currency.id`, selectedOption.value);
                                                                formik.setFieldValue(`currency.title`, selectedOption.label);
                                                            }}
                                                            options={
                                                                currency?.result.map((option: any) => ({
                                                                    value: option.id,
                                                                    label: option.value,
                                                                    key: option.id,
                                                                }))
                                                            }
                                                            placeholder={labelKey.selectCurrency}
                                                            disabled={formik.values?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard}
                                                        />

                                                    </div>
                                                    <div className='col-md-6 col-lg-4 mb-5'>
                                                        <SearchSelect
                                                            label={labelKey.paymentMethod}
                                                            required={true}
                                                            value={
                                                                formik.values.paymentMethod?.id
                                                                    ? {
                                                                        value: formik.values.paymentMethod.id,
                                                                        label: formik.values.paymentMethod.title || 'Cash',
                                                                    }
                                                                    : null
                                                            }
                                                            onChange={(selectedOption: any) => {
                                                                formik.setFieldValue(`paymentMethod.id`, selectedOption.value);
                                                                formik.setFieldValue(`paymentMethod.title`, selectedOption.label);
                                                            }}
                                                            options={
                                                                parseInt(formik.values.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage
                                                                    ? getPaymentMethod?.result
                                                                        .filter((option: any) => option.id !== 2 && option.id !== 3) // Exclude options with id 2 or 3
                                                                        .map((option: any) => ({
                                                                            value: option.id,
                                                                            label: option.value,
                                                                            key: option.id,
                                                                        }))
                                                                    : getPaymentMethod?.result
                                                                        .filter((option: any) => formik.values.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard || option.id !== 3) // Exclude option with id 3 unless it's corporate credit card
                                                                        .map((option: any) => ({
                                                                            value: option.id,
                                                                            label: option.value,
                                                                            key: option.id,
                                                                        }))
                                                            }
                                                            placeholder={labelKey.selectPayment}
                                                            selectClass={`${parseInt(formik.values.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage && 'bg-secondary'}`}
                                                            disabled={parseInt(formik.values.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage ||
                                                                parseInt(formik.values.paymentMethod?.id) === constraintConfig.paymentMethod.corporateCreditCard
                                                            }
                                                        />

                                                    </div>
                                                    {formik.values.isSplitLineItem !== true &&
                                                        <div className={`${mileageCol ? 'col-lg-4 col-md-6' : 'col-md-6 col-lg-4'}  mb-5`}>
                                                            <SearchSelect
                                                                label={labelKey.chargeLocation}
                                                                required
                                                                options={(locationsData?.result || []).map((option: any, index: any) => ({
                                                                    value: option.id,
                                                                    label: option?.value,
                                                                    key: option.id,
                                                                }))}
                                                                value={
                                                                    formik.values.expItemLocation?.id
                                                                        ? {
                                                                            value: formik.values.expItemLocation?.id,
                                                                            label: formik.values.expItemLocation?.title || '',
                                                                        }
                                                                        : null
                                                                }
                                                                onChange={(selectedOption: any) => {
                                                                    formik.setFieldValue(`expItemLocation.id`, selectedOption?.value || null);
                                                                    formik.setFieldValue(`expItemLocation.title`, selectedOption?.label || null);
                                                                }}
                                                            />

                                                        </div>
                                                    }
                                                    {parseInt(formik.values?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage ?
                                                        <>
                                                            <div className={`${mileageCol ? 'col-lg-4 col-md-6' : 'col-md-6'} mb-5`}>
                                                                <SearchSelect
                                                                    label={labelKey.tripType}
                                                                    required={true}
                                                                    value={
                                                                        formik.values.tripType?.id
                                                                            ? {
                                                                                value: formik.values.tripType.id,
                                                                                label: formik.values.tripType.title || 'One Way',
                                                                            }
                                                                            : null
                                                                    }
                                                                    onChange={(selectedOption: any) => {
                                                                        formik.setFieldValue(`tripType.id`, selectedOption.value);
                                                                        formik.setFieldValue(`tripType.title`, selectedOption.label);
                                                                    }}
                                                                    options={
                                                                        tripTypeData?.result.map((option: any) => ({
                                                                            value: option.id,
                                                                            label: option.value,
                                                                            key: option.id,
                                                                        }))
                                                                    }
                                                                    placeholder="Select Trip Type"
                                                                    selectClass={formik.values?.tripType?.id || !isSubmitted ? '' : 'border-red'}
                                                                />

                                                            </div>
                                                            {isLoaded && (
                                                                <>
                                                                    <div className={`${locationType === 1 ? 'd-block' : 'd-none'} col-lg-8 mb-5`}>
                                                                        <div className="row">
                                                                            <div className='col-md-6 mb-5'>
                                                                                <label className='fs-5 fw-bold mb-2 required'>
                                                                                    {labelKey.startLocation}
                                                                                </label>
                                                                                <FaLock className='fs-7 ms-1' />
                                                                                <Autocomplete
                                                                                    onLoad={(autocomplete) => {
                                                                                        originAutocompleteRef.current = autocomplete;
                                                                                        autocomplete.addListener('place_changed', handleStartPlaceChanged);
                                                                                    }}
                                                                                >
                                                                                    <input type='text'
                                                                                        className={`form-control form-control-lg form-control-solid default-input 
                                                                                            ${formik.values.startLocation || !isSubmitted ? '' : 'border-red'}`}
                                                                                        placeholder='Origin'
                                                                                        ref={originRef}
                                                                                        // onKeyDown={handleKeyDown}
                                                                                        onKeyDown={(event) => handleKeyDown(event, originRef)}
                                                                                        onChange={(event) => handleLocationChange(event, originRef)}
                                                                                        value={formik.values?.startLocation || ''}
                                                                                    />
                                                                                </Autocomplete>
                                                                            </div>
                                                                            <div className='col-md-6'>
                                                                                <label className='fs-5 fw-bold mb-2 required'>
                                                                                    {labelKey.endLocation}
                                                                                </label>
                                                                                <FaLock className='fs-7 ms-1' />
                                                                                <div className='d-flex gap-2'>
                                                                                    <Autocomplete className='w-100'
                                                                                        onLoad={(autocomplete) => {
                                                                                            destinationAutocompleteRef.current = autocomplete;
                                                                                            autocomplete.addListener('place_changed', handleEndPlaceChanged);
                                                                                        }}
                                                                                    >
                                                                                        <input
                                                                                            type='text'
                                                                                            className={`form-control form-control-lg form-control-solid default-input 
                                                                                                ${formik.values.endLocation || !isSubmitted ? '' : 'border-red'}`}
                                                                                            placeholder='Destination'
                                                                                            ref={destinationRef}
                                                                                            // onKeyDown={handleKeyDown}
                                                                                            onKeyDown={(event) => handleKeyDown(event, destinationRef)}
                                                                                            onChange={(event) => handleLocationChange(event, destinationRef)}
                                                                                            value={formik.values?.endLocation || ''}
                                                                                        />
                                                                                    </Autocomplete>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                            <div className={`${locationType === 2 ? 'd-block' : 'd-none'} col-lg-8 mb-5`}>
                                                                <div className="row">
                                                                    <div className='col-lg-6'>
                                                                        <SearchSelect
                                                                            label={labelKey.startLocation}
                                                                            required
                                                                            options={(locationsData?.result || []).map((option: any, index: any) => ({
                                                                                value: option.id,
                                                                                label: option?.value,
                                                                                key: option.id,
                                                                            }))}
                                                                            placeholder={
                                                                                formik.values?.startLocation
                                                                                    ? formik.values?.startLocation
                                                                                    : 'Select Origin'
                                                                            }
                                                                            value={formik.values?.startLocation}
                                                                            // onChange={(selectedOption: any) => {
                                                                            //     setSelectedByMouse(true);
                                                                            //     formik.setFieldValue(`startLocation`, selectedOption?.label || '');
                                                                            // }}
                                                                            onChange={(event: any) => handleSelectLocationChange(event, originRef)}
                                                                            selectClass={formik.values.startLocation || !isSubmitted ? '' : 'border-red'}
                                                                        />

                                                                    </div>
                                                                    <div className='col-lg-6'>
                                                                        <div className='d-flex gap-2 w-100'>
                                                                            <SearchSelect
                                                                                label={labelKey.endLocation}
                                                                                options={(locationsData?.result || []).map((option: any, index: any) => ({
                                                                                    value: option.id,
                                                                                    label: option?.value,
                                                                                    key: option.id,
                                                                                }))}
                                                                                placeholder={
                                                                                    formik.values.endLocation
                                                                                        ? formik.values?.endLocation
                                                                                        : 'Select Destination'
                                                                                }
                                                                                getOptionLabel={(option: any) => option?.label || ''}
                                                                                value={formik.values?.endLocation}
                                                                                // onChange={(selectedOption: any) => {
                                                                                //     setSelectedByMouse(true);
                                                                                //     formik.setFieldValue(`endLocation`, selectedOption?.label || '');
                                                                                // }}
                                                                                onChange={(event: any) => handleSelectLocationChange(event, destinationRef)}
                                                                                selectClass={`w-100 ${formik.values.endLocation || !isSubmitted ? '' : 'border-red'}`}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-2 col-md-3 mb-5'>
                                                                <div className='position-relative'>
                                                                    {/* {distanceIsLoading &&
                                                                        <div className="spinner-border spinner-border-sm position-absolute me-5" style={{ right: 0, marginTop: '36px' }} role="status">
                                                                            <span className="sr-only">{labelKey.loading}...</span>
                                                                        </div>
                                                                        } */}
                                                                </div>
                                                                <TextField
                                                                    label={labelKey.distance}
                                                                    required={true}
                                                                    type='number'
                                                                    placeholder='Enter Distance'
                                                                    min={0}
                                                                    readOnly={true}
                                                                    {...formik.getFieldProps(`milage`)}

                                                                />
                                                            </div>
                                                            <div className='col-lg-2 col-md-3 mb-5'>
                                                                <TextField
                                                                    label={labelKey.reimbursableDistance}
                                                                    type='number'
                                                                    readOnly={true}
                                                                    {...formik.getFieldProps(`reimbursableDistance`)}
                                                                />
                                                            </div>
                                                            <div className='col-lg-2 col-md-3 mb-5'>
                                                                <TextField
                                                                    label={getMileageByDate?.result?.ratePerKM ? "Rate Per KM" : "Rate Per MI"}
                                                                    type='text'
                                                                    {...formik.getFieldProps(`mileageRate`)}
                                                                    value={formik.values.mileageRate || 0}
                                                                    readOnly={true}
                                                                />
                                                            </div>
                                                            <div className='col-lg-2 col-md-3 mb-5'>
                                                                <TextField
                                                                    label={labelKey.amount}
                                                                    required={true}
                                                                    type='number'
                                                                    {...formik.getFieldProps(`amount`)}
                                                                    readOnly={true}
                                                                    disabled={formik.values?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard}
                                                                />
                                                            </div>

                                                        </>
                                                        :
                                                        <div className='col-md-6 col-lg-4 mb-5'>
                                                            <TextField
                                                                label={labelKey.amount}
                                                                required={true}
                                                                type='number'
                                                                placeholder='Enter Amount'
                                                                min={0}
                                                                {...formik.getFieldProps(`amount`)}
                                                                value={formik.values?.amount || ''}
                                                                onBlur={handleBlur}
                                                                disabled={formik.values.recordSourceId === constraintConfig.recordSourceId.BankFeed ||
                                                                    formik.values?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard
                                                                }
                                                                fieldClass={formik.values.amount || !isSubmitted ? '' : 'border-red'}

                                                            />
                                                        </div>
                                                    }
                                                    {parseInt(formik.values?.expenseCategory?.id) !== constraintConfig.expenseCategoryID.mileage &&
                                                        <>
                                                            <div className='col-md-6 col-lg-4 mb-5'>
                                                                <SearchSelect
                                                                    label={labelKey.vendor}
                                                                    customLabel={labelKey.addNew}
                                                                    customLabelClick={() => setShowAddUpdateVendorModal(true)}
                                                                    customLabelClass={`cursor-pointer ${formik.values?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard ? 'd-none' : 'd-block'}`}
                                                                    required
                                                                    options={vendor?.result?.map((option: any) => ({
                                                                        label: option?.value,
                                                                        value: option?.id
                                                                    }))}
                                                                    value={
                                                                        formik.values.vendor?.vendorId
                                                                            ? {
                                                                                value: formik.values.vendor?.vendorId,
                                                                                label: formik.values.vendor?.name || '',
                                                                            }
                                                                            : null
                                                                    }
                                                                    onChange={(selectedOption: any) => {
                                                                        formik.setFieldValue(`vendor.vendorId`, selectedOption?.value || null);
                                                                        formik.setFieldValue(`vendor.name`, selectedOption?.label || null);
                                                                    }}
                                                                    placeholder='Select Vendor'
                                                                    disabled={
                                                                        (
                                                                            formik.values?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard &&
                                                                            formik.values.vendor?.vendorId !== 1
                                                                        )
                                                                    }
                                                                    selectClass={formik.values.vendor.vendorId || !isSubmitted ? '' : 'border-red'}
                                                                />
                                                            </div>
                                                        </>}




                                                    <div className='col-md-12'>
                                                        <div>
                                                            <TextAreaField
                                                                label={labelKey.description}
                                                                placeholder='Enter Expense Description'
                                                                {...formik.getFieldProps(`itemDetail`)}
                                                                value={formik?.values?.itemDetail || ''}
                                                                onChange={(e) => {
                                                                    formik.handleChange(e);
                                                                    handleTextAreaChange(e);
                                                                }}
                                                            />
                                                            <p className="text-muted text-end mb-0">Characters: ({wordCount}/500)</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12 col-lg-12 col-xl-10 mb-5'>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <div>
                                                                <label className='form-label fs-5 fw-bold'>
                                                                    Split Amount to Locations
                                                                </label>
                                                                <div className="form-check form-switch">
                                                                    <input
                                                                        className="form-check-input cursor-pointer"
                                                                        type="checkbox"
                                                                        role="switch"
                                                                        id={`flexSwitchCheckChecked`}
                                                                        {...formik.getFieldProps('isSplitLineItem')}
                                                                        checked={formik.values.isSplitLineItem}
                                                                        onChange={(e) => {
                                                                            const isChecked = e.target.checked;
                                                                            formik.setFieldValue(`isSplitLineItem`, isChecked);

                                                                            if (isChecked) {
                                                                                const currentSplitLineItems = formik.values?.splitLineItems || [];
                                                                                if (currentSplitLineItems.length === 1) {
                                                                                    formik.setFieldValue(`splitLineItems`, [
                                                                                        ...currentSplitLineItems,
                                                                                        { splitLineItemId: 0, expItemLocation: { id: 0, title: '' }, amount: '' },
                                                                                    ]);
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {formik.values.isSplitLineItem === true &&
                                                                <div className='pe-10'>
                                                                    <div className='d-flex gap-2 align-items-center'>
                                                                        <p className='mb-0 fs-4'><CurrencyComp amount={totalSplitAmount?.toString()} />  of </p>
                                                                        <p className='mb-0 fs-4'> <CurrencyComp amount={formik.values?.amount} />  </p>
                                                                    </div>
                                                                    <p className={`${remainingAmountClass} fs-4 mb-0`}>
                                                                        <CurrencyComp amount={formattedRemainingAmount} />
                                                                        &nbsp; {roundedRemainingAmount >= 0 ? 'left' : 'Over'}
                                                                    </p>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-2"></div>
                                                    {formik.values.isSplitLineItem === true &&
                                                        <div className='col-md-12 col-lg-12 col-xl-10'>

                                                            {formik.values?.splitLineItems?.map((item: any, index: any) => (
                                                                <div key={index} className="row">
                                                                    <div className='col-md-6 col-lg-6 col-xl-6'>
                                                                        <div>
                                                                            <SearchSelect
                                                                                label={labelKey.splitLocation}
                                                                                required
                                                                                labelClass='mt-5'
                                                                                options={(locationsData?.result || []).map((option: any) => ({
                                                                                    value: option.id,
                                                                                    label: option?.value,
                                                                                    key: option.id,
                                                                                }))}
                                                                                value={
                                                                                    item.expItemLocation?.id
                                                                                        ? {
                                                                                            value: item.expItemLocation.id,
                                                                                            label: item.expItemLocation.title || '',
                                                                                        }
                                                                                        : null
                                                                                }
                                                                                onChange={(selectedOption: any) => handleSplitLocationChange(selectedOption, index)}
                                                                                selectClass={`w-100 ${formik.values?.splitLineItems[index].expItemLocation.id || !isSubmitted ? '' : 'border-red'}`}

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-xl-3">
                                                                        <div className='d-flex gap-2 align-items-center w-100'>
                                                                            <TextField
                                                                                label={labelKey.splitAmount}
                                                                                mainClass='w-100'
                                                                                required
                                                                                labelClass='mt-5'
                                                                                type='number'
                                                                                placeholder='Enter Amount'
                                                                                min={0}
                                                                                {...formik.getFieldProps(`splitLineItems[${index}].amount`)}
                                                                                value={formik.values?.splitLineItems[index]?.amount || ''}
                                                                                onChange={(e) => handleSplitAmountChange(index, e.target.value)}
                                                                                fieldClass={formik.values?.splitLineItems[index].amount || !isSubmitted ? '' : 'border-red'}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-xl-3">
                                                                        <div className='d-flex gap-2 align-items-center w-100 position-relative'>
                                                                            <span className='position-absolute mt-13 ps-3 pe-2 h-35px d-flex align-items-center justify-content-center' style={{ borderRight: '1px solid #a1a5b7' }}>
                                                                                <FaPercentage className='h-20px w-20px text-muted' />
                                                                            </span>
                                                                            <TextField
                                                                                label={labelKey.splitAmountWithPer}
                                                                                required
                                                                                labelClass='mt-5'
                                                                                type='number'
                                                                                placeholder='Enter Percentage'
                                                                                min={0}
                                                                                max={100}
                                                                                {...formik.getFieldProps(`splitLineItems[${index}].percentage`)}
                                                                                value={formik.values.splitLineItems[index]?.percentage || ''}
                                                                                onChange={(e) => handleSplitPercentageChange(index, e.target.value)}
                                                                                fieldClass={`ps-15 ${formik.values?.splitLineItems[index].percentage || !isSubmitted ? '' : 'border-red'}`}
                                                                            />

                                                                            <KTSVG path='/media/icons/duotune/general/gen027.svg'
                                                                                className='svg-icon-3 text-hover-danger mt-12' svgClassName='svg-danger cursor-pointer'
                                                                                onClick={() => {
                                                                                    const updatedSplitLineItems = formik.values.splitLineItems.filter((_: any, i: any) => i !== index);
                                                                                    formik.setFieldValue('splitLineItems', updatedSplitLineItems);
                                                                                    if (updatedSplitLineItems.length === 1) {
                                                                                        formik.setFieldValue(`isSplitLineItem`, false);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <div className='d-flex justify-content-end w-100'>
                                                                <button
                                                                    type="button"
                                                                    className='btn btn-sm text-primary ps-0 pe-10'
                                                                    onClick={() => {
                                                                        const currentSplitLineItems = Array.isArray(formik.values.splitLineItems)
                                                                            ? formik.values.splitLineItems
                                                                            : []; // Ensure it's an array

                                                                        formik.setFieldValue('splitLineItems', [
                                                                            ...currentSplitLineItems,
                                                                            {
                                                                                splitLineItemId: 0,
                                                                                expenseItemId: 0,
                                                                                expItemLocation: { id: 0, title: '' },
                                                                                amount: '',
                                                                            },
                                                                        ]);
                                                                    }}
                                                                >
                                                                    Add More
                                                                </button>
                                                            </div>

                                                        </div>}

                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-3">
                                                <div>
                                                    <div className='d-flex align-items-baseline'>
                                                        <label className={`d-flex align-items-center fs-5 fw-bold mb-2 ${formik.values.amount <= orgDetail?.result?.receiptReqMinAmnt ? null : 'required'}`}>
                                                            {labelKey.receiptDocument}
                                                        </label>
                                                    </div>
                                                    <div className='gap-2 w-100'>
                                                        {!fileIsloading &&
                                                            <div className='position-relative w-100 default-input rounded'>
                                                                <input
                                                                    type="file"
                                                                    id="file"
                                                                    name="file"
                                                                    accept=".png, .jpg, .jpeg, .pdf"
                                                                    ref={fileInputRef}
                                                                    onChange={handleImageChangeFirst}
                                                                    className='w-100 opacity-0 position-absolute h-40px cursor-pointer'
                                                                    disabled={
                                                                        formik.values?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator
                                                                    }
                                                                />
                                                                <div className={`form-control-lg form-control-solid form-control ${formik.values?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator ? 'bg-secondary' : null}`}  >
                                                                    <div className='d-flex align-items-center gap-2'>
                                                                        <FaUpload />
                                                                        {fileName ? (
                                                                            <div className='d-flex align-items-center gap-2 w-100'>
                                                                                <span className='fw-bold fs-5 mb-0'>{fileName}</span>
                                                                                <span className='ms-auto' onClick={handleRemoveFile} style={{ zIndex: 13 }}>
                                                                                    <FaTrash className='ms-4 text-danger cursor-pointer' />
                                                                                </span>
                                                                            </div>
                                                                        ) : (
                                                                            <p className='fw-bold fs-5 mb-0'>{labelKey.uploadYourFile}</p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {formik.values?.attachmentFile ?
                                                            <>
                                                                {formik ?
                                                                    <>
                                                                        {formik.values?.attachmentFile && (
                                                                            <div className="mt-2 cursor-pointer d-flex justify-content-center align-items-center">
                                                                                <LazyLoadImage
                                                                                    alt='rcp'
                                                                                    effect="blur"
                                                                                    src={formik.values?.attachmentFile.endsWith('.pdf') ? 'media/svg/files/pdf.svg' : formik.values?.attachmentFile}
                                                                                    style={{ maxWidth: '100%', maxHeight: '500px' }}
                                                                                    className='h-300px w-100 cursor-pointer rounded'
                                                                                    wrapperClassName="w-100"
                                                                                    onClick={() => openPdfInBrowserOrLightbox(formik.values?.attachmentFile)}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </> :
                                                                    <>

                                                                        {!fileIsloading && <>
                                                                            {imagePreviewFirst && (
                                                                                <img
                                                                                    className='cursor-pointer'
                                                                                    src={isPdf ? 'media/svg/files/pdf.svg' : imagePreviewFirst}
                                                                                    alt=''
                                                                                    style={{ maxWidth: '100%', maxHeight: '500px' }}
                                                                                    onClick={() => openPdfInBrowserOrLightbox(imagePreviewFirst)}
                                                                                />
                                                                            )}</>}
                                                                    </>}

                                                                {lightboxOpen && (
                                                                    <Lightbox
                                                                        mainSrc={lightboxData.imageURL}
                                                                        onCloseRequest={() => setLightboxOpen(false)}
                                                                        imageCaption="Attachment"
                                                                        enableZoom={true}
                                                                        imagePadding={50}
                                                                    />
                                                                )}
                                                            </>
                                                            :
                                                            <>
                                                                {parseInt(formik.values?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage &&
                                                                    <>

                                                                        <div className='position-relative d-flex flex-column align-items-center h-500px w-100 mt-3 overflow-hidden' id='test-ss'>
                                                                            <div className="position-absolute top-0 start-0 h-100 w-100">
                                                                                {isLoaded && (
                                                                                    <div id="map-container" style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                                                                                        <GoogleMap
                                                                                            center={center}
                                                                                            zoom={15}
                                                                                            mapContainerStyle={{ width: '100%', height: '100%', overflow: 'hidden' }}
                                                                                            options={{
                                                                                                zoomControl: false,
                                                                                                streetViewControl: false,
                                                                                                mapTypeControl: false,
                                                                                                fullscreenControl: false,
                                                                                            }}
                                                                                            onLoad={(map) => setMap(map)}
                                                                                        >
                                                                                            {directionsResponse && (
                                                                                                <DirectionsRenderer directions={directionsResponse} />
                                                                                            )}
                                                                                        </GoogleMap>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-end align-items-center gap-5 mt-4'>
                                                {addUpdateExpenseItemIsLoading ?
                                                    <button className='btn btn-md btn-primary' style={{ padding: '10px 30px' }} disabled={addUpdateExpenseItemIsLoading}>
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                            {labelKey.pleaseWait}{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    </button> :
                                                    <>
                                                        <div className='btn btn-md btn-light'
                                                            style={{ padding: '10px 30px' }}
                                                            onClick={() => {
                                                                setIsEditValues(false);
                                                                setExpenseItemsDataCheck(false);
                                                                setIsLineItemApiTrigger(false);
                                                                setIsSubmitted(false);
                                                                setFileName(null);
                                                            }}>Close</div>
                                                        <div
                                                            className="btn btn-md btn-primary"
                                                            style={{ padding: '10px 30px' }}
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                handleSubmitLineItems()
                                                            }}
                                                        >
                                                            {isEditValues === true ? "Update" : "Save"}
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </div>
                        </Accordion>
                    }
                </div>
            </div>
        </>
    )
}

export default Step2NewItems