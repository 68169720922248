import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setHiddenColumns, toggleColumn } from '../../features/columnVisibilitySlice';
import { useAppSelector } from '../../hooks';
import { selectAuth } from '../../features/authSlice';

interface UseColumnVisibilityProps {
  pageName: string;
}

const useColumnVisibility = ({ pageName }: UseColumnVisibilityProps) => {
  const dispatch = useDispatch();
  const hiddenColumns = useSelector((state: RootState) => state.columnVisibility.pages[pageName] || []);
  const { userAccountID } = useAppSelector(selectAuth);

  // Load hidden columns from local storage on component mount
  useEffect(() => {
    const storedHiddenColumns = localStorage.getItem(`hiddenColumns_${userAccountID}`);
    if (storedHiddenColumns) {
      const parsedHiddenColumns = JSON.parse(storedHiddenColumns);
      if (parsedHiddenColumns[pageName]) {
        dispatch(setHiddenColumns({ page: pageName, hiddenColumns: parsedHiddenColumns[pageName] }));
      }
    }
  }, [dispatch, pageName, userAccountID]);

  // Save hidden columns to local storage whenever they change
  useEffect(() => {
    const storedColumns = JSON.parse(localStorage.getItem(`hiddenColumns_${userAccountID}`) || '{}');
    storedColumns[pageName] = hiddenColumns;
    localStorage.setItem(`hiddenColumns_${userAccountID}`, JSON.stringify(storedColumns));
  }, [hiddenColumns, pageName, userAccountID]);

  // Toggle column visibility
  const handleToggleColumn = (columnId: string) => {
    dispatch(toggleColumn({ page: pageName, columnId }));
  };

  return {
    hiddenColumns,
    handleToggleColumn,
  };
};

export default useColumnVisibility;


// import { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { RootState } from '../../store';
// import { setHiddenColumns, toggleColumn } from '../../features/columnVisibilitySlice';
// import { useAppSelector } from '../../hooks';
// import { selectAuth } from '../../features/authSlice';

// interface UseColumnVisibilityProps {
//   pageName: string;
// }

// const useColumnVisibility = ({ pageName }: UseColumnVisibilityProps) => {
//   const dispatch = useDispatch();
//   const definedColumns = useSelector((state: RootState) => state.columnVisibility.pages[pageName] || []);
//   const hiddenColumns = useSelector((state: RootState) => state.columnVisibility.pages[pageName] || []);
//   const { userAccountID } = useAppSelector(selectAuth);

//   // Load hidden columns from local storage on component mount
//   useEffect(() => {
//     const storedHiddenColumns = localStorage.getItem(`hiddenColumns_${userAccountID}`);
//     let parsedHiddenColumns = storedHiddenColumns ? JSON.parse(storedHiddenColumns) : {};
    
//     // If there are new columns that aren’t in the stored hidden columns, add them to the state
//     if (parsedHiddenColumns[pageName]) {
//       const missingColumns = definedColumns.filter(column => !parsedHiddenColumns[pageName].includes(column));
//       if (missingColumns.length > 0) {
//         parsedHiddenColumns[pageName] = [...parsedHiddenColumns[pageName], ...missingColumns];
//         dispatch(setHiddenColumns({ page: pageName, hiddenColumns: parsedHiddenColumns[pageName] }));
//         localStorage.setItem(`hiddenColumns_${userAccountID}`, JSON.stringify(parsedHiddenColumns));
//       }
//     } else {
//       // If no entry for this page in localStorage, initialize it with all columns as visible
//       parsedHiddenColumns[pageName] = definedColumns;
//       dispatch(setHiddenColumns({ page: pageName, hiddenColumns: parsedHiddenColumns[pageName] }));
//       localStorage.setItem(`hiddenColumns_${userAccountID}`, JSON.stringify(parsedHiddenColumns));
//     }
//   }, [dispatch, definedColumns, pageName, userAccountID]);

//   // Save hidden columns to local storage whenever they change
//   useEffect(() => {
//     const storedColumns = JSON.parse(localStorage.getItem(`hiddenColumns_${userAccountID}`) || '{}');
//     storedColumns[pageName] = hiddenColumns;
//     localStorage.setItem(`hiddenColumns_${userAccountID}`, JSON.stringify(storedColumns));
//   }, [hiddenColumns, pageName, userAccountID]);

//   // Toggle column visibility
//   const handleToggleColumn = (columnId: string) => {
//     dispatch(toggleColumn({ page: pageName, columnId }));
//   };

//   return {
//     hiddenColumns,
//     handleToggleColumn,
//   };
// };

// export default useColumnVisibility;
