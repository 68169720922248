import React from 'react'
import { IoIosRepeat } from 'react-icons/io'
import { Tooltip } from 'react-tooltip'

interface RefreshComponentProps {
    onClick: () => void; // Define the type for onClick
}

const RefreshComponent: React.FC<RefreshComponentProps> = ({ onClick }) => {
    return (
        <>
            <Tooltip id="refreshData" place="top" />
            <div
                data-tooltip-id="refreshData"
                data-tooltip-content='Refresh Data'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2'
                onClick={onClick}
            >
                <IoIosRepeat className='h-20px w-20px' />
            </div>
        </>
    )
}

export default RefreshComponent
