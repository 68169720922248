import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { KTSVG } from '../../../_metronic/helpers'
import { selectAuth } from '../../../features/authSlice'
import { useAppSelector } from '../../../hooks'
import { useUpdateExpenseStatusMutation } from '../../../services/ExpenseApi'
import { useExpenseStatusLookupQuery } from '../../../services/GeneralApi'
import labelKey from "../../localization/label.json"
import tosterKey from "../../localization/toster.json"
import SelectField from '../../../components/textFields/SelectField'
import { constraintConfig } from '../../../constraintConfig'
type Props = {
    show: boolean
    handleClose: () => void
    data: any
    refetch?: any
}

const validationSchema = Yup.object().shape({
    expenseStatus: Yup.object().shape({
        id: Yup.number().required('Status is required'),
    }),
});
const ExpenseStatusUpdate = ({ show, handleClose, data, refetch }: Props) => {
    const { roleID } = useAppSelector(selectAuth);
    const { data: expenseStatusLookup } = useExpenseStatusLookupQuery('')
    const [updateExpenseStatus, { data: updateStatusData, isLoading, isSuccess, isError, error }] = useUpdateExpenseStatusMutation()

    const initialValues = {
        expenseId: 0,
        expenseStatus: {
            id: 0,
            title: ''
        },
    }

    const [id, setId] = useState(0);

    useEffect(() => {
        if (data) {
            setId(data?.approverId);
            formik.setValues({ ...data })
        }
    }, [data]);
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            updateExpenseStatus(values)
            resetForm()
        },
    })

    // useEffect(() => {
    //     if (isSuccess) {
    //         toast.success(tosterKey.statusUpdatedSuccessfully);
    //         handleClose()
    //     }
    // }, [isSuccess]);

    useEffect(() => {
        if (isSuccess) {
            const responseData = (updateStatusData as any)?.message;
            const successMessage = responseData;
            toast.success(successMessage);
            handleClose();
            refetch();
        }
    }, [isSuccess, updateStatusData]);

    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{labelKey.updateApproverStatus}</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>

                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='modal-body py-lg-4 px-lg-10'>
                        <div className="row">
                            <div className='col-md-12 mb-5'>
                                <SelectField
                                    label={labelKey.approverStatus}
                                    required={true}
                                    {...formik.getFieldProps('expenseStatus.id')}
                                >
                                    <option >{labelKey.selectStatus}</option>
                                    {roleID === constraintConfig.roleID.role4 ?
                                        <>
                                            {expenseStatusLookup?.result.map((option: any, index: any) => (
                                                <option key={option.id} value={option.id}
                                                >{option?.value}</option>
                                            ))}
                                        </> :
                                        <>
                                            {expenseStatusLookup?.result.map((option: any, index: any) => {
                                                if (option.id === "1" || option.id === "2" || option.id === "3") {
                                                    return null; // Skip rendering the option for "Administrator" with id equal to 1
                                                }
                                                return <option key={index} value={option.id}>{option.value}</option>;
                                            })}
                                        </>
                                    }
                                </SelectField>
                                {formik.touched.expenseStatus?.id && formik.errors.expenseStatus?.id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.expenseStatus?.id}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center'>
                        <button
                            type='button'
                            className='btn btn-lg btn-light'
                            onClick={handleClose}
                        >
                            {labelKey.cancel}
                        </button>
                        <button
                            type='submit'
                            className='btn btn-lg btn-primary'
                        >
                            {!isLoading && <span className='indicator-label'>{labelKey.update}</span>}
                            {isLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default ExpenseStatusUpdate