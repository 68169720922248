import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import labelKey from '../../localization/label.json'
import { ProfileDetails } from '../../modules/accounts/components/settings/cards/ProfileDetails'
import AddCardModal from '../vendorAndCard/card/AddCardModal'
type Props = {
    show: boolean
    handleClose: () => void
    // setIsHomeAddressModal?: any
}
const UserAddressModal = ({ show, handleClose,
    //  setIsHomeAddressModal
}: Props) => {
    const [showAddUpdateCardModal, setShowAddUpdateCardModal] = useState(false)

    const handleUserAddressModalClose = () => {
        handleClose(); // Close the UserAddressModal
        // setShowAddUpdateCardModal(true); // Open the AddCardModal
    };
    return (
        <>
            {/* <AddCardModal show={showAddUpdateCardModal}
                handleClose={() => setShowAddUpdateCardModal(false)} /> */}

            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-lg  modal-dialog-centered'
                show={show}
                onHide={handleUserAddressModalClose}
                backdrop="static"
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{labelKey.userDetails}</h2>
                    {/* begin::Close */}
                    {/* <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleUserAddressModalClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div> */}
                    {/* end::Close */}
                </div>

                <div className='modal-body py-0'>
                    <ProfileDetails showOnlyHomeAddress={true}
                    // setIsHomeAddressModal={setIsHomeAddressModal} 
                    />
                </div>
            </Modal>
        </>
    )
}

export default UserAddressModal