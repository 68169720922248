import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { baseUrl } from '../../services/baseUrl';
import { useAppSelector } from '../../hooks';
import { selectAuth } from '../../features/authSlice';

const HardReloadModal = () => {
  const { webVersion } = useAppSelector(selectAuth);
  const [showModal, setShowModal] = useState(false);
  const [modalShowCount, setModalShowCount] = useState(0);
  const [timerVisible, setTimerVisible] = useState(false);
  const [timer, setTimer] = useState(30); // For the third modal

  // const APP_VERSION = baseUrl.APP_VERSION; // Define your app version
  const APP_VERSION = webVersion; // Define your app version

  let timerInterval: number; // To track the countdown interval

  useEffect(() => {
    // Check local storage for the last seen version
    const savedVersion = localStorage.getItem('appVersion');
    if (savedVersion !== APP_VERSION) {
      setShowModal(true); // Show the modal initially
    }
  }, [APP_VERSION]);

  useEffect(() => {
    // Clear timer on component unmount
    return () => clearInterval(timerInterval);
  }, []);

  const handleReload = () => {
    // Save version and reload
    localStorage.setItem('appVersion', APP_VERSION ?? '');
    window.location.reload();
  };

  const handleForceReload = () => {
    clearInterval(timerInterval);
    handleReload();
  };

  const startCountdown = () => {
    setTimerVisible(true);
    setTimer(30); // Initialize the timer
    timerInterval = window.setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(timerInterval); // Clear the interval when timer reaches 1
          handleForceReload(); // Force reload
        }
        return prev - 1;
      });
    }, 1000); // Decrement the timer every second
  };

  const scheduleModal = (delay: number, isCountdown: boolean = false) => {
    setTimeout(() => {
      setShowModal(true);
      if (isCountdown) {
        startCountdown();
      }
    }, delay);
  };

  const handleClose = () => {
    setShowModal(false);
    setModalShowCount((prev) => {
      const newCount = prev + 1;

      if (newCount === 1) {
        // Schedule the second modal after 1 minute
        scheduleModal(60000);
      } else if (newCount === 2) {
        // Schedule the third modal with countdown after 30 seconds
        scheduleModal(30000, true);
      }
      return newCount;
    });
  };

  return (
    <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Browser Refresh Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {timerVisible ? (
          <p>
            Your browser will automatically reload in <strong>{timer} seconds</strong>.
          </p>
        ) : (
          <p>
            The new SpendSail application version <span className='fw-bold text-primary'>{APP_VERSION}</span> is now live! Please reload your browser to access the latest updates and features. Additionally, make sure to close all other open tabs related to the SpendSail app for optimal performance.
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        {timerVisible ? (
          <Button variant="primary" onClick={handleForceReload}>
            Reload Now
          </Button>
        ) : (
          <>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="primary" onClick={handleReload}>
              Refresh
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default HardReloadModal;
