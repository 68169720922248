import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaLock } from "react-icons/fa6";
import { Tooltip } from "react-tooltip";
import "./TextField.css";

interface Props {
    label?: string;
    leftLabel?: string;
    labelClass?: string;
    fieldClass?: string;
    selected?: Date | null;
    placeholder?: string;
    onChange?: (date: Date | null, event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void;
    value?: string;
    required?: boolean;
    min?: Date; // Updated to accept Date objects
    max?: Date; // Updated to accept Date objects
    name?: string;
    disabled?: boolean;
    ref?: React.RefObject<HTMLInputElement>;
    iconTooltip?: string;
    rightLabel?: string;
    mainClass?: string;
    customLabel?: React.ReactNode;
    customLabelClick?: any;
    customLabelClass?: any;
    isClearable?: boolean;
    dateFormat?: string;
    useCurrentDate?: boolean;
}

const DateField: React.FC<Props> = ({
    label,
    leftLabel,
    rightLabel,
    labelClass,
    fieldClass,
    selected,
    placeholder,
    onChange,
    required,
    min,
    max,
    name,
    disabled,
    iconTooltip,
    mainClass,
    customLabel,
    customLabelClick,
    customLabelClass,
    isClearable = true,
    dateFormat,
    useCurrentDate
}) => {
    const currentDate = new Date();
    const dateToSelect = useCurrentDate ? currentDate : selected;

    // Validate user input format
    const handleRawChange = (event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement> | undefined) => {
        if (event && "target" in event) {
            const inputValue = (event.target as HTMLInputElement).value;
            const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(\d{0,4})$/;

            // Allow input only if it matches the format
            if (!regex.test(inputValue) && inputValue !== "") {
                event.preventDefault?.();
            }
        }
    };

    return (
        <>
            <div className={`${leftLabel || rightLabel ? 'd-flex  w-100 gap-2 align-items-center' : ''} ${mainClass}`}>
                {label || leftLabel ? (
                    <div className='d-flex justify-content-between align-items-center'>
                        <label className={`form-label fw-bold text-dark fs-6 text-nowrap ${labelClass}`}>
                            {label}{leftLabel}
                            {required && <span className="required-star text-danger ms-1">*</span>}
                            {disabled && <FaLock className='fs-7 ms-1' />}
                            {iconTooltip && (
                                <>
                                    <i className="fa fa-info-circle ms-2 cursor-pointer"
                                        data-tooltip-id={`${label}-tooltip`}
                                        data-tooltip-content={iconTooltip}></i>
                                    <Tooltip id={`${label}-tooltip`} place="top" />
                                </>
                            )}
                        </label>
                        {customLabel && (
                            <label className={`form-label fw-bold text-primary text-nowrap ${customLabelClass}`} onClick={customLabelClick}>
                                {customLabel}
                            </label>
                        )}
                    </div>
                ) : null}

                <div>
                    <DatePicker
                        calendarIconClassName="pt-5 calanderIcon"
                        showIcon
                        showFullMonthYearPicker
                        showMonthDropdown
                        showYearDropdown
                        selected={dateToSelect}
                        onChange={onChange}
                        placeholderText={placeholder || 'DD-MM-YYYY'}
                        minDate={min} // Setting the minimum date
                        maxDate={max} // Setting the maximum date
                        disabled={disabled}
                        name={name}
                        required={required}
                        isClearable={isClearable}
                        dateFormat={dateFormat}
                        className={`form-control form-control-lg form-control-solid default-input ${fieldClass}`}
                        onChangeRaw={handleRawChange}
                    />
                </div>
                {rightLabel &&
                    <label className={`form-label fw-bold text-dark fs-6 text-nowrap ${labelClass}`}>
                        {rightLabel}
                        {required && <span className="required-star text-danger ms-1">*</span>}
                    </label>
                }
            </div>
        </>
    );
};

export default DateField;
