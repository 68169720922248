import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { KTSVG } from '../../../_metronic/helpers'
import labelKey from "../../localization/label.json"
import tosterKey from "../../localization/toster.json"
import { useUpdateStatusToApproveExpenseV2Mutation } from '../../../services/WorkFlowApi'
import { constraintConfig } from '../../../constraintConfig'
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp'
import { FaEye } from 'react-icons/fa6'
import Lightbox from 'react-image-lightbox'
import DateComp from '../../../components/dateComponent/DateComp'
import { Tooltip } from 'react-tooltip'
import NoRecordFound from '../../../components/noRecord/NoRecordFound'
type Props = {
    show: boolean
    handleClose: () => void
    data: any
    selectedExpenseItemIds: any
    unSelectedExpenseItemIds: any
    selectedExpenseItemArray: any
    unSelectedExpenseItemArray: any
    openPdfInBrowserOrLightbox: any
    lightboxOpen: any
    setLightboxOpen: any
    lightboxData: any
    isAnyUnChecked?: any
    handleCloseModals?: any
    refetch?: any
    setShowInnerTable?: any
}
const ApproveAndRejectReportSummaryModal = ({ show, handleClose, data, selectedExpenseItemIds, unSelectedExpenseItemIds, selectedExpenseItemArray, unSelectedExpenseItemArray, openPdfInBrowserOrLightbox, lightboxOpen, setLightboxOpen, lightboxData, isAnyUnChecked, handleCloseModals, refetch, setShowInnerTable }: Props) => {
    // console.log('data',data);

    // console.log('selectedExpenseItemArray', selectedExpenseItemArray);
    // console.log('unSelectedExpenseItemArray', unSelectedExpenseItemArray);
    // console.log('selectedExpenseItemIds', selectedExpenseItemIds);
    // console.log('unSelectedExpenseItemIds', unSelectedExpenseItemIds);

    const [wordCount, setWordCount] = useState(0);
    const [updateAprroverStatus, { data: updateStatusData, isLoading, isSuccess, isError, error }] = useUpdateStatusToApproveExpenseV2Mutation()

    const initialValues = {
        approverId: 0,
        expenseId: 0,
        approvedExpenseItemIds: [
            0
        ],
        rejectedExpenseItemIds: [
            0
        ],
        approveComment: '',
        rejectedComment: ''
    }

    const formik = useFormik({
        initialValues,
        // validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (!formik.values.rejectedComment) {
                toast.error(tosterKey.commentIsRequiredForRejectedExpenseItems);
            } else if (values.rejectedComment.length > 500) {
                toast.error('Comment cannot exceed 500 characters.');
            } else {
                updateAprroverStatus(values);
            }
        },
    })
    // Effect to handle data when modal is opened
    useEffect(() => {
        if (data) {
            formik.setValues({
                // ...data,
                expenseId: data?.expenseId || 0,
                approverId: data?.approverId || 0,
                approvedExpenseItemIds: selectedExpenseItemIds || [],
                rejectedExpenseItemIds: unSelectedExpenseItemIds || [],
                rejectedComment: data?.commentByApprover || '',
                approveComment: data?.commentByApprover || '',
            });
        }
    }, [data]);
    const { resetForm } = formik;

    useEffect(() => {
        if (isSuccess) {
            const responseData = (updateStatusData as any)?.message;
            const successMessage = responseData;
            toast.success(successMessage);
            handleClose()
            if (handleCloseModals) {
                handleCloseModals();
            }
            if (setShowInnerTable) {
                setShowInnerTable(null)
            }
            resetForm()
            refetch()
        }
    }, [isSuccess, updateStatusData]);
    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    const handleTextAreaChange = (event: any) => {
        const text = event.target.value;
        const words = text.trim().split('');
        const wordCount = words.reduce((count: any, word: any) => count + word.length, 0);
        setWordCount(wordCount);
    };
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog custom-modal-size modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>Summary  ({data?.trackingId})</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>

                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='modal-body py-lg-4 px-lg-10'>
                        <div className="row">
                            <div className="col-md-6">
                                <h1>Approved Expense Items</h1>
                                <p className='fs-4 min-h-60px'><b>Message:</b> These items will be approved and forwarded to the next manager for further approval.</p>
                                <div className='table-responsive table-height mb-5'>
                                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 bg-light-success'>
                                        <thead>
                                            <tr>
                                                <th className='ps-4 rounded-start w-50px'>{labelKey.sr}</th>
                                                <th className='ps-4'>{labelKey.expenseType}</th>
                                                <th>{labelKey.category}</th>
                                                <th>{labelKey.currency}</th>
                                                <th className='text-end'>{labelKey.amount}</th>
                                                <th className='w-150px text-end'>{labelKey.reimbursableDistance}</th>
                                                <th>{labelKey.vendor}</th>
                                                <th>{labelKey.paymentMethod}</th>
                                                <th className='w-100px'>{labelKey.itemDate}	</th>
                                                <th>{labelKey.chargeLocation}</th>
                                                <th className='w-100px'>{labelKey.attachment}</th>
                                                <th>{labelKey.source}</th>
                                                <th className='w-200px rounded-end'>{labelKey.description}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedExpenseItemArray?.length > 0 ? (
                                                <>
                                                    {selectedExpenseItemArray?.map((itemData: any, index: any) => (
                                                        <>
                                                            <tr key={index}>
                                                                <td className=' ps-4 w-50px'>
                                                                    {index + 1}
                                                                </td>
                                                                <td className='ps-4'>{itemData?.expenseType?.title}</td>
                                                                <td className='ps-5'>{itemData?.expenseCategory?.title}</td>
                                                                <td>{itemData?.currency?.title}</td>
                                                                <td className='text-end'> <CurrencyComp amount={itemData?.amount} /></td>
                                                                <td className='text-end'>
                                                                    {itemData?.expenseCategory?.id === constraintConfig.categoriesId.mileage ?
                                                                        <>
                                                                            {itemData?.reimbursableDistance}
                                                                        </>
                                                                        : null
                                                                    }
                                                                </td>
                                                                <td>{itemData?.vendor?.name !== " " ? itemData?.vendor?.name : "N/A"}</td>
                                                                <td>{itemData?.paymentMethod?.title}</td>
                                                                <td><DateComp formattedDate={itemData.itemDate} /></td>
                                                                <td>{itemData?.expItemLocation?.title ? itemData?.expItemLocation?.title : <span>N/A</span>}</td>
                                                                <td className="text-center">
                                                                    {itemData?.attachmentFile === "undefined" || itemData?.attachmentFile === "" || itemData?.attachmentFile === "null" || itemData?.attachmentFile === null ?
                                                                        <span>N/A</span> :
                                                                        <FaEye onClick={() => openPdfInBrowserOrLightbox(itemData?.attachmentFile)} />
                                                                    }
                                                                    {lightboxOpen && (
                                                                        <Lightbox
                                                                            mainSrc={lightboxData.imageURL}
                                                                            onCloseRequest={() => setLightboxOpen(false)}
                                                                            imageCaption="Attachment"
                                                                            enableZoom={true}
                                                                            imagePadding={50}
                                                                        />
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <Tooltip id="web-source" place="top" />
                                                                    <Tooltip id="mobile-source" place="top" />
                                                                    <Tooltip id="bank-source" place="top" />
                                                                    {itemData?.recordSourceId === constraintConfig.recordSourceId.WebApp ?
                                                                        <KTSVG path="/media/icons/figmaIcons/Web.svg" className='svg-icon-1 ms-4'
                                                                            svgClassName='w-20px h-20px'
                                                                            data-tooltip-id="web-source" data-tooltip-content='Website' />
                                                                        :
                                                                        itemData?.recordSourceId === constraintConfig.recordSourceId.Mobile ?
                                                                            <KTSVG path="/media/icons/figmaIcons/Mobile.svg" className='svg-icon-1 ms-4'
                                                                                data-tooltip-id="mobile-source" data-tooltip-content='Mobile' /> :
                                                                            itemData?.recordSourceId === constraintConfig.recordSourceId.BankFeed ?
                                                                                <KTSVG path="/media/icons/figmaIcons/Bank.svg" className='svg-icon-1 ms-4'
                                                                                    data-tooltip-id="bank-source" data-tooltip-content='Bank' />
                                                                                : null}
                                                                </td>
                                                                <td>
                                                                    <div className='mh-100px overflow-auto text-wrap'>
                                                                        {itemData?.itemDetail}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {itemData.splitLineItems && itemData.splitLineItems.map((splitItem: any, splitIndex: any) => (
                                                                <>

                                                                    {splitIndex === 0 && splitItem.amount > 0 && (
                                                                        <tr className="p-2">
                                                                            <td></td>
                                                                            <td className="text-start fw-bold bg-light" colSpan={2}>{labelKey.splitLocations} ( {itemData.splitLineItems.length} )</td>
                                                                            <td className="text-end fw-bold bg-light pe-5">{labelKey.splitAmount} </td>
                                                                            {/* <td className="text-start fw-bold bg-light" colSpan={11}></td> */}
                                                                        </tr>
                                                                    )}
                                                                    {splitItem.amount > 0 &&
                                                                        <tr key={`${index}-${splitIndex}`} className="split-item-row">
                                                                            <td></td>
                                                                            <td className="text-start bg-light" colSpan={2}>
                                                                                {splitItem.expItemLocation?.title ? splitItem.expItemLocation.title : <span>N/A</span>}
                                                                            </td>
                                                                            <td className="text-end bg-light pe-5">
                                                                                <CurrencyComp amount={splitItem.amount} />
                                                                            </td>
                                                                            {/* <td className="text-end bg-light" colSpan={11}></td> */}
                                                                        </tr>
                                                                    }
                                                                </>
                                                            ))}
                                                        </>
                                                    ))}
                                                </>
                                            ) :
                                                (
                                                    <tr>
                                                        <td colSpan={22}>
                                                            <NoRecordFound />
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <label className={`d-flex align-items-center fs-5 fw-bold mb-2`}>
                                        {labelKey.approvedComment}
                                    </label>
                                    <div>
                                        <textarea className='form-control form-control-solid'
                                            rows={5}
                                            placeholder='Enter a Comment'
                                            {...formik.getFieldProps('approveComment')}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                handleTextAreaChange(e);
                                            }}
                                        ></textarea>
                                        <p className="text-muted text-end mb-0">Characters: ({wordCount}/500)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h1>Rejected Expense Items</h1>
                                <p className='fs-4 min-h-60px'><b>Message:</b>These items will be rejected and sent back to the user with a new expense report, accompanied by the Report ID: <span className='font-bold text-primary'>{data?.trackingId}</span> to indicate the rejection is linked to that specific ID.</p>

                                <div className='table-responsive table-height mb-5'>
                                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 bg-light-danger'>
                                        <thead>
                                            <tr>
                                                <th className='ps-4 rounded-start w-50px'>{labelKey.sr}</th>
                                                <th className='ps-4'>{labelKey.expenseType}</th>
                                                <th>{labelKey.category}</th>
                                                <th>{labelKey.currency}</th>
                                                <th className='text-end'>{labelKey.amount}</th>
                                                <th className='w-150px text-end'>{labelKey.reimbursableDistance}</th>
                                                <th>{labelKey.vendor}</th>
                                                <th>{labelKey.paymentMethod}</th>
                                                <th className='w-100px'>{labelKey.itemDate}	</th>
                                                <th>{labelKey.chargeLocation}</th>
                                                <th className='w-100px'>{labelKey.attachment}</th>
                                                <th>{labelKey.source}</th>
                                                <th className='w-200px rounded-end'>{labelKey.description}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {unSelectedExpenseItemArray?.length > 0 ? (
                                                <>
                                                    {unSelectedExpenseItemArray?.map((itemData: any, index: any) => (
                                                        <>
                                                            <tr key={index}>
                                                                <td className=' ps-4 w-50px'>
                                                                    {index + 1}
                                                                </td>
                                                                <td className='ps-4'>{itemData?.expenseType?.title}</td>
                                                                <td className='ps-5'>{itemData?.expenseCategory?.title}</td>
                                                                <td>{itemData?.currency?.title}</td>
                                                                <td className='text-end'> <CurrencyComp amount={itemData?.amount} /></td>
                                                                <td className='text-end'>
                                                                    {itemData?.expenseCategory?.id === constraintConfig.categoriesId.mileage ?
                                                                        <>
                                                                            {itemData?.reimbursableDistance}
                                                                        </>
                                                                        : null
                                                                    }
                                                                </td>
                                                                <td>{itemData?.vendor?.name !== " " ? itemData?.vendor?.name : "N/A"}</td>
                                                                <td>{itemData?.paymentMethod?.title}</td>
                                                                <td><DateComp formattedDate={itemData.itemDate} /></td>
                                                                <td>{itemData?.expItemLocation?.title ? itemData?.expItemLocation?.title : <span>N/A</span>}</td>
                                                                <td className="text-center">
                                                                    {itemData?.attachmentFile === "undefined" || itemData?.attachmentFile === "" || itemData?.attachmentFile === "null" || itemData?.attachmentFile === null ?
                                                                        <span>N/A</span> :
                                                                        <FaEye onClick={() => openPdfInBrowserOrLightbox(itemData?.attachmentFile)} />
                                                                    }
                                                                    {lightboxOpen && (
                                                                        <Lightbox
                                                                            mainSrc={lightboxData.imageURL}
                                                                            onCloseRequest={() => setLightboxOpen(false)}
                                                                            imageCaption="Attachment"
                                                                            enableZoom={true}
                                                                            imagePadding={50}
                                                                        />
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <Tooltip id="web-source" place="top" />
                                                                    <Tooltip id="mobile-source" place="top" />
                                                                    <Tooltip id="bank-source" place="top" />
                                                                    {itemData?.recordSourceId === constraintConfig.recordSourceId.WebApp ?
                                                                        <KTSVG path="/media/icons/figmaIcons/Web.svg" className='svg-icon-1 ms-4'
                                                                            svgClassName='w-20px h-20px'
                                                                            data-tooltip-id="web-source" data-tooltip-content='Website' />
                                                                        :
                                                                        itemData?.recordSourceId === constraintConfig.recordSourceId.Mobile ?
                                                                            <KTSVG path="/media/icons/figmaIcons/Mobile.svg" className='svg-icon-1 ms-4'
                                                                                data-tooltip-id="mobile-source" data-tooltip-content='Mobile' /> :
                                                                            itemData?.recordSourceId === constraintConfig.recordSourceId.BankFeed ?
                                                                                <KTSVG path="/media/icons/figmaIcons/Bank.svg" className='svg-icon-1 ms-4'
                                                                                    data-tooltip-id="bank-source" data-tooltip-content='Bank' />
                                                                                : null}
                                                                </td>
                                                                <td>
                                                                    <div className='mh-100px overflow-auto text-wrap'>
                                                                        {itemData?.itemDetail}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {itemData.splitLineItems && itemData.splitLineItems.map((splitItem: any, splitIndex: any) => (
                                                                <>

                                                                    {splitIndex === 0 && splitItem.amount > 0 && (
                                                                        <tr className="p-2">
                                                                            <td></td>
                                                                            <td className="text-start fw-bold bg-light" colSpan={2}>{labelKey.splitLocations} ( {itemData.splitLineItems.length} )</td>
                                                                            <td className="text-end fw-bold bg-light pe-5">{labelKey.splitAmount} </td>
                                                                            {/* <td className="text-start fw-bold bg-light" colSpan={11}></td> */}
                                                                        </tr>
                                                                    )}
                                                                    {splitItem.amount > 0 &&
                                                                        <tr key={`${index}-${splitIndex}`} className="split-item-row">
                                                                            <td></td>
                                                                            <td className="text-start bg-light" colSpan={2}>
                                                                                {splitItem.expItemLocation?.title ? splitItem.expItemLocation.title : <span>N/A</span>}
                                                                            </td>
                                                                            <td className="text-end bg-light pe-5">
                                                                                <CurrencyComp amount={splitItem.amount} />
                                                                            </td>
                                                                            {/* <td className="text-end bg-light" colSpan={11}></td> */}
                                                                        </tr>
                                                                    }
                                                                </>
                                                            ))}
                                                        </>
                                                    ))}
                                                </>
                                            ) :
                                                (
                                                    <tr>
                                                        <td colSpan={22}>
                                                            <NoRecordFound />
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <label className={`d-flex align-items-center fs-5 fw-bold mb-2 required  `}>
                                        {labelKey.rejectedComment}
                                    </label>
                                    <div>
                                        <textarea className='form-control form-control-solid'
                                            rows={5}
                                            placeholder='Enter a Comment'
                                            {...formik.getFieldProps('rejectedComment')}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                handleTextAreaChange(e);
                                            }}
                                        ></textarea>
                                        <p className="text-muted text-end mb-0">Characters: ({wordCount}/500)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button
                            type='button'
                            className='btn btn-lg btn-light'
                            onClick={handleClose}
                        >
                            {labelKey.cancel}
                        </button>
                        <button
                            type='submit'
                            className='btn btn-lg btn-primary'
                            disabled={isLoading}
                        >
                            {!isLoading && <span className='indicator-label'>{labelKey.submit}</span>}
                            {isLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default ApproveAndRejectReportSummaryModal