import { setUser } from "../features/authSlice";

export const handleSetUser = ({ dispatch, data, overrides = {} }: any) => {
    const defaultValues = {
        token: data?.result?.token,
        expiresAt: data?.result?.expiresAt,
        baseURL: data?.result?.baseURL,
        microServiceURL: data?.result?.microServiceURL,
        webVersion: data?.result?.webVersion,
        firstName: data?.result?.userAccountBasicInfo?.firstName,
        lastName: data?.result?.userAccountBasicInfo?.lastName,
        email: data?.result?.userAccountBasicInfo?.email,
        role: data?.result?.userAccountBasicInfo?.roleName,
        roleID: data?.result?.userAccountBasicInfo?.roleID,
        contactPhone: data?.result?.userAccountBasicInfo?.cellPhone,
        businessPhone: data?.result?.userAccountBasicInfo?.businessPhone,
        businessExt: data?.result?.userAccountBasicInfo?.businessExt,
        userAccountID: data?.result?.userAccountBasicInfo?.userAccountID,
        userInOrgId: data?.result?.userAccountBasicInfo?.userAccountInOrgs[0]?.userInOrgId,
        locationType: data?.result?.userAccountBasicInfo?.locationType?.id,
        expenseType: data?.result?.userAccountBasicInfo?.expenseType?.id,
        location: data?.result?.userAccountBasicInfo?.location?.id,
        locationName: data?.result?.userAccountBasicInfo?.location?.value,
        reportPermision: data?.result?.userAccountBasicInfo?.reportPermision,
        timeZone: data?.result?.userAccountBasicInfo?.userTimeZone?.title,
        isHomeAddressPopup: data?.result?.userAccountBasicInfo?.isHomeAddressPopup,
        isCreditCardInfoPopup: data?.result?.userAccountBasicInfo?.isCreditCardInfoPopup,
        userPermission: data?.result?.userAccountBasicInfo?.userPermission?.map((permission: any) => ({
            lst_PermissionId: permission.lst_PermissionId,
        })),
    };

    // Merge default values with overrides
    const finalValues = { ...defaultValues, ...overrides };

    dispatch(setUser(finalValues));
};
