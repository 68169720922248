// import React from 'react'
// import DateRangePicker from 'react-bootstrap-daterangepicker'
// import { getRanges } from '../../app/pages/reportAnalytics/ReportFilterOptions'
// import moment from 'moment-timezone'
// import labelKey from '../../app/localization/label.json';
// const DateRangeComp = ({ startDate, endDate, onApply, onCancel, value, label = "Date Range", required }: any) => {
//     return (
//         <div>
//             {label &&
//                 <label className='form-label fs-6 fw-bold text-dark'>{label}:
//                     {required && <span className="required-star text-danger ms-1">*</span>}
//                 </label>
//             }
//             <DateRangePicker
//                 key={`${startDate}-${endDate}`}
//                 initialSettings={{
//                     startDate: startDate || moment().subtract(6, 'days'), // Use default start date if not passed
//                     endDate: endDate || moment(),
//                     ranges: getRanges(),
//                     locale: {
//                         format: 'MM/DD/YYYY',
//                     },
//                     autoUpdateInput: false, // Disable auto-updating of input for custom display
//                 }}
//                 onApply={onApply} onCancel={onCancel}>
//                 <input type="text"
//                     className='form-select form-select-solid cursor-pointer default-input'
//                     value={value} />
//             </DateRangePicker>
//         </div>
//     )
// }

// export default DateRangeComp
import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { getRanges } from '../../app/pages/reportAnalytics/ReportFilterOptions';
import moment from 'moment-timezone';

const DateRangeComp = ({
    startDate,
    endDate,
    onApply,
    onCancel,
    value,
    label = 'Date Range',
    required,
}: any) => {
    return (
        <div>
            {label && (
                <label className="form-label fs-6 fw-bold text-dark">
                    {label}:
                    {required && <span className="required-star text-danger ms-1">*</span>}
                </label>
            )}
            <DateRangePicker
                key={`${startDate}-${endDate}`}
                initialSettings={{
                    startDate: startDate || moment().subtract(6, 'days'),
                    endDate: endDate || moment(),
                    ranges: getRanges(),
                    locale: {
                        format: 'MM/DD/YYYY',
                    },
                    autoUpdateInput: false,
                }}
                onApply={onApply}
                onCancel={onCancel}
            >
                <input
                    type="text"
                    className="form-select form-select-solid cursor-pointer default-input"
                    value={value}
                    readOnly // Prevent manual editing
                />
            </DateRangePicker>
        </div>
    );
};

export default DateRangeComp;
